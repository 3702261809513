import {Component, HostListener, Injector} from '@angular/core';
import {FormControl} from '@angular/forms';
import {HousingServices} from '../../housing-core/services/housing-services.service';
import {Program, ProgramModalData, CreateProgramModal, ProgramType} from '../../housing-core/services/housing-models';
import {AccountingService} from '../../accounting/service/accounting.service';
import {GlobalModalService} from 'src/app/kanso-common/core/service/globalmodal.service';
import {ProgramSetupComponent} from '../program-setup/program-setup.component';
import {map, startWith} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {KansoActionResult} from 'src/app/core/service/core-models';
import {CoreService} from 'src/app/core/service/core.service';
import lodash from 'lodash';

@Component({
  selector: 'program-setup-list',
  templateUrl: './program-setup-list.component.html',
  styleUrls: ['./program-setup-list.component.scss'],
})
export class ProgramSetupListComponent {
  programs: Program[];
  filteredPrograms$: Observable<Program[]>;
  programFilter = new FormControl('');
  programSort = new FormControl('');
  loading = true;
  newProgram: Program;
  selectedProgram = null;
  error = false;
  errorMsg = '';
  selectedprogramsIndex: number;
  selectedprogramSnapshot: Program;

  constructor(
    public housingServices: HousingServices,
    public accountingService: AccountingService,
    public globalModalService: GlobalModalService,
    protected injector: Injector,
    public coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.getPrograms();
  }

  getPrograms() {
    this.accountingService.getAllPrograms().subscribe(
      response => {
        this.programs = response.body;
        this.filteredPrograms$ = combineLatest([
          this.programFilter.valueChanges.pipe(
            startWith(null),
            map(value => value)
          ),
          this.programSort.valueChanges.pipe(
            startWith('name'),
            map(value => value)
          ),
        ]).pipe(map(([aName, aSort]) => this._filterPrograms(aSort, aName)));
        this.loading = false;
      },
      error => {
        console.log(`Error fetching accounts: ${error}`);
        this.errorMsg = 'An unexpected error occurred fetching program records. Please try again.';
        this.loading = false;
        this.error = true;
      }
    );
  }

  private _filterPrograms(sortValue: string, filterValue?: string): Program[] {
    return filterValue
      ? this._sortByProperty(
          this.programs.filter(program => program.name.toLowerCase().includes(filterValue.toLowerCase())),
          sortValue
        )
      : this._sortByProperty(this.programs, sortValue);
  }

  private _sortByProperty(accounts: Program[], propertyName: string) {
    return accounts.sort((a: Program, b: Program) => {
      const aName = a[propertyName].toLowerCase();
      const bName = b[propertyName].toLowerCase();
      switch (true) {
        case aName > bName:
          return 1;
        case aName < bName:
          return -1;
        default:
          return 0;
      }
    });
  }

  editProgram(program: Program | string, i: number) {
    this.selectedprogramsIndex = i;
    this.selectedprogramSnapshot = lodash.cloneDeep(this.programs[i]);
    const isNew = typeof program === 'string';
    this.selectedProgram = program as Program;
    if (this.selectedProgram.rentCalc) {
      this.selectedProgram.rentCalc.allowableDependentDeduction =
        this.selectedProgram.rentCalc.allowableDependentDeduction && this.selectedProgram.rentCalc.allowableDependentDeduction / 100;
      this.selectedProgram.rentCalc.allowableElderlyDisabilityDeduction =
        this.selectedProgram.rentCalc.allowableElderlyDisabilityDeduction &&
        this.selectedProgram.rentCalc.allowableElderlyDisabilityDeduction / 100;
    }

    this.globalModalService.openModal<ProgramModalData>(
      ProgramSetupComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      {selectedProgram: this.selectedProgram, editMode: !isNew, updateFunction: this.saveNewProgram}
    );
  }

  saveNewProgram = editedProgram => {
    if (editedProgram === 'cancel') {
      this.programs[this.selectedprogramsIndex] = lodash.cloneDeep(this.selectedprogramSnapshot);
    } else {
      if (editedProgram.rentCalc) {
        editedProgram.rentCalc.allowableDependentDeduction = editedProgram.rentCalc.allowableDependentDeduction * 100;
        editedProgram.rentCalc.allowableElderlyDisabilityDeduction = editedProgram.rentCalc.allowableElderlyDisabilityDeduction * 100;
      }
      if (!editedProgram._id) {
        this.accountingService.createProgram(editedProgram).subscribe(this.saveProgramHandler);
      } else {
        this.accountingService.updateProgram(editedProgram).subscribe(this.saveProgramHandler);
      }
    }
  };

  saveProgramHandler = (response?: KansoActionResult<Program>) => {
    try {
      this.getPrograms();
      console.log('Successfully create | update program!', response.body);
    } catch (e) {
      this.coreService.displayError('Error trying to save Program');
    }
  };

  newDefaultProgram() {
    const newProgram = {
      name: null,
      type: null,
      aelValue: null,
      programCode: null,
      isHousingProgram: null,
      phaCode: null,
      accountDefinitions: [],
      chartOfAccounts: null,
      isProjectBasedAccounting: true,
      customAttributes: [],
      rentCalc: null,
      housingAuthority: null,
      legacyKey: null,
      legacyHousingAuthorityKey: null,
      isRecertRequired: false,
      recertPeriod: 1,
      recertPeriodUnit: null,
      autoChargeConfigurations: [],
      requires58PicForm: false,
      enhancedRentToggle: false,
      enhancedRentTransactions: [],
    };
    return newProgram;
  }

  newDefaultRentcalc() {
    const rentCalc = {
      programType: '',
      calcKey: 'rentcalc.default',
      assistanceCalcKey: null,
      elderlyDeductionAge: 62,
      allowableElderlyDisabilityDeduction: 400,
      allowableDependentDeduction: 480,
      totalTenantPaymentPercentage: 0.3,
      lateFeeAmount: 0,
      monthOfFiscalYearEnd: 12,
      nearElderAge: 60,
      fundingSource: null,
      adminFees: null,
      flatRents: null,
      minimumCharges: null,
      maximumCharges: null,
      rentLimitArea: null,
      noteRateRents: null,
      utilityAllowances: null,
      fairMarketRents: null,
      basicRents: null,
      observeNahasda30Percent: true,
      incomeLimitArea: null,
      taxCreditIncomeLimitArea: null,
      paymentStandard: null,
      imputedAssetRate: 0.0006,
      requiresHotmaUpdates: false,
      isIncomeBased: false,
      isHardToHouse: false,
    };
    return rentCalc;
  }

  addNewProgram() {
    this.newProgram = this.newDefaultProgram();
    this.newProgram.rentCalc = this.newDefaultRentcalc();
    this.globalModalService.openModal<ProgramModalData>(
      ProgramSetupComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      {selectedProgram: this.newProgram, editMode: false, updateFunction: this.saveNewProgram}
    );
  }
}
