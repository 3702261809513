<mat-dialog-content>
  <div class="flexRowError">
    <h4 class="errorMessage">
      {{ this.errorMessage }}
    </h4>
    <h5 class="errorMessage">
      Please try again
    </h5>
    <button mat-button (click)="btnClick()" class="dismissButton ">
      Dismiss
    </button>
  </div>
</mat-dialog-content>
