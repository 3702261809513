export class Voucher {
  id: string;
  customerId: string;
  siteId: string;
  householdId: string;
  fundingProgramId: string;
  fundingProjectId: string;
  voucherType: VoucherType;
  specialType: string;
  voucherNumber: string;
  authorizedBedrooms: string;
  state: VoucherState;
  stateDisplay: string;
  issuedOn: string;
  leasedOn: string;
  expiresOn: string;
  tenancyRequests: VoucherRFTA[];
  voucherStateChangeEvents: string;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
  archivedOn: string;
  archivedBy: string;
  canMoveIn: string;
  canMoveOut: string;
  canTerminate: string;
  canTerminateWithoutLease: string;
  issuedDays: number;
  unit: VoucherUnit;
  unitId: string;
}

export class VoucherProgram {
  Id: string;
  Name: string;
  CustomerId: string;
  SiteId: string;
  ProgramType: string;
  ProgramCode: string;
  IsHousingProgram: string;
  ChartOfAccountsId: string;
  HousingAuthorityId: string;
  IsRecertificationRequired: string;
  RecertificationPeriod: string;
  RecertPeriodUnit: string;
  CreatedOn: string;
  CreatedBy: string;
}

export class VoucherProject {
  Id: string;
  CustomerId: string;
  SiteId: string;
  Name: string;
  ProjectNumber: string;
  ProjectCode: string;
  ProgramId: string;
  DateOfFullAvailability: string;
  ProjectSegmentCode: string;
  CreatedOn: string;
  CreatedBy: string;
}

export class VoucherHistory {
  position: string;
  unit: string;
  landlord: string;
  startdate: string;
  stopdate: string;
  result: string;
  date: string;
  reason: string;
}

export enum VoucherState {
  'Terminated' = 0,
  'Shop Mode' = 1,
  'Issued' = 2,
  'Port Out' = 3,
}

export enum VoucherType {
  'Tenant Based' = 0,
  'Project Based' = 1,
  'Homeownership' = 2,
}

export enum VoucherSpecialType {
  'RAD',
  'FUPF',
  'MS1',
  'MS5',
  'VASH',
  'NED',
  'FUPY',
}

export class VoucherRFTA {
  id: string;
  customerId?: string;
  siteId?: string;
  voucherId: string;
  unitId?: string;
  voucher?: Voucher;
  landlordId?: string;
  requestStartOn: string;
  requestStopOn?: string;
  result?: string;
  resultComments?: string;
  finalizedOn?: string;
  createdOn: string;
  createdBy?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  archivedOn?: string;
  archivedBy?: string;
}

export class VoucherUnit {
  id: string;
  programId: string;
  projectId: string;
  ppuCode: string;
  streetAddress: string;
}
