<mat-card class="knso-card utility-overrides-container">
    <mat-card-header>
        <mat-toolbar>
            <span>Utility Allowance</span>
        <button mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
    </mat-card-header> 
    <mat-card-content>
        <div class="loading-spinner" *ngIf="isBusy">
            <mat-spinner diameter="50" class="centered_spinner"> </mat-spinner> Loading
        </div>
        <div class="utility-overrides-content" *ngIf="!isBusy">
            <div class="utility-info">
                <div>
                    <h6>Bedrooms</h6>
                    <p>{{ unitBedroomSize }}</p>
                </div>
                <div>
                    <h6>Unit Type</h6>
                    <p>{{utilityAllowance.name}}</p>
                </div>
                <div class="unit-type-effective-date">
                    <mat-form-field class="unit-type-date-selection" appearance="outline">
                        <mat-label>Effective Date for Rates</mat-label>
                        <mat-select
                            [(ngModel)]="selectedUtilityAllowancesTable" 
                            ngModel
                            #effectiveDate="ngModel"
                            (selectionChange)="onEffectiveDateChange($event)"
                            name="effectiveDate">
                            <mat-option 
                                *ngFor="let utilityAllowancesTable of utilityAllowance.utilityAllowanceTable"
                                [value]="utilityAllowancesTable">
                            <span>{{ utilityAllowancesTable.effectiveDate | date}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="overrides-info">
                <table mat-table [dataSource]="selectedUtilityAllowancesTable.utilityAllowanceTableValues" class="overrides-table">
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>Select</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox 
                                [checked]="element.selected" 
                                (change)="selectOverrideValue(element)"
                            > </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef>Category</th>
                        <td mat-cell *matCellDef="let element">
                            {{element.category}}</td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef>Description</th>
                        <td mat-cell *matCellDef="let element">
                            {{element.description}}</td>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef>amount</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element['bedroom' + unitBedroomSize] | currency }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-row"></tr>
                </table>
            </div>
            <div class="ovverides-sum">
                <h6>Total: </h6>
                <div>{{ overrideSum | currency }}</div>
            </div>
        </div>
    </mat-card-content> 
    <mat-card-actions *ngIf="!isBusy">
        <button mat-flat-button class="hds_button cancel" (click)="closeDialog()">Undo</button>
        <button mat-flat-button class="hds_button save" (click)="save()">Confirm</button>
    </mat-card-actions>
</mat-card>