<mat-card class="knso-card posted-on-card">
    <mat-card-header>
        <div class="title">
            <p>General Transaction Information</p>
            <button mat-icon-button (click)="onClosePostedOn()"><mat-icon>close</mat-icon></button>
        </div>
    </mat-card-header>
    <mat-card-content>
       <form [formGroup]="form">
        <div class="posted-on-input">
            <p>
                Posted  On Date
            </p>
            <mat-form-field class="posted-on-input-area posted-on-date-picker">
                <input matInput [matDatepicker]="dateTimePicker" formControlName="postedOnDate" (keypress)="keyDown($event)" required/>
                <mat-datepicker-toggle matSuffix [for]="dateTimePicker"></mat-datepicker-toggle>
                <mat-datepicker #dateTimePicker></mat-datepicker>
                <mat-hint *ngIf="form.value.postedOnDate === null">Please select a posted on date!</mat-hint>
            </mat-form-field>
        </div>
        <div class="posted-on-input">
            <p>
                Default Description
            </p>
            <mat-form-field class="posted-on-input-area">
                <textarea matInput formControlName="postedOnDescription" rows="4"></textarea>
            </mat-form-field>
        </div>
       </form>
    </mat-card-content>
    <mat-card-actions>
        <button mat-flat-button class="hds_button cancel" (click)="onClosePostedOn()">Cancel</button>
        <button mat-flat-button class="hds_button" (click)="onSubmitPostedOn()" [disabled]="form.value.postedOnDate === null">Submit</button>
    </mat-card-actions>
</mat-card>