<section class="tabs__panel layout-column" >
    <div class="page-header">
        <h2>Programs</h2>
    </div>
    <div *ngIf="error" class="layout-row">
        {{errorMsg}}
    </div>
    <div *ngIf="this.loading">
        <mat-spinner diameter="25" class="centered_spinner"> </mat-spinner> Loading Programs
    </div>
    <div *ngIf="!this.loading && !error">
        <section>
            <div class="tab-header">
                <mat-form-field appearance="outline">
                    <mat-label>Sort By</mat-label>
                    <mat-select [formControl]="programSort" >
                        <mat-option value="type">Type</mat-option>
                        <mat-option value="name">Name</mat-option>
                        <mat-option value="programCode">Program Code</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <input 
                        matInput
                        [formControl]="programFilter"
                        placeholder="search by name..."/>
                </mat-form-field>
                <div class="top-buttons">
                    <button
                        mat-flat-button
                        class="hds_button" 
                        style="margin: 20px 0;"
                        (click)="addNewProgram()">
                        Add New
                    </button>
                </div>    
            </div>
        </section>
        <p *ngIf="!programs || !programs.length">There are no Programs created yet</p>
        <section>
            <div *ngIf="this.loading">
                <mat-spinner diameter="25" class="centered_spinner"> </mat-spinner> Loading account data
            </div>
            <div *ngIf="!this.loading">
                <p *ngIf="(!programs || !programs.length) && !error ">There are no Programs configured</p>
                <p *ngIf="!programs || !programs.length && error ">{{errorMsg}}</p>
                <table class="kanso-table altRows" *ngIf="programs && programs.length"  layout="row" layout-align="start center">
                    <thead>
                        <tr class="centerData">
                            <th>Type</th>
                            <th>Name</th>
                            <th>Program Code</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tr class="centerData" *ngFor="let program of filteredPrograms$|async; let i = index"> 
                        <td><a (click)="editProgram(program, i)">{{program.type}}</a></td>
                        <td><a (click)="editProgram(program, i)">{{program.name}}</a></td>
                        <td *ngIf="program.programCode">{{program.programCode}}</td>
                        <td *ngIf="!program.programCode">--</td>
                    </tr>
                </table>
            </div>
        </section>
    </div>
</section>