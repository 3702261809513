import {Component, Input, ViewChild} from '@angular/core';
import {GlobalModalChild, GlobalModalParent} from '../../../../kanso-common/core/service/globalmodal.service';
import {
  RegionalRentModalData,
  RentLimitArea,
  RentLimitAreaModal,
  RentLimitModal,
  RentValueModal,
} from '../../../housing-core/services/housing-models';
import moment from 'moment';
import lodash from 'lodash';

@Component({
  selector: 'app-edit-regional-rent-limit',
  templateUrl: './edit-regional-rent-limit.component.html',
  styleUrls: ['./edit-regional-rent-limit.component.scss'],
})
export class EditRegionalRentLimitComponent implements GlobalModalChild<RegionalRentModalData>, RegionalRentModalData {
  //eslint-disable-next-line
  @Input() updateFunction: Function;
  @Input() name: string;
  @Input() index: number;
  @Input() editMode: boolean;
  _rentLimitArea: RentLimitArea;
  createMode: boolean;
  @Input() set rentLimitArea(setArea: RentLimitArea) {
    this._rentLimitArea = setArea;
    if (!setArea) {
      this.createMode = true;
      this.editArea = new RentLimitAreaModal();
      this.editEffectiveDate = true;
    } else {
      this.createMode = false;
      this.editArea = lodash.cloneDeep(this._rentLimitArea);
      this.selectedEffectiveDate = setArea.rentLimitTables[0].effectiveDate;
      for (const table of this.editArea.rentLimitTables) {
        for (const level of table.rentLimits) {
          for (const value of level.rentValues) {
            if (value.amount) {
              value.amount = value.amount / 100;
            } else {
              value.amount = 0;
            }
          }
        }
      }
    }
    this.loading = false;
  }
  get rentLimitArea(): RentLimitArea {
    return this._rentLimitArea;
  }

  editArea: RentLimitArea;
  globalModalParent: GlobalModalParent;
  selectedIndex = 0;
  selectedEffectiveDate;
  editEffectiveDate = false;
  loading = true;

  enableEditMode() {
    this.editMode = true;
    this.createMode = true;
  }

  addTableRow() {
    const rentLimits = new RentLimitModal('', 0, [
      new RentValueModal(0, 0),
      new RentValueModal(1, 0),
      new RentValueModal(2, 0),
      new RentValueModal(3, 0),
      new RentValueModal(4, 0),
      new RentValueModal(5, 0),
      new RentValueModal(6, 0),
      new RentValueModal(7, 0),
      new RentValueModal(8, 0),
    ]);
    this.editArea.rentLimitTables[this.selectedIndex].rentLimits.push(rentLimits);
  }

  deleteTableRow(i) {
    this.editArea.rentLimitTables[this.selectedIndex].rentLimits.splice(i, 1);
  }

  effectiveDateChanged() {
    this.selectedIndex = this.editArea.rentLimitTables.findIndex(t => t.effectiveDate == this.selectedEffectiveDate);
  }
  addNewEffectiveDate() {
    this.editEffectiveDate = true;
    //Adding Default Values
    const defaultRentLimit = new RentLimitAreaModal();
    this.editArea.rentLimitTables.push(defaultRentLimit.rentLimitTables[0]);
    this.selectedIndex = this.editArea.rentLimitTables.length - 1;
  }

  saveNewEffectiveDate() {
    this.editEffectiveDate = false;
    this.selectedIndex = 0;
  }

  cancelNewEffectiveDate() {
    this.editArea.rentLimitTables.splice(this.editArea.rentLimitTables.length - 1, 1);
    this.selectedIndex = this.editArea.rentLimitTables.length - 1;
    this.editEffectiveDate = false;
  }

  compareEffectiveDate(o1, o2) {
    if (o1 == o2) {
      return true;
    } else {
      return false;
    }
  }

  cancel() {
    this.globalModalParent.closePopup();
  }

  onSubmit() {
    for (const table of this.editArea.rentLimitTables) {
      table.effectiveDate = moment(table.effectiveDate).format('YYYY/MM/DD');
      for (const level of table.rentLimits) {
        for (const value of level.rentValues) {
          if (value.amount) {
            value.amount = value.amount * 100;
          } else {
            value.amount = 0;
          }
        }
      }
    }
    this.editMode = false;
    if (this.editEffectiveDate) {
      this.saveNewEffectiveDate();
    }
    if (!this._rentLimitArea) {
      this.rentLimitArea = Object.assign({}, this.editArea);
    } else {
      this.rentLimitArea = Object.assign(this._rentLimitArea, this.editArea);
    }
    this.updateFunction(this.rentLimitArea, this.index);
    this.effectiveDateChanged();
  }
}
