import {Component, OnInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';

import PSPDFKit from 'pspdfkit';
import {Instance} from 'pspdfkit';

import {IFormObject} from './PSPDFKitInterfaces';
import {FormEntryService, ValidationError} from './form-entry.service';
import {CountdownPopupComponent} from './countdown-popup/countdown-popup.component';
import {TimeExtensionPopupComponent} from './time-extension-popup/time-extension-popup.component';
import {FailureLoadFormPopupComponent} from './failure-load-form-popup/failure-load-form-popup.component';
import {LoggingService} from '../global-services/logging.service';

@Component({
  selector: 'app-form-entry',
  templateUrl: './form-entry.component.html',
  styleUrls: ['./form-entry.component.scss'],
})
export class FormEntryComponent implements OnInit, OnDestroy {
  @Output() emitDocumentId = new EventEmitter<{documentId: string; templateId: string}>();
  @Output() emit58ValidationErrorsDisableNext = new EventEmitter<boolean>();

  @Input() actionRequest: any;

  @Input() set formIdToDisplay(value: {unit: string; household: string; action: string; templateId: string}) {
    if (value && typeof value === 'object') {
      this.currentDocumentTemplateId = value.templateId;
      this.unitId = value.unit;
      this.action = value.action;
      this.householdId = value.household;
      this.loadPDF(null);
    }
  }

  @Input() set recentlyCreatedFormToDisplay(value: {documentId: string; templateId: string}) {
    if (value) {
      this.loadPDF(value.documentId);
    }
  }

  _previouslyCreatedDocument: {documentId: string; templateId: string};
  _formIdToDisplay: string;
  isLoading = false;
  currentDocumentTemplateId: string;
  householdId: string;
  action: string;
  unitId: string;
  firstDocument = true;
  // pspdf kit specific
  PSPDFKitInstance: Instance;
  jwt: string = null;
  documentId: string = null;
  errorLoadingDocument = false;
  toolbarItems: any;
  currentPopup: MatSnackBarRef<CountdownPopupComponent>;
  MinsLeft15 = true;
  MinsLeft10 = false;
  MinsLeft5AndBelow = false;
  UTCDateTimeJTCExpire: string;
  FifteenMinutes = 900_000;
  TenMinutes = 600_000;
  FiveMinutes = 300_000;
  validationErrors: ValidationError[];
  allow50058Revalidate = false;
  revalidateMessage = false;
  temp: any;

  constructor(
    private popup: MatSnackBar,
    private formEntryService: FormEntryService,
    public dialog: MatDialog,
    private loggingService: LoggingService
  ) {}

  async ngOnInit(): Promise<void> {
    setInterval(() => {
      this.createExpirationPopup();
      this.toolbarItems = this.createToolbarItemsRequest();
    }, 1000);
  }

  async loadPDF(documentId: string): Promise<void> {
    this.isLoading = true;
    this.validationErrors = [];
    this.allow50058Revalidate = false;
    this.revalidateMessage = false;
    //if we have a document id for this form, load from documentId rather than creating new doc
    if (documentId) {
      console.log('loading previously created document');
      this.documentId = documentId;

      //refresh jwt for document
      this.temp = await this.formEntryService.generateNewJWT(documentId);
      this.jwt = this.temp.jwt;
      this.UTCDateTimeJTCExpire = this.temp.jwtUTCExpiration;
      if (this.temp && this.temp.validationErrors) {
        this.validationErrorEmitter(this.temp);
      }
    } else {
      console.log('loading document from prefill api');
      this.temp = await this.getPrefilledPdfDocument();
      this.documentId = this.temp.documentId;
      this.jwt = this.temp.jwt;
      this.UTCDateTimeJTCExpire = this.temp.jwtUTCExpiration;
      if (this.temp && this.temp.validationErrors) {
        console.log('FROM getPrefilledPdfDocument: \n\n', this.temp.validationErrors, JSON.parse(this.temp.validationErrors));
        this.validationErrors = JSON.parse(this.temp.validationErrors);
        this.validationErrorEmitter(this.temp);
      }
    }

    await this.loadPdfDocument(this.toolbarItems, this.documentId, this.jwt);
    console.log('PSPDFKit for Web loaded!');
    this.isLoading = false;
  }

  validationErrorEmitter(temp): void {
    this.allow50058Revalidate = true;
    if (temp.validationErrors != '[]') {
      this.revalidateMessage = true;
      this.emit58ValidationErrorsDisableNext.emit(true);
    } else {
      this.revalidateMessage = false;
      this.emit58ValidationErrorsDisableNext.emit(false);
    }
  }

  async revalidate50058Form() {
    this.isLoading = true;
    let revalidatedData: any;

    try {
      revalidatedData = await this.formEntryService.revalidate50058Form(this.documentId);
      this.validationErrors = revalidatedData;
      this.temp.validationErrors = JSON.stringify(revalidatedData);
      this.isLoading = false;
      this.validationErrorEmitter(this.temp);
    } catch (error) {
      console.log('revalidate 50058 form failed: ' + JSON.stringify(error));
      throw error;
    }
  }

  private async getPrefilledPdfDocument() {
    let temp: any;
    console.log('getting document prefill for template id: ' + this.currentDocumentTemplateId);

    try {
      temp = await this.formEntryService.createAndGenerateForm(
        this.currentDocumentTemplateId,
        this.householdId,
        this.unitId,
        this.action,
        this.actionRequest
      );
    } catch (error) {
      const {userMessage} = error;
      console.log('pspdfkit broke during prefill: ' + JSON.stringify(error));

      this.dialog.open(FailureLoadFormPopupComponent, {
        width: '25%',
        data: {message: userMessage}, // technical error comes in as 'error'
        disableClose: false,
      });
      this.errorLoadingDocument = true;
      throw error;
    }

    this.emitNewDocumentId(temp.documentId);
    return temp;
  }

  ngOnDestroy(): void {
    this.unloadPspdfkit();
    if (this.currentPopup) {
      this.currentPopup.dismiss();
    }
  }

  emitNewDocumentId(documentId: string): void {
    this.emitDocumentId.emit({documentId, templateId: this.currentDocumentTemplateId});
  }

  openPopup(): void {
    if (this.MinsLeft5AndBelow) {
      this.currentPopup = this.popup.openFromComponent(CountdownPopupComponent, {
        duration: 1000 * 60 * 5 + 30 * 1000,
        verticalPosition: 'top',
        panelClass: ['mat-toolbar', 'mat-warn'],
      });

      this.timeExtensionPopup();
    } else if (this.MinsLeft10) {
      this.currentPopup = this.popup.openFromComponent(CountdownPopupComponent, {
        duration: 1000 * 60,
        verticalPosition: 'top',
        panelClass: ['mat-toolbar', 'mat-warn'],
      });

      this.currentPopup.instance.minsToDisplay = '10';
      this.currentPopup.instance.secondsToDisplay = '00';
    } else if (this.MinsLeft15) {
      this.currentPopup = this.popup.openFromComponent(CountdownPopupComponent, {
        duration: 1000 * 60,
        verticalPosition: 'top',
        panelClass: ['mat-toolbar', 'mat-warn'],
      });

      this.currentPopup.instance.minsToDisplay = '15';
      this.currentPopup.instance.secondsToDisplay = '00';
    }
  }

  timeExtensionPopup(): void {
    const dialogRef = this.dialog.open(TimeExtensionPopupComponent, {disableClose: true, autoFocus: false});

    dialogRef.afterClosed().subscribe(async result => {
      if (result.event === 'generateNewJWT') {
        const data = await this.formEntryService.generateNewJWT(this.documentId);
        this.jwt = data.jwt;
        this.UTCDateTimeJTCExpire = data.jwtUTCExpiration;

        await this.loadPdfDocument(this.toolbarItems, this.documentId, this.jwt);
        console.log('PSPDFKit for Web was refreshed!');
      }
    });
  }

  private createExpirationPopup() {
    if (this.currentPopup && this.MinsLeft5AndBelow) {
      this.currentPopup.instance.updateCountDown();
    }

    if (Math.abs(new Date(this.UTCDateTimeJTCExpire).getTime() - new Date(new Date().toUTCString()).getTime()) === this.FifteenMinutes) {
      this.MinsLeft15 = true;
      this.MinsLeft10 = false;
      this.MinsLeft5AndBelow = false;

      this.openPopup();
    } else if (Math.abs(new Date(this.UTCDateTimeJTCExpire).getTime() - new Date(new Date().toUTCString()).getTime()) === this.TenMinutes) {
      this.MinsLeft15 = false;
      this.MinsLeft10 = true;
      this.MinsLeft5AndBelow = false;

      this.openPopup();
    } else if (
      Math.abs(new Date(this.UTCDateTimeJTCExpire).getTime() - new Date(new Date().toUTCString()).getTime()) === this.FiveMinutes
    ) {
      this.MinsLeft15 = false;
      this.MinsLeft10 = false;
      this.MinsLeft5AndBelow = true;

      this.openPopup();
    }
  }

  private unloadPspdfkit() {
    if (this.PSPDFKitInstance) {
      PSPDFKit.unload(this.PSPDFKitInstance);
    }
    PSPDFKit.unload('#pdfkit');
  }

  private async loadPdfDocument(toolbarItems, documentId, jwt) {
    this.unloadPspdfkit();
    //load up the new doc
    PSPDFKit.load({
      baseUrl: location.protocol + '//' + location.host + '/assets/',
      serverUrl: await this.formEntryService.pspdfkitConnectionURL(),
      container: '#pdfkit',
      documentId: documentId,
      authPayload: {
        jwt: jwt,
      },
      instant: true,
      toolbarItems,
    })
      .then((instance: Instance) => {
        this.PSPDFKitInstance = instance;
      })
      .catch(error => {
        console.log('pspdfkit broke: ' + error);
        this.loggingService.error(
          'Something failed in loading up PSPDFKit client side',
          'File: form-entry.component.ts Function: loadPdfDocument',
          error
        );
      });
  }

  private createToolbarItemsRequest() {
    const desiredToolbarItems = ['pager', 'pan', 'zoom-out', 'zoom-in', 'search', 'spacer', 'print'];
    let toolbarItems = PSPDFKit.defaultToolbarItems.filter(toolbarItem => {
      return desiredToolbarItems.includes(toolbarItem.type);
    });

    const tempToolbarItems = [];
    for (let i = 0; i < desiredToolbarItems.length; i++) {
      const currentItem = desiredToolbarItems[i];

      tempToolbarItems.push(
        toolbarItems.filter(item => {
          return item.type === currentItem;
        })[0]
      );

      if (i === desiredToolbarItems.length - 1) {
        toolbarItems = tempToolbarItems;
      }
    }
    return toolbarItems;
  }
}
