import {Component, Input} from '@angular/core';
import {GlobalModalChild, GlobalModalParent} from '../../../../kanso-common/core/service/globalmodal.service';
import {Vendor, VendorNotesModalData} from 'src/app/custom/housing-core/services/housing-models';
import {Note} from 'src/app/core/service/core-models';

@Component({
  selector: 'app-vendor-notes-create',
  templateUrl: './vendor-notes-create.component.html',
  styleUrls: ['./vendor-notes-create.component.scss'],
})
export class VendorNotesCreateComponent implements GlobalModalChild<VendorNotesModalData>, VendorNotesModalData {
  // eslint-disable-next-line
  @Input() updateFunction: Function;
  globalModalParent: GlobalModalParent;
  _selectedNote: Note;
  editNote: Note;
  noteCategories: string[] = ['Address Change', 'Alert', 'Correspondence', 'Other', 'Ownership Change', 'Payables', 'Phone Call'];
  @Input() set selectedNote(selectedNote: Note) {
    this._selectedNote = selectedNote;
    this.editNote = Object.assign({}, selectedNote);
  }

  // eslint-disable-next-line
  constructor() {}

  closeDialog() {
    this.globalModalParent.closePopup();
  }

  saveVendorNote() {
    this.updateFunction(this.editNote);
    this.closeDialog();
  }
}
