import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FormEntryComponent} from './form-entry.component';
import {TimeExtensionPopupComponent} from './time-extension-popup/time-extension-popup.component';
import {CountdownPopupComponent} from './countdown-popup/countdown-popup.component';
import {MaterialModule} from 'projects/material/src/public_api';
import {FailureLoadFormPopupComponent} from './failure-load-form-popup/failure-load-form-popup.component';

@NgModule({
  declarations: [FormEntryComponent, CountdownPopupComponent, TimeExtensionPopupComponent, FailureLoadFormPopupComponent],
  imports: [CommonModule, MaterialModule],
  exports: [FormEntryComponent],
  entryComponents: [FormEntryComponent, CountdownPopupComponent, TimeExtensionPopupComponent],
})
export class FormEntryModule {}
