import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {GlobalModalChild, GlobalModalParent} from 'src/app/kanso-common/core/service';
import {UtilityActionModal, UtilityActionOnChangeEvent, UtilityActionType} from '../../services/setup-utility-model';
import {
  CreateUtilityAllowanceCommandInput,
  UpdateUtilityAllowanceCommandInput,
  CloneUtilityAllowanceCommandInput,
  DeleteUtilityAllowanceCommandInput,
  CreateUtilityAllowanceTableCommandInput,
  CreateUtilityAllowanceTableValuesCommandInput,
  UpdateUtilityAllowanceTableValuesCommandInput,
  DeleteUtilityAllowanceTableValueCommandInput,
} from '../../services/setup-utility-object';
import {NgForm} from '@angular/forms';
import moment from 'moment';
@Component({
  selector: 'app-utility-action-popup',
  templateUrl: './utility-action-popup.component.html',
  styleUrls: ['./utility-action-popup.component.scss'],
})
export class UtilityActionPopupComponent implements OnInit, GlobalModalChild<UtilityActionModal>, UtilityActionModal {
  @ViewChild('utilityActionForm') utilityActionForm: NgForm;
  @Input() header: string;
  @Input() actionType: number;
  @Input() inputCommandObject:
    | CreateUtilityAllowanceCommandInput
    | UpdateUtilityAllowanceCommandInput
    | DeleteUtilityAllowanceCommandInput
    | CloneUtilityAllowanceCommandInput
    | CreateUtilityAllowanceTableCommandInput
    | CreateUtilityAllowanceTableValuesCommandInput
    | UpdateUtilityAllowanceTableValuesCommandInput
    | DeleteUtilityAllowanceTableValueCommandInput;
  // eslint-disable-next-line
  @Input() updateFunction: Function;
  globalModalParent: GlobalModalParent;
  effectiveDateList?: Date[];
  combinationList?: string[];
  initialObject?: any;
  utilityActiontype = UtilityActionType;
  utilityActionOnChangeEvent? = UtilityActionOnChangeEvent;
  combinationDuplicated = false;
  effectiveDateExpectedDisplay?: string;
  // eslint-disable-next-line
  constructor() {}

  ngOnInit(): void {
    if (this.actionType == UtilityActionType.addEffectiveDate) {
      const inputObject = this.inputCommandObject as CreateUtilityAllowanceTableCommandInput;
      this.effectiveDateRangeModifier(inputObject.effectiveDate);
    }
  }

  closeDialog() {
    this.globalModalParent.closePopup();
  }

  save() {
    this.updateFunction(this.inputCommandObject);
    this.closeDialog();
  }

  utilityInputChange(actionType: number, value: any, fieldName: string, itemList?: any[]) {
    switch (actionType) {
      case UtilityActionOnChangeEvent.textOnChange:
        this.combinationDuplicated = false;
        if (itemList.length > 0) {
          for (const item of itemList) {
            switch (this.actionType) {
              case UtilityActionType.addUtilityItem: {
                let combinationString;
                if (fieldName == 'utilityCategory') {
                  const descriptionString = this.utilityActionForm.form.value.utilityDescription
                    ? this.utilityActionForm.form.value.utilityDescription.toLowerCase().trim()
                    : '';
                  combinationString = `${value.toLowerCase().trim()}${descriptionString}`;
                }
                if (fieldName == 'utilityDescription') {
                  const categoryString = this.utilityActionForm.form.value.utilityCategory
                    ? this.utilityActionForm.form.value.utilityCategory.toLowerCase().trim()
                    : '';
                  combinationString = `${categoryString}${value.toLowerCase().trim()}`;
                }
                if (item.toLowerCase().trim() == combinationString) {
                  this.combinationDuplicated = true;
                }
                break;
              }
              case UtilityActionType.editUtilityItem: {
                const existingString = `${this.initialObject.category.toLowerCase()}${
                  this.initialObject.description ? this.initialObject.description.toLowerCase() : ''
                }`;
                let editedCombinationString;
                if (fieldName == 'utilityCategory') {
                  const descriptionString = this.utilityActionForm.form.value.utilityDescription
                    ? this.utilityActionForm.form.value.utilityDescription.toLowerCase().trim()
                    : '';
                  editedCombinationString = `${value.toLowerCase().trim()}${descriptionString}`;
                }
                if (fieldName == 'utilityDescription') {
                  const categoryString = this.utilityActionForm.form.value.utilityCategory
                    ? this.utilityActionForm.form.value.utilityCategory.toLowerCase().trim()
                    : '';
                  editedCombinationString = `${categoryString}${value.toLowerCase().trim()}`;
                }
                if (item.toLocaleLowerCase().trim() == editedCombinationString && existingString.trim() != editedCombinationString) {
                  this.combinationDuplicated = true;
                }
                break;
              }
            }
          }
        }
        break;
      case UtilityActionOnChangeEvent.numberOnChange:
        if (value.length == 0 || value < 0) {
          this.utilityActionForm.controls[fieldName].setErrors({smallerThanZero: true});
        }
        break;
    }
  }

  effectiveDateRangeModifier(value: Date) {
    if (this.actionType == UtilityActionType.addEffectiveDate) {
      const sortedEffectiveDateList = [...this.effectiveDateList];
      let index = 0;
      for (const datetime of sortedEffectiveDateList) {
        if (moment(datetime).isSameOrBefore(value)) {
          break;
        }
        index++;
      }
      sortedEffectiveDateList.splice(index, 0, new Date(value));
      if (index == sortedEffectiveDateList.length - 1) {
        this.effectiveDateExpectedDisplay = `${moment(sortedEffectiveDateList[index]).format('MM/DD/YYYY')} and ${moment(
          sortedEffectiveDateList[index - 1]
        ).format('MM/DD/YYYY')}`;
      } else {
        this.effectiveDateExpectedDisplay = `${moment(sortedEffectiveDateList[index + 1]).format('MM/DD/YYYY')} and ${moment(
          sortedEffectiveDateList[index]
        ).format('MM/DD/YYYY')}`;
      }
    }
  }
}
