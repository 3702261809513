import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

export interface ValidationError {
  AttemptedValue: any;
  CustomState: any;
  ErrorCode: string;
  ErrorMessage: string;
  FormattedMessagePlaceholderValues: any;
  PropertyName: string;
  Severity: string;
}

export interface FormToken {
  jwt: string;
  documentId: string;
  jwtUTCExpiration: string;
  validationErrors: string;
}

export interface PHRentcalcResponse {
  publicHousingTenantRent: string;
  publicHousingTenantCredit: string;
  publicHousingMixedFamilyTenantRent: string;
  publicHousingMixedFamilyTenantCredit: string;
  publicHousingIncomeBasedRent: number;
}

const ReexaminationListData: ReexaminationDetails[] = [
  {value: 1, description: '01 = Alternative rent hardship request (MTWEX only)'},
  {value: 2, description: '02 = Stepped rent update without income reexamination (MTWEX only)'},
  {value: 4, description: '04 = Increased income'},
  {value: 5, description: '05 = Household composition change'},
  {value: 6, description: '06 = Contract rent change'},
  {value: 7, description: '07 = Decreased income'},
  {value: 8, description: '08 = Public housing over-income status'},
];
export class Reexamination {
  readonly reasonList: ReexaminationDetails[];
  constructor() {
    this.reasonList = ReexaminationListData;
  }
}

class ReexaminationDetails {
  value: number;
  description: string;
}

export enum PublicHousingActionEvent {
  checkValidButtonEvent = 0,
  fisrtTimeLoadingEvent,
}


export interface Revalidate58Response {
  errors: string;
}

@Injectable({
  providedIn: 'root',
})
export class FormEntryService {
  constructor(private http: HttpClient) {}

  async pspdfkitConnectionURL(): Promise<string> {
    try {
      return (await this.http.get<{pspdfkitURL: string}>('/api/fileroom/pspdfkit').toPromise()).pspdfkitURL;
    } catch (error) {
      error.userMessage = 'There was an error in retrieving the connection URL to the PDF editor engine.';
      throw error;
    }
  }

  async createAndGenerateForm(
    templateId: string,
    householdId: string,
    unitId: string,
    action: string,
    actionRequest: Record<string, unknown>
  ): Promise<FormToken> {
    try {
      return await this.http
        .post<FormToken>(`/api/fileroom/createandgenerateform`, {
          templateId,
          householdId,
          unitId,
          action,
          actionRequest,
        })
        .toPromise();
    } catch (error) {
      error.userMessage = 'There was an error in creating the form.';
      throw error;
    }
  }

  async calculatePublicHousingRentcalc(
    templateId: string,
    householdId: string,
    unitId: string,
    action: string,
    actionRequest: Record<string, unknown>
  ): Promise<PHRentcalcResponse> {
    try {
      return await this.http
        .post<PHRentcalcResponse>(`/api/fileroom/calculatePublicHousingRentcalc`, {
          templateId,
          householdId,
          unitId,
          action,
          actionRequest,
        })
        .toPromise();
    } catch (error) {
      error.userMessage = 'There was an error calculating the public housing rentcalc.';
      throw error;
    }
  }

  async revalidate50058Form(documentId: string): Promise<string> {
    try {
      return await this.http.post<string>(`/api/fileroom/form50058Revalidate/${documentId}`, {}).toPromise();
    } catch (error) {
      error.userMessage = 'There was an error revalidating the 50058 form.';
      throw error;
    }
  }

  async generateNewJWT(documentId: string): Promise<FormToken> {
    try {
      return await this.http.get<FormToken>(`/api/fileroom/refreshJWT/${documentId}`).toPromise();
    } catch (error) {
      error.userMessage = 'There was an error in increasing your time to edit the form.';
      throw error;
    }
  }
}
