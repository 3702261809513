import {Component, Input, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {GlobalModalChild, GlobalModalParent} from '../../../../kanso-common/core/service/globalmodal.service';
import {
  IncomeLimitModalData,
  IncomeLimitArea,
  IncomeLimitAreaModal,
  IncomeLevelModal,
  IncomeValueModal,
} from '../../../housing-core/services/housing-models';
import moment from 'moment';
import lodash from 'lodash';
@Component({
  selector: 'app-edit-income-limit-area',
  templateUrl: './edit-income-limit-area.component.html',
  styleUrls: ['./edit-income-limit-area.component.scss'],
})
export class EditIncomeLimitAreaComponent implements GlobalModalChild<IncomeLimitModalData>, IncomeLimitModalData {
  //eslint-disable-next-line
  @Input() updateFunction: Function;
  @Input() name: string;
  @Input() index: number;
  @Input() editMode: boolean;
  _incomeLimitArea: IncomeLimitArea;
  createMode: boolean;
  @Input() set incomeLimitArea(setArea: IncomeLimitArea) {
    this._incomeLimitArea = setArea;
    if (!setArea) {
      this.createMode = true;
      this.editArea = new IncomeLimitAreaModal();
      this.editEffectiveDate = true;
    } else {
      this.createMode = false;
      this.editArea = lodash.cloneDeep(this._incomeLimitArea);
      this.selectedEffectiveDate = setArea.incomeLimitTables[0].effectiveDate;
      for (const table of this.editArea.incomeLimitTables) {
        table.medianIncome = table.medianIncome / 100;
        for (const level of table.incomeLevels) {
          for (const value of level.incomeValues) {
            if (value.amount) {
              value.amount = value.amount / 100;
            } else {
              value.amount = 0;
            }
          }
        }
      }
    }
    this.loading = false;
  }
  get incomeLimitArea(): IncomeLimitArea {
    return this._incomeLimitArea;
  }

  editArea: IncomeLimitArea;
  globalModalParent: GlobalModalParent;
  selectedIndex = 0;
  selectedEffectiveDate;
  editEffectiveDate = false;
  loading = true;

  enableEditMode() {
    this.editMode = true;
    this.createMode = true;
  }

  addTableRow() {
    const incomeLevels = new IncomeLevelModal('', 0, [
      new IncomeValueModal(1, 0),
      new IncomeValueModal(2, 0),
      new IncomeValueModal(3, 0),
      new IncomeValueModal(4, 0),
      new IncomeValueModal(5, 0),
      new IncomeValueModal(6, 0),
      new IncomeValueModal(7, 0),
      new IncomeValueModal(8, 0),
    ]);
    this.editArea.incomeLimitTables[this.selectedIndex].incomeLevels.push(incomeLevels);
  }

  deleteTableRow(i) {
    this.editArea.incomeLimitTables[this.selectedIndex].incomeLevels.splice(i, 1);
  }

  effectiveDateChanged() {
    this.selectedIndex = this.editArea.incomeLimitTables.findIndex(t => t.effectiveDate == this.selectedEffectiveDate);
  }

  addNewEffectiveDate() {
    this.editEffectiveDate = true;
    //Adding Default Values
    const defaultIncomeLimit = new IncomeLimitAreaModal();
    this.editArea.incomeLimitTables.push(defaultIncomeLimit.incomeLimitTables[0]);
    this.selectedIndex = this.editArea.incomeLimitTables.length - 1;
  }

  saveNewEffectiveDate() {
    this.editEffectiveDate = false;
    this.selectedIndex = 0;
  }

  cancelNewEffectiveDate() {
    this.editArea.incomeLimitTables.splice(this.editArea.incomeLimitTables.length - 1, 1);
    this.selectedIndex = this.editArea.incomeLimitTables.length - 1;
    this.editEffectiveDate = false;
  }

  compareEffectiveDate(o1, o2) {
    if (o1 == o2) {
      return true;
    } else {
      return false;
    }
  }

  cancel() {
    this.globalModalParent.closePopup();
  }

  onSubmit() {
    for (const table of this.editArea.incomeLimitTables) {
      table.medianIncome = table.medianIncome * 100;
      table.effectiveDate = moment(table.effectiveDate).format('YYYY/MM/DD');
      for (const level of table.incomeLevels) {
        for (const value of level.incomeValues) {
          value.amount = value.amount * 100;
        }
      }
    }
    this.editMode = false;
    if (this.editEffectiveDate) {
      this.saveNewEffectiveDate();
    }
    if (!this._incomeLimitArea) {
      this.incomeLimitArea = Object.assign({}, this.editArea);
    } else {
      this.incomeLimitArea = Object.assign(this._incomeLimitArea, this.editArea);
    }
    this.updateFunction(this.incomeLimitArea, this.index);
  }
}
