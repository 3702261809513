export interface IPicBatchHousehold {
  disable: boolean;
  id: number;
  headFirstName: string;
  headLastName: string;
  action: string;
  program: string;
  project: string;
  effectiveDate: string;
  createdOnDate: string;
  selected: boolean;
}

export class PicBatchExportRequest {
  formIds: number[];
  user: string;
}
