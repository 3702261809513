/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-coming-soon-popup',
  templateUrl: './coming-soon-popup.component.html',
  styleUrls: ['./coming-soon-popup.component.scss'],
})
export class ComingSoonPopupComponent {
  constructor(public dialogRef: MatDialogRef<ComingSoonPopupComponent>) {}

  btnClick() {
    this.dialogRef.close();
  }
}
