<section *ngIf="!loading">
    <div class="nameAddress">
        <span class="tenant-page__title">
            <span *ngIf="!vendor.companyName">{{vendor.lastName}}, {{vendor.firstName}}</span>
            <span *ngIf="vendor.companyName">{{vendor.companyName}}</span>
        </span>
        <div class="tenant-page__address--label addresses">
            <span *ngIf="vendor.addresses.length && vendor.addresses[0].streetAddress.length">
                <span>{{vendor.addresses[0].streetAddress}}</span>
                <span *ngIf="vendor.addresses[0].unit"> {{vendor.addresses[0].unit}}</span>
                <span>, {{vendor.addresses[0].city}}, {{vendor.addresses[0].state}}
                    {{vendor.addresses[0].postalCode}}</span>
            </span>
            <span *ngIf="!vendor.addresses.length || !vendor.addresses[0].streetAddress.length">No Address on File</span>
        </div>
    </div>
</section>