<mat-card class="knso-card">
    <mat-card-header>
        <mat-toolbar>
            <span>{{getHeader()}}</span>
        <button mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
    </mat-card-header>
    <mat-card-content>
        <form #rentCalcForm="ngForm">
            <div class="layout-row">
                <mat-form-field 
                    appearance="outline">
                    <mat-label flex="20">Effective Date:</mat-label>
                        <input 
                            matInput 
                            [(ngModel)]="editRentCalcOption.effectiveDate" 
                            ngModel 
                            required
                            #effectiveDate="ngModel"
                            name="effectiveDate"
                            aria-label="Effective Date"
                            [matDatepicker]="datepicker">
                            <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                            <mat-datepicker #datepicker></mat-datepicker
                        >
                </mat-form-field>
            </div>
            <div class="layout-row">
                <mat-form-field 
                    appearance="outline">
                    <mat-label flex="20">0 Bedroom(Studio):</mat-label>
                        <input 
                            matInput
                            ngModel
                            [(ngModel)]="editRentCalcOption.value[0]"
                            name="0bedroom"
                            #0bedroom="ngModel"                   
                            type="number" 
                            aria-label="Amount">
                </mat-form-field>
                <mat-form-field 
                    appearance="outline">
                    <mat-label flex="20">1 Bedroom:</mat-label>
                        <input 
                            matInput
                            ngModel
                            [(ngModel)]="editRentCalcOption.value[1]"
                            name="1bedroom"
                            #1bedroom="ngModel"                   
                            type="number" 
                            aria-label="Amount">
                </mat-form-field>
                <mat-form-field 
                    appearance="outline">
                    <mat-label flex="20">2 Bedrooms:</mat-label>
                        <input 
                            matInput
                            ngModel
                            [(ngModel)]="editRentCalcOption.value[2]"
                            name="2bedroom"
                            #2bedroom="ngModel"
                            type="number" 
                            aria-label="Amount">
                </mat-form-field>
                <mat-form-field 
                    appearance="outline">
                    <mat-label flex="20">3 Bedrooms:</mat-label>
                        <input 
                            matInput
                            ngModel
                            [(ngModel)]="editRentCalcOption.value[3]"
                            name="3bedroom"
                            #3bedroom="ngModel"  
                            type="number" 
                            aria-label="Amount">
                </mat-form-field>
                <mat-form-field 
                    appearance="outline">
                    <mat-label flex="20">4 Bedrooms:</mat-label>
                        <input 
                            matInput
                            ngModel
                            [(ngModel)]="editRentCalcOption.value[4]"
                            name="4bedroom"
                            #4bedroom="ngModel"
                            type="number" 
                            aria-label="Amount">
                </mat-form-field>
            </div>
            <div class="layout-row">
                <mat-form-field 
                    appearance="outline">
                    <mat-label flex="20">5 Bedrooms:</mat-label>
                        <input 
                            matInput
                            ngModel
                            [(ngModel)]="editRentCalcOption.value[5]"
                            name="5bedroom"
                            #5bedroom="ngModel"  
                            type="number" 
                            aria-label="Amount">
                </mat-form-field>
                <mat-form-field 
                    appearance="outline">
                    <mat-label flex="20">6 Bedrooms:</mat-label>
                        <input 
                            matInput
                            ngModel
                            [(ngModel)]="editRentCalcOption.value[6]"
                            name="6bedroom"
                            #6bedroom="ngModel" 
                            type="number" 
                            aria-label="Amount">
                </mat-form-field>
                <mat-form-field 
                    appearance="outline">
                    <mat-label flex="20">7 Bedrooms:</mat-label>
                        <input 
                            matInput
                            ngModel
                            [(ngModel)]="editRentCalcOption.value[7]"
                            name="7bedroom"
                            #7bedroom="ngModel"                   
                            type="number" 
                            aria-label="Amount">
                </mat-form-field>
                <mat-form-field 
                    appearance="outline">
                    <mat-label flex="20">8 Bedrooms:</mat-label>
                        <input 
                            matInput
                            ngModel
                            [(ngModel)]="editRentCalcOption.value[8]"
                            name="8bedroom"
                            #8bedroom="ngModel"                   
                            type="number" 
                            aria-label="Amount">
                </mat-form-field>
            </div>
            <div class="layout-row">
                <mat-form-field 
                    appearance="outline">
                    <mat-label flex="20">9 Bedrooms:</mat-label>
                        <input 
                            matInput
                            ngModel
                            [(ngModel)]="editRentCalcOption.value[9]"
                            name="9bedroom"
                            #9bedroom="ngModel"                   
                            type="number" 
                            aria-label="Amount">
                </mat-form-field>
                <mat-form-field 
                    appearance="outline">
                    <mat-label flex="20">10 Bedrooms:</mat-label>
                        <input 
                            matInput
                            ngModel
                            [(ngModel)]="editRentCalcOption.value[10]"
                            name="10bedroom"
                            #10bedroom="ngModel"                   
                            type="number" 
                            aria-label="Amount">
                </mat-form-field>
            </div>

        </form>
    </mat-card-content>
    <mat-card-actions>    
            <button 
                mat-flat-button
                (click)="closeDialog()"
                class="hds_button cancel"            
                id="cancelButton">
                    Cancel
            </button>
            <button 
                mat-flat-button 
                class="hds_button"             
                (click)="save()"
                [disabled]="!rentCalcForm.valid || rentCalcForm.pristine"
                id="saveButton">
                    Save
            </button>
    </mat-card-actions>    
</mat-card>
