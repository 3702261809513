import {Component, Input, OnInit} from '@angular/core';
import {PayableObjectWizardView} from 'src/app/custom/vendor/vendor.service';

@Component({
  selector: 'payable-wizard-table',
  templateUrl: './payable-wizard-table.component.html',
  styleUrls: ['./payable-wizard-table.component.scss'],
})
export class PayableWizardTableComponent implements OnInit {
  @Input() tableData: PayableObjectWizardView[];
  @Input() displayedColumns: string[];
  keyInObject: string[];

  // eslint-disable-next-line
  constructor() {}

  ngOnInit(): void {
    if (this.tableData.length > 0) {
      this.keyInObject = Object.keys(this.tableData[0]);
    }
  }

  sumAmount(paymentDetail: PayableObjectWizardView[], property: string): number {
    return paymentDetail.reduce((sum: number, obj: PayableObjectWizardView) => sum + obj[property], 0);
  }

  typeOfValue(value: any) {
    return typeof value;
  }
}
