/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {Component, OnInit, Input} from '@angular/core';
import {VoucherService} from '../vouchers-services/vouchers.service';
import * as moment from 'moment';
import {Query, Voucher} from 'src/generated/graphql';

@Component({
  selector: 'app-vouchers-moveout',
  templateUrl: './vouchers-moveout.component.html',
  styleUrls: ['./vouchers-moveout.component.scss'],
})
export class VouchersMoveOutComponent implements OnInit {
  customerId: string;
  siteId: string;
  @Input() data: any;
  loggedInUser: string;
  voucher: Voucher;
  activeVoucher = false;
  observable: any;
  voucherStatusList = [
    {name: 'Terminated', value: 'TERMINATED'},
    {name: 'Issued', value: 'ISSUED'},
    {name: 'Shop Mode', value: 'SHOP_MODE'},
  ];
  voucherstatus: any = this.voucherStatusList[0];
  currentVoucher: Voucher;
  voucherCheck: boolean;
  spinnerCheck: boolean;
  private CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  private SITE_ID = sessionStorage.getItem('SITEID') || '';

  constructor(private voucherService: VoucherService) {}

  ngOnDestroy() {
    //Service takes voucherStatus for the final page's voucher update.
    if (this.data.step != 'review') {
      this.voucherService.setActionItems(this.voucherstatus);
    } else {
      this.voucherService.setActionItems(null); //Setting service item to null so it will not carry over to different households or actions
    }
  }

  ngOnInit() {
    this.customerId = this.CUSTOMER_ID;
    this.siteId = this.SITE_ID;
    this.spinnerCheck = true;
    // Saving to a Service item due to selection being on 2nd/date step but the voucher update needs to happen on the 4th/review step
    const selectedStatus = this.voucherService.loadActionItems();
    this.getCurrentUsersLogInCookie();
    this.observable = this.voucherService.getVoucherByHouseHoldIdTrimmed(this.data.householdId).subscribe(
      (result: any) => {
        const query: Query = result?.data;
        if (query.vouchers.edges.length > 0) {
          const finder = query.vouchers.edges.find(edge => edge.node.state === 'LEASED');
          if (finder) {
            this.voucher = finder.node;
            if (this.voucher) {
              this.voucherCheck = true;
              this.spinnerCheck = false;
              if (this.data.step === 'review') {
                const voucherCommand = {
                  voucherId: this.voucher.id,
                  customerId: this.customerId,
                  siteId: this.siteId,
                  modifiedBy: this.loggedInUser,
                  movedOutOn: moment(this.data.effectiveDate).format('MM-DD-YYYY'),
                  terminatedOn: null,
                  newState: selectedStatus.value,
                  effectiveDate: moment(this.data.effectiveDate).format('MM-DD-YYYY'),
                };
                if (selectedStatus.value === 'TERMINATED') {
                  voucherCommand.terminatedOn = moment(new Date()).format('MM-DD-YYYY');
                }
                const newExpirationDate = moment(this.data.effectiveDate).add(60, 'days');
                const command2 = {
                  voucherId: this.voucher.id,
                  fundingProgramId: this.voucher.program.id,
                  fundingProjectId: this.voucher.project.id,
                  customerId: this.voucher.customerId,
                  siteId: this.voucher.siteId,
                  voucherNumber: this.voucher.voucherNumber,
                  expiresOn: moment(newExpirationDate).format('MM-DD-YYYY'),
                  modifiedBy: this.loggedInUser ? this.loggedInUser : 'voucher form', //we need to save the user in cookies or session storage to pull this value
                  unitId: this.voucher?.unitId ? this.voucher?.unitId : null,
                };
                this.voucherService.changeVoucherStatus(voucherCommand).subscribe(
                  (results: any) => {
                    this.voucherService.editVoucher(command2).subscribe(
                      (results2: any) => {},
                      error => {
                        console.log('There was an error updating voucher expiration date', error);
                      }
                    );
                  },
                  error => {
                    console.log('There was an error sending the voucher transition', error);
                  }
                );
              }
            } else {
              this.spinnerCheck = false;
            }
          } else {
            this.spinnerCheck = false;
          }
        }
        this.spinnerCheck = false;
      },
      error => {
        this.spinnerCheck = false;
      }
    );
  }

  getCurrentUsersLogInCookie() {
    const cookies = document.cookie.split(';');
    let usersAuthCookie;
    for (const cookie of cookies) {
      if (cookie.includes('LastAuthUser')) {
        //found the cookie of the user
        usersAuthCookie = cookie;
      }
    }
    const arrayOfCookieStrings = usersAuthCookie.split('=');
    this.loggedInUser = arrayOfCookieStrings[1];
  }
}
