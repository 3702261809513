<busy-notice
    [isBusy]="this.isLoading"
    [busyText]="this.busyText"
    headerText="KANSO">
</busy-notice>
<div *ngIf="!isLoading">
    <div class="section-body">
        <form #publicHousingActionForm="ngForm">
            <div class="subsidyStatusContainer" *ngIf="data.requiresHotmaUpdates && data.request.actionType == 'Interim Recertification'">
                <div>Re Examination Reason</div>
                <mat-form-field class="dropdownContainer" appearance="outline">
                    <mat-select
                        ngModel
                        [(ngModel)]="data.request.reExaminationReason"
                        #reExaminationReason="ngModel"
                        (selectionChange)="publicHousingLoadingEmitter()"
                        name="reExaminationReason"
                        id="reExaminationReason"
                        required>
                        <mat-option *ngFor="let er of reExamination.reasonList" [value]="er.value">
                            {{er.description}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="reExaminationReason.hasError('required')">Required</mat-error>
                </mat-form-field>
            </div>
            <div class="subsidyStatusContainer" *ngIf="data.requires58PicForm && !data.unit.landlord">
                <div>Family Subsidy Status under Noncitizens Rule (3u)</div>
                <mat-form-field class="dropdownContainer" appearance="outline">
                    <mat-select
                        ngModel
                        [(ngModel)]="data.request.familySubsidyStatus"
                        (selectionChange)="getIncomeBasedRent()"
                        #familySubsidyStatus="ngModel"
                        name="familySubsidyStatus"
                        id="familySubsidyStatus"
                        required>
                        <mat-option value="C" id="C">C: Qualified for continuation of full assistance</mat-option>
                        <mat-option value="E" id="E">E: Eligible for full assistance</mat-option>
                        <mat-option value="F" id="F">F: Eligible for full assistance pending verification of status</mat-option>
                        <mat-option value="P" id="P">P: Prorated assistance</mat-option>
                    </mat-select>

                </mat-form-field>
            </div>
            <div class="rentSelectedContainer" *ngIf="data.requires58PicForm && !data.unit.landlord && data.request.rentCalc">
                <div class="radioSpacing">Type of Rent Selected:</div>
                <mat-radio-group
                    ngModel
                    [(ngModel)]="data.request.rentCalc.isIncomeBasedRent"
                    #isIncomeBasedRent="ngModel"
                    name="isIncomeBasedRent"
                    id="isIncomeBasedRent">
                    <label class="radioSpacing">
                        <mat-radio-button [value]="true">Income Based {{(data.request.rentCalc.finalRent/100) | currency}}</mat-radio-button>
                    </label>
                    <label class="radioSpacing">
                        <mat-radio-button [value]="false">Flat Rent {{(data.request.rentCalc.flatRent/100) | currency}}</mat-radio-button>
                    </label>
                </mat-radio-group>
            </div>
        </form>
    </div>
</div>
