import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-time-extension-popup',
  templateUrl: './time-extension-popup.component.html',
  styleUrls: ['./time-extension-popup.component.scss'],
})
export class TimeExtensionPopupComponent {
  constructor(public dialogRef: MatDialogRef<TimeExtensionPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {}

  generateNewJWT(): void {
    this.dialogRef.close({event: 'generateNewJWT'});
  }
}
