<mat-card class="knso-card">
    <mat-card-header>
        <mat-toolbar>
            <span>Post Adjustment</span>
            <button mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
    </mat-card-header>

    <mat-card-content>
        <form #postAdjustmentForm="ngForm">

            <div class="section-header layout-row">General Information</div>
                <div class="section-body">
                    <div class="layout-row">
                        <mat-form-field class="account" appearance="outline">
                            <mat-label>Account</mat-label>
                            <mat-select
                                name="account"
                                id="account"
                                ngModel
                                [(ngModel)]="vendorAdjustmentInput.account"
                                #account="ngModel"
                                required>
                                <input class="select-search" matInput placeholder="Search" [formControl]="accountFilter">
                                <mat-option *ngFor="let account of filteredAccounts$ | async" 
                                [value]="account.node.id" (click)="getSubledgers(account.node.subLedgerMappingId)">
                                    {{account.expectedDisplayInfo}} - {{account.node.displayName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Type</mat-label>
                            <mat-select
                                name="type"
                                id="type"
                                ngModel
                                [(ngModel)]="vendorAdjustmentInput.type"
                                #type="ngModel"
                                required>
                                <mat-option *ngFor="let subledger of subledgers$ | async" [value]="subledger.id">
                                    {{subledger.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

            <div class="section-header layout-row">Transaction Details</div>
                <div class="section-body">
                    <div class="layout-row">
                        <div class="layout-column">
                            <mat-form-field class="postedOn" appearance="outline">
                                <input 
                                    matInput 
                                    [matDatepicker]="picker" 
                                    placeholder="Posted on Date"
                                    ngModel 
                                    [(ngModel)]="vendorAdjustmentInput.postedOn" 
                                    #postedOn="ngModel" 
                                    name="postedOn"
                                    required>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field  appearance="outline">
                                <mat-label class="flex-20">Amount</mat-label>
                                <input 
                                    matInput
                                    type="number" 
                                    ngModel 
                                    [(ngModel)]="vendorAdjustmentInput.amount" 
                                    #amount="ngModel" 
                                    name="amount"
                                    required>
                            </mat-form-field>
                        </div>
                        <mat-form-field  class="description" layout-align="end" appearance="outline">
                            <mat-label class="flex-20">Description</mat-label>
                            <textarea 
                                matInput 
                                ngModel 
                                [(ngModel)]="vendorAdjustmentInput.description" 
                                #description="ngModel" 
                                name="description"
                                matTextareaAutosize>
                            </textarea>
                        </mat-form-field>
                    </div>      
                </div>

            <mat-card-actions>
                <button 
                    mat-flat-button
                    (click)="closeDialog()"
                    class="hds_button cancel"
                    id="cancelButton">
                        Cancel
                </button>
                <button 
                    mat-flat-button 
                    class="hds_button"
                    (click)="saveAdjustment(vendorLedgerReport[0].node.vendorId)"
                    [disabled]="!postAdjustmentForm.valid || postAdjustmentForm.$pristine"
                    id="Submit">
                        Post
                </button>
            </mat-card-actions>
        </form>
    </mat-card-content>
</mat-card>