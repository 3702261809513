import {Component, Input} from '@angular/core';
import {GlobalModalChild, GlobalModalParent} from '../../../../../kanso-common/core/service/globalmodal.service';

@Component({
  selector: 'app-unit-setup-monthly-charge',
  templateUrl: './unit-setup-monthly-charge.component.html',
  styleUrls: ['./unit-setup-monthly-charge.component.scss'],
})
export class UnitSetupMonthlyChargeComponent implements GlobalModalChild<any> {
  @Input() index: number;
  @Input() chargeType: string;
  // eslint-disable-next-line
  @Input() updateFunction: Function;
  _effectiveDate: any;
  @Input() set effectiveDate(setEffectiveDate: any) {
    this._effectiveDate = setEffectiveDate;
    this.selectedDate = setEffectiveDate ? new Date(setEffectiveDate) : new Date();
  }

  get effectiveDate() {
    return this._effectiveDate;
  }

  _value: any;
  @Input() set value(setValue: any) {
    this._value = setValue;
  }
  get value() {
    return this._value;
  }
  _bedroomSize: number;
  @Input() set bedroomSize(setBedroomSize: number) {
    this._bedroomSize = setBedroomSize;
  }
  get bedroomSize() {
    return this._bedroomSize;
  }

  selectedDate: Date = new Date();
  globalModalParent: GlobalModalParent;

  getHeader() {
    switch (this.chargeType) {
      case 'adminFees':
        return 'Admin Fee Details';
      case 'minimumCharges':
        return 'Minimum Rent Details';
      case 'maximumCharges':
        return 'Maximum Charge Details';
      case 'utilityAllowances':
        return 'Utility Allowance Details';
      case 'fairMarketRents':
        return ' Fair Market Rent Details';
      case 'flatRents':
        return 'Flat Rent Details';
    }
    return;
  }

  closeDialog() {
    this.globalModalParent.closePopup();
  }

  save() {
    this.updateFunction(this.value, this.selectedDate, this.chargeType, this.bedroomSize, this.index);
    this.closeDialog();
  }
}
