<section class="tabs__panel layout-column payablePage" *ngIf="showPage === 'new-batch' || showPage === 'historical'">
    <div class="headerPadding">
        <h2>Payables</h2>
        <nav>
            <ul class="nav-pills">
                <li>
                    <a  [class]="getNavClass('new-batch')" (click)="onPayableNavigate('new-batch')" routerLinkActive="active">
                        {{ showPage === 'historical' ? 'Current' : 'New batch'}}
                    </a>
                </li>
                <li>
                    <a  [class]="getNavClass('historical')" (click)="onPayableNavigate('historical')" routerLinkActive="active">
                        Historical
                    </a>
                </li>
            </ul>
        </nav>
    </div>
        <div>
            <div (click)="showFilterToggle()" class="filterRow">
                <mat-icon>filter_alt</mat-icon>
                <a class="filterButton">SHOW FILTERS</a>
            </div>
        </div>
</section>

<section class="tabs__panel layout-column payable-detail-header2" *ngIf="showPage === 'payable-detail'">
    <div class="headerPadding2">
        <h2 class="h2-detail">Payable Batched on {{currentBatchCreatedOn}}</h2>
        <p>Created By {{currentBatchCreatedBy}}</p>
        <p>Batch ID: <b>{{currentBatchId}}</b></p>
    </div>
    <div class="nav-payable-detail">
        <a (click)="onPayableNavigate('historical')" class="anchor-class">
            
            <!-- <mat-icon class="back-history-icon">arrow_back</mat-icon> -->
            <mat-icon svgIcon="download-icon-blue" class="backIcon"></mat-icon>
            Back to Historical List</a>
    </div>
</section>

<section class="tabs__panel  payablePage" >
    <div class="flexColumn" *ngIf="showPage === 'new-batch'">
        <busy-notice
            [isBusy]="this.loading"
            [busyText]="this.busyText"
            headerText="KANSO">
        </busy-notice>
        <div class="layout-row mainCheckbox" *ngIf="!loading">
         <mat-checkbox
            class="master-checkbox"
            [checked]="allTransactions"
            [indeterminate]="someSelected()"
            (change)="selectAllTransactions($event.checked)"
            > 
            </mat-checkbox>
            <div class="checkboxPadding">
                {{checkboxMessage}}
            </div>
        </div>
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="tablePadding"  *ngIf="!loading">
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox 
                        [checked]="!row.selected" 
                        (change)="updateOuterCheckbox(row.vendorId)"
                        [indeterminate]="row.indeterminate"
                    > </mat-checkbox>
                </td>
                <td mat-footer-cell *matFooterCellDef></td> 
            </ng-container>
            <ng-container matColumnDef="chevron">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <a class="anchor-class">
                        <mat-icon class="collapse-icon kansoGray" 
                            [ngClass]="((element == expandedElement) && (expandedPanel == true)) ? 'collapse-icon--open' : ''"
                            (click)="toggleRow(element)">chevron_right
                        </mat-icon>
                    </a>
                </td>
                <td mat-footer-cell *matFooterCellDef></td> 
            </ng-container>
            <ng-container matColumnDef="Pay To">
                <th mat-header-cell *matHeaderCellDef>Pay To</th>
                <td mat-cell *matCellDef="let element">
                    <a (click)="onNavigateVendorURL(element.vendorId)" class="anchor-class payables-hover">{{ element.vendorName.length ? element.vendorName: '' }}</a>
                </td>
                <td mat-footer-cell *matFooterCellDef>Grand Total</td>   
            </ng-container>
            <ng-container matColumnDef="# of Accounts">
                <th mat-header-cell *matHeaderCellDef># of Transactions</th>
                <td mat-cell *matCellDef="let element">{{ element.numberOfTransactions}}</td>
                <td mat-footer-cell *matFooterCellDef>{{ sumAmount(dataSource, 'numberOfTransactions')}}</td> 
            </ng-container>
            <ng-container matColumnDef="Last Payable Amt">
                <th mat-header-cell *matHeaderCellDef>Last Payable Amt</th>
                <td mat-cell *matCellDef="let element">{{ getAbsoluteValue(element.lastPayment) | currency}}
                </td>
                <td mat-footer-cell *matFooterCellDef>{{ sumAbsoluteValue(dataSource, 'lastPayment') | currency}}</td>
            </ng-container>
            <ng-container matColumnDef="Total Balance">
                <th mat-header-cell *matHeaderCellDef>Total Balance</th>
                <td mat-cell *matCellDef="let element">{{ element.currentCharges | currency}}
                </td>
                <td mat-footer-cell *matFooterCellDef>{{ sumAmount(dataSource, 'currentCharges') | currency}}</td>
            </ng-container>
            <ng-container matColumnDef="Balance to be Paid">
                <th mat-header-cell *matHeaderCellDef>Balance to be Paid</th>
                <td mat-cell *matCellDef="let element">{{ element.balanceToBePaid | currency}}
                </td>
                <td mat-footer-cell *matFooterCellDef>{{ sumAmount(dataSource, 'balanceToBePaid') | currency}}</td>
            </ng-container>
            <ng-container matColumnDef="Notes">
                <th mat-header-cell *matHeaderCellDef>Notes</th>
                <td mat-cell *matCellDef="let element"><mat-icon (click)="onOpenNotes(element)">create</mat-icon>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                </td> 
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td
                  mat-cell
                  *matCellDef="let element"
                  [attr.colspan]="displayedColumns.length">
                <div class="element-detail"
                    [@detailExpand]="((element == expandedElement) && (expandedPanel == true)) ? 'expanded' : 'collapsed'">
                    <div class="innerTable" *ngIf="expandedElement">
                        <div *ngIf="!element.details && innerLoading">
                            <mat-spinner diameter="50" class="centered_spinner"> </mat-spinner>
                        </div>
                        <table mat-table [dataSource]="element.details" class="altRows checkboxPadding" *ngIf="element.details">
                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef>
                                </th>
                                <td mat-cell *matCellDef="let innerElement">
                                    <mat-checkbox
                                        [checked]="!innerElement.selected" 
                                        (change)="updateInnerSelected(innerElement.sequence)"
                                        [disabled]="innerElement.disable">
                                    </mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Name">
                                <th mat-header-cell *matHeaderCellDef>Name</th>
                                <td mat-cell *matCellDef="let innerElement">{{ innerElement.tenantName }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="transactionId">
                                <th mat-header-cell *matHeaderCellDef>Unit</th>
                                <td mat-cell *matCellDef="let innerElement">{{ innerElement.ppuCode}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Last Payed Date">
                                <th mat-header-cell *matHeaderCellDef>Posted Date</th>
                                <td mat-cell *matCellDef="let innerElement">{{ innerElement.postedOn | date:'MM/dd/yyyy' : 'UTC'}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Payment Type">
                                <th mat-header-cell *matHeaderCellDef>Payment Type</th>
                                <td mat-cell *matCellDef="let innerElement">{{ innerElement.chargeTypeName}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Amount">
                                <th mat-header-cell *matHeaderCellDef >Transaction Amount</th>
                                <td mat-cell *matCellDef="let innerElement">{{ innerElement.amount | currency}}
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns" class="table-header innerCheckbox"></tr>
                            <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns;" class="centerData"></tr>
                        </table>
                    </div> 
                  </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="topTable kansoGray"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns" class="footerStyling"></tr>
        </table>
        <div *ngIf="vendorPayables && vendorPayables.length > 0  && !loading" class="pageNavigation">
            <div class="navControlsWrapper">
                <mat-icon [class]="navButtonClass('previous')" (click)="goPage('previous')" >navigate_next</mat-icon>
                <span class="pageOfText">
                    {{currentPage}}
                </span>
                <mat-icon [class]="navButtonClass('next')" (click)="goPage('next')">navigate_next</mat-icon>
            </div>
        </div>
        <div class="moveButton" *ngIf="!loading">
            <button mat-raised-button [disabled]="onCheckDisableProcessPayble()" (click)="onOpenWizard()" class= "processButton">
                Process Payables
            </button>
        </div>        
    </div>
    <div *ngIf="showPage === 'payable-detail'">
        <app-payable-detail  [currentBatchId]="currentBatchId"></app-payable-detail>
    </div>
    <div *ngIf="showPage === 'historical'">
        <app-payables-history (pageEmitter)="onPayableNavigate($event)" (batchIdEmitter)="setCurrentBatchId($event)"></app-payables-history>
    </div>
</section>

<div [@openClose]="showFilters ? 'open' : 'closed'" class="dataFilter">
    <div class="flexColumn">
        <div>
            <h4>Payable Type</h4>
        </div>
        <mat-form-field>
            <mat-select [formControl]="payableTypes" multiple>
                <mat-option *ngFor="let payableType of payableTypeList" [value]="payableType">{{payableType}}</mat-option>
            </mat-select>
        </mat-form-field>
        <div>
            <h4>Program</h4>
        </div>
        <mat-form-field>
            <mat-select [formControl]="programs" multiple>
                <mat-option *ngFor="let program of programList" [value]="program._id">{{program.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <div>
            <h4>Payment Preference</h4>
        </div>
        <mat-form-field>
            <mat-select [formControl]="paymentPreference" multiple>
                <mat-option *ngFor="let paymentPreference of paymentPreferenceList" [value]="paymentPreference">{{paymentPreference}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
  
    <div class="buttonFlexRow">
        <a class="cancelLink" (click)="showFilterToggle()">
            Cancel
          </a>
      <a mat-button class="btn-right" (click)="resetFilters()">
        Reset
      </a>
      <button mat-button class="btn-right" (click)="applyFilters()">
        Apply
      </button>
    </div>
</div>

