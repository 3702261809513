/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {Component, OnInit, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as moment from 'moment';
import {VoucherService} from '../vouchers-services/vouchers.service';
import {RequestForTenancyApproval, Voucher} from 'src/generated/graphql';
import {TollingtimepopupComponent} from '../tollingtimepopup/tollingtimepopup.component';

@Component({
  selector: 'app-vouchers-addeditrfta',
  templateUrl: './vouchers-addeditrfta.component.html',
  styleUrls: ['./vouchers-addeditrfta.component.scss'],
})
export class VouchersAddEditrftaComponent implements OnInit {
  voucherRFTAForm: FormGroup;
  submitted = false;
  addOrEdit = 'Add';
  rfta: RequestForTenancyApproval;
  voucher: Voucher;
  loggedInUser;
  daysToIncrease: number;
  failSelected = false;
  updatedMinDate: Date;
  spinnerCheck = false;
  overlay = false;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VouchersAddEditrftaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private voucherService: VoucherService
  ) {}

  ngOnInit() {
    this.getCurrentUsersLogInCookie();
    // what do we display
    if (this.data.editMode === true) {
      this.addOrEdit = 'Edit';
    }
    //set what came from the button onInit
    this.voucher = this.data.voucher;
    this.rfta = this.data.RFTA;

    //set up the form
    this.voucherRFTAForm = this.formBuilder.group({
      rftaStartDate: moment(this.rfta.requestStartOn)
        .add(1, 'day')
        .toDate(),
      rftaStopDate:
        this.data.editMode === true && this.rfta.requestStopOn
          ? moment(this.rfta.requestStopOn)
              .add(1, 'day')
              .toDate()
          : null,
      result: this.rfta.result ? this.rfta.result : 'AWAITING_APPROVAL',
      resultComments: this.rfta.resultComments,
    });
    this.updatedMinDate = this.voucherRFTAForm.value.rftaStartDate;
  }

  onCancelClick(): void {
    this.dialogRef.close(null);
  }

  clickFailed() {
    this.failSelected = true;
  }

  getCurrentUsersLogInCookie() {
    const cookies = document.cookie.split(';');
    let usersAuthCookie;
    for (const cookie of cookies) {
      if (cookie.includes('LastAuthUser')) {
        //found the cookie of the user
        usersAuthCookie = cookie;
      }
    }
    const arrayOfCookieStrings = usersAuthCookie.split('=');
    this.loggedInUser = arrayOfCookieStrings[1];
  }

  updateMinStopDate() {
    console.log('this.voucherRFTAForm.value.rftaStartDate', this.data.voucher.issuedOn);
    this.updatedMinDate = new Date(this.voucherRFTAForm.value.rftaStartDate);
  }

  onSubmit() {
    this.spinnerCheck = true;
    this.overlay = true;
    let dialogRef;
    this.submitted = true;

    // stop here if form is invalid
    if (this.voucherRFTAForm.invalid) {
      return;
    }

    if (this.voucherRFTAForm.value.rftaStopDate) {
      dialogRef = this.dialog.open(TollingtimepopupComponent, {
        width: '50%',
        data: {
          rftaStart: moment(this.voucherRFTAForm.value.rftaStartDate).format('MM-DD-YYYY'),
          rftaStop: moment(this.voucherRFTAForm.value.rftaStopDate).format('MM-DD-YYYY'),
        },
        disableClose: true, //just for disabling the outside click to close a dialog, that way they don't accidenally lose their data
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.response === 'YES') {
          this.daysToIncrease = result.daysToIncrease;
        }

        if (this.addOrEdit === 'Add') {
          const command = {
            voucherId: this.voucher.id,
            customerId: this.voucher.customerId,
            siteId: this.voucher.siteId,
            createdBy: this.loggedInUser,
            startOn: moment(this.voucherRFTAForm.value.rftaStartDate).format('MM-DD-YYYY'),
            stopOn: moment(this.voucherRFTAForm.value.rftaStopDate).format('MM-DD-YYYY'),
            result: this.voucherRFTAForm.value.result,
            resultComments: this.voucherRFTAForm.value.resultComments ? this.voucherRFTAForm.value.resultComments : null,
          };
          this.voucherService.createRFTACommand(command).subscribe(
            (result: any) => {
              if (result.data.createRfta.status === 'SUCCESS') {
                this.dialogRef.close({rfta: result.data.createRfta.affectedEntity, daysToIncrease: this.daysToIncrease});
              }
            },
            error => {
              console.log('there was an error sending the mutation', error);
            }
          );
        } else {
          const command = {
            rftaId: this.rfta.id,
            customerId: this.voucher.customerId,
            siteId: this.voucher.siteId,
            modifiedBy: this.loggedInUser,
            stopOn: moment(this.voucherRFTAForm.value.rftaStopDate).format('MM-DD-YYYY'),
            result: this.voucherRFTAForm.value.result,
            resultComments: this.voucherRFTAForm.value.resultComments ? this.voucherRFTAForm.value.resultComments : null,
          };

          this.voucherService.editRFTACommand(command).subscribe(
            (result: any) => {
              if (result.data.editRfta.status === 'SUCCESS') {
                this.dialogRef.close({rfta: result.data.editRfta.affectedEntity, daysToIncrease: this.daysToIncrease});
              }
            },
            error => {
              console.log('there was an error sending the mutation', error);
            }
          );
        }
      });
    } else {
      if (this.addOrEdit === 'Add') {
        const command = {
          voucherId: this.voucher.id,
          customerId: this.voucher.customerId,
          siteId: this.voucher.siteId,
          createdBy: this.loggedInUser,
          startOn: moment(this.voucherRFTAForm.value.rftaStartDate).format('MM-DD-YYYY'),
          stopOn: this.voucherRFTAForm.value.rftaStopDate ? moment(this.voucherRFTAForm.value.rftaStopDate).format('MM-DD-YYYY') : null,
          result: this.voucherRFTAForm.value.result,
          resultComments: this.voucherRFTAForm.value.resultComments ? this.voucherRFTAForm.value.resultComments : null,
        };

        this.voucherService.createRFTACommand(command).subscribe(
          (result: any) => {
            if (result.data.createRfta.status === 'SUCCESS') {
              this.dialogRef.close({rfta: result.data.createRfta.affectedEntity, daysToIncrease: this.daysToIncrease});
            }
          },
          error => {
            console.log('there was an error sending the mutation', error);
          }
        );
      } else {
        const command = {
          rftaId: this.rfta.id,
          customerId: this.voucher.customerId,
          siteId: this.voucher.siteId,
          modifiedBy: this.loggedInUser,
          stopOn: this.voucherRFTAForm.value.rftaStopDate ? moment(this.voucherRFTAForm.value.rftaStopDate).format('MM-DD-YYYY') : null,
          result: this.voucherRFTAForm.value.result,
          resultComments: this.voucherRFTAForm.value.resultComments ? this.voucherRFTAForm.value.resultComments : null,
        };
        this.voucherService.editRFTACommand(command).subscribe(
          (result: any) => {
            if (result.data.editRfta.status === 'SUCCESS') {
              this.dialogRef.close({rfta: result.data.editRfta.affectedEntity, daysToIncrease: this.daysToIncrease});
            }
          },
          error => {
            console.log('there was an error sending the mutation', error);
          }
        );
      }
    }
  }
}
