import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ParameterStoreService} from './service';
import {ConfirmPopupComponent} from './components/confirm-popup/confirm-popup.component';
import {MaterialModule} from '../shared/material/material.module';
import {ErrorPopupComponent} from './components/error-popup/error-popup.component';
import {PageNavComponent} from './components/page-nav/page-nav.component';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BusyNoticeComponent} from './components/busy-notice/busy-notice.component';

@NgModule({
  providers: [ParameterStoreService],
  imports: [CommonModule, MaterialModule, MatSelectModule, FormsModule, ReactiveFormsModule],
  declarations: [ConfirmPopupComponent, ErrorPopupComponent, PageNavComponent, BusyNoticeComponent],
  entryComponents: [ConfirmPopupComponent, ErrorPopupComponent],
  exports: [ConfirmPopupComponent, PageNavComponent, BusyNoticeComponent],
})
export class CoreModule {}
export {ParameterStoreService};
