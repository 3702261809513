<section class="tabs__panel" *ngIf="!loading">
    <div class="tab-header">
        <div class="member-details__name">
            <span>All Notes</span>
        </div>
        <div class="top-buttons">
            <button mat-flat-button
                type="button"
                class="hds_button"
                id="addVendorNoteButton"
                (click)="createVendorNote()"
                    >Add Note
            </button>
        </div>
    </div>
</section>

<busy-notice
    [isBusy]="this.loading"
    [busyText]="this.busyText"
    headerText="KANSO">
</busy-notice>

<div *ngIf="!loading" class="widget__body altRows roundedCorners">
    <mat-select [(ngModel)]="selectedCategory" (ngModelChange)="categoryFilteredNotes()" class="category-select">
            <mat-option [value]="''">All Categories</mat-option>
            <mat-option *ngFor="let cat of noteCategories;" [value]="cat">{{cat}}</mat-option>
    </mat-select>

    <div *ngFor="let note of filteredNotes; let $index=index">
        <div class="note-content-rows">
            <div class="note-content-columns">
                <div class="note-content-columns-top">Posted by: </div>
                <div class="note-content-columns blue-text">{{note.createdBy}}</div>
                <div class="note-content-columns bold-text">Category: {{note.category}}</div>
                <div class="note-content-columns italic">{{note.createdOn | date: 'mediumDate'}}</div>
                <div>{{note.body}}</div>
            </div>
            <div class="note-content-grouped">
                <div class="glyphicon glyphicon-pencil kanso-blue-text" (click)="editVendorNote(note)"></div>
                <div class="fa fa-trash-o kanso-blue-text" (click)="deleteVendorNote(note)"></div>
            </div>
        </div>
    </div>
    <span *ngIf="!vendor.notes.length">No Notes to Display</span>
</div>


