import {Component, Input, OnInit} from '@angular/core';
import {GlobalModalChild, GlobalModalParent} from 'src/app/kanso-common/core/service';
import {SetupUtilityAllowancesService} from '../../services/setup-utility-allowances.service';
import {
  UtilityAllowanceDto,
  UtilityAllowanceOverride,
  utilityAllowanceOverrideCommandInput,
  UtilityAllowancesTableDto,
  UtilityAllowancesTableOverrideValue,
} from '../../services/setup-utility-model';
import {SetupUtilityGlobalStoreService} from '../../services/setup-utility-global-store.service';
import {map} from 'rxjs/operators';      
import {CoreService} from 'src/app/core/service/core.service';
import _ from 'lodash';

@Component({
  selector: 'app-unit-utility-overrides',
  templateUrl: './unit-utility-overrides.component.html',
  styleUrls: ['./unit-utility-overrides.component.scss'],
})
export class UnitUtilityOverridesComponent implements OnInit, GlobalModalChild<any> {
  @Input() unitId;
  @Input() unitBedroomSize;
  @Input() utilityAllowanceId;
  @Input() utilityAllowanceOverride;
  // eslint-disable-next-line
  @Input() updateFuntion: Function;
  // eslint-disable-next-line
  @Input() cancelFuntion: Function;
  globalModalParent: GlobalModalParent;
  utilityAllowance: UtilityAllowanceDto;
  selectedUtilityAllowancesTable: UtilityAllowancesTableDto;
  defaultUtilityAllowanceOverridesList: UtilityAllowanceOverride[];
  defaultCategoryDescriptionList: string[];
  isBusy = false;
  displayedColumns: string[] = ['select', 'category', 'description', 'amount'];
  overrideSum = 0;

  deleteList: utilityAllowanceOverrideCommandInput[] = [];

  createList: utilityAllowanceOverrideCommandInput[] = [];

  updateList: UtilityAllowanceOverride[] = [];
  // eslint-disable-next-line
  constructor(public sutas: SetupUtilityAllowancesService, public sugs: SetupUtilityGlobalStoreService, public coreService: CoreService) {}

  ngOnInit(): void {
    this.isBusy = true;
    if (this.unitId) {
      const existingUtilityAllowanceOverrides = this.sugs.getUtilityAllowanceOverridesObject();
      if (existingUtilityAllowanceOverrides) {
        this.storeOverridesData(existingUtilityAllowanceOverrides);
        this.activateUI();
      } else {
        this.sutas
          .getUtilityAllowanceOverrides(this.unitId)
          .pipe(
            map((uao: UtilityAllowanceOverride[]) => {
              if (uao.length > 0) {
                this.storeOverridesData(uao);
                return uao;
              } else {
                this.storeDefaultData();
                return null;
              }
            })
          )
          .subscribe(
            data => {
              this.activateUI();
            },
            error => {
              console.log(error);
              this.isBusy = false;
              this.globalModalParent.closePopup();
              this.coreService.displayError('There was an error getting utility allowance override data');
            }
          );
      }
    } else {
      this.storeDefaultData();
      this.activateUI();
    }
  }

  storeDefaultData() {
    const utilityAllowanceTableObject = this.sugs.getUtilityAllowanceTableObject();
    if (utilityAllowanceTableObject) {
      const idList = [];
      const combinationList = [];
      for (const u of utilityAllowanceTableObject.utilityAllowanceTableValues) {
        idList.push(u.id);
        combinationList.push(u.category + u.description);
      }
      this.defaultCategoryDescriptionList = combinationList;
    }
  }

  storeOverridesData(uao: UtilityAllowanceOverride[]) {
    // store utitlty overrides data for comparision
    this.defaultUtilityAllowanceOverridesList = uao;
    this.sugs.setUtilityAllowanceOverridesObject(uao);
    const idList = [];
    const combinationList = [];
    for (const u of uao) {
      idList.push(u.utilityAllowanceTableValueId);
      combinationList.push(u.category + u.description);
    }
    this.defaultCategoryDescriptionList = combinationList;
  }

  activateUI() {
    const existingUtilityAllowance = this.sugs.getUtilityAllowanceObject();
    if (existingUtilityAllowance && existingUtilityAllowance.id === this.utilityAllowanceId) {
      this.utilityAllowance = existingUtilityAllowance;
      const existingUtilityAllowanceTable = this.sugs.getUtilityAllowanceTableObject();
      if (existingUtilityAllowanceTable) {
        // Global store table data still exists
        this.selectedUtilityAllowancesTable = existingUtilityAllowanceTable;

        this.selectedUtilityAllowancesTable.utilityAllowanceTableValues.forEach(uatv => {
          const categoryDescription = uatv.category + uatv.description;

          if (uatv.selected) {
            if (this.defaultCategoryDescriptionList.includes(categoryDescription)) {
              this.deleteList = this.deleteList.filter(d => d.utilityAllowanceTableValuesId !== uatv.id);
            } else {
              this.createList.push({ utilityAllowanceTableValuesId: uatv.id, categoryDescription });
              this.defaultCategoryDescriptionList.push(categoryDescription);
            }
          } else {
            if (this.defaultCategoryDescriptionList.includes(categoryDescription)) {
              const createObjectExists = this.createList.some(c => c.utilityAllowanceTableValuesId === uatv.id);
              if (createObjectExists) {
                this.createList = this.createList.filter(c => c.utilityAllowanceTableValuesId !== uatv.id);
              } else {
                this.deleteList.push({ utilityAllowanceTableValuesId: uatv.id, categoryDescription });
              }
            } else {
              this.createList = this.createList.filter(c => c.utilityAllowanceTableValuesId !== uatv.id);
            }
          }
        });
      } else {
        // Handle rebuilding utility allowance table data when user click undo
        this.rebuildUtilityAllowanceTable();
      }
      this.overridesAmountSum();
      this.isBusy = false;
    } else {
      this.getUtitlityAllowanceObject(this.utilityAllowanceId);
    }
  }

  getUtitlityAllowanceObject(id: string) {
    this.sutas.getUtilityAllowance(id).subscribe(
      utitlityAllowance => {
        if(utitlityAllowance) {
          this.utilityAllowance = utitlityAllowance;
          this.rebuildUtilityAllowanceTable();
          this.overridesAmountSum();
          this.sugs.setUtilityAllowanceObject(this.utilityAllowance);
          this.sugs.setUtilityAllowanceTableObject(this.selectedUtilityAllowancesTable);
        } else {
          this.globalModalParent.closePopup();
          this.coreService.displayError('There was an error getting utility allowance data');
        }
        this.isBusy = false;
      },
      error => {
        console.log(error);
        this.isBusy = false;
        this.globalModalParent.closePopup();
        this.coreService.displayError('There was an error getting utility allowance data');
      }
    );
  }

  rebuildUtilityAllowanceTable() {
    if (this.utilityAllowanceOverride && this.defaultUtilityAllowanceOverridesList) {
      const selectedTable = this.utilityAllowance.utilityAllowanceTable.find(
        u => u.id === this.utilityAllowanceOverride
      ) || this.getLatestUtilityAllowanceTable();
  
      this.selectedUtilityAllowancesTable = selectedTable;
      this.updateSelectedUtilityAllowanceTableValues();
    } else {
      // Default to the latest utility allowance table
      this.selectedUtilityAllowancesTable = this.getLatestUtilityAllowanceTable();
      this.defaultCategoryDescriptionList = this.selectedUtilityAllowancesTable.utilityAllowanceTableValues.map(uatv => {
        uatv.selected = true;
        return uatv.category + uatv.description;
      });
    }
  }
  
  getLatestUtilityAllowanceTable() {
    return this.utilityAllowance.utilityAllowanceTable.reduce((latest, current) =>
      new Date(current.effectiveDate) > new Date(latest.effectiveDate) ? current : latest
    );
  }
  
  updateSelectedUtilityAllowanceTableValues() {
    this.selectedUtilityAllowancesTable.utilityAllowanceTableValues.forEach(uatv => {
      const categoryDescription = uatv.category + uatv.description;
      uatv.selected = this.defaultCategoryDescriptionList.includes(categoryDescription);
    });
  }
  



  onEffectiveDateChange($event) {
    this.selectedUtilityAllowancesTable = this.utilityAllowance.utilityAllowanceTable.find(uat => uat.id == $event.value.id);
    if (this.utilityAllowanceOverride) {
      this.updateSelectedUtilityAllowanceTableValues();
      const newCreateList: utilityAllowanceOverrideCommandInput[] = [];
      const newDeleteList: utilityAllowanceOverrideCommandInput[] = [];
      const newUpdateList: UtilityAllowanceOverride[] = [];
      for (const uatv of this.selectedUtilityAllowancesTable.utilityAllowanceTableValues) {
        const categoryDescriptionCombination = uatv.category + uatv.description;
        const createObject = this.createList.find(c => c.categoryDescription === categoryDescriptionCombination);
        const deleteObjcet = this.deleteList.find(d => d.categoryDescription === categoryDescriptionCombination);
        const updateObject = this.defaultUtilityAllowanceOverridesList.find(
          u => u.category + u.description === categoryDescriptionCombination
        );
        if (createObject) {
          newCreateList.push({
            utilityAllowanceTableValuesId: uatv.id,
            categoryDescription: categoryDescriptionCombination,
          });
        }
        if (deleteObjcet) {
          newDeleteList.push({
            utilityAllowanceTableValuesId: uatv.id,
            categoryDescription: categoryDescriptionCombination,
          });
        }
        if (updateObject) {
          newUpdateList.push(updateObject);
        }
      }
      this.createList = newCreateList;
      this.deleteList = newDeleteList;
      this.updateList = newUpdateList;
    } else {
      this.updateSelectedUtilityAllowanceTableValues();
    }
    this.overridesAmountSum();
  }

  selectOverrideValue(e) {
    for (const uatv of this.selectedUtilityAllowancesTable.utilityAllowanceTableValues) {
      if (uatv.id === e.id) {
        uatv.selected = !e.selected;
        if (this.utilityAllowanceOverride && this.defaultUtilityAllowanceOverridesList) {
          if (uatv.selected) {
            if (this.defaultCategoryDescriptionList.includes(uatv.category + uatv.description)) {
              const deleteObject = this.deleteList.find(d => d.utilityAllowanceTableValuesId === uatv.id);
              if (deleteObject) {
                this.deleteList = this.deleteList.filter(d => d.utilityAllowanceTableValuesId !== uatv.id);
              }
            } else {
              const deleleObject = this.deleteList.find(d => d.categoryDescription === uatv.category + uatv.description);
              if (deleleObject) {
                this.deleteList = this.deleteList.filter(d => d.utilityAllowanceTableValuesId !== uatv.id);
              } else {
                this.createList.push({
                  utilityAllowanceTableValuesId: uatv.id,
                  categoryDescription: uatv.category + uatv.description,
                });
              }
            }
          } else {
            if (this.defaultCategoryDescriptionList.includes(uatv.category + uatv.description)) {
              const createObject = this.createList.find(c => c.utilityAllowanceTableValuesId === uatv.id);
              if (createObject) {
                this.createList = this.createList.filter(c => c.utilityAllowanceTableValuesId !== uatv.id);
              } else {
                this.deleteList.push({
                  utilityAllowanceTableValuesId: uatv.id,
                  categoryDescription: uatv.category + uatv.description,
                });
              }
            } else {
              this.createList = this.createList.filter(d => d.utilityAllowanceTableValuesId !== uatv.id);
            }
          }
        }
        if (!this.defaultCategoryDescriptionList.includes(uatv.category + uatv.description)) {
          this.defaultCategoryDescriptionList.push(uatv.category + uatv.description);
        } else {
          this.defaultCategoryDescriptionList = this.defaultCategoryDescriptionList.filter(d => d !== uatv.category + uatv.description);
        }
        break;
      }
    }
    this.overridesAmountSum();
  }

  overridesAmountSum() {
    let sum = 0;
    for (const uatv of this.selectedUtilityAllowancesTable.utilityAllowanceTableValues) {
      if (uatv.selected) {
        sum += uatv[`bedroom${this.unitBedroomSize}`];
      }
    }
    this.overrideSum = sum;
  }

  closeDialog() {
    this.sugs.resetUtilityAllowanceOverridesObject();
    this.sugs.resetUtilityAllowanceTableObject();
    this.cancelFuntion(this.utilityAllowanceId);
    this.globalModalParent.closePopup();
  }

  save() {
    if (this.utilityAllowanceOverride && this.defaultUtilityAllowanceOverridesList && this.unitId) {
      const createOverridesData: UtilityAllowancesTableOverrideValue[] = [];
      const updateOverridesData: UtilityAllowanceOverride[] = [];
      const deleteIdList: string[] = [];
      const operationalCategoryDescriptionCombinationList: string[] = [];
      for (const uatv of this.selectedUtilityAllowancesTable.utilityAllowanceTableValues) {
        const categoryDescription = uatv.category + uatv.description;
        const createObject = this.createList.find(c => c.categoryDescription === categoryDescription);
        const deleteObject = this.deleteList.find(d => d.categoryDescription === categoryDescription);
        const updateObject = _.cloneDeep(
          this.defaultUtilityAllowanceOverridesList.find(
            u => u.category + u.description === categoryDescription && u.utilityAllowanceTableId !== uatv.utilityAllowanceTableId
          )
        );
  
        if (createObject) {
          createOverridesData.push(uatv);
          operationalCategoryDescriptionCombinationList.push(categoryDescription);
          continue;
        }

        if (deleteObject) {
          const deleteOverrideObject = this.defaultUtilityAllowanceOverridesList.find(
            duao => duao.category + duao.description === deleteObject.categoryDescription
          );
          if (deleteOverrideObject) {
            deleteIdList.push(deleteOverrideObject.id);
            operationalCategoryDescriptionCombinationList.push(categoryDescription);
          }
          continue;
        }

        if (updateObject) {
          if (updateObject.utilityAllowanceTableValueId !== uatv.id) {
            updateObject.utilityAllowanceTableId = uatv.utilityAllowanceTableId;
            updateObject.utilityAllowanceTableValueId = uatv.id;
            updateOverridesData.push(updateObject);
            operationalCategoryDescriptionCombinationList.push(categoryDescription);
          }
        }
      }
      if (updateOverridesData.length > 0) {
        // delete orphan object when user update entirely to a new utility allowance override
        // category and description that doesn't match the one of the existing in new utility allowance will be deleted
        if (operationalCategoryDescriptionCombinationList.length !== this.defaultUtilityAllowanceOverridesList.length) {
          for (const uao of this.defaultUtilityAllowanceOverridesList) {
            if (!operationalCategoryDescriptionCombinationList.includes(uao.category + uao.description)) {
              deleteIdList.push(uao.id);
            }
          }
        }
      }
            this.sugs.setOverridesCommandListObject({
        createCommands: this.sutas.buildCreateUtilityOverrideCommandList(createOverridesData, this.unitId),
        updateCommands: this.sutas.buildUpdateUtilityOverrideCommandList(updateOverridesData),
        deleteCommands: this.sutas.buildDeleteUtilityOverrideCommandList(deleteIdList),
      });
    } 
    this.sugs.setUtilityAllowanceTableObject(this.selectedUtilityAllowancesTable);
    this.updateFuntion(this.selectedUtilityAllowancesTable.id);
    this.globalModalParent.closePopup();
  }
}
