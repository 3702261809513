<div>
  <h1 mat-dialog-title class="addedit-header">{{addOrEdit}} RFTA Record</h1>

  <div *ngIf="spinnerCheck" class="centered_spinner">
    <mat-spinner></mat-spinner>
  </div>

  <div [ngClass]="{'disableBackground': this.overlay}">
    <div mat-dialog-content>
      <div class="section-header addedit-heading">General Information</div>

      <form [formGroup]="voucherRFTAForm" (ngSubmit)="onSubmit()">
        <div layout="row" class="layout-row addedit-body">
          <p flex="15" class="text-right marg-right-1 flex-15"><strong>Unit</strong></p>
          <p flex="30" class="ng-binding flex-30">
            Address: {{ data.voucher?.unit?.streetAddress }} Apt#: {{ data.voucher?.unit?.apartmentNumber }}
          </p>
        </div>
        <div layout="row" class="layout-row addedit-body">
          <p flex="15" class="text-right marg-right-1 flex-15"><strong>Landlord</strong></p>
          <p flex="30" class="ng-binding flex-30">
            {{ data.voucher.unit?.landlord?.firstName ? data.voucher.unit?.landlord?.firstName :
            (data.voucher.unit?.landlord?.companyName ? data.voucher.unit?.landlord?.companyName : "--")}}&nbsp;{{
            data.voucher.unit?.landlord?.lastName ? data.voucher.unit?.landlord?.lastName : null}}
          </p>
        </div>
        <div layout="row" class="layout-row addedit-body">
          <p flex="15" class="text-right marg-right-1 flex-15"><strong>RFTA Start Date</strong></p>
          <p flex="30" class="ng-binding flex-30">
            <mat-form-field>

              <input matInput [matDatepicker]="rftastartdate" [min]="data.voucher.issuedOn"
                placeholder="Choose Start Date" formControlName="rftaStartDate" (dateChange)="updateMinStopDate()" />
              <mat-datepicker-toggle matSuffix [for]="rftastartdate"></mat-datepicker-toggle>
              <mat-datepicker #rftastartdate></mat-datepicker>
            </mat-form-field>
          </p>
        </div>
        <div layout="row" class="layout-row addedit-body">
          <p flex="15" class="text-right marg-right-1 flex-15"><strong>RFTA Stop Date</strong></p>
          <p flex="30" class="ng-binding flex-30">
            <mat-form-field>
              <input matInput [matDatepicker]="rftastopdate" [min]="updatedMinDate" placeholder="Choose Stop Date"
                formControlName="rftaStopDate" />
              <mat-datepicker-toggle matSuffix [for]="rftastopdate"></mat-datepicker-toggle>
              <mat-datepicker #rftastopdate></mat-datepicker>
            </mat-form-field>
          </p>
        </div>
        <div layout="row" class="layout-row addedit-body">
          <p flex="15" class="text-right marg-right-1 flex-15"><strong>Result</strong></p>
          <p flex="30" class="ng-binding flex-30">
            <mat-radio-group aria-label="Select an option" formControlName="result">
              <mat-radio-button value="AWAITING_APPROVAL">Awaiting Approval</mat-radio-button>
              <mat-radio-button class="addedit-padradio" value="APPROVED">Approved</mat-radio-button>
              <mat-radio-button class="addedit-padradio" value="DENIED" (click)="clickFailed()">Denied
              </mat-radio-button>
            </mat-radio-group>
          </p>
        </div>
        <div *ngIf="failSelected" layout="row" class="layout-row addedit-body">
          <p flex="15" class="text-right marg-right-1 flex-15"><strong>Reason</strong></p>
          <p flex="30">
            <mat-radio-group aria-label="Select an option" formControlName="resultComments">
              <mat-radio-button value="HQS Fail">HQS Fail</mat-radio-button>
              <mat-radio-button class="addedit-padradio" value="Landlord Leased Unit">Landlord Leased Unit
              </mat-radio-button>
              <mat-radio-button class="addedit-padradio" value="Tenant Declined">Tenant Declined</mat-radio-button>
            </mat-radio-group>
          </p>
        </div>
      </form>
    </div>



    <div mat-dialog-actions class="addedit-button">
      <button mat-button (click)="onCancelClick()" class="md-button" type="button"
        [(disabled)]="spinnerCheck">Cancel</button>
      <button *ngIf="!data.editMode" mat-button class="md-button" type="submit" [(disabled)]="spinnerCheck"
        (click)="onSubmit($event)">
        Add RFTA Record
      </button>
      <button *ngIf="data.editMode" mat-button class="md-button" type="submit" [(disabled)]="spinnerCheck"
        (click)="onSubmit($event)">
        Save RFTA Record
      </button>
    </div>
  </div>
</div>