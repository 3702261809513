<div [ngClass]="{'disableBackground': this.overlay}">
 <section *ngIf="showPanelView == 'exported-actions'">
    <section class="tabs__panel">
      <section>
        <div layout="row">
          <h2>PIC Batching</h2>
        </div>
      </section>
      <hr class="throughTout" />
      <div>
        <div (click)="showFilterToggle()" class="filterRow">
          <mat-icon>filter_alt</mat-icon>
          <a class="filterButton">SHOW FILTERS</a>
        </div>
      </div>
    </section>

    <section class="tabs__panel">
      <div class="flexColumn">
        <nav>
          <ul class="nav-pills">
            <li>
              <a (click)="showPanelView = 'new-actions'" routerLinkActive="active">New Actions</a>
            </li>
            <li>
              <a class="nav-active" (click)="showPanelView = 'exported-actions'" routerLinkActive="active">Exported Actions</a>
            </li>
          </ul>
        </nav>

        <div *ngIf="this.loading">
          <mat-spinner diameter="50" class="centered_spinner"> </mat-spinner>
        </div>

        <div *ngIf="!this.loading">
          <table mat-table [dataSource]="exportedActionsBatch" class="altRows">
            <ng-container matColumnDef="Export">
              <th mat-header-cell *matHeaderCellDef>Exported #</th>
              <td mat-cell *matCellDef="let element">{{ element.sequenceNumberBySite }}</td>
            </ng-container>

            <ng-container matColumnDef="Exported On">
              <th mat-header-cell *matHeaderCellDef>Exported On</th>
              <td mat-cell *matCellDef="let element">{{ element.exportedOn.length ? (element.exportedOn | date: 'mediumDate') : '' }}</td>
            </ng-container>

            <ng-container matColumnDef="Exported By">
              <th mat-header-cell *matHeaderCellDef>Exported By</th>
              <td mat-cell *matCellDef="let element">{{ element.exportedByLastName }}</td>
            </ng-container>

            <ng-container matColumnDef="Actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let element">{{ element.countOfActions }}</td>
            </ng-container>

            <ng-container matColumnDef="Download">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element"><mat-icon *hasPermission="'admin || performPICExports'" svgIcon="download-icon" (click)="getExportedBatchById(element.picBatchId)"></mat-icon></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-header" (click)="getExportedAction(row)"></tr>
          </table>
          <p *ngIf="this.exportedActionsBatch == 0" class="centered_message">
            No Exported Actions found.
          </p>
        </div>
      </div>
    </section>
  </section>
</div>

<div [@openClose]="showFilter ? 'open' : 'closed'" class="dataFilter">
  <div class="flexColumn">
    <h4>Exported Date</h4>
    <div class="dateFlexRow">
      <div>From</div>
      <mat-form-field appearance="outline">
        <mat-label>Choose a start date</mat-label>
        <input matInput [matDatepicker]="picker" (dateChange)="setStartDate($event.value)" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <div>To</div>
      <mat-form-field appearance="outline">
        <mat-label>Choose a end date</mat-label>
        <input matInput [matDatepicker]="picker2" (dateChange)="setEndDate($event.value)" />
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <div>
      <h4>Exported By</h4>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Select Exported By</mat-label>
      <mat-select [formControl]="exportedByUsers" multiple>
        <mat-option *ngFor="let user of this.filteredAllUsers" [value]="user"> {{ user }} </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="buttonFlexRow">
    <a class="cancelLink " (click)="showFilterToggle()">
      Cancel
    </a>
    <button mat-button class="btn-right" (click)="applyFilter()">
      Apply
    </button>
  </div>
</div>

<div *ngIf="showPanelView == 'new-actions'">
  <app-pic-batching></app-pic-batching>
</div>

<div *ngIf="showPanelView == 'exported-actions-details'">
  <app-exported-actions-details [currentPicBatch]="this.exportedActionsDetail"></app-exported-actions-details>
</div>
