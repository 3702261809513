import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'kanso-wizard-content',
  templateUrl: './kanso-wizard-content.component.html',
  styleUrls: ['./kanso-wizard-content.component.scss'],
})
export class KansoWizardContentComponent implements OnInit {
  @Input() contentTitle: string;

  // eslint-disable-next-line
  constructor() {}
  // eslint-disable-next-line
  ngOnInit(): void {}
}
