<mat-card class="knso-card regional-rent-limit-container">
    <mat-card-header>
        <mat-toolbar>
            <span>Regional Rent Limit</span>
            <button mat-icon-button (click)="cancel()"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
    </mat-card-header>
    <form #popupForm="ngForm" novalidate *ngIf="!loading">
        <mat-card-content>
            <div class="layout-row topAndBottomPadding">
                <div class="rightPadding" *ngIf="!createMode">
                    <button 
                        [disabled]="editMode"
                        mat-flat-button
                        class="hds_button editButton"
                        (click)="enableEditMode()" 
                        id="editButton">
                        Edit
                    </button>
                </div>

                <mat-form-field class="editNameField" appearance="outline">
                    <mat-label>Name</mat-label>
                    <input 
                        matInput  
                        ngModel 
                        [(ngModel)]="editArea.name"
                        #editNameField="ngModel"
                        name="editNameField"
                        [disabled]="!editMode"
                        id="nameInput"
                        type="text"
                        required
                        aria-label="Name">
                </mat-form-field>
                <mat-form-field class="regionalLimitEffectiveDate" appearance="outline" *ngIf="!editEffectiveDate">
                    <mat-label>Effective Date</mat-label>
                    <mat-select ngModel
                        [(ngModel)]="selectedEffectiveDate"
                        (selectionChange)="effectiveDateChanged()"
                        [compareWith]="compareEffectiveDate"
                        aria-label="Select an effective date" 
                        #incomeAreaEffectiveDate="ngModel" 
                        name="incomeAreaEffectiveDate"
                        id="effectiveDateDropdown"
                        required>
                        <mat-option [value]="a.effectiveDate" *ngFor="let a of editArea.rentLimitTables; let $index=index">
                            {{a.effectiveDate |date:'yyyy/MM/dd'}}
                        </mat-option>
                    </mat-select> 
                </mat-form-field>
                <mat-form-field class="regionalLimitEffectiveDate" appearance="outline" *ngIf="editEffectiveDate">
                    <mat-label>New Effective Date:</mat-label>
                    <div class="layout-row">
                        <input class="leftPadding"
                            matInput 
                            [(ngModel)]="editArea.rentLimitTables[selectedIndex].effectiveDate" 
                            ngModel 
                            required
                            #effectiveDatePicker="ngModel"
                            name="effectiveDatePicker"
                            aria-label="New Effective Date"
                            [matDatepicker]="datepicker">
                        <mat-datepicker-toggle class="kansoBlue" matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                        <mat-datepicker class="kansoBlue" #datepicker></mat-datepicker>
                    </div>
                </mat-form-field>
                <div class="effectiveDateButtons">
                    <button *ngIf="editEffectiveDate"
                        appearance="outline"
                        type="button"
                        mat-flat-button 
                        class="hds_button"
                        (click)="saveNewEffectiveDate()" 
                        id="saveNewEffectiveDateButton">
                        Save New Effective Date
                    </button>
                    <button *ngIf="editEffectiveDate && editArea.rentLimitTables.length > 1"
                        appearance="outline"
                        type="button"
                        mat-flat-button 
                        class="hds_button"
                        (click)="cancelNewEffectiveDate()" 
                        id="cancelNewEffectiveDateBUtton">
                        Cancel New Effective Date
                    </button>
                    <button *ngIf="editMode && !editEffectiveDate"
                        appearance="outline"
                        type="button"
                        mat-flat-button 
                        class="hds_button"
                        (click)="addNewEffectiveDate()" 
                        id="addEffectiveDateButton">
                        Add Effective Date
                    </button>
                </div>
            </div>
            <div class="layout-column">
                <div class="addIncomePadding"> 
                    <button 
                        *ngIf="editMode" 
                        appearance="outline"
                        type="button"
                        mat-flat-button
                        class="hds_button"
                        (click)="addTableRow()" 
                        id="cancelButton">
                        Add Income Level
                    </button>
                </div>
                <table *ngIf="!editMode" class="altRows">
                    <thead>
                        <tr >
                            <th class="textCenter">Percent</th>
                            <th class="textCenter">Description</th>
                            <th class="textCenter">0 Bedroom</th>
                            <th class="textCenter">1 Bedroom</th>
                            <th class="textCenter">2 Bedrooms</th>
                            <th class="textCenter">3 Bedrooms</th>
                            <th class="textCenter">4 Bedrooms</th>
                            <th class="textCenter">5 Bedrooms</th>
                            <th class="textCenter">6 Bedrooms</th>
                            <th class="textCenter">7 Bedrooms</th>
                            <th class="textCenter">8 Bedrooms</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tr *ngFor="let f of editArea.rentLimitTables[selectedIndex].rentLimits; let i = index"
                        id="fairMarketInput">
                        <td><span *ngIf="!editMode" class="tablePadding" id="description">{{f.percentageOfRent}}</span></td>
                        <td><span *ngIf="!editMode" class="tablePadding" id="description">{{f.name}}</span></td>
                        <td><span *ngIf="!editMode && f.rentValues[0]" class="tablePadding" id="bedroom0">{{f.rentValues[0].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.rentValues[1]" class="tablePadding" id="bedroom1">{{f.rentValues[1].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.rentValues[2]" class="tablePadding" id="bedroom2">{{f.rentValues[2].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.rentValues[3]" class="tablePadding" id="bedroom3">{{f.rentValues[3].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.rentValues[4]" class="tablePadding" id="bedroom4">{{f.rentValues[4].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.rentValues[5]" class="tablePadding" id="bedroom5">{{f.rentValues[5].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.rentValues[6]" class="tablePadding" id="bedroom6">{{f.rentValues[6].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.rentValues[7]" class="tablePadding" id="bedroom7">{{f.rentValues[7].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.rentValues[8]" class="tablePadding" id="bedroom8">{{f.rentValues[8].amount | currency}}</span></td>
                </table>
                <table>
                    <tr *ngFor="let f of editArea.rentLimitTables[selectedIndex].rentLimits; let i = index"
                        id="fairMarketInput">
                        <td>
                            <mat-form-field appearance="outline" *ngIf="editMode" >
                                <mat-label>Percentage</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.percentageOfRent"
                                    #percentageOfRentField="ngModel"
                                    [name]="'percentageOfRentField' + i"
                                    id="percentageOfRentField"
                                    type="number"
                                    required
                                    aria-label="Percentage">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" *ngIf="editMode" >
                                <mat-label>Description</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.name"
                                    #descriptionField="ngModel"
                                    [name]="'descriptionField' + i"
                                    id="descriptionField"
                                    type="text"
                                    required
                                    aria-label="Description">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" *ngIf="editMode  && f.rentValues[0]" >
                                <mat-label>0 Bedroom</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.rentValues[0].amount"
                                    #0BedroomInput="ngModel"
                                    [name]="'0BedroomInput' + i"
                                    id="0BedroomInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" *ngIf="editMode  && f.rentValues[1]" >
                                <mat-label>1 Bedroom</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.rentValues[1].amount"
                                    #1BedroomInput="ngModel"
                                    [name]="'1BedroomInput' + i"
                                    id="1BedroomInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" *ngIf="editMode  && f.rentValues[2]" >
                                <mat-label>2 Bedrooms</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.rentValues[2].amount"
                                    #2BedroomInput="ngModel"
                                    [name]="'2Bedroomput' + i"
                                    id="2BedroomInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" *ngIf="editMode  && f.rentValues[3]" >
                                <mat-label>3 Bedrooms</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.rentValues[3].amount"
                                    #3BedroomInput="ngModel"
                                    [name]="'3BedroomInput' + i"
                                    id="3BedroomInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" *ngIf="editMode  && f.rentValues[4]" >
                                <mat-label>4 Bedrooms</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.rentValues[4].amount"
                                    #4BedroomInput="ngModel"
                                    [name]="'4BedroomInput' + i"
                                    id="4BedroomInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" *ngIf="editMode && f.rentValues[5]" >
                                <mat-label>5 Bedrooms</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.rentValues[5].amount"
                                    #5BedroomInput="ngModel"
                                    [name]="'5BedroomInput' + i"
                                    id="5BedroomInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" *ngIf="editMode && f.rentValues[6]" >
                                <mat-label>6 Bedrooms</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.rentValues[6].amount"
                                    #6BedroomInput="ngModel"
                                    [name]="'6BedroomInput' + i"
                                    id="6BedroomInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" *ngIf="editMode && f.rentValues[7]" >
                                <mat-label>7 Bedrooms</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.rentValues[7].amount"
                                    #7BedroomInput="ngModel"
                                    [name]="'7BedroomInput' + i"
                                    id="7BedroomInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" *ngIf="editMode && f.rentValues[8]" >
                                <mat-label>8 Bedrooms</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.rentValues[8].amount"
                                    #8BedroomInput="ngModel"
                                    [name]="'8BedroomInput' + i"
                                    id="8BedroomInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <div class="layout-row"  *ngIf="editMode">
                                <a style="font-size:18px;margin-left: 10px;" 
                                    (click)="deleteTableRow(i)"><mat-icon>delete</mat-icon>
                                </a>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </mat-card-content>

        <mat-card-actions>
            <button *ngIf="!editMode"
                type="button"
                mat-flat-button 
                class="hds_button cancel" 
                (click)="cancel()" 
                id="cancelButton">
                Close
            </button>
            <button *ngIf="editMode"
                type="button"
                mat-flat-button 
                class="hds_button cancel" 
                (click)="cancel()" 
                [disabled]="!editMode"
                id="cancelButton">
                Cancel
            </button>
            <button 
                type="button"
                mat-flat-button 
                class="hds_button" 
                (click)="onSubmit()" 
                [disabled]="!editMode || !popupForm.valid"
                id="saveButton">
                Update
            </button>
        </mat-card-actions>
    </form>
</mat-card>
