import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'busy-notice',
  templateUrl: './busy-notice.component.html',
  styleUrls: ['./busy-notice.component.scss'],
})
export class BusyNoticeComponent {
  @Input() isBusy: boolean;
  @Input() busyText: string;
  @Input() headerText: string;
}
