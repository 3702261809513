import {Component, OnInit, Injector} from '@angular/core';
import {FormControl} from '@angular/forms';
import {GlobalModalService} from '../../../../kanso-common/core/service/globalmodal.service';
import {AccountingService} from 'src/app/custom/accounting/service/accounting.service';
import {CoreService} from '../../../../core/service/core.service';
import {KansoActionResult} from '../../../../core/service/core-models';
import {map, startWith} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {GeneralLedgerAccount} from '../../../accounting/service/accounting-models';
import {GeneralLedgerModalData} from '../models/accounting-setup.models';
import {GeneralLedgerEditComponent} from './general-ledger-edit/general-ledger-edit.component';

@Component({
  selector: 'general-ledger',
  templateUrl: './general-ledger.component.html',
  styleUrls: ['./general-ledger.component.scss'],
})
export class GeneralLedgerComponent {
  glAccounts: GeneralLedgerAccount[];
  filteredAccounts$: Observable<GeneralLedgerAccount[]>;
  accountFilter = new FormControl('');
  accountSort = new FormControl('');
  selectedAccount: GeneralLedgerAccount;
  accountSentToApi: GeneralLedgerAccount;
  loading = true;
  error = false;
  errorMsg = '';
  showPage = 'general-ledger';

  private deleteConfirmMsg =
    'Deleting a General Ledger Account cannot be undone. Any previous ledger activity with this account will need to be manually adjusted.';
  private deleteActionMsg = 'Do you wish to continue?';

  constructor(
    public globalModalService: GlobalModalService,
    public coreService: CoreService,
    protected injector: Injector,
    private accountingService: AccountingService
  ) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.getAccts();
  }

  getNavClass(page: string) {
    return page === this.showPage ? 'nav-active' : '';
  }

  getAccts() {
    this.accountingService.getAllGeneralLedgerAccounts().subscribe(
      response => {
        this.glAccounts = response.body;
        this.filteredAccounts$ = combineLatest([
          this.accountFilter.valueChanges.pipe(
            startWith(null),
            map(value => value)
          ),
          this.accountSort.valueChanges.pipe(
            startWith('name'),
            map(value => value)
          ),
        ]).pipe(map(([aName, aSort]) => this._filterAccounts(aSort, aName)));
        this.loading = false;
      },
      error => {
        console.log(`Error fetching accounts: ${error}`);
        this.errorMsg = 'An unexpected error occurred fetching general ledger records. Please try again.';
        this.loading = false;
        this.error = true;
      }
    );
  }

  private _filterAccounts(sortValue: string, filterValue?: string): GeneralLedgerAccount[] {
    return filterValue
      ? this._sortByProperty(
          this.glAccounts.filter(account => account.name.toLowerCase().includes(filterValue.toLowerCase())),
          sortValue
        )
      : this._sortByProperty(this.glAccounts, sortValue);
  }

  private _sortByProperty(accounts: GeneralLedgerAccount[], propertyName: string) {
    return accounts.sort((a: GeneralLedgerAccount, b: GeneralLedgerAccount) => {
      const aName = a[propertyName].toLowerCase();
      const bName = b[propertyName].toLowerCase();
      switch (true) {
        case aName > bName:
          return 1;
        case aName < bName:
          return -1;
        default:
          return 0;
      }
    });
  }

  editGeneralLedgerAccount(account: GeneralLedgerAccount | string) {
    const isNew = typeof account === 'string';
    const temp = new GeneralLedgerAccountClass();
    this.selectedAccount = isNew ? new GeneralLedgerAccountClass() : (account as GeneralLedgerAccount);

    this.globalModalService.openModal<GeneralLedgerModalData>(
      GeneralLedgerEditComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      {generalLedgerAccount: this.selectedAccount, updateFunction: this.saveAccount, editMode: !isNew}
    );
  }

  deleteGeneralLedgerAccount(account: GeneralLedgerAccount) {
    this.selectedAccount = account;
    this.coreService.confirmAction(
      this.deleteConfirmMsg,
      this._deleteGeneralLedgerAccount,
      `Confirm Delete - "${this.selectedAccount.name}"`,
      this.deleteActionMsg
    );
  }

  private _deleteGeneralLedgerAccount = () => {
    this.accountingService.deleteGeneralLedgerAccount(this.selectedAccount).subscribe(res => {
      if (res.statusCode === 200) {
        console.log(`Deleted: ${JSON.stringify(this.selectedAccount)}`);
        this.selectedAccount = null;
      } else {
        this.coreService.displayError(
          `Error deleting ${this.selectedAccount.name}: ${res.message}`,
          null,
          'Error deleting General Ledger Account'
        );
      }
      this.getAccts();
    });
  };
  refreshAccts = () => {
    this.getAccts();
  };

  //have to use the = () => format to pass as an input to the modals
  saveAccount = () => {
    this.accountSentToApi = Object.assign({}, this.selectedAccount);
    if (!this.selectedAccount._id) {
      this.accountingService.createGeneralLedgerAccount(this.selectedAccount).subscribe(this.saveAccountHandler);
    } else {
      this.accountingService.updateGeneralLedgerAccount(this.selectedAccount).subscribe(this.saveAccountHandler);
    }
  };

  saveAccountHandler = (response?: KansoActionResult<GeneralLedgerAccount>) => {
    if (response.statusCode === 200) {
      this.selectedAccount = response.body;
      if (!this.accountSentToApi._id) {
        this.getAccts();
      }
    } else {
      this.coreService.displayError(
        `Error trying to save General Ledger Account ${this.accountSentToApi.name}: ${response.message}`,
        this.refreshAccts,
        'Error saving account'
      );
      console.log(`Error trying to save General Ledger Account: ${JSON.stringify(this.accountSentToApi)} error: ${response.message}`);
    }
  };
}

export class GeneralLedgerAccountClass implements GeneralLedgerAccount {
  name: string;
  accountCode: string;
  _id?: string;
  createdOn?: Date;
  createdBy?: string;
  modifiedOn?: Date;
  modifiedBy?: string;
}
