<busy-notice
    [isBusy]="this.isBusy"
    [busyText]="this.busyText"
    headerText="KANSO">
</busy-notice>

<section>
    <div class="tabs__panel tabs__panel--ledger layout-column">
        <div class="member-summary">
            <span class="member-summary__span">{{vendorName}}</span>
            <span class="member-summary__span">{{vendorType}}</span>
        </div>
    </div>
</section>

<section class="widget__container layout-row">
    <div class="widget widget--accounts flex-66">
        <div class="accounts__actions layout-row  addBoxshadow roundedCorners" layout-align="space-between">
            <div class="adjust-filter-container" *ngIf="showPage === 'payout-history'">
                <!-- Future development -->
            </div>
            <div class="layout-row accounts-detail" *ngIf="showPage === 'individual-accounts'">
                <div (click)="showFilters = true" class="centerWhiteIcons">
                    <mat-icon class="material-icons-outlined">
                        filter_alt
                    </mat-icon>
                    <a id="tenantLedgerFilterButton">Filters</a>
                </div>
                <div *ngIf="showFilters" class="tenant-ledger-filter" match-height="#js-account-details-body"
                        offset="55">
                    <div class="filterBody layout-column">
                        <div class="date-range-container">
                            <strong>Date Range</strong>
                            <div class="date-range">
                                <div>
                                    <p>From</p>
                                    <mat-form-field appearance="outline" class="ledger-date-picker">
                                        <mat-label flex="20">Min Date:</mat-label>
                                        <input 
                                            matInput 
                                            [(ngModel)]="minDate" 
                                            name="minDate"
                                            aria-label="Min Date"
                                            [matDatepicker]="minDatePicker">
                                            <mat-datepicker-toggle matIconSuffix [for]="minDatePicker" ></mat-datepicker-toggle>
                                            <mat-datepicker #minDatePicker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <p>to</p>   
                                    <mat-form-field appearance="outline" class="ledger-date-picker">
                                        <mat-label flex="20">Max Date:</mat-label>
                                        <input 
                                            matInput 
                                            [(ngModel)]="maxDate" 
                                            name="minDate"
                                            aria-label="Max Date"
                                            [matDatepicker]="maxDatePicker">
                                            <mat-datepicker-toggle matIconSuffix [for]="maxDatePicker" ></mat-datepicker-toggle>
                                            <mat-datepicker #maxDatePicker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <a (click)="clearDates()">Clear Dates</a>
                        </div>
                        

                            <strong class="marg-bottom-half">Other Options</strong>
                            <!-- Add Back in once On Hold is implemented on vendorTransactions -->
                            <!-- <mat-checkbox [(ngModel)]="showOnlyHoldTransactions">
                                Show Only On Hold Transactions
                            </mat-checkbox> -->

                            <mat-checkbox [(ngModel)]="includeVoidedTransactions">
                                Include Voided Transactions
                            </mat-checkbox>

                        <div class="layout-row layout-align-end-center">
                            <a (click)="showFilters = false">Cancel</a>
                            <button mat-flat-button class="hds_button" id="applyFiltersButton"
                                (click)="applyFilters()">
                                Apply</button>
                        </div>
                    </div>
                </div>
                <div (click)="openPostAdjust()" class="centerWhiteIcons">
                    <mat-icon class="material-icons-outlined">
                        check_circle
                    </mat-icon>
                    <a id="makeAdjustment" has-permission="'postAdjustment'">Adjust</a>
                </div>
            </div>
        </div>

        <div class="tabs__panel layout-column roundedCorners accounts-transaction-payout" id="js-account-details-body">
            <div  class="layout-row layout-align-start-center" >
                <nav>
                    <ul class="nav-pills">
                    <li>
                        <a [class]="getNavClass('payout-history')" (click)="showPage = 'payout-history'" routerLinkActive="disabled">Payout History</a>
                    </li>
                    <li>
                        <a [class]="getNavClass('individual-accounts')" (click)="showPage = 'individual-accounts'" routerLinkActive="active">Individual Accounts</a>
                    </li>
                    </ul>
                </nav>
            </div>
            <div *ngIf="showPage === 'payout-history'">
                <table class="account__details-table" *ngIf="payoutHistory && payoutHistory.length > 0; else noPayout">
                    <thead>
                        <tr class="borderBottom">
                            <th class="account__details-cell account__details-cell--header">Date</th>
                            <th class="account__details-cell account__details-cell--header">Subcategory</th>
                            <th class="account__details-cell account__details-cell--header" >Amount</th>
                            <th class="account__details-cell account__details-cell--header">Batch Id</th>
                            <th class="account__details-cell account__details-cell--header">#of Adjs</th>
                        </tr>
                    </thead>
                    <tbody class="account__details-tbody" *ngFor="let payout of payoutHistory; let $index=index">
                        <tr class="account__details-row account__details-row--green">
                            <td class="account__details-cell account__details-cell--date">
                                <div id="createdOnDate{{$index}}">{{ payout.createdOn | date :'MM/dd/YYYY' }}</div>
                            </td>
                            <td class="account__details-cell">
                                <div id="subCategoryName{{$index}}" title="{{payout.chargeTypeName}}">{{ payout.chargeTypeName }}</div>
                            </td>
                            <td class="account__details-cell">
                                <div id="amount{{$index}}" title="{{payout.totalAmount}}">{{ payout.totalAmount | currency }}</div>
                            </td>
                            <td class="account__details-cell">
                                <div id="batchId{{$index}}" title="{{payout.batchId}}">{{ payout.batchId ? payout.batchId : 'N/A' }}</div>
                            </td>
                            <td class="account__details-cell">
                                <div id="numberOfTransactions{{$index}}" title="{{payout.numberOfTransactions}}">{{ payout.numberOfTransactions }}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="pageNavigation" *ngIf="payoutHistory && payoutHistory.length > 0">
                    <page-nav
                        [totalCount]="payOutTotalCount"
                        [pageInfo]="payOutPageInfo"
                        [currentRecordsCount]="currentPayoutRecordsCount"
                        [pageSize]="payOutPageSize"
                        (goToPage)="goToPageHandler($event, true)">
                    </page-nav>
                </div>
                <ng-template #noPayout>
                    <div class="info-container">
                        <p class="info">There have been no payouts made to this vendor</p>
                        <img alt="image" src='assets/img/LedgerTabImage.jpg'>
                    </div>
                </ng-template>
            </div>
            <div *ngIf="showPage === 'individual-accounts'">
                <div class="nameAddress" *ngIf="selectedAccountId">
                    <h5 *ngIf="tenantAddress" id="ledgerName" >{{tenantName}} - {{tenantAddress}}</h5>
                    <div>
                        <a id="tenantLedgerPrintButton" class="output-icon" (click)="print()"><mat-icon>text_snippet</mat-icon></a>
                    </div>
                </div>
                <table class="account__details-table" *ngIf="transactions && transactions.length > 0; else noTransaction">
                    <thead>
                    <tr class="borderBottom">
                        <th class="account__details-cell account__details-cell--header account__details-cell--collapse"></th>
                        <th class="account__details-cell account__details-cell--header">Date</th>
                        <th class="account__details-cell account__details-cell--header">Category</th>
                        <th class="account__details-cell account__details-cell--header" >TYPE</th>
                        <th class="account__details-cell account__details-cell--header account__details-cell--amount">Amount</th>
                        <th class="account__details-cell account__details-cell--header account__details-cell--amount" *ngIf="displayRunningBalance">Balance</th>
                    </tr>
                    </thead>
                    <tbody class="account__details-tbody" *ngFor="let t of transactions; let $index=index">
                        <tr class="account__details-row"
                        (click)="t.isCollapsed = !t.isCollapsed" id="toggleTransactionButton{{$index}}"
                        [ngClass]="{'account__details-row--open' : !t.isCollapsed, 'account__details-row--green' : ( t.amount > 0), 'account__details-row--voided': t.voidedOn}"
                        >

                        <td class="account__details-cell account__details-cell--collapse"
                            [ngClass]="{'account__details-cell--collapse--green' : (t.amount > 0)}">
                            <a class="account__details-collapse grayChevron">
                                <mat-icon class="collapse-icon" [ngClass]="{'collapse-icon--open' : !t.isCollapsed}">chevron_right</mat-icon>
                            </a>
                        </td>
                    

                        <td class="account__details-cell account__details-cell--date">
                            <div id="postedOnDate{{$index}}">{{t.postedOn | date :'MM/dd/YYYY' : 'UTC'}}</div>
                        </td>
                        <td class="account__details-cell account__details-cell--description">
                            <div id="subCategoryName{{$index}}" title="{{t.description}}">{{t.chargeTypeName}}</div>
                        </td>
                        <td class="account__details-cell">
                            ADJ
                        </td>
                        <td class="account__details-cell account__details-cell--amount"
                            [ngClass]="{'account__details-cell--green' : ( t.amount > 0)}">
                            <div *ngIf="t.amount <= 0" id="chargeTransactionAmount{{$index}}">{{-t.amount | currency}}</div>
                            <div *ngIf="t.amount > 0" [ngClass]="{'currencyColor parensWrap':(t.amount > 0)}" id="adjustentTransactionAmount{{$index}}">{{t.amount  | currency}}</div>
                        </td>
                        <td class="account__details-cell account__details-cell--balance" *ngIf="displayRunningBalance">
                            <span *ngIf="!t.voidedOn" [ngClass]="{'currencyColor parensWrap':(t.runningBalance > 0), 'color-red':(t.runningBalance < 0)}">{{t.runningBalance | currency}}</span>
                        </td>
                        <td class="account__details-cell--void-overview" *ngIf="t.voidedOn">VOID</td>
                        </tr>
                        <tr class="account__dropdown-row" *ngIf="!t.isCollapsed">
                            <td></td>
                            <td colspan="5">
                                <vendor-transaction-detail [transaction]="t" (voidedBatchId)="voidAdditionalTransactions($event)"></vendor-transaction-detail>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="pageNavigation" *ngIf="transactions && transactions.length > 0">
                    <page-nav
                        [totalCount]="ledgerTotalCount"
                        [pageInfo]="ledgerPageInfo"
                        [currentRecordsCount]="currentLedgerRecordsCount"
                        [pageSize]="ledgerPageSize"
                        (goToPage)="goToPageHandler($event, false)">
                    </page-nav>
                </div>
                <ng-template #noPayout>
                    <div class="info-container">
                        <p class="info">There have been no payouts made to this vendor</p>
                        <img alt="image" src='assets/img/LedgerTabImage.jpg'>
                    </div>
                </ng-template>
                <ng-template #noTransaction>
                    <div class="info-container">   
                        <p class="info">
                            {{
                                selectedAccountId 
                                ? 'No Transactions for this Category or Date Range'
                                : 'Please select an account on the right to display detailed transaction data'
                            }}
                        </p>
                        <img alt="image" src='assets/img/LedgerTabImage.jpg'>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

     <div class="layout-column flex-33">
        <section class="widget account-balances-container">
            <div class="widget__header roundedCorners addBoxshadow">
                <div class="centerWhiteIcons">
                    Account Balances
                </div>
            </div>
            <div class="layout-column layout-padding widget__body roundedCorners">
                <div *ngIf="vendorLedgerReport && vendorLedgerReport.length; else noReport">
                    <mat-form-field  appearance="outline" class="account-balance-input">
                        <input 
                            matInput
                            aria-label="Account"
                            [matAutocomplete]="vendorAccountAuto"
                            [formControl]="vendorAccountCtrl"
                            placeholder="Search by address or tenant....">
                        <button mat-button *ngIf="vendorAccountCtrl.value" matSuffix mat-icon-button (click)="clearVendorAccountSelection()">
                            <mat-icon>close</mat-icon>
                        </button>  
                        <mat-autocomplete #vendorAccountAuto="matAutocomplete" [displayWith]="vendorAccountSelectDisplay" (optionSelected)="onAccountSelected($event)">
                            <mat-option *ngFor="let vl of filteredVendorLedgerReport;" 
                                [value]="vl">
                                <span style="font-size: small;">{{vl.infoToSearch}}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <div class="layout-column altRows">
                        <ng-container *ngIf="vendorLedgerReport && vendorLedgerReport.length">
                            <div id="accountGroup{{$index}}" *ngFor="let ledger of vendorLedgerReport; let $index = index;">
                                <div class="layout-column">
                                    <div class="layout-row">
                                        <p><span>{{ledger.expectedDisplayInfo}}</span></p>                          
                                        <span *ngIf="ledger.node.deletedOn"> (inactive)</span>
                                    </div>
                                    <ng-container> 
                                        <div class="layout-row" layout-align="space-between">
                                            <a id="account" (click)="getTransactions(ledger.node.id)" [ngClass]="{'color-default' : selectedAccountId === ledger.node.id}">
                                                <span>{{ledger.node.displayName}}</span>
                                            </a>
                                            <span class="leftPadding" id="closingBal{{$index}}"
                                                [ngClass]="{'currencyColor parensWrap':(ledger.closingBalance > 0), 'color-red':(ledger.closingBalance < 0)}"
                                                >{{ledger.closingBalance | currency}}</span>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>
        
                    <div class="account-page-navigation">
                        <div class="navControlsWrapper">
                            <mat-icon [class]="navButtonClass('previous')" (click)="goPage('previous')" >navigate_next</mat-icon>
                            <span class="pageOfText">
                                {{accountCurrentPage}}
                            </span>
                            <mat-icon [class]="navButtonClass('next')" (click)="goPage('next')">navigate_next</mat-icon>
                        </div>
                    </div>
                </div>
                <ng-template #noReport>
                    <div class="lightWeight">There are no accounts associated with this vendor.</div>
                </ng-template>
            </div>
        </section>

        <section class="widget">
            <div class="notification-slide">
                <div class="roundTop banner deposits"
                    [ngClass]="{'is-green': amountOwedTotal >= 0, 'is-red': amountOwedTotal < 0}">
                    Payables Owed
                </div>
                <div class="roundBottom body layout-row layout-row payablesBoxShadow">
                    <div class="amount layout-align-center-center layout-column flex-none" flex="none">
                        <div class="layout-row layout-align-center-center"
                            [ngClass]="{'cta-red': amountOwedTotal < 0, 'cta-green': amountOwedTotal >= 0}">
                            {{amountOwedTotal | currency}}
                        </div>
                        <div>PAYABLES OWED</div>
                    </div>
                    <div class="layout-column flex-grow" layout-align="center start"
                        class="layout-align-center-start layout-column flex-grow">
                        <div>Total payables owed across all AP accounts related to this vendor </div>
                    </div>
                </div>
                </div>
        </section>
    </div>
</section>