import {Component, Input} from '@angular/core';
import {GlobalModalChild, GlobalModalParent} from '../../../../kanso-common/core/service/globalmodal.service';
import {OfficeLocation, OfficeLocationsModalData} from '../../../housing-core/services/housing-models';

@Component({
  selector: 'edit-office-location',
  templateUrl: './edit-office-location.component.html',
  styleUrls: ['./edit-office-location.component.scss'],
})
export class EditOfficeLocationComponent implements GlobalModalChild<OfficeLocationsModalData>, OfficeLocationsModalData {
  @Input() officeAddresses: OfficeLocation[];
  // eslint-disable-next-line
  @Input() updateFunction: Function;
  @Input() editMode: boolean;
  _selectedLocation: OfficeLocation;
  @Input() set selectedLocation(setLocation: OfficeLocation) {
    this._selectedLocation = setLocation;
    this.editOfficeLocation = {
      ...setLocation,
      address: [{...setLocation.address[0]}],
    };
  }

  globalModalParent: GlobalModalParent;
  editOfficeLocation: OfficeLocation;

  // eslint-disable-next-line
  get selectedLocation(): OfficeLocation {
    // eslint-disable-next-line
    return this._selectedLocation;
  }

  cancel() {
    this.globalModalParent.closePopup();
  }

  onSubmit() {
    this.selectedLocation = Object.assign(this._selectedLocation, {
      ...this.editOfficeLocation,
      address: [{...this.editOfficeLocation.address[0]}],
    });
    this.updateFunction();
    this.globalModalParent.closePopup();
  }
}
