import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {MaterialModule} from '../../../projects/material/src/public_api';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';

import {PicBatchingComponent} from './pic-batching/pic-batching.component';
import {ComingSoonPopupComponent} from './coming-soon-popup/coming-soon-popup.component';
import {MincComponent} from './minc/minc.component';
import {TicComponent} from './tic/tic.component';
import {HmisComponent} from './hmis/hmis.component';
import {ErrorPopupComponent} from './error-popup/error-popup.component';
import {ExportedActionsComponent} from './exported-actions/exported-actions.component';
import {ExportedActionsDetailsComponent} from './exported-actions/exported-actions-details.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  declarations: [
    PicBatchingComponent,
    ComingSoonPopupComponent,
    MincComponent,
    TicComponent,
    HmisComponent,
    ErrorPopupComponent,
    ExportedActionsComponent,
    ExportedActionsDetailsComponent,
  ],
  // Shared Module has the system-wide custom directive hasPermissions
  imports: [SharedModule, CommonModule, MaterialModule, MatIconModule, HttpClientModule, MatDialogModule, FormsModule, ReactiveFormsModule],
  entryComponents: [
    PicBatchingComponent,
    ComingSoonPopupComponent,
    MincComponent,
    TicComponent,
    HmisComponent,
    ErrorPopupComponent,
    ExportedActionsComponent,
    ExportedActionsDetailsComponent,
  ],
})
export class DataExportModule {}
