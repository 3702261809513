<div collapse="transaction.isCollapsed" class="collapsable">
    <div class="account-details-cell--details">
        <div class="btn-group" *ngIf="!transaction.voidedOn">
            <button id="printTransactionButton" class="blueLightColor" (click)="openPrintableTransaction();">
                Print
            </button>
            <button id="editTransactionButton" class="blueLightColor" [disabled]="isInEditMode || isBusy" (click)="editTransaction()"
                has-permission="'editExistingTransactions'" remove>
                Edit
            </button>
            <button class="color-red" id="voidTransactionButton" (click)="voidTransaction()" has-permission="'voidTransactions'"
                remove>
                Void
            </button>
        </div>

        <table class="account__dropdown-table" id="printable" ng-form name="transactionForm">
            <tr>
                <th class="account__dropdown-cell account__dropdown-cell--label" scope="row">
                    Reference:
                </th>
                <td class="account__dropdown-cell">
                    {{transaction.sequence}}
                </td>
                <th class="account__dropdown-cell account__dropdown-cell--label" scope="row" style="width:90px">
                    Posted on:
                </th>
                <td id="postedOnDate" class="account__dropdown-cell" class="layout-row layout-align-start-center">
                    <span>{{transaction.postedOn | date: 'MMM d, YYYY'}}</span>
                </td>
            </tr>
            <tr>
                <th class="account__dropdown-cell account__dropdown-cell--label" scope="row">
                    Created by:
                </th>
                <td class="account__dropdown-cell">
                    <span id="createdByUser">{{transaction.createdBy}}</span>
                </td>
                <th class="account__dropdown-cell account__dropdown-cell--label" scope="row">
                    Account:
                </th>
                <td class="account__dropdown-cell">
                    <span>{{transaction.vendorAccountName}}</span>
                </td>
            </tr>
            <tr>
                <th class="account__dropdown-cell account__dropdown-cell--label" scope="row">
                    Created on:
                </th>
                <td id="transactionCreatedOn" class="account__dropdown-cell">{{transaction.createdOn | date: 'mediumDate'}}</td>
                <th class="account__dropdown-cell account__dropdown-cell--label" scope="row">
                    Type:
                </th>
                <td class="account__dropdown-cell">
                    <span id="transactionCategory">{{transaction.subLedgerMappingName}}</span>,&nbsp;
                    <span id="transactionSubCategory">{{transaction.chargeTypeName}}</span>
                </td>
            </tr>
            <tr>
                <th class="account__dropdown-cell account__dropdown-cell--label" scope="row">
                    Transaction Type:
                </th>
                <td id="transactionType" class="account__dropdown-cell">Adjustment</td>
            </tr>
            <tr>
                <th class="account__dropdown-cell account__dropdown-cell--label" scope="row">
                    Program:
                </th>
                <td id="transactionsProgram" class="account__dropdown-cell">
                    {{transaction.programName || 'N/A'}}
                </td>
                <th *ngIf="transaction.voidedOn" class="account__dropdown-cell account__dropdown-cell--label" scope="row">Voided On:</th>
                <td *ngIf="transaction.voidedOn" class="account__dropdown-cell">{{transaction.voidedOn | date}}</td>
            </tr>
            <tr>
                <th class="account__dropdown-cell account__dropdown-cell--label" scope="row">
                    Project:
                </th>
                <td id="transactionsProject" class="account__dropdown-cell">
                    {{transaction.projectName || 'N/A'}}
                </td>
                <th *ngIf="transaction.voidedOn" class="account__dropdown-cell account__dropdown-cell--label" scope="row">Voided By:</th>
                <td *ngIf="transaction.voidedOn" class="account__dropdown-cell">{{transaction.voidedBy}}</td>
            </tr>
            <tr>
                <th class="account__dropdown-cell account__dropdown-cell--label" scope="row">
                    Check or MO No.:
                </th>
                <td class="account__dropdown-cell">
                    <span *ngIf="!isInEditMode"> {{transaction.checkNumber || 'N/A'}}</span>
                    <textarea id="transactionCheckNumber" class="hds-text-input hds-textarea" *ngIf="isInEditMode" [(ngModel)]="editedCheckNumber"></textarea>
                </td>
            </tr>
            <tr>
                <th class="account__dropdown-cell account__dropdown-cell--label" scope="row">
                    Description:
                </th>
                <td class="account__dropdown-cell">
                    <span *ngIf="!isInEditMode"> {{transaction.description || 'N/A'}}</span>
                    <textarea id="transactionDescription" class="hds-text-input hds-textarea" *ngIf="isInEditMode" [(ngModel)]="editedDescription"></textarea>
                </td>
            </tr>
            <tr *ngIf="isInEditMode">
                <td></td>
                <td></td>
                <td></td>
                <td>
                    <button mat-flat-button class="hds_button" id="cancelEditButton" (click)="cancelEdit()" [disabled]="isBusy">Cancel</button>
                    <button mat-flat-button class="hds_button" (click)="updateTransaction()" [disabled]="isBusy" id="saveUpdatedTransaction">Save</button>
                </td>
            </tr>
        </table>
    </div>
</div>