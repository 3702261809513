import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {FileroomUploaderComponent, FileroomDashboardComponent, FileroomService} from '@hds1/fileroom';
import {ParameterStoreService, ParameterStoreVariables} from 'src/app/core';

@Component({
  selector: 'app-fileroom-upload-button',
  templateUrl: './fileroom-upload-button.component.html',
  styleUrls: ['./fileroom-upload-button.component.scss'],
})
export class FileroomUploadButtonComponent {
  @Input() refreshDelegate;
  @Input() householdId = null;
  @Input() userId = null;
  @Input() siteSection = null; // household, unit, inspection, etc.
  @Input() siteSectionId = null;
  @Input() siteSubsection = null; // documents, images, etc

  constructor(private parameterStoreService: ParameterStoreService, public dialog: MatDialog) {
    this.setEnvironmentParameters();
  }

  async setEnvironmentParameters(parameters: ParameterStoreVariables[] = []): Promise<string[]> {
    const fetchedParameters = await this.parameterStoreService.fetchAndSetParameters(parameters);
    return fetchedParameters;
  }

  public openUploadDialog(): void {
    const siteId = sessionStorage.getItem('SITEID');
    const customerId = sessionStorage.getItem('CUSTOMERID');
    const api = sessionStorage.getItem('FILEROOM_SERVICE_URI');
    const apikey = sessionStorage.getItem('FILEROOM_SERVICE_API_KEY');

    const dialogRef = this.dialog
      .open(FileroomUploaderComponent, {
        data: {
          apiEndpoint: api,
          apiKey: apikey,
          userId: this.userId,
          customerId: customerId,
          siteId: siteId,
          householdId: this.householdId ?? null,
          siteSection: this.siteSection,
          siteSectionId: this.siteSectionId,
          siteSubsection: this.siteSubsection ?? null,
        },
      })
      .afterClosed()
      .subscribe(result => {
        console.log('The upload dialog was closed', result);
        if (result) {
          this.refreshDelegate();
        }
      });
  }
}
