/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ajax, AjaxResponse} from 'rxjs/ajax';

@Injectable({
  providedIn: 'root',
})
export class FeatureConfigurationService {
  constructor(private http: HttpClient) {}

  getConfig(slug: string): Observable<AjaxResponse> {
    const apiData = ajax(`/api/configuration/${slug}`).pipe(catchError(this.handleError));
    return apiData;
  }

  private handleError(err: HttpErrorResponse) {
    // -Implement error display for user
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // Client-side or network error occured
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response
      errorMessage = `Server returned code: ${err.status}, error message is ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
