import {Component, Input, OnInit} from '@angular/core';
import {GlobalModalChild, GlobalModalParent} from 'src/app/kanso-common/core/service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PostedOn} from 'src/app/custom/vendor/vendor.service';
@Component({
  selector: 'app-payable-posted-on',
  templateUrl: './payable-posted-on.component.html',
  styleUrls: ['./payable-posted-on.component.scss'],
})
export class PayablePostedOnComponent implements GlobalModalChild<PostedOn>, PostedOn, OnInit {
  // eslint-disable-next-line
  @Input() updateFunction: Function;

  form: FormGroup;
  date: Date;
  description: string;
  globalModalParent: GlobalModalParent;

  // eslint-disable-next-line
  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      postedOnDate: [this.date],
      postedOnDescription: [this.description],
    });
  }

  onClosePostedOn(): void {
    this.globalModalParent.closePopup();
  }

  onSubmitPostedOn(): void {
    this.updateFunction(this.form);
    this.globalModalParent.closePopup();
  }

  keyDown(event: KeyboardEvent): void {
    // eslint-disable-next-line
    if (!event.key.match(/[0-9\/]/g)) {
      event.preventDefault();
    }
  }
}
