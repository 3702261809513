import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {GlobalModalChild, GlobalModalParent} from 'src/app/kanso-common/core/service';

@Component({
  selector: 'app-post-adjustment',
  templateUrl: './post-adjustment.component.html',
  styleUrls: ['./post-adjustment.component.scss'],
})
export class PostAdjustmentComponent implements GlobalModalChild<PaymentDetail>, PaymentDetail, OnInit {
  form;
  landLord: string;
  vendorDetail: VendorDetail;
  options: string[] = ['HAP', 'WO labor'];
  description: string;
  globalModalParent: GlobalModalParent;
  constructor(private fb: FormBuilder) {}
  ngOnInit(): void {
    this.form = this.fb.group({
      selectedPaymentOption: [this.vendorDetail.paymentType],
      transactionAmount: [this.vendorDetail.transactionAmount],
      lastPayedAmountDate: [new Date(this.vendorDetail.lastPayedAmountDate)],
      transactionDescription: [this.description],
    });
  }

  onCancelPayment(): void {
    this.globalModalParent.closePopup();
  }

  onPostPayment(): void {
    this.globalModalParent.closePopup();
  }
}

interface VendorDetail {
  transactionName: string;
  transactionNumber: string;
  lastPayedAmountDate: string;
  paymentType: string;
  transactionAmount: number;
}

interface PaymentDetail {
  landLord: string;
  vendorDetail: VendorDetail;
}
