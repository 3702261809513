<section class="tabs__panel layout-column" >
    <div class="page-header">
        <h2>Subledger Mappings</h2>

        <p> 
            <span class="glyphicon glyphicon-warning-sign"></span>
            <strong>Subledger Mappings</strong> are a collection of categories.
             A subledger mapping is later attributed to an account definition.
             While there is much flexibility in creating a subledger mapping,
             it would be best practice to have one subledger mapping for each
             account definition type in your site (ex: Accounts Receivable, Deposit,
             Principal Account, Equity Account, etc) or by Program type (Low Rent, Loan, Assistance, etc).
        </p>
    </div>

    <section>
        <div class="tab-header">
            <mat-form-field appearance="outline">
                <input 
                   matInput
                   [formControl]="subledgerMapFilter"
                   placeholder="Search..."/>
            </mat-form-field>
            <div class="top-buttons">
                <button
                   mat-flat-button
                   (click)="editSubledgerMap()"  
                   class="hds_button" 
                   style="margin: 20px 0;">
                   Add New
                </button>
            </div>    
        </div>
    </section>

    <section>
        <div *ngIf="this.loading">
            <mat-spinner diameter="25" class="centered_spinner"> </mat-spinner> Loading subledger maps data
        </div>
        <div *ngIf="!this.loading">
            <p *ngIf="(!subledgerMaps || !subledgerMaps.length) && !error">There are no Subledger Mappings configured</p>
            <p *ngIf="(!subledgerMaps || !subledgerMaps.length) && error">{{errorMsg}}</p>
            <table class="kanso-table altRows" *ngIf="subledgerMaps  && subledgerMaps.length">
                <thead>
                    <tr class="centerData">
                        <th>Name</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tr class="centerData"
                    *ngFor="let map of filteredSubledgerMaps$|async">
                    <td> <a (click)="editSubledgerMapFull(map)">{{map.name}}</a></td>
                    <td class="actionColumn">
                        <mat-icon (click)="editSubledgerMap(map)" >create</mat-icon>
                    </td>
                    <td class="actionColumn">
                        <mat-icon (click)="cloneSubledgerMap(map)">file_copy</mat-icon>                
                    </td>
                    <td class="actionColumn delete">
                        <mat-icon (click)="deleteSubledgerMap(map)">delete_outline</mat-icon>                
                    </td>
                </tr>
            </table>
        </div>
    </section>
</section>