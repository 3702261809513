import {BsonEntityId, BsonModifiedBy, BsonDeletedBy, Query, BsonEntityIdStr, BsonModifiedByStr} from '../../../core/service/core-models';
import {Unit} from '../../housing-core/services/housing-models';
import {vendorPayableViewObject} from '../../vendor/vendor.service';

export enum TransactionRequestType {
  Credit = 'Credit',
  Charge = 'Charge',
  Adjustment = 'Adjustment',
  Transfer = 'Transfer',
}

export interface AccountingBsonEntityId {
  voidedOn?: Date;
  voidedBy?: string;
}

export type Month =
  | 'January'
  | 'February'
  | 'March'
  | 'April'
  | 'May'
  | 'June'
  | 'July'
  | 'August'
  | 'September'
  | 'October'
  | 'November'
  | 'December';

export class TransactionQuery extends Query {
  includeVoidedTransactions: boolean;
  showOnlyHoldTransactions: boolean;
}

export interface History {
  closingPrincipal: number;
  endDate: Date;
  interestCharges: number;
  interestPayments: number;
  month: Month;
  number: number;
  principalCharges: number;
  principalPayments: number;
  startDate: Date;
  totalCharges: number;
  totalPayments: number;
  year: string;
}

export interface AmortizationReport {
  amortizationTable: AmortizationTable[];
  finalPaymentAmount: number;
  finalPaymentDate: Date;
  interestRate: number;
  loanAmount: number;
  paymentAmount: number;
  totalInterestPaid: number;
  totalPrincipalPaid: number;
}

export interface AmortizationTable {
  accumulatedInterest: number;
  accumulatedPrincipal: number;
  amount: number;
  interest: number;
  loanBalance: number;
  month: Month;
  principal: number;
  startingPrincipal: number;
  txDate: Date;
  year: string;
}

export interface PaymentAccountRemaining {
  accountId: string;
  amount: number;
}

export interface Payment extends AccountingBsonEntityId {
  amount?: number;
  chargeId?: string;
  creditId?: string;
}

export interface Transaction extends AccountingBsonEntityId {
  household: string;
  deposit?: string;
  sequence: number;
  type: TransactionRequestType;
  account: string;
  accountName: string;
  accountDefinition?: string;
  amount?: number;
  amountRemaining?: number;
  payTo?: string;
  program: string;
  programName?: string;
  project: string;
  projectName?: string;
  headFirstName?: string;
  headLastName?: string;
  chartOfAccounts?: string;
  chartOfAccountsName?: string;
  category?: string;
  categoryName?: string;
  subCategory?: string;
  subCategoryName?: string;
  checknumber?: string;
  description?: string;
  payments?: Payment[];
  transactionGroup?: string;
  postedOn?: Date;
  postedBy?: string;
  importDeposit?: string;
  receiptGroupId?: string;
  paymentGroupId?: string;
  ppuCode?: string;
  yrmo?: string;
  __v: number;
}

export interface VendorTransaction {
  id: string;
  programId: string;
  projectId: string;
  accountDefinitionId: string;
  vendorAccountId: string;
  amount: number;
  headFirstName?: string;
  headLastName?: string;
  ppuCode?: string;
  createdBy: string;
  createdOn: string;
  postedOn: string;
  postedBy: string;
  subLedgerMappingName: string;
  chargeTypeName: string;
  checkNumber: string;
  programName: string;
  projectName: string;
  voidedBy: string;
  voidedOn: string;
  vendorId: string;
  vendorAccountName: string;
  description: string;
  sequence: number;
  batchId?: number;
  isPayment?: boolean;
  hold: boolean;
  holdDescription: string;
  // Lodash created values in households-tenant-ledger
  runningBalance?: number;
  isCollapsed?: boolean;
}

export interface VendorAdjustmentModalData {
  vendorLedgerReport: any;
  vendorAdjustmentInput: VendorAdjustmentInput;

  // eslint-disable-next-line
  updateFunction: Function;
}

export interface VendorAdjustmentInput {
  account: string;
  type: string;
  postedOn: string;
  amount: number;
  description: string;
}

export interface ChargeType {
  id: string;
  name: string;
}

export interface ChargeGroup {
  id: string;
}

export interface TransactionRequest {
  account: string;
  accountDefinition: string;
  accountName: string;
  amount: number;
  payTo: string;
  categoryId: string;
  categoryName: string;
  chartOfAccounts: string;
  householdId: string;
  unitId: string;
  loanTransactionType?: 'Principal' | 'Interest';
  programId: string;
  programName: string;
  projectId: string;
  projectName: string;
  subCategoryId: string;
  subCategoryName: string;
  type: TransactionRequestType;
}

export interface LoanTermDetails {
  adjustmentToEstablishPrincipalBalanceSubCategory: string;
  autoReductionDate?: number;
  chargeAutoPostDate?: number;
  equityAccountDefinitions: string[];
  householdId: string;
  interestChargeSubCategory?: string;
  interestRate: number;
  ledgerName: string;
  loanAssignmentActionId?: string;
  mepaAccountDefinition?: string;
  monthlyPaymentAmount: number;
  originatingActionId: string;
  principal: number;
  principalRemaining: number;
  principalAccountDefinitionId: string;
  principalAccountId: string;
  principalChargeSubCategory?: string;
  principalReductionSubCategory?: string;
  programId: string;
  programName: string;
  projectId: string;
  projectName: string;
  startDate: Date;
  stopOn?: Date;
  term: number;
  closingPrincipal?: number;
  effectiveDateOfAction?: Date;
  unchargedPrincipal?: number;
  chargedPrincipal?: number;
  finalPaymentDate?: number;
}

export interface HistoryItem {
  number: number;
  startDate: string;
  endDate: string;
  month: string;
  year: string;
  interestCharges: number;
  principalCharges: number;
  totalCharges: number;
  interestPayments: number;
  principalPayments: number;
  totalPayments: number;
  closingPrincipal: number;
}

export interface ChargeAndPaymentHistory extends LoanTermDetails {
  loanAssignmentActionId: string;
  history: HistoryItem[];
  interestChargeTotal: number;
  principalChargeTotal: number;
  chargeTotal: number;
  interestPaymentTotal: number;
  principalPaymentTotal: number;
  paymentTotal: number;
  principalRemaining: number;
}

export interface Term<T> {
  type: 'Loan' | 'Rental' | 'Other';
  transactionRequests: TransactionRequest[];
  paymentTransactionRequests: TransactionRequest[];
  details: T;
}

//export interface RentalTerms extends Term {
//    type: 'Rental',
//    details: RentCalc
//}

//export interface LoanTerm extends Term {
//    details: LoanTermDetails;
//}

//export interface HousingTerm extends Term {
//    details: HousingTermDetails;
//}
export type HouseholdActionType =
  | 'Assignment'
  | 'Move In'
  | 'Interim Recertification'
  | 'Annual Recertification'
  | 'Move Out'
  | 'Conveyance'
  | 'Loan Assignment'
  | 'Program Enrollment';

export interface HouseholdAction<T> extends BsonEntityId {
  action: HouseholdActionType;
  effectiveDate: Date;
  unit: string;
  conveyanceEligibilityAction: boolean;
  hasAssistance: boolean;
  description: string;
  unitAssignment: string;
  term: Term<T>;
  monthlyCharge: string;
  programId: string;
  projectId: string;
  deletedOn: string;
  deletedBy: string;
}

export interface ImportKey {
  coaImportKey?: string;
  accountDef?: string;
  effectiveDateOfAdjustments?: string;
}

export interface DefaultTerms {
  term?: number;
  rate?: number;
}

export type SourceTransactionType = 'Credit' | 'Charge' | 'Credit Adjustment' | 'Charge Adjustment';

export enum SourceTransactionTypeEnum {
  Credit = 0,
  CreditAdjustment = 1,
  Charge = 2,
  ChargeAdjustment = 3,
}

export type AdjustmentTransactionType = 'Credit' | 'Charge';

export enum AdjustmentTransactionTypeEnum {
  Credit = 0,
  Charge = 1,
}

export interface AccountActivityRule extends BsonEntityId, BsonModifiedBy, BsonDeletedBy {
  displayName: string;
  sourceAccount: string;
  sourceTransactionType: SourceTransactionTypeEnum;
  sourceTransactionChartOfAccounts: string;
  sourceTransactionSubCategory: string;
  destinationAccount: string;
  adjustmentTransactionType: AdjustmentTransactionTypeEnum;
  adjustmentTransactionChartOfAccounts: string;
  adjustmentTransactionSubCategory: string;
}

export interface AccountActivityRuleModalData {
  activityRules: AccountActivityRule[];
  activityRule?: AccountActivityRule;
  // eslint-disable-next-line
  updateFunction: Function;
}

export enum AccountDefinitionType {
  Tenant = 0,
  Landlord = 1,
  Utility = 2,
  Other = 3,
  Maintenance = 4,
}

export interface AccountDefinition {
  _id?: string;
  name: string;
  accountDefinitionType?: AccountDefinitionType;
  chartOfAccounts?: string;
  chartOfAccountsName?: string;
  disableAutoPost?: boolean;
  principalChargeSubCategory?: string;
  interestChargeSubCategory?: string;
  principalReductionSubCategory?: string;
  adjustmentToEstablishPrincipalBalanceSubCategory?: string;
  lateFeeSubCategory?: string;
  assistancePaymentSubCategory?: string;
  equityAccountDefinitions?: string[];
  mepaAccountDefinition?: string;
  activityRules?: AccountActivityRule[];
  isAR?: boolean;
  isAssistance?: boolean;
  isAccountsPayable?: boolean;
  isPrimary?: boolean;
  isPrincipal?: boolean;
  isDeposit?: boolean;
  isRepayment?: boolean;
  isBadDebt?: boolean;
  creditAllocationPriority?: number;
  // eslint-disable-next-line
  typeMetadata?: object;
  importKey?: ImportKey;
  defaultTerms?: DefaultTerms;
  payableAchSubCategory?: string;
  payableCheckSubCategory?: string;
  payableOtherSubCategory?: string;
  utilityAllowanceSubcategory?: string;
}

export interface AccountRecord {
  name: string;
  accountDefinitionId: string;
  accountId: string;
  actionId: string;
  chartOfAccountsId: string;
  deactivatedOn: Date;
  displayName: string;
  isAR: boolean;
  isAssistance: boolean;
  isAccountsPayable: boolean;
  isPrimary: boolean;
  isBadDebt: boolean;
  isDeposit: boolean;
  isRepayment: boolean;
  isPrincipal: boolean;
  programId: string;
  projectId: string;
  unitId: string;
  openingBalance: number;
  closingBalance: number;
  transactionIds: string[];
  tenantLedgerName?: string;
}

export interface TenantLedgeReport {
  _id: string;
  accountRecords: AccountRecord[];
  actionId: string;
  closingBalances: true;
  closingDate: Date;
  conveyanceDate: Date;
  deactivatedOn: Date;
  formattedUnitTenantStatus: string;
  headFirstName: string;
  headLastName: string;
  household: string;
  householdActions: HouseholdAction<any>[];
  moveInDate: Date;
  moveOutDate: Date;
  openingDate: Date;
  ppuCode: string;
  program: string;
  programName: string;
  programType: string;
  project: string;
  projectName: string;
  projectSegmentCode: string;
  tenantLedgerName: string;
  unit: string;
  unitAddress: string;
  unitCSZ: string;
  unitnumber: string;
  unitPPUCode: string;
  unitSegmentCode: string;
  unitStreetAddress: string;
  unitTenantStatus: string;
  transactions: null;
  sortField: string;
  sortOrder: number;
  isPrimary: boolean;
}

export interface VendorLedgerReport {
  id: string;
  accountDefinitionId: string;
  createdBy: string;
  createdOn: string;
  displayName: string;
  name: string;
  programId: string;
  projectId?: string;
  unitId?: string;
  vendorId: string;
  __typename: string;
  deteledOn: string;
  unit?: VendorLedgerReportUnitWithOccupancies;
}

export interface VendorLedgerReportUnitWithOccupancies extends Unit {
  unitStatusChangedEvents: {
    household: {
      headOfHouseholdId?: string;
      effectiveMoveInDate?: string;
      lastActionName?: string;
      members: {
        id: string;
        firstName: string;
        lastName: string;
        middleName: string;
      }[];
    };
    __typename: string;
  }[];
}

export interface VendorHouseholdAction extends BsonEntityId {
  term: VendorHouseholdActionTerm;
  action: HouseholdActionType;
  effectiveDate: Date | string;
  unit: string;
  deletedOn: string;
}

export interface VendorHouseholdActionTerm {
  transactionRequests: VendorTransactionRequest[];
}

export interface VendorTransactionRequest {
  amount: number;
  subCategoryId: string;
}

export interface RepaymentAgreement extends BsonEntityId, BsonModifiedBy {
  account?: string;
  // amount and remaining are inserted for use in details but are not saved
  amount: number;
  remaining?: number;
  balance?: number;
  comments?: [];
  household: string;
  monthlyPaymentAmount: number;
  propertyChanges?: RepaymentPropertyChanges[];
  startDate?: Date;
  completedOn?: Date;
  archivedBy?: string;
  archivedOn?: Date;
}
export interface RepaymentPropertyChanges extends BsonModifiedBy {
  propertyName: string;
}

export interface TransactionSubCategory extends BsonEntityId {
  name: string;
  reportName: string;
  transactionDescription: string;
  transactionType: string;
  categoryName: string;
  postTo?: string;
  offsetTo?: string;
  chartOfAccountsId?: string;
  payoffPriority: number;
  include1099: boolean;
  accountCode: string;
  isRent: boolean;
  isSplit1: boolean;
  isSplit2: boolean;
  isSplit3: boolean;
  isSplit4: boolean;
  isSplit5: boolean;
  isSplit6: boolean;
  isHidden: boolean;
}

export interface TransactionCategory extends BsonEntityId {
  name: string;
  subCategories: TransactionSubCategory[];
}

export interface ISubLedgerMap extends BsonEntityId, BsonModifiedBy {
  name: string;
  description: string;
  defaultProgramType?: string;
  glAccount: string;
  chargeCategories: TransactionCategory[];
  creditCategories: TransactionCategory[];
  systemCategories: TransactionCategory[];
  isGeneric: boolean;
  accountCode?: string;
}

export interface Deposit extends BsonEntityId, BsonModifiedBy {
  sequence: number;
  transactionIds: [string];
  voidedOn: Date;
  voidedBy: string;
  exportDates: [Date];
  postedOn: Date;
  voidComment: string;
  importKey: string;
  reconciled: boolean;
}

export interface GeneralLedgerAccount extends BsonEntityIdStr, BsonModifiedByStr {
  name: string;
  accountCode: string;
}

export interface ActionFormQuery {
  action: string;
  program: string;
  project: string;
}

export interface ActionForm {
  formName: string;
  docReferenceId: string;
}

export type ActionFormsArray = Array<ActionForm>;

export interface unitNode {
  apartmentNumber: string;
  city: string;
  customerId: string;
  id: string;
  ppuCode: string;
  programId: string;
  projectId: string;
  siteId: string;
  streetAddress: string;
  unitNumber: string;
  __typename: string;
}
export enum UnitSortFields {
  Address = 0,
  Program = 1,
  Project = 2,
}

export interface payableWizardModal {
  payableObject: vendorPayableViewObject[];
  // eslint-disable-next-line
  updateFunction: Function;
}

export interface PayablesNotes {
  vendorId?: string;
  landLord?: string;
  notes?: NoteDetail[];
}

export interface NoteDetail {
  author: string;
  releaseDate: string;
  content: string;
}

export interface stepInfo {
  stepHeader: string;
  stepTitle: string;
}
