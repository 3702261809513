<div *ngIf= "spinnerCheck === true && this.data.step != 'review'">
  <mat-spinner class="centered_spinner"></mat-spinner>
</div>

<div class="section-header" *ngIf= "unitCheck == true && this.data.step != 'review'">Voucher Details</div>

<div class="section-body" *ngIf= "unitCheck == true && this.data.step != 'review' && !multiVoucher">
  <p>
    <strong>Attention!</strong> It looks like there is an active voucher associated with ths household. For the voucher to accurately track
    a leased status upon move-in, the unit must be tied to the voucher. Would you like us to update the unit associated on the active voucher
    for you?
  </p>
</div>

<div class="section-body" *ngIf= "unitCheck == true && this.data.step != 'review' && multiVoucher">
  <p>
    <strong>Attention!</strong> It looks like there are multiple active vouchers associated with ths household. For a voucher to accurately track
    a leased status upon move-in, the unit must be tied to the voucher. Would you like us to update a unit associated on the active voucher
    for you?
  </p>
</div>

<div class="section-body" *ngIf= "unitCheck == true && this.data.step != 'review'">

  <mat-radio-group id="voucherunitSel" [(ngModel)]="voucherunit" attr.voucherunit-src="{{ voucherunit }}">
    <mat-radio-button class="rdoSpace" [value]="true">Yes</mat-radio-button>
    <mat-radio-button class="rdoSpace" [value]="false">No</mat-radio-button>
  </mat-radio-group>
</div>

<div *ngIf= "multiVoucher && this.data.step != 'review' && voucherunit == true">
  Please chooose which voucher to update:
  <mat-form-field appearance="outline">
    <mat-select [(ngModel)]="selectedVoucher" (selectionChange)="changeSelectedVoucher(selectedVoucher)">
      <mat-option *ngFor="let voucher of multiVoucherArray" [value]="voucher">{{ voucher.voucherNumber }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
