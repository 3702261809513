/**
 * As part of our build process we make this file dynamically, we attempt to ascertain the SITEID and CUSTOMERID from
 * session storage but will fall back to a hardcoded value in an effort to stay fault tolerant on the front-end
 *
 * To see the dynamic creation of the file itself please look at scripts/createEnvironmentAndVersionFile.sh
 */
export const environment = {
  get CUSTOMER_ID() {
    return sessionStorage.getItem('CUSTOMERID') || '';
  },
  get SITE_ID() {
    return sessionStorage.getItem('SITEID') || '';
  },
  production: false,
};
