import {Component} from '@angular/core';
import {FeatureConfigurationsService} from 'src/app/shared/services/feature-configurations.service';
import {TelerikReports} from 'src/app/shared/services/telerik-reports-service';
import {CoreService} from 'src/app/core/service/core.service';
import lodash from 'lodash';

@Component({
  selector: 'app-feature-configurations-list',
  templateUrl: './feature-configurations-list.component.html',
  styleUrls: ['./feature-configurations-list.component.scss'],
})
export class FeatureConfigurationsListComponent {
  customerId: string;
  siteId: string;
  checkReportLoading = true;
  gLReportLoading = true;
  error = false;
  errorMsg = '';
  reportCatagories: any;
  payableReports = [];
  generalLedgerReports = [];
  featureConfigurations = [];
  configurationsValues = [];
  selectedOptions = [];
  telerikCheckReport = [];
  allConfigurationsValues = [];
  telerikGLReport = [];
  originalConfigValues = [];
  command = {
    featureConfigurationId: null,
    textValue: null,
    modifiedBy: null,
    siteId: null,
    customerId: null,
    booleanValue: null,
    configurationsOptionsId: null,
    legacyId: null,
    id: null,
  };
  newCommand = {
    featureConfigurationId: null,
    textValue: null,
    modifiedBy: null,
    siteId: null,
    customerId: null,
    booleanValue: null,
    configurationsOptionsId: null,
    legacyId: null,
  };
  configBooleanValue: string;
  selectMessage = 'Please Select';
  loggedInUser = '';
  isBusy = false;
  busyText: string;
  private CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  private SITE_ID = sessionStorage.getItem('SITEID') || '';

  // eslint-disable-next-line
  constructor(
    private telerikReportService: TelerikReports,
    private featureConfigurationsService: FeatureConfigurationsService,
    private coreService: CoreService
  ) {
    this.busyText = 'Loading Features';
    this.getFeatureConfigurations();
    this.getReportCategories();
    this.loggedInUser = this.coreService.getCurrentUsersLogInCookie();
  }

  getFeatureConfigurations() {
    this.isBusy = true;
    this.featureConfigurationsService.getFeatureConfigurations().subscribe(result => {
      this.featureConfigurations = lodash.cloneDeep(result[0]);
      this.featureConfigurations = this.featureConfigurations.sort((a, b) => a.domain.localeCompare(b.domain));
      this.featureConfigurationsService.getConfigurationsValues().subscribe(
        configValues => {
          this.configurationsValues = lodash.cloneDeep(configValues[0]);
          this.allConfigurationsValues = lodash.cloneDeep(configValues[0]);

          let configurationIndex = 0;
          for (const feature of this.featureConfigurations) {
            switch (feature.type) {
              case 'toggle': {
                const selectedConfig = this.configurationsValues.find(config => config.featureConfigurationId == feature.id);
                this.featureConfigurations[configurationIndex].value = selectedConfig ? selectedConfig.booleanValue.toString() : null;
                break;
              }
              case 'select': {
                for (const option of feature.configurationsOptions) {
                  const selectedValueByOption = this.configurationsValues.find(value => value.configurationsOptionsId == option.id);
                  if (selectedValueByOption) {
                    this.featureConfigurations[configurationIndex].value = selectedValueByOption ? selectedValueByOption.textValue : null;
                  }
                }
                const selectedValueByFeature = this.configurationsValues.find(value => value.featureConfigurationId == feature.id);
                if (selectedValueByFeature) {
                  this.featureConfigurations[configurationIndex].value = selectedValueByFeature ? selectedValueByFeature.textValue : null;
                }
                break;
              }
            }
            configurationIndex++;
          }
          this.isBusy = false;
        },
        error => {
          console.log('there was an error getting feature configurations: ', error);
          this.errorMsg = 'Did not find any feature configurations.';
          this.coreService.displayError('An unexpected error occurred getting feature configurations.');
          this.error = true;
        }
      );
    });
  }

  getReportCategories() {
    this.customerId = this.CUSTOMER_ID;
    this.siteId = this.SITE_ID;
    this.reportCatagories = this.telerikReportService.getTelerikCatagories().subscribe(result => {
      for (const report of result) {
        const siteSpecificReport: boolean = report.fullName.includes(this.siteId);
        const allSitesreport: boolean = report.fullName.includes('All.Site');
        if (siteSpecificReport || allSitesreport) {
          if (report.categoryName == 'Payables') {
            this.payableReports.push(report);
            this.checkReportLoading = false;
          }
          if (report.categoryName == 'General Ledger Exports') {
            this.generalLedgerReports.push(report);
            this.gLReportLoading = false;
          }
        }
      }
    });
  }

  createCommand(selected, config) {
    this.command = {
      featureConfigurationId: config.id ? config.id : null,
      textValue: selected.value ? selected.value : null,
      modifiedBy: this.loggedInUser,
      siteId: sessionStorage.getItem('SITEID'),
      customerId: sessionStorage.getItem('CUSTOMERID'),
      booleanValue: null,
      configurationsOptionsId: null,
      legacyId: null,
      id: null,
    };
  }

  createNewCommand(selected, config) {
    this.newCommand = {
      featureConfigurationId: config.id ? config.id : null,
      textValue: selected.value ? selected.value : null,
      modifiedBy: this.loggedInUser,
      siteId: sessionStorage.getItem('SITEID'),
      customerId: sessionStorage.getItem('CUSTOMERID'),
      booleanValue: true,
      configurationsOptionsId: null,
      legacyId: null,
    };
  }

  configChange(selected, config) {
    this.isBusy = true;
    this.busyText = 'Updating Selected Feature';
    const selectedConfigValue = this.configurationsValues.find(value => value.featureConfigurationId == config.id);
    if (selectedConfigValue) {
      this.createCommand(selected, config);
      this.command.booleanValue = selected.checked ? selected.checked : false;
      this.command.id = selectedConfigValue.id ? selectedConfigValue.id : null;

      const selectedConfigOption = config.configurationsOptions.find(co => co.id == selectedConfigValue.configurationsOptionsId);
      if (selectedConfigOption) {
        this.command.configurationsOptionsId = selectedConfigOption.id;
      }
      this.featureConfigurationsService.updateConfigurationsValue(this.command).subscribe((result: any) => {
        if (result.data.updateConfigurationsValue.status === 'SUCCESS') {
          this.getFeatureConfigurations();
        } else {
          console.log(result.data.updateConfigurationsValue);
          this.coreService.displayError('An unexpected error occurred saving configuration values. Please try again.');
          this.isBusy = false;
        }
      });
    } else {
      this.createNewCommand(selected, config);
      this.featureConfigurationsService.createConfigurationsValue(this.newCommand).subscribe((result: any) => {
        if (result.data.createConfigurationsValue.status === 'SUCCESS') {
          this.getFeatureConfigurations();
        } else {
          console.log(result.data.createConfigurationsValue);
          this.coreService.displayError('An unexpected error occurred saving configuration values. Please try again.');
          this.isBusy = false;
        }
      });
    }
  }

  changedMultiSelect(selected, config) {
    this.isBusy = true;
    this.originalConfigValues = [];
    this.busyText = 'Updating Selected Feature';
    this.selectedOptions = lodash.cloneDeep(selected.value);

    for (const value of this.allConfigurationsValues) {
      if (value.booleanValue === true && value.featureConfigurationId === config.id) {
        this.originalConfigValues.push(value);
      }
    }

    // If selected.value is greater than orinalConfigVlaues this means an additional option has been selected
    if (selected.value.length >= this.originalConfigValues.length) {
      this.createCommand(selected, config);
      const allSelectedOptions = {A: this.selectedOptions};
      const allSelectedValues = {B: this.originalConfigValues};
      const missingConfig = lodash.filter(
        allSelectedOptions.A,
        configOption => !lodash.some(allSelectedValues.B, valueOption => configOption.id === valueOption.configurationsOptionsId)
      );
      const missingConfigValue = this.configurationsValues.find(value => value.configurationsOptionsId == missingConfig[0].id);
      if (missingConfigValue && missingConfigValue.id) {
        this.command.textValue = missingConfig[0].display;
        this.command.booleanValue = true;
        this.command.configurationsOptionsId = missingConfig[0].id;
        this.command.id = missingConfigValue.id;

        this.featureConfigurationsService.updateConfigurationsValue(this.command).subscribe((result: any) => {
          if (result.data.updateConfigurationsValue.status === 'SUCCESS') {
            this.getFeatureConfigurations();
          } else {
            console.log(result.data.updateConfigurationsValue);
            this.coreService.displayError('An unexpected error occurred saving configuration values. Please try again.');
            this.isBusy = false;
          }
        });
      } else {
        this.createNewCommand(selected, config);
        this.newCommand.textValue = missingConfig[0].display;
        this.newCommand.configurationsOptionsId = missingConfig[0].id;
        this.featureConfigurationsService.createConfigurationsValue(this.newCommand).subscribe((result: any) => {
          if (result.data.createConfigurationsValue.status === 'SUCCESS') {
            this.getFeatureConfigurations();
          } else {
            console.log(result.data.createConfigurationsValue);
            this.coreService.displayError('An unexpected error occurred saving configuration values. Please try again.');
            this.isBusy = false;
          }
        });
      }
    } else {
      const allSelectedOptions = {A: config.configurationsOptions};
      const allSelectedValues = {B: this.selectedOptions};
      const missingConfigs = lodash.filter(
        allSelectedOptions.A,
        configOption => !lodash.some(allSelectedValues.B, valueOption => configOption.id === valueOption.id)
      );
      for (const config of missingConfigs) {
        const missingConfigValue = this.configurationsValues.find(
          value => value.configurationsOptionsId == config.id && value.booleanValue === true
        );
        if (missingConfigValue) {
          this.command.textValue = missingConfigValue.textValue;
          this.command.booleanValue = false;
          this.command.configurationsOptionsId = missingConfigValue.configurationsOptionsId;
          this.command.id = missingConfigValue.id;
        }
      }
      this.featureConfigurationsService.updateConfigurationsValue(this.command).subscribe((result: any) => {
        if (result.data.updateConfigurationsValue.status === 'SUCCESS') {
          this.getFeatureConfigurations();
        } else {
          console.log(result.data.updateConfigurationsValue);
          this.coreService.displayError('An unexpected error occurred saving configuration values. Please try again.');
          this.isBusy = false;
        }
      });
    }
  }

  compareValues(o1, o2) {
    if (o2 && o1.id == o2.configurationsOptionsId && o2.booleanValue == true) {
      return true;
    } else {
      return false;
    }
  }

  setBusy(message: string) {
    this.busyText = message;
    this.isBusy = true;
  }
}
