export class MongoResults<T> {
  totalCount: number;
  pageCount: number;
  results?: T[];
  body: any;
}
export class MongoData<T> extends MongoResults<T> {}

export class KansoActionResult<T> {
  statusCode: number;
  message: string;
  body: T;
}

export class CollectionGetResponse<T> {
  results: Array<T>;
}
export class MongoReport<T> extends MongoResults<T> {
  aggregationResults?: MongoAggregationResults<T>;
}
export class MongoAggregationResults<T> extends MongoReport<T> {}

export interface ReportCallback<T> {
  (err: string, aggregationResults: MongoAggregationResults<T>, centsConverted: boolean);
}

export interface Choice {
  label: string;
  value: any;
}
//export interface QueryResults<T> {
//  pageCount: number;
//  results: T[];
//  totalCount: number;
//}
export class Query {
  limit?: number;
  page? = 0;
  reportName?: string;
  filterSet: any;
}

export interface BsonEntityIdStr {
  _id?: string;
  createdOn?: Date;
  createdBy?: string;
}

export interface BsonModifiedByStr {
  modifiedOn?: Date;
  modifiedBy?: string;
}

export interface BsonDeletedByStr {
  deletedOn?: Date;
  deletedBy?: string;
}

export interface BsonCompletedByStr {
  completedOn?: Date;
  completedBy?: string;
}

export interface BsonEntityId {
  _id?: string;
  createdOn?: Date;
  createdBy?: string;
}

export interface BsonModifiedBy {
  modifiedOn?: Date;
  modifiedBy?: string;
}

export interface BsonDeletedBy {
  deletedOn?: Date;
  deletedBy?: string;
}

export interface BsonCompletedBy {
  completedOn?: Date;
  completedBy?: string;
}

export interface BsonArchivedBy {
  archivedOn?: Date;
  archivedBy?: string;
}

export interface IConfiguration {
  _id: string;
  permissions: [string];
  domain: string;
  subdomain: string;
  type: string;
  name: string;
  description: string;
  slug: string;
  value: boolean;
  options: [string];
}

export class User implements BsonEntityId {
  _id: string;
  createdOn: Date;
  createdBy: string;
  email: string;
  roles: string[];
  permissions: [string];
  title: string;
  firstName: string;
  lastName: string;
  phone: string;
  sendSms: boolean;
  userSignature: string;
  isActive: boolean;
  hourlyRate: number;
  preferredLocation: string;
  hidden: boolean;
  get userName7(): string {
    let userName = this.firstName || this.email;
    if (userName && userName.length > 7) {
      userName = userName.substr(0, 7);
      userName += '...';
    }
    return userName;
  }
  get isAdmin(): boolean {
    return this.permissions && this.permissions.includes('admin');
  }
}

export class Role implements BsonEntityId {
  _id: string;
  name: string;
  permissions: [string];
  dashboards: [string];
  description: string;
}

export interface Condition {
  fieldName: string;
  fieldValue: string;
  fieldType: 'text' | 'toggle';
  equality: 'equal' | 'notEqual';
}

export interface SupportedCustomAttributeEntity {
  entityName: string;
  reportCenterName: string;
}
export type CustomAttributeType = 'text' | 'toggle' | 'date' | 'dropdown' | 'multi';

export interface CustomAttributes extends BsonEntityId, BsonModifiedBy {
  entityName: string;
  name: string;
  group: string;
  type: CustomAttributeType;
  values: [string];
  active: boolean;
  required: boolean;
  conditions: Condition[];
}

export interface CustomAttribute extends BsonEntityId {
  id?: string;
  name: string;
  value: any;
  values: [string];
  entityAttributeId?: string;
  group?: string;
  active?: boolean;
  type?: string;
  __typename?: string;
}

export interface ICustomAttributesSql {
  id: string; //the id of custom attribute being save in SQL
  entityAttributeId?: string; //the _id of custom attribute in mongo will be save as entityAttributeId in SQL
  entityAttributesName?: string;
  textValue?: string;
  booleanValue?: boolean;
  dateTimeValue?: Date;
  group: string;
  name: string;
  type: string;
  value: any;
  values: [string];
  attribute?: any;
}

export interface ICustomAttributesValue {
  id: string; //the id of custom attribute being save in SQL
  entityAttributeId: string; //the _id of custom attribute in mongo will be save as entityAttributeId in SQL
  entityInstanceId: string;
  textValue?: string;
  booleanValue?: boolean;
  dateTimeValue?: Date;
}

export class CustomAttributesValue implements ICustomAttributesValue {
  id: string;
  entityAttributeId: string;
  entityInstanceId: string;
  textValue?: string;
  booleanValue?: boolean;
  dateTimeValue?: Date;

  fromValue(customAttributeValue: CustomAttributesValue) {
    this.id = customAttributeValue.id;
  }
  constructor(customAttribute: CustomAttribute | CustomAttributesValue) {
    //this is a bit of an ugly hack needed until we rework client side data modles
    if (!(customAttribute instanceof CustomAttributesValue) && customAttribute.__typename != 'UnitAttributeValueViewModel') {
      this.id = customAttribute.id ?? null;
      this.entityAttributeId = customAttribute.entityAttributeId;
      this.textValue = null;
      this.booleanValue = null;
      this.dateTimeValue = null;
      switch (customAttribute.type) {
        case 'text':
          this.textValue = customAttribute.value;
          break;
        case 'toggle':
          this.booleanValue = customAttribute.value;
          break;
        case 'date':
          this.dateTimeValue = customAttribute.value;
          break;
        case 'dropdown':
          this.textValue = customAttribute.value;
          break;
        case 'multi':
          this.textValue = customAttribute.value.join(', ');
          break;
      }
    } else {
      this.id = customAttribute.id;
      this.entityAttributeId = (customAttribute as CustomAttributesValue).entityAttributeId;
      this.entityInstanceId = (customAttribute as CustomAttributesValue).entityInstanceId;
      this.booleanValue = (customAttribute as CustomAttributesValue).booleanValue;
      this.textValue = (customAttribute as CustomAttributesValue).textValue;
      this.dateTimeValue = (customAttribute as CustomAttributesValue).dateTimeValue;
    }
  }
}

export interface EmailCategories extends BsonEntityId {
  name: string;
}
export interface PhoneCategories extends BsonEntityId {
  name: string;
}

export interface AddressCategories extends BsonEntityId {
  name: string;
}

export interface NotesCategory extends BsonEntityId {
  name: string;
}

export interface AlertInfo {
  dismissedOn: Date;
  dismissedBy: string;
}

export interface DateRange {
  firstDay: Date;
  lastDay: Date;
  label: string;
}

export interface Note extends BsonEntityId, BsonModifiedBy, BsonDeletedBy {
  body: string;
  category: string;
  alertInfo?: AlertInfo[];
  id?: string;
}

export interface NoteComponentData {
  categoryType: string;
  categories: NotesCategory[];
  selectedCategory?: string;
  notes: Note[];
  user: User;
  selectedNote?: Note;
  // eslint-disable-next-line
  updateFunction: Function;
}

export interface SiteProperties {
  officeName?: string;
  siteId: string;
  customerName?: string;
  customerId: string;
  timezone: string;
  modifiedOn: Date;
  modifiedBy: string;
  cognitoUserPoolId: string;
  cognitoClientId: string;
  pipeDriveId: string;
  multiTenancy: boolean;
}

export interface ErrorModalData {
  errorMessage: string;
  headerText?: string;
  actionMessage?: string;
  // eslint-disable-next-line
  callbackFunction?: Function;
}

export interface ConfirmModalData {
  confirmMessage: string;
  headerText: string;
  actionMessage: string;
  confirmButtonText: string;
  // eslint-disable-next-line
  callbackFunction: Function;
}

export const insertItemInList = <T>(arr: T[], index: number, newItem: T): T[] => [...arr.slice(0, index), newItem, ...arr.slice(index)];

// paging nav models
export interface goToPagePayload {
  cursor: string;
  direction: string;
  pageSize: number;
}

export const pageNext = 'forward';
export const pagePrevious = 'back';

export interface ComplexSaveResult {
  success: boolean;
  errorMessage?: string;
  affectedEntity: any;
}
