/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {Component, OnInit, OnDestroy} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {from, Subscription} from 'rxjs';

import {ErrorPopupComponent} from '../error-popup/error-popup.component';
import {IPicBatchHousehold, PicBatchExportRequest} from './household-interface';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FormControl} from '@angular/forms';
import {FeatureConfigurationService} from 'src/app/shared/services/feature-configuration.client.service';
import moment from 'moment';
import {PICActionsDataService} from '../exported-actions/exported-actions-data-service';

@Component({
  selector: 'app-pic-batching',
  templateUrl: './pic-batching.component.html',
  styleUrls: ['./pic-batching.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          left: '0px',
          top: '190px',
          backgroundColor: '#fff',
          zIndex: 2,
        })
      ),
      state(
        'closed',
        style({
          left: '-1300px',
          top: '190px',
          opacity: 0,
          backgroundColor: '#fff',
          zIndex: 2,
        })
      ),
      transition('open => closed', [animate('1s')]),
      transition('closed => open', [animate('0.3s')]),
    ]),
  ],
})
export class PicBatchingComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  allSelectedPicBatch: IPicBatchHousehold[] = [];
  filteredPicBatch: IPicBatchHousehold[] = [];
  exportedPicBatch: IPicBatchHousehold[] = [];
  fromDateFilteredPicBatch: IPicBatchHousehold[] = [];
  toDateFilteredPicBatch: IPicBatchHousehold[] = [];
  actionFilteredPicBatch: IPicBatchHousehold[] = [];
  programsFilteredPicBatch: IPicBatchHousehold[] = [];
  projectsFilteredPicBatch: IPicBatchHousehold[] = [];
  filteredActions: string[] = [];
  filteredPrograms: string[] = [];
  filteredProjects: string[] = [];
  picBatchHouseholdIds: any[] = [];
  showFilter: boolean;
  startDate: moment.Moment;
  convertedEffectiveDate: moment.Moment;
  disableCheckbox = true;
  endDate: moment.Moment;
  checkboxMessage: string;
  errorMessage: string;
  errorLocation: string;
  showPanelView: any = 'new-actions';
  allPicActions: boolean;
  enableExportDetailsBtn: boolean;
  loading = true;
  enableFilter: boolean;
  picBatchFile: string;
  loggedInUser: string;
  popUp: boolean;
  totalChecked: number;
  overlay: boolean;

  actions = new FormControl();
  programs = new FormControl();
  projects = new FormControl();
  allActions: string[] = [];
  allPrograms: string[] = [];
  allProjects: string[] = [];
  displayedColumns: string[] = ['select', 'Household', 'Action', 'Program', 'Project', 'Effective Date', 'Created On Date'];

  constructor(
    private picActionsDataService: PICActionsDataService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private dialog: MatDialog,
    private featureConfiguration: FeatureConfigurationService
  ) {
    this.matIconRegistry.addSvgIconLiteral(
      'filter-icon',
      this.domSanitizer.bypassSecurityTrustHtml(
        '<svg id="Filter_Icon" data-name="Filter Icon" xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14"><defs><style>.cls-1 {fill: #00aad4;}</style></defs><g><rect class="cls-1" x="5" width="6" height="6"/><rect id="Rectangle_9_copy" data-name="Rectangle 9 copy" class="cls-1" x="13" y="2" width="8" height="2"/><rect id="Rectangle_9_copy_2" data-name="Rectangle 9 copy 2" class="cls-1" y="2" width="3" height="2"/></g><g id="Group_1_copy" data-name="Group 1 copy"><rect class="cls-1" x="10" y="8" width="6" height="6"/><rect id="Rectangle_9_copy-2" data-name="Rectangle 9 copy" class="cls-1" y="10" width="8" height="2"/><rect id="Rectangle_9_copy_2-2" data-name="Rectangle 9 copy 2" class="cls-1" x="18" y="10" width="3" height="2"/></g></svg>'
      )
    );
    this.matIconRegistry.addSvgIconLiteral(
      'alarm-clock-icon',
      this.domSanitizer.bypassSecurityTrustHtml(
        '<svg id="Alarm_Clock_Icon" data-name="Alarm Clock Icon" xmlns="http://www.w3.org/2000/svg" width="20" height="16px" viewBox="0 0 24 24" width="20px" fill="#ffffff"><path d="M0 0h20v20H0V0z" fill="none"/><path d="M12.5 8H11v6l4.75 2.85.75-1.23-4-2.37zm4.837-6.19l4.607 3.845-1.28 1.535-4.61-3.843zm-10.674 0l1.282 1.536L3.337 7.19l-1.28-1.536zM12 4c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9-4.03-9-9-9zm0 16c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7z"/></svg>'
      )
    );
  }

  ngOnInit(): void {
    this.getPicData();
  }

  async getPicData() {
    (await this.picActionsDataService.getPicBatchs()).subscribe(
      response => {
        this.allSelectedPicBatch = this.addSelectedField(response);
        this.filteredPicBatch = this.allSelectedPicBatch.sort((a, b) => +moment(a.effectiveDate) - +moment(b.effectiveDate));
        this.dateDisable();
        this.loading = false;
      },
      err => {
        this.errorMessage = err;
        this.errorLocation = 'There was an error retrieving New Actions';
        console.log('error message: ', this.errorMessage);
        this.errorPopup();
      }
    );
    this.checkConfiguration();
    this.allPicActions = true;
    this.overlay = false;
    this.showFilter = false;
  }

  dateDisable() {
    const now = moment();
    for (const picAction of this.filteredPicBatch) {
      const picActionEffectiveDate = moment(picAction.effectiveDate);
      if (picActionEffectiveDate > now) {
        picAction.disable = true;
        picAction.selected = false;
      } else {
        picAction.disable = false;
      }
    }
  }

  showFilterToggle() {
    this.overlay = !this.overlay;
    this.showFilter = !this.showFilter;
    this.allActions = this.allSelectedPicBatch.map(pic => pic.action);
    this.filteredActions = this.removeDuplicates(this.allActions);

    this.allPrograms = this.allSelectedPicBatch.map(pic => pic.program);
    this.filteredPrograms = this.removeDuplicates(this.allPrograms);

    this.allProjects = this.allSelectedPicBatch.map(eachProject => eachProject.project);
    this.filteredProjects = this.removeDuplicates(this.allProjects);
  }

  removeDuplicates<T>(array: T[]) {
    return (array = array.filter((a, b) => b === array.indexOf(a)));
  }

  setStartDate(date) {
    this.startDate = date;
  }
  setEndDate(date) {
    this.endDate = date;
  }

  addSelectedField(observableResult: IPicBatchHousehold[]): IPicBatchHousehold[] {
    const temp = observableResult;
    for (const picGroup of temp) {
      picGroup.selected = true;
    }
    return temp;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  errorPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {errorMessage: this.errorLocation};
    this.dialog.open(ErrorPopupComponent, dialogConfig);
  }

  applyFilter() {
    this.loading = true;
    this.overlay = !this.overlay;
    this.showFilter = !this.showFilter;

    if (typeof this.startDate !== 'undefined' && this.startDate !== null) {
      this.fromDateFilteredPicBatch = this.allSelectedPicBatch.filter(fd => {
        const A = moment(fd.effectiveDate);
        const B = moment(this.startDate);
        console.log(A.diff(B, 'days'));
        if (A.diff(B, 'days') > -1) {
          return fd;
        }
      });
    } else {
      this.fromDateFilteredPicBatch = this.allSelectedPicBatch;
    }

    if (typeof this.endDate !== 'undefined' && this.endDate !== null) {
      this.toDateFilteredPicBatch = this.fromDateFilteredPicBatch.filter(td => {
        const A = moment(td.effectiveDate);
        const B = moment(this.endDate);
        console.log(A.diff(B, 'days'));
        if (A.diff(B, 'days') < 1) {
          return td;
        }
      });
    } else {
      this.toDateFilteredPicBatch = this.fromDateFilteredPicBatch;
    }

    if (this.actions.pristine == false) {
      this.actionFilteredPicBatch = [];
      if (this.actions.value.length !== 0) {
        for (const action of this.actions.value) {
          this.toDateFilteredPicBatch.filter(a => {
            if (action == a.action || this.actions.value == null) {
              this.actionFilteredPicBatch.push(a);
            }
          });
        }
      } else {
        this.actionFilteredPicBatch = this.toDateFilteredPicBatch;
      }
    } else {
      this.actionFilteredPicBatch = this.toDateFilteredPicBatch;
    }

    if (!this.programs.pristine) {
      this.programsFilteredPicBatch = [];
      if (this.programs.value.length !== 0) {
        for (const program of this.programs.value) {
          this.actionFilteredPicBatch.filter(a => {
            if (program == a.program || this.programs.value == null) {
              this.programsFilteredPicBatch.push(a);
            }
          });
        }
      } else {
        this.programsFilteredPicBatch = this.actionFilteredPicBatch;
      }
    } else {
      this.programsFilteredPicBatch = this.actionFilteredPicBatch;
    }

    if (!this.projects.pristine) {
      this.projectsFilteredPicBatch = [];
      if (this.projects.value.length !== 0) {
        for (const project of this.projects.value) {
          this.programsFilteredPicBatch.filter(a => {
            if (project == a.project || this.projects.value == null) {
              this.projectsFilteredPicBatch.push(a);
            }
          });
        }
      } else {
        this.projectsFilteredPicBatch = this.programsFilteredPicBatch;
      }
    } else {
      this.projectsFilteredPicBatch = this.programsFilteredPicBatch;
    }

    this.filteredPicBatch = this.projectsFilteredPicBatch;
    this.actions.markAsPristine();
    this.loading = false;
  }

  getCurrentUsersLogInCookie() {
    const cookies = document.cookie.split(';');
    let usersAuthCookie;
    for (const cookie of cookies) {
      if (cookie.includes('LastAuthUser')) {
        //found the cookie of the user
        usersAuthCookie = cookie;
      }
    }
    const arrayOfCookieStrings = usersAuthCookie.split('=');
    this.loggedInUser = arrayOfCookieStrings[1];
  }

  async reviewExportDetails() {
    this.getCurrentUsersLogInCookie();
    this.exportedPicBatch = this.filteredPicBatch.filter(t => t.selected);
    this.picBatchHouseholdIds = this.exportedPicBatch.map(eachID => {
      return eachID.id;
    });
    const request = new PicBatchExportRequest();
    request.formIds = this.picBatchHouseholdIds;
    request.user = this.loggedInUser;

    this.subscriptions.add(
      from(await this.picActionsDataService.postPicBatchs(request)).subscribe({
        next: (data: any) => {
          const downloadUrl = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.download = `pic.asc`;
          link.click();
        },
        error: err => {
          this.errorMessage = err;
          this.errorLocation = 'There was an error posting New Actions';
          this.allPicActions = false;
          this.checkboxMessage = ' ';
          console.log('error message: ', this.errorMessage);
          this.errorPopup();
        },
      })
    );
    this.showPanelView = 'exported-actions';
  }

  updateAllSelected(id) {
    const row = this.filteredPicBatch.filter(t => t.id === id);
    row[0].selected = !row[0].selected;
    this.allPicActions = this.filteredPicBatch != null && this.filteredPicBatch.every(t => t.selected);
  }

  someSelected(): boolean {
    if (this.filteredPicBatch == null) {
      return false;
    }
    if (this.allPicActions) {
      this.checkboxMessage = 'Click to Deselect All';
    } else {
      this.checkboxMessage = 'Click to Select All';
    }
    this.countChecked();
    return this.filteredPicBatch.filter(t => t.selected).length > 0 && !this.allPicActions;
  }

  selectAllPicActions(selected: boolean) {
    this.allPicActions = selected;
    if (this.filteredPicBatch == null) {
      return;
    }
    this.filteredPicBatch.forEach(t => {
      if (t.disable == false) {
        t.selected = selected;
      }
    });
  }

  checkConfiguration() {
    this.subscriptions.add(
      from(this.featureConfiguration.getConfig('enable-filter-pic-batching')).subscribe({
        next: configObject => {
          this.enableFilter = configObject.response.value;
        },
      })
    );
  }

  countChecked() {
    const countOfCheckedBoxes = this.filteredPicBatch.filter(pic => pic.selected).length;
    this.enableExportDetailsBtn = countOfCheckedBoxes > 0;
    this.totalChecked = countOfCheckedBoxes;
  }

  btnClick() {
    this.popUp = !this.popUp;
    this.overlay = !this.overlay;
  }
}
