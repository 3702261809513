/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {Component, OnInit, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {VoucherService} from '../vouchers-services/vouchers.service';
import * as moment from 'moment';
import {Query, Voucher} from 'src/generated/graphql';

@Component({
  selector: 'app-vouchers-movein',
  templateUrl: './vouchers-movein.component.html',
  styleUrls: ['./vouchers-movein.component.scss'],
})
export class VouchersMoveInComponent implements OnInit {
  customerId: string;
  siteId: string;
  @Input() data: any;
  loggedInUser: string;
  _location: Location;
  _route: ActivatedRoute;
  voucherNumber: any = '';
  voucher: Voucher;
  activeVoucher = false;
  unitCheck: boolean;
  updateVoucherUnit: boolean;
  selectedVoucher: Voucher;
  selectedVoucherId: any;
  multiVoucher = false;
  multiVoucherArray = [];
  observable: any;
  step: string;
  spinnerCheck: boolean;
  private CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  private SITE_ID = sessionStorage.getItem('SITEID') || '';

  constructor(public voucherService: VoucherService) {}

  ngOnDestroy() {
    if (this.data.step === 'review') {
      this.voucherService.setActionItems(null); //Setting service item to null so it will not carry over to different households or actions
    } else {
      this.voucherService.setActionItems([this.unitCheck, this.updateVoucherUnit, this.selectedVoucherId]); // Saving these items so they can be used on the last step of the wizard
    }
  }

  async ngOnInit() {
    this.customerId = this.CUSTOMER_ID;
    this.siteId = this.SITE_ID;
    this.getCurrentUsersLogInCookie();
    this.step = this.data.step;
    if (this.data.step == 'date') {
      this.spinnerCheck = true;
      // This step simply loads the voucherNumber if it exists
      this.observable = this.voucherService.getVoucherByHouseHoldIdTrimmed(this.data.household).subscribe(
        (result: any) => {
          const query: Query = result?.data;
          if (query.vouchers.edges.length > 0) {
            // This will filter for current vouchers
            const finder = query.vouchers.edges.filter(edge => edge.node.state === 'ISSUED' || edge.node.state === 'SHOP_MODE');
            if (finder && finder.length === 1) {
              this.voucher = finder[0].node;
              if (this.voucher) {
                this.voucherNumber = this.voucher.voucherNumber;
                this.activeVoucher = true;
                this.spinnerCheck = false;
                this.selectedVoucherId = this.voucher.id;
                if (this.voucher.unitId != this.data.unit) {
                  this.unitCheck = true;
                }
              } else {
                this.spinnerCheck = false;
              }
            } else if (finder && finder.length > 1) {
              for (const node of finder) {
                this.multiVoucherArray.push(node.node);
              }
              this.multiVoucher = true;
              this.spinnerCheck = false;
              this.activeVoucher = true;
              this.selectedVoucher = finder[0].node;
              this.changeSelectedVoucher(finder[0].node);
            } else {
              this.spinnerCheck = false;
            }
          }
        },
        error => {
          this.spinnerCheck = false;
        }
      );
    } else if (this.data.actionType == 'Unit Transfer') {
      this.observable = this.voucherService.getVoucherByHouseHoldId(this.data.household).subscribe((result: any) => {
        const query: Query = result?.data;
        if (query.vouchers.edges.length > 0) {
          this.voucher = query.vouchers.edges.find(edge => edge.node.state === 'LEASED').node;
          if (this.voucher) {
            const command = {
              voucherId: this.voucher.id,
              voucherNumber: this.voucher.voucherNumber,
              fundingProgramId: this.voucher.program.id,
              fundingProjectId: this.voucher.project.id,
              customerId: this.customerId,
              siteId: this.siteId,
              modifiedBy: this.loggedInUser,
              unitId: this.data.selectedUnit._id, // Update voucher with new unitId
            };
            this.voucherService.editVoucher(command).subscribe(
              (results: any) => {},
              error => {
                console.log('There was an error updating the voucher unit', error);
              }
            );
          }
        }
      });
    } else {
      const serviceArray = this.voucherService.loadActionItems();
      if (serviceArray) {
        this.unitCheck = serviceArray[0];
        this.updateVoucherUnit = serviceArray[1];
        this.selectedVoucherId = serviceArray[2];
      }
      this.observable = this.voucherService.getVoucherByHouseHoldId(this.data.household).subscribe((result: any) => {
        const query: Query = result?.data;
        if (query.vouchers.edges.length > 0) {
          // This will find the current Active Voucher assuming a household can only have 1 at a time
          this.voucher = query.vouchers.edges.find(edge => edge.node.id === this.selectedVoucherId).node;
          if (this.data.step === 'review' && this.voucher) {
            this.voucherNumber = this.voucher.voucherNumber;
            // Transition Voucher to Leased
            const voucherCommand = {
              voucherId: this.voucher.id,
              customerId: this.customerId,
              siteId: this.siteId,
              modifiedBy: this.loggedInUser,
              leasedOn: moment(this.data.moveInOn).format('MM-DD-YYYY'),
              newState: 'LEASED',
              effectiveDate: moment(this.data.moveInDate).format('MM-DD-YYYY'),
            };
            this.voucherService.changeVoucherStatus(voucherCommand).subscribe(
              (results: any) => {},
              error => {
                console.log('There was an error transitioning the voucher', error);
              }
            );
            // Update voucher with unitId if option is selected
            if (this.unitCheck == true && this.updateVoucherUnit == true) {
              const command = {
                voucherId: this.voucher.id,
                voucherNumber: this.voucher.voucherNumber,
                fundingProgramId: this.voucher.program.id,
                fundingProjectId: this.voucher.project.id,
                customerId: this.customerId,
                siteId: this.siteId,
                modifiedBy: this.loggedInUser,
                unitId: this.data.unit,
              };
              this.voucherService.editVoucher(command).subscribe(
                (results: any) => {},
                error => {
                  console.log('There was an error sending the voucher transition', error);
                }
              );
            }
            // Update RFTA to APPROVED
            if (this.voucher.tenancyRequests.length > 0) {
              const sortedByCreatedOnDateTenancyRequestsIds = this.voucher.tenancyRequests
                .sort((b, a) => +moment(new Date(b.createdOn)) - +moment(new Date(a.createdOn)))
                .map(record => record.id);
              const latestRFTAID = sortedByCreatedOnDateTenancyRequestsIds[0];
              const filteredTenancyRequest = this.voucher.tenancyRequests.find(tr => tr.id.toString() == latestRFTAID.toString());
              if (filteredTenancyRequest.result === 'AWAITING_APPROVAL') {
                const rtfaCommand = {
                  rftaId: filteredTenancyRequest.id,
                  customerId: this.customerId,
                  siteId: this.siteId,
                  modifiedBy: this.loggedInUser,
                  stopOn: moment(filteredTenancyRequest.requestStopOn).format('MM-DD-YYYY'),
                  result: 'APPROVED',
                  resultComments: 'APPROVED via Move In',
                };
                this.voucherService.editRFTACommand(rtfaCommand).subscribe(
                  (results: any) => {},
                  error => {
                    console.log('There was an error sending the RFTA mutation', error);
                  }
                );
              }
            }
          }
        }
      });
    }
  }

  getCurrentUsersLogInCookie() {
    const cookies = document.cookie.split(';');
    let usersAuthCookie;
    for (const cookie of cookies) {
      if (cookie.includes('LastAuthUser')) {
        //found the cookie of the user
        usersAuthCookie = cookie;
      }
    }
    const arrayOfCookieStrings = usersAuthCookie.split('=');
    this.loggedInUser = arrayOfCookieStrings[1];
  }

  changeSelectedVoucher(voucher) {
    this.selectedVoucherId = voucher.id;
    this.voucherNumber = voucher.voucherNumber;
    if (voucher.unitId != this.data.unit) {
      this.unitCheck = true;
    } else {
      this.unitCheck = false;
    }
  }
}
