import {Component, Injector, OnInit} from '@angular/core';
import {Vendor, VendorEditModalData, ILandlordAddress, IVendor} from '../../housing-core/services/housing-models';
import {CustomAttributes, ICustomAttributesSql, Note} from '../../../core/service/core-models';
import {VendorService} from '../vendor.service';
import {CoreService} from '../../../core/service/core.service';
import {GlobalModalService} from '../../../kanso-common/core/service/globalmodal.service';
import {VendorEditComponent} from '../vendor-edit/vendor-edit.component';
import {VendorAttributeCommand} from '../vendor-model';
import {ControlContainer, NgForm} from '@angular/forms';
import {finalize, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {TitleCasePipe} from '@angular/common';

@Component({
  selector: 'app-vendor-overview',
  templateUrl: './vendor-overview.component.html',
  styleUrls: ['./vendor-overview.component.scss'],
  providers: [{provide: ControlContainer, useExisting: NgForm}],
})
export class VendorOverviewComponent implements OnInit {
  customerId: string;
  siteId: string;
  vendor: Vendor;
  loading = true;
  updating = false;
  loggedInUser: string;
  filteredNotes: Note[];
  activeCustomAttributes: CustomAttributes[];
  vendorId: string;
  inititialVendorFieldValue: IVendor;
  inititialVendorAddressValue: any;
  initialAttributesValues: any[];
  attributeValueCount = 0;
  nullAttributeValueCount = 0;
  addressId: string;
  private CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  private SITE_ID = sessionStorage.getItem('SITEID') || '';
  // eslint-disable-next-line
  constructor(
    private vendorService: VendorService,
    public coreService: CoreService,
    public globalModalService: GlobalModalService,
    protected injector: Injector
  ) {}

  ngOnInit(): void {
    this.customerId = this.CUSTOMER_ID;
    this.siteId = this.SITE_ID;
    this.vendorId = this.coreService.getPathNamePart(this.coreService.VendorPathParts.Id);
    this.coreService.getCustomAttributesByEntity('Landlord').subscribe(result => {
      this.activeCustomAttributes = result.results.filter(attr => attr.active);
    });
    this.vendorService.getSelectedVendor(this.vendorId).subscribe(
      (result: Vendor) => {
        this.vendor = result;
        this.refreshDataPipelines();
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.coreService.displayError('There was an error getting Vendor');
      }
    );
  }

  private refreshDataPipelines() {
    this.vendorService.sendVendor(this.vendor);
    this.vendorService.sendVendorNotes(
      this.vendor.notes.filter(note => !note.deletedOn),
      false
    );
    this.vendorService.getVendorNotes().subscribe(result => {
      this.filteredNotes = result;
    });
  }
  onGetInitialAttributesValue(attributeValues) {
    return attributeValues
      .filter(attr => attr.id)
      .map(attr => {
        if (attr.value === undefined) {
          if (attr.id) {
            if (attr.textValue !== null) {
              return {id: attr.id, value: attr.textValue};
            }
            if (attr.booleanValue !== null) {
              return {id: attr.id, value: attr.booleanValue};
            }
            if (attr.dateTimeValue !== null) {
              return {id: attr.id, value: attr.dateTimeValue};
            }
          }
        } else {
          return {id: attr.id, value: Array.isArray(attr.value) ? attr.value.join(', ') : attr.value};
        }
      });
  }

  onCheckEqualObject(obj: Vendor | ILandlordAddress, updatedObj: Vendor | ILandlordAddress): boolean {
    const object = {...obj};
    const updatedObject = {...updatedObj};
    for (const key in object) {
      if (Array.isArray(object[key])) {
        delete object[key];
      }
      if (object[key] == null) {
        object[key] = 'null';
      }
    }

    for (const key in updatedObject) {
      if (Array.isArray(updatedObject[key])) {
        delete updatedObject[key];
      }
      if (updatedObject[key] == null) {
        updatedObject[key] = 'null';
      }
    }

    const keysInObject = Object.keys(object);
    const keysInUpdatedObject = Object.keys(updatedObject);

    if (keysInObject.length !== keysInUpdatedObject.length) {
      return false;
    }

    for (const key of keysInObject) {
      if (object[key].toLowerCase() !== updatedObject[key].toLowerCase()) {
        return false;
      }
    }

    return true;
  }

  editVendor(vendor) {
    this.inititialVendorFieldValue = vendor;
    this.inititialVendorAddressValue = vendor.addresses[0];
    this.initialAttributesValues = this.onGetInitialAttributesValue(vendor.attributeValues);
    this.loggedInUser = this.coreService.getCurrentUsersLogInCookie();
    this.globalModalService.openModal<VendorEditModalData>(
      VendorEditComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      {selectedVendor: vendor, updateFunction: this.updateVendor, isNew: false}
    );
  }

  updateVendor = async vendor => {
    this.loading = true;
    this.updating = true;
    const response = await this.vendorService.saveVendor(vendor, this.inititialVendorFieldValue);
    this.loading = false;
    this.updating = false;
    if (!response.success) {
      this.coreService.displayError(response.errorMessage);
    }
    this.vendor = response.affectedEntity;
    this.refreshDataPipelines();
  };
  //NOTE: leaving this until we circle back on custom attributes
  // updateVendorCustomAttributes(attributes: ICustomAttributesSql[], vendorId: string) {
  //   try {
  //     this.attributeValueCount = 0;
  //     this.nullAttributeValueCount = 0;
  //     attributes.forEach(attr => {
  //       const vendorAttributeCommand: VendorAttributeCommand = {
  //         customerId: this.customerId,
  //         siteId: this.siteId,
  //       };
  //       switch (attr.type) {
  //         case 'text':
  //           vendorAttributeCommand.textValue = attr.value;
  //           break;
  //         case 'date':
  //           vendorAttributeCommand.dateTimeValue = attr.value;
  //           break;
  //         case 'dropdown':
  //           vendorAttributeCommand.textValue = attr.value === null ? '' : attr.value;
  //           break;
  //         case 'multi':
  //           vendorAttributeCommand.textValue = attr.value === null ? null : attr.value.join(', ');
  //           break;
  //         case 'toggle':
  //           vendorAttributeCommand.booleanValue = attr.value;
  //           break;
  //       }
  //       if (attr.id) {
  //         attr.attribute = {id: attr.entityAttributeId};
  //         vendorAttributeCommand.id = attr.id;
  //         const isUpdated = this.initialAttributesValues.some(
  //           obj => obj.id === attr.id && obj.value === (Array.isArray(attr.value) ? attr.value.join(', ') : attr.value)
  //         );
  //         const defaultValue = this.initialAttributesValues.find(obj => obj.id === attr.id);
  //         if (!isUpdated) {
  //           this.vendorService
  //             .updateVendorAttributeValues(vendorAttributeCommand)
  //             .pipe(
  //               map((result: any) => {
  //                 this.attributeValueCount++;
  //                 if (result.data.updateVendorAttributeValue.status === 'SUCCESS') {
  //                   const updatedAttribute = result.data.updateVendorAttributeValue.affectedEntity;
  //                   attr.textValue = updatedAttribute.textValue;
  //                   attr.booleanValue = updatedAttribute.booleanValue;
  //                   attr.dateTimeValue = updatedAttribute.dateTimeValue;
  //                   return updatedAttribute;
  //                 } else {
  //                   console.log(
  //                     'there was an error updating a vendor custom attributes, status: ',
  //                     result.data.updateVendorAttributeValue.status
  //                   );
  //                   const unUpdatedAttribute = this.onModifyAttributeValues(attr, defaultValue.value);
  //                   return unUpdatedAttribute;
  //                 }
  //               }),
  //               finalize(() => {
  //                 this.updating = this.attributeValueCount === attributes.length - this.nullAttributeValueCount ? false : true;
  //                 this.loading = this.updating;
  //               })
  //             )
  //             .subscribe(
  //               result => {
  //                 console.log(result);
  //               },
  //               error => {
  //                 console.log('there was an error updating a vendor custom attributes', error);
  //                 this.nullAttributeValueCount++;
  //                 attr = this.onModifyAttributeValues(attr, defaultValue.value);
  //                 this.updating = this.attributeValueCount === attributes.length - this.nullAttributeValueCount ? false : true;
  //                 this.loading = this.updating;
  //               }
  //             );
  //         } else {
  //           this.nullAttributeValueCount++;
  //           attr = this.onModifyAttributeValues(attr, defaultValue.value);
  //           this.updating = this.attributeValueCount === attributes.length - this.nullAttributeValueCount ? false : true;
  //           this.loading = this.updating;
  //         }
  //       } else {
  //         attr.attribute = {id: attr.entityAttributeId};
  //         if (attr.value) {
  //           vendorAttributeCommand.entityInstanceId = vendorId;
  //           vendorAttributeCommand.entityAttributeId = attr.entityAttributeId;
  //           this.vendorService
  //             .createVendorAttributeValues(vendorAttributeCommand)
  //             .pipe(
  //               map((result: any) => {
  //                 this.attributeValueCount++;
  //                 if (result.data.createVendorAttributeValue.status === 'SUCCESS') {
  //                   const createdAttribute = result.data.createVendorAttributeValue.affectedEntity;
  //                   attr.id = createdAttribute.id;
  //                   attr.textValue = createdAttribute.textValue;
  //                   attr.booleanValue = createdAttribute.booleanValue;
  //                   attr.dateTimeValue = createdAttribute.dateTimeValue;
  //                   return createdAttribute;
  //                 } else {
  //                   console.log(
  //                     'there was an error creating a vendor custom attributes, status: ',
  //                     result.data.createVendorAttributeValue.status
  //                   );
  //                   attr.value = null;
  //                   attr.textValue = null;
  //                   attr.booleanValue = null;
  //                   attr.dateTimeValue = null;
  //                   return attr;
  //                 }
  //               }),
  //               finalize(() => {
  //                 this.updating = this.attributeValueCount === attributes.length - this.nullAttributeValueCount ? false : true;
  //                 this.loading = this.updating;
  //               })
  //             )
  //             .subscribe(
  //               (result: any) => {
  //                 console.log(result);
  //               },
  //               error => {
  //                 console.log('there was an error creating a vendor custom attributes', error);
  //                 this.nullAttributeValueCount++;
  //                 attr.value = null;
  //                 attr.textValue = null;
  //                 attr.booleanValue = null;
  //                 attr.dateTimeValue = null;
  //                 this.updating = this.attributeValueCount === attributes.length - this.nullAttributeValueCount ? false : true;
  //                 this.loading = this.updating;
  //               }
  //             );
  //         } else {
  //           this.nullAttributeValueCount++;
  //           this.updating = this.attributeValueCount === attributes.length - this.nullAttributeValueCount ? false : true;
  //           this.loading = this.updating;
  //         }
  //       }
  //     });
  //   } catch (error) {
  //     this.loading = false;
  //   } finally {
  //     if (attributes.length === 0) {
  //       //prevent loading keep spinning when there is no custom attribute
  //       this.loading = false;
  //     }
  //     // eslint-disable-next-line
  //     return attributes;
  //   }
  // }

  // onModifyAttributeValues(customAttribute: ICustomAttributesSql, expectedValue) {
  //   switch (customAttribute.type) {
  //     case 'text':
  //       customAttribute.textValue = expectedValue;
  //       break;
  //     case 'date':
  //       customAttribute.dateTimeValue = expectedValue;
  //       break;
  //     case 'dropdown':
  //       customAttribute.textValue = expectedValue;
  //       break;
  //     case 'multi':
  //       customAttribute.textValue = expectedValue;
  //       break;
  //     case 'toggle':
  //       customAttribute.booleanValue = expectedValue;
  //       break;
  //   }
  //   customAttribute.value = expectedValue;
  //   return customAttribute;
  // }
}
