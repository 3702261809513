import {Component, Input, OnInit} from '@angular/core';

import {FormControl, FormGroup, NgForm, NgModel, Validators} from '@angular/forms';
import {GeneralLedgerAccount} from '../../../../accounting/service/accounting-models';
import {GlobalModalChild, GlobalModalParent, GlobalModalService} from '../../../../../kanso-common/core/service/globalmodal.service';
import {GeneralLedgerModalData} from '../../models/accounting-setup.models';

@Component({
  selector: 'general-ledger-edit',
  templateUrl: './general-ledger-edit.component.html',
  styleUrls: ['./general-ledger-edit.component.scss'],
})
export class GeneralLedgerEditComponent implements GlobalModalChild<GeneralLedgerModalData>, GeneralLedgerModalData {
  // eslint-disable-next-line
  @Input() updateFunction: Function;
  @Input() editMode: boolean;
  _generalLedgerAccount: GeneralLedgerAccount;
  @Input() set generalLedgerAccount(selectedGeneralLedgerAccount: GeneralLedgerAccount) {
    this._generalLedgerAccount = selectedGeneralLedgerAccount;
    this.editGeneralLedgerAccount = Object.assign({}, selectedGeneralLedgerAccount);
  }

  get generalLedgerAccount(): GeneralLedgerAccount {
    return this._generalLedgerAccount;
  }

  editGeneralLedgerAccount: GeneralLedgerAccount;
  globalModalParent: GlobalModalParent;

  getErrorMessage(ngModel: NgModel, inputName: string) {
    if (ngModel.hasError('required')) {
      return this.errorText(inputName);
    }
    return '';
  }

  private errorText(inputControl: string) {
    switch (inputControl) {
      case 'accountName':
        return 'You must supply a name';
      default:
        return 'You must supply an account code';
    }
  }

  getHeaderText() {
    return `${this.editMode ? 'Edit' : 'New'} General Ledger Account`;
  }

  closeDialog() {
    this.globalModalParent.closePopup();
  }

  saveAccount() {
    this.generalLedgerAccount = Object.assign(this.generalLedgerAccount, this.editGeneralLedgerAccount);
    this.updateFunction();
    this.closeDialog();
  }
}
