import {Component, Injector} from '@angular/core';
import {FormControl} from '@angular/forms';
import {GlobalModalService} from 'src/app/kanso-common/core/service/globalmodal.service';
import {CoreService} from 'src/app/core/service/core.service';
import {Observable} from 'rxjs';

import {HousingServices} from '../../../housing-core/services/housing-services.service';
import {AccountingService} from 'src/app/custom/accounting/service/accounting.service';
import {
  Authority,
  Program,
  Project,
  IRentCalcDetails,
  Unit,
  UnitModalData,
  UnitUiSections,
  Vendor,
  IUnit,
} from '../../../housing-core/services/housing-models';
import {UnitSetupComponent} from '../unit-setup/unit-setup.component';
import {qqlPageInfo} from 'src/app/custom/vendor/vendor-model';
import {ComplexSaveResult, goToPagePayload, pageNext} from 'src/app/core/service/core-models';
import {UnitSortFields} from 'src/app/custom/accounting/service/accounting-models';

@Component({
  selector: 'units-list',
  templateUrl: './units-list.component.html',
  styleUrls: ['./units-list.component.scss'],
})
export class UnitsListComponent {
  customerId: string;
  siteId: string;
  // selectedProgram: Program;
  // selectedProject: Project;
  units: Unit[];
  showFilters = false;
  filterHint: string;
  isBusy = false;
  busyText: string;
  //pageing properties
  pageSize = new FormControl('25');
  vendorId: string;
  pageInfo: qqlPageInfo;
  totalCount = 0;
  currentRecordsCount = 0;
  lastPageSize = 0;
  //end pageing properties
  viewPage: number;
  showEditPanel = false;
  selectedUnit;
  defaultRentCalc: IRentCalcDetails;
  unitFilter = new FormControl('');
  unitSort = new FormControl('');
  sortField: UnitSortFields = UnitSortFields.Address;
  filteredUnits$: Observable<Unit[]>;
  housingAuthority: Authority;
  error = false;
  errorMsg = '';
  programList: Program[];
  projectList: Project[];
  isNewRecord = false;
  private CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  private SITE_ID = sessionStorage.getItem('SITEID') || '';

  constructor(
    public housingServices: HousingServices,
    public accountingService: AccountingService,
    public coreService: CoreService,
    public globalModalService: GlobalModalService,
    protected injector: Injector
  ) {
    this.unitSort.setValue('Address');
    this.pageSize.valueChanges.subscribe(value => {
      //page size has changed so start over from beginnning
      this.refreshUnitList();
    });
    this.unitFilter.valueChanges.subscribe(value => {
      //if unitfilter aka search box is cleared then refresh units from server
      if (value == '') {
        this.refreshUnitList();
      }
    });
    this.unitSort.valueChanges.subscribe(value => {
      switch (value) {
        case 'Program':
          this.sortField = UnitSortFields.Program;
          break;
        case 'Project':
          this.sortField = UnitSortFields.Project;
          break;
        default:
          this.sortField = UnitSortFields.Address;
          break;
      }
      this.refreshUnitList();
    });
  }

  searchUnits() {
    //bound to search box enter key & search icon click
    this.refreshUnitList();
  }

  ngOnInit(): void {
    this.customerId = this.CUSTOMER_ID;
    this.siteId = this.SITE_ID;
    this.getProgramProjectData();
  }

  getProgramProjectData() {
    this.setBusy('Loading Units');
    this.accountingService.getAllPrograms().subscribe(results => {
      this.programList = results.body;
      this.accountingService.getAllProjects().subscribe(results => {
        this.projectList = results.body;
        this.refreshUnitList();
      });
    });
  }

  goToPageHandler(pagePayload: goToPagePayload) {
    this.getUnits(pagePayload.cursor, pagePayload.direction, pagePayload.pageSize, this.unitFilter.value, this.sortField);
  }

  setBusy(message: string) {
    this.busyText = message;
    this.isBusy = true;
  }

  getUnits(
    cursor: string,
    direction: string,
    pageSize: number,
    searchText = '',
    sortField: UnitSortFields = UnitSortFields.Address,
    skipCache = false
  ) {
    const countMultiplier: number = direction != 'forward' ? -1 : 1;
    this.accountingService
      .getAllUnits(skipCache, this.siteId, this.customerId, pageSize, cursor, direction, searchText, sortField)
      .subscribe(
        response => {
          this.units = response.data;
          this.pageInfo = response.pageInfo;
          this.totalCount = response.totalCount;
          const countAdjSize = direction != 'forward' ? this.lastPageSize : response.data.length;
          this.currentRecordsCount = this.currentRecordsCount + countAdjSize * countMultiplier;
          this.lastPageSize = response.data.length;

          this.units = this.units.map(u => {
            const prog = this.programList.find(p => p._id == u.program);
            const proj = this.projectList.find(p => p._id == u.project);
            u.programName = prog ? prog.name : `Program ${u.program} not found`;
            u.projectName = proj ? proj.name : `Project ${u.project} not found`;
            return u;
          });
          this.isBusy = false;
        },
        error => {
          console.log(`Error fetching units: ${error}`);
          this.coreService.displayError(error.message, null, 'Error loading Unit Data');
          this.errorMsg = 'An unexpected error occurred fetching units. Please try again.';
          this.error = true;
          this.isBusy = false;
        }
      );
  }

  refreshUnitList = () => {
    //reset curent record counter
    this.currentRecordsCount = 0;
    //fetch units forcing cache skip
    this.getUnits('', pageNext, this.pageSize.value, this.unitFilter.value, this.sortField, true);
  };

  createUnit() {
    this.setBusy('Creating new unit');
    this.isNewRecord = true;
    this.openEditUnit();
  }

  editUnit(unit) {
    this.setBusy('Loading unit details');
    this.isNewRecord = false;
    this.openEditUnit(unit);
  }

  async openEditUnit(unit?: Unit) {
    try {
      let defaultRentCalc: IRentCalcDetails;
      if (unit) {
        await this.getUnit(unit);
        defaultRentCalc = await this.accountingService.getDefaultRentCalc(unit);
      } else {
        this.selectedUnit = new Unit() as IUnit;
      }
      if (!this.housingAuthority) {
        await this.getHousingAuthority();
      }
      this.globalModalService.openModal<UnitModalData>(UnitSetupComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
        unit: this.selectedUnit,
        editMode: unit ? true : false,
        programs: this.programList,
        projects: this.projectList,
        housingAuthority: this.housingAuthority,
        defaultRentCalc: defaultRentCalc ?? null,
        openPanel: UnitUiSections.GeneralInfo,
        cancelFunction: null,
        updateFunction: this.saveUnit,
      });
    } catch (error) {
      this.coreService.displayError(error.message, null, 'Error loading Unit Data');
    } finally {
      this.isBusy = false;
    }
  }

  saveUnit = async (unit: Unit) => {
    let isError = false;
    let response: ComplexSaveResult;
    if (unit.convertedFromProgramId == null) {
      if (unit.convertedFromProjectId !== null) {
        unit.convertedFromProjectId = null;
      }
    }
    try {
      this.setBusy('Saving Unit');
      //convert custom attribute records to CustomAttribute values
      unit.customAttributes = this.accountingService.convertAttributes(unit.customAttributes);
      //check for new unit
      if (this.isNewRecord) {
        response = await this.accountingService.saveUnit(unit);
      } else {
        //convert existing custom attribute records to CustomAttribute values
        this.selectedUnit.customAttributes = this.accountingService.convertAttributes(this.selectedUnit.customAttributes);
        response = await this.accountingService.saveUnit(unit, this.selectedUnit);
      }

      if (!response.success) {
        isError = true;
        this.coreService.displayError(response.errorMessage, null, 'Error saving unit');
      }
    } catch (error) {
      this.coreService.displayError(error, null, 'Error saving unit');
    } finally {
      this.isBusy = false;
      this.refreshUnitList();
    }
  };

  async getUnit(unit: Unit) {
    try {
      const result = await this.accountingService.getUnit(unit.id).toPromise();
      this.selectedUnit = Object.assign({}, result[0]);
    } catch (error) {
      throw new Error('Error fetching Unit data');
    }
  }

  async getHousingAuthority() {
    try {
      const result = await this.housingServices.getHousingAuthority().toPromise();
      this.housingAuthority = result.body;
    } catch (error) {
      throw new Error('Error fetching Housing Authority data');
    }
  }
}
