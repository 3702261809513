<div class="flexColumn">
    <table mat-table [dataSource]="tableData" *ngIf="tableData.length > 0; else noData">
        <ng-container  *ngFor="let col of displayedColumns; index as i;" matColumnDef={{col}}>
            <th mat-header-cell *matHeaderCellDef>{{ displayedColumns[i] }}</th>

            <td mat-cell *matCellDef="let element">
                {{ 
                    typeOfValue(element[keyInObject[i]]) === 'string' || keyInObject[i] == 'numberofPayments'
                    ? element[keyInObject[i]] 
                    : (element[keyInObject[i]] | currency)
                }}
            </td>
        
            <td mat-footer-cell *matFooterCellDef>
                {{ 
                    typeOfValue(sumAmount(tableData, keyInObject[i])) == 'string'
                    ? 'Total' 
                    : (keyInObject[i] !== 'numberofPayments' ?  (sumAmount(tableData, keyInObject[i]) | currency) : sumAmount(tableData, keyInObject[i]))
                }}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns" class="table-footer"></tr>
    </table>
    <ng-template #noData>
        <p class="no-data-message">No vendor transactions were selected for this payment type.</p>
    </ng-template>
</div>