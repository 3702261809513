<mat-card class="knso-card program-setup-container">
    <div *ngIf="loading" class="layout-row spinnerPadding">
        <mat-spinner diameter="25" > </mat-spinner> 
        <div class="leftPadding">Loading Program...</div>
    </div>
    <mat-card-header *ngIf= "!loading">
        <div>
            <mat-toolbar class="layout-row">
                <div *ngIf="selectedProgram.name == null">
                    <span>New Program</span>
                </div>
                <div *ngIf="selectedProgram.name !== null">
                    <span class="programText">{{selectedProgram.name}}</span>
                </div>
                <button mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
            </mat-toolbar>
        </div>
    </mat-card-header>
    <form #editProgramForm="ngForm" class="modal-content">
        <mat-card-content *ngIf= "!loading">
            <div class="mat-card-content-content">
                <div class="section-header chevronAlign">
                    <a class="account__details-collapse">
                        <mat-icon class="collapse-icon" 
                            [ngClass]="{'collapse-icon--open' : generalInfoPanelOpenState}"
                            (click)="generalInfoPanelOpenState = !generalInfoPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="headerPadding">General Information</div>
                </div>
                <mat-expansion-panel [expanded]="generalInfoPanelOpenState" class="hidePanel">
                    <div class="section-body layout-row layout-align-start-center">
                        <mat-form-field class="programName" appearance="outline">
                            <mat-label>Program Name</mat-label>
                            <input 
                                matInput
                                ngModel
                                [(ngModel)]="selectedProgram.name"  
                                id="programNameInput" 
                                aria-label="Program name"
                                #programNameInput="ngModel"
                                name="programNameInput"
                                required>
                                <mat-error *ngIf="programNameInput.hasError('required')">
                                    <div ng-message="required">Program name is required</div>
                                </mat-error>
                        </mat-form-field>
                    
                        <mat-form-field class="programCode" appearance="outline">
                            <mat-label>Program Code</mat-label>
                            <input 
                                matInput
                                ngModel 
                                [(ngModel)]="selectedProgram.programCode"
                                id="programCodeInput"
                                aria-label="Program code"
                                #programCodeInput="ngModel"
                                name="programCodeInput">
                        </mat-form-field>
                        <mat-form-field class="programType" appearance="outline">
                            <mat-label>Program Type</mat-label>
                            <mat-select
                                name="programType"
                                id="programType"
                                ngModel
                                [disabled]="editMode"
                                [(ngModel)]="selectedProgram.type"
                                #programType="ngModel"
                                required
                                (selectionChange)="programTypeSelected($event)">
                                <mat-option *ngFor="let type of programTypes" [value]="type">
                                    {{type}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="section-body layout-row layout-align-start-center">
                        <mat-form-field class="phaCode" appearance="outline">
                            <mat-label>PHA Code</mat-label>
                            <input 
                                matInput
                                ngModel 
                                [(ngModel)]="selectedProgram.phaCode"
                                aria-label="PHA Code"
                                maxlength="5"
                                id="programPhaCodeInput"
                                #programPhaCodeInput="ngModel"
                                name="programPhaCodeInput">
                        </mat-form-field>

                        <mat-form-field class="aelValue" appearance="outline">
                            <mat-label>46% of AEL Value</mat-label>
                            <input 
                                matInput 
                                ngModel
                                [(ngModel)]="selectedProgram.aelValue"
                                type="number"
                                monetary-input
                                aria-label="AEL Value"
                                id="programAelInput"
                                #programAelInput="ngModel"
                                name="programAelInput">
                        </mat-form-field>
                    </div>

                    <div class="section-body layout-row layout-align-start-center">
                        <div class="requires58PicForm wordPadding58" appearance="outline">Does this program require<br/>50058 Form/PIC Reporting?</div>
                        <mat-radio-group ngModel
                            [(ngModel)]="selectedProgram.requires58PicForm"
                            aria-label="Does this program require a 50058 Form/Pic Reporting?"
                            class="layout-row"
                            id="programRequires58"
                            #programRequires58="ngModel"
                            name="programRequires58">
                            <mat-radio-button [value]="true" class="md-primary leftPadding" id="pic58Required">Yes</mat-radio-button>
                            <mat-radio-button [value]="false" class="md-primary leftPadding" id="pic58NotRequired">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </mat-expansion-panel>

                <div *ngIf="selectedProgram.isHousingProgram" class="section-header chevronAlign">
                    <a class="account__details-collapse">
                        <mat-icon class="collapse-icon" 
                            [ngClass]="{'collapse-icon--open' : certificationInfoPanelOpenState}"
                            (click)="certificationInfoPanelOpenState =!certificationInfoPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="headerPadding">Certification Information</div>
                </div>
                <mat-expansion-panel [expanded]="certificationInfoPanelOpenState" class="hidePanel">
                    <div class="leftPadding section-body" *ngIf="selectedProgram.isHousingProgram">
                        <mat-checkbox 
                            ngModel 
                            [(ngModel)]="selectedProgram.isRecertRequired"
                            id="isRecertRequired"
                            #isRecertRequired="ngModel"
                            name="isRecertRequired">
                        </mat-checkbox>
                        <mat-label class="wordPadding">Certification Required</mat-label>
                    </div>
                    <div class="section-body layout-row layout-align-start-center"
                        *ngIf="selectedProgram.isRecertRequired && selectedProgram.isHousingProgram">
                        <mat-form-field class="recertPeriod" appearance="outline">
                            <mat-label>Certification Period</mat-label>
                            <input 
                                matInput 
                                ngModel
                                [(ngModel)]="selectedProgram.recertPeriod" 
                                id="programCertInput"
                                #programCertInput="ngModel"
                                aria-label="Program Certification Period"
                                name="programCertInput">
                        </mat-form-field>
                        <mat-form-field class="recertPeriodUnit" appearance="outline">
                            <mat-select 
                                ngModel
                                [(ngModel)]="selectedProgram.recertPeriodUnit"
                                aria-label="Program Certification Unit"
                                placeholder="Choose One" 
                                id="programCertUnit"
                                #programCertUnit="ngModel"
                                name="programCertUnit">
                                <mat-option value="Days">Days</mat-option>
                                <mat-option value="Months">Months</mat-option>
                                <mat-option value="Years">Years</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
                
                <div class="section-header chevronAlign">
                    <a class="account__details-collapse">
                        <mat-icon class="collapse-icon"
                            [ngClass]="{'collapse-icon--open' : customAttributesInfoPanelOpenState}"
                            (click)="customAttributesInfoPanelOpenState = !customAttributesInfoPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="headerPadding">Custom Attributes</div>
                </div>
                <mat-expansion-panel [expanded]="customAttributesInfoPanelOpenState" class="hidePanel">
                    <div class="section-body section-wording">
                        <app-custom-attributes
                          *ngIf="customAttributesTemplates?.length > 0;else noCustomAttributes"
                          [entity]="'Program'"
                          [editMode]="true"
                          [sqlData]="false"
                          [(currentCustomAttributes)]="selectedProgram.customAttributes"
                          >
                        </app-custom-attributes>
                        <ng-template #noCustomAttributes>
                            <p>There are no custom attributes that apply to this Program.</p>
                        </ng-template>
                    </div>
                </mat-expansion-panel>

                <div *ngIf="selectedProgram.type !== 'Loan' && selectedProgram.type !== 'Misc'">
                    <div class="section-header chevronAlign">
                        <a class="account__details-collapse">
                            <mat-icon class="collapse-icon" 
                                [ngClass]="{'collapse-icon--open' : monthlyChargeInfoPanelOpenState}"
                                (click)="monthlyChargeInfoPanelOpenState =!monthlyChargeInfoPanelOpenState">chevron_right
                            </mat-icon>
                        </a>
                        <div class="headerPadding">Monthly Charge Calculation Information</div>
                    </div>
                    <mat-expansion-panel [expanded]="monthlyChargeInfoPanelOpenState" class="hidePanel">
                        <div class="section-body section-wording" has-permission="'admin'">
                            <div class="layout-row layout-align-start-center">
                                <mat-form-field appearance="outline"
                                    *ngIf="selectedProgram.type !== 'Rural Development'">
                                    <mat-label>Total Tenant Payment Percent</mat-label>
                                    <input matInput ngModel
                                        [(ngModel)]="selectedProgram.rentCalc.totalTenantPaymentPercentage" 
                                        type="number"
                                        id="programTTPPercentageInput"
                                        #programTTPPercentageInput="ngModel"
                                        name="programTTPPercentageInput" 
                                        aria-label="Total tenant payment percent">
                                        <mat-hint>Input as a decimal, e.g. 0.3 for 30%</mat-hint>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>Passbook Rate</mat-label>
                                    <input matInput
                                        [formControl]="passbookRateFormGroup.get('imputedAssetRate')"
                                        type="number"
                                        id="imputedAssetRate"
                                        name="imputedAssetRate"
                                        (change)="onPassBookRateChange()"
                                        aria-label="Passbook Rate">
                                    <mat-error *ngIf="passbookRateFormGroup.get('imputedAssetRate').errors?.min">
                                        <div>Passbook rate must be greater than or equal to 0</div>
                                    </mat-error>
                                    <mat-error *ngIf="passbookRateFormGroup.get('imputedAssetRate').errors?.max">
                                        <div>Passbook rate must be less than or equal to 0.9999</div>
                                    </mat-error>
                                    <mat-error *ngIf="passbookRateFormGroup.get('imputedAssetRate').errors?.pattern">
                                        <div>Passbook rate is limited to four decimal places</div>
                                    </mat-error>
                                    
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>Allowable Dependent Deduction</mat-label>
                                    <input matInput ngModel
                                        [(ngModel)]="selectedProgram.rentCalc.allowableDependentDeduction"
                                        type="number"
                                        id="programAllowableDependentDeductionInput"
                                        #programAllowableDependentDeductionInput="ngModel" 
                                        name="programAllowableDependentDeductionInput" 
                                        aria-label="Allowable dependent deduction"
                                        monetary-input>
                                </mat-form-field>
                            </div>
    
                            <div class="layout-row layout-align-start-center">
                                <mat-form-field appearance="outline">
                                    <mat-label>Allowable Elderly/Disability Deduction</mat-label>
                                    <input matInput ngModel
                                        [(ngModel)]="selectedProgram.rentCalc.allowableElderlyDisabilityDeduction"
                                        type="number"
                                        id="programAllowableElderlyDeductionInput"
                                        #programAllowableElderlyDeductionInput="ngModel"
                                        name="programAllowableElderlyDeductionInput"
                                        aria-label="Allowable elderly deduction"
                                        monetary-input>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>Age to Qualify for Elderly Deduction</mat-label>
                                    <input matInput ngModel
                                        [(ngModel)]="selectedProgram.rentCalc.elderlyDeductionAge"
                                        type="number"
                                        id="programElderlyDeductionAgeInput"
                                        #programElderlyDeductionAgeInput="ngModel"
                                        name="programElderlyDeductionAgeInput"
                                        aria-label="Elderly deduction age">
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>Age to Qualify for Near Elder Status</mat-label>
                                    <input matInput ngModel
                                        [(ngModel)]="selectedProgram.rentCalc.nearElderAge" type="number" 
                                        id="programNearElderAgeInput"
                                        #programNearElderAgeInput="ngModel"
                                        aria-label="Near elder age"
                                        name="programRentCalcNearElderAge"> 
                                </mat-form-field>
                            </div>
    
                            <div class="layout-row layout-align-start-center">
                                <mat-form-field appearance="outline" *ngIf="housingAuthority && housingAuthority.incomeLimitAreas">
                                    <mat-label>Income Limits Area</mat-label>
                                    <mat-select ngModel
                                        [(ngModel)]="selectedProgram.rentCalc.incomeLimitArea"
                                        aria-label="Select income limit area for this Low Rent program"
                                        id="incomeLimitDropdown"
                                        #incomeLimitDropdown="ngModel"
                                        name="incomeLimitDropdown">
                                        <mat-option [value] = "undefined" id = "none"> none </mat-option>
                                        <mat-option value="{{a._id}}" *ngFor="let a of housingAuthority.incomeLimitAreas"
                                            id="{{a.name}}">{{a.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" *ngIf="housingAuthority && housingAuthority.incomeLimitAreas">
                                    <mat-label>Tax Credit Income Limits Area</mat-label>
                                    <mat-select ngModel
                                        [(ngModel)]="selectedProgram.rentCalc.taxCreditIncomeLimitArea"
                                        aria-label="Select income limit area for this Tax Credit program"
                                        id="taxCreditIncomeLimitDropdown"
                                        #taxCreditIncomeLimitDropdown="ngModel"
                                        name="taxCreditIncomeLimitDropdown"
                                        [disabled]="selectedProgram.type !== 'Tax Credit'">
                                        <mat-option [value] = "undefined" id = "none"> none </mat-option>
                                        <mat-option value="{{a._id}}" *ngFor="let a of housingAuthority.incomeLimitAreas">
                                            {{a.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline"  *ngIf="housingAuthority && housingAuthority.rentLimitAreas">
                                    <mat-label>Rent Limits Area</mat-label>
                                    <mat-select ngModel
                                        [(ngModel)]="selectedProgram.rentCalc.rentLimitArea"
                                        aria-label="Select rent limit area for this Low Rent program"
                                        id="rentLimitDropdown"
                                        #rentLimitDropdown="ngModel"
                                        name="rentLimitDropdown">
                                        <mat-option [value] = "undefined" id = "none"> none </mat-option>
                                        <mat-option value="{{a._id}}" *ngFor="let a of housingAuthority.rentLimitAreas"
                                            id="{{a.name}}">{{a.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
    
                            <div class="layout-row layout-align-start-center">
                                <mat-form-field appearance="outline">
                                    <mat-label>Calc Key</mat-label>
                                    <input matInput ngModel
                                        type="text"
                                        [(ngModel)]="selectedProgram.rentCalc.calcKey"
                                        id="rentCalcInput"
                                        #rentCalcInput="ngModel"
                                        name="rentCalcInput"
                                        aria-label="Calc key">
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>Assistance Calc Key</mat-label>
                                    <input matInput ngModel
                                        type="text"
                                        [(ngModel)]="selectedProgram.rentCalc.assistanceCalcKey"
                                        id="assistanceRentCalcInput"
                                        #assistanceRentCalcInput="ngModel"
                                        name="assistanceRentCalcInput"
                                        aria-label="Calc key">
                                </mat-form-field>
                                <mat-form-field appearance="outline"  *ngIf="housingAuthority && housingAuthority.paymentStandards">
                                    <mat-label>Payment Standards</mat-label>
                                    <mat-select ngModel
                                        [(ngModel)]="selectedProgram.rentCalc.paymentStandard"
                                        aria-label="Select payment standard for this Low Rent program"
                                        id="paymentStandardDropdown"
                                        #paymentStandardDropdown="ngModel"
                                        name="paymentStandardDropdown">
                                        <mat-option [value] = "undefined" id = "none"> none </mat-option>
                                        <mat-option value="{{paymentStandard._id}}"
                                            *ngFor="let paymentStandard of housingAuthority.paymentStandards"
                                            id="{{paymentStandard.name}}">{{paymentStandard.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="layout-row layout-align-start-center" *ngIf="selectedProgram.type && selectedProgram.type == 'Public Housing'">
                                <mat-form-field appearance="outline" >
                                    <mat-label>Min TTP (50058 Form, 9h)</mat-label>
                                    <input matInput
                                        [formControl]="minTTPFormGroup.get('minTTP')"    
                                        type="number"
                                        id="minTTP"
                                        name="minTTP"
                                        aria-label="Min TTP">
                                        <mat-error *ngIf="minTTPFormGroup.get('minTTP').errors?.min">
                                            <div>Min TTP must be between 0-51</div>
                                        </mat-error>
                                        <mat-error *ngIf="minTTPFormGroup.get('minTTP').errors?.max">
                                            <div>Min TTP must be between 0-51</div>
                                        </mat-error>
                                        <mat-error *ngIf="minTTPFormGroup.get('minTTP').errors?.pattern">
                                            <div>Min TTP must be a whole number</div>
                                        </mat-error>
                                        <mat-error *ngIf="minTTPFormGroup.get('minTTP').errors?.required">
                                            <div>Min TTP is required. Please enter a number between 0-51</div>
                                        </mat-error>
                                </mat-form-field>
                            </div>


                            <div class="section-body layout-row layout-align-start-center" *ngIf="selectedProgram.rentCalc">
                                <div class="requiresHotmaUpdates wordPaddingHotma" appearance="outline">Does this program require<br/>Hotma Updates?</div>
                                <mat-radio-group ngModel
                                    [(ngModel)]="selectedProgram.rentCalc.requiresHotmaUpdates"
                                    aria-label="Does this program require Hotma Updates?"
                                    class="layout-row"
                                    id="programRequiresHotmaUpdates"
                                    #programRequiresHotmaUpdates="ngModel"
                                    name="programRequiresHotmaUpdates">
                                    <mat-radio-button [value]="true" class="md-primary leftPadding" id="hotmaUpdateRequired">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false" class="md-primary leftPadding" id="hotmaUpdateNotRequired">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
  
                            
                            <div class="layout-row layout-align-start-center">
                                <div class="checkboxPadding section-body">
                                    <mat-checkbox ngModel
                                        [(ngModel)]="selectedProgram.rentCalc.observeNahasda30Percent" 
                                        id="programNahasda30PercentInput"
                                        #programNahasda30PercentInput="ngModel"
                                        name="programNahasda30PercentInput"
                                        aria-label="does this program observe the nahasda 30 percent rule?" >
                                    </mat-checkbox>
                                    <mat-label class="wordPadding">Observe NAHASDA 30% Rule</mat-label>      
                                </div>
                            </div>

                            <div class="layout-row layout-align-start-center" *ngIf="selectedRentCalcOption == 'utilityAllowances'">
                                <mat-radio-group 
                                    ngModel
                                    [(ngModel)]="selectedProgram.rentCalc.isUtilityAllowanceCalculations"
                                    aria-label="Does this program require utilityAllowance calculations?"
                                    class="layout-row"
                                    id="isUtilityAllowanceCalculations"
                                    #isUtilityAllowanceCalculations="ngModel"
                                    name="isUtilityAllowanceCalculations">
                                    <mat-radio-button [value]="true" class="md-primary leftPadding" id="utilityAllowanceCalculations">Utility Allowance Calculation</mat-radio-button>
                                    <mat-radio-button [value]="false" class="md-primary leftPadding" id="flatRateutilityCalculations"> Flat Rate Utility Allowance</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="inner-padding"  *ngIf="selectedRentCalcOption == 'utilityAllowances' && selectedProgram.rentCalc.isUtilityAllowanceCalculations">
                                Please ensure each unit has a unit type selected in order to calculate utility allowance by bedroom according to utility items provided 
                            </div>

                            <div class="layout-row layout-align-center-start">
                                <div class="section-body">
                                    <mat-form-field appearance="outline">
                                        <mat-label>RentCalc Options</mat-label>
                                        <mat-select 
                                        [(ngModel)]="selectedRentCalcOption"
                                        (selectionChange)="changeDisplayedRentCalcOption()"
                                        name="rentCalcOption"
                                        >
                                            <mat-option value=adminFees id="adminFees">Admin Fee</mat-option>
                                            <mat-option value=flatRents id="flatRents">Flat Rent/Charge</mat-option>
                                            <mat-option value=fairMarketRents id="fairMarketRents">Fair Market Rent</mat-option>
                                            <mat-option value=minimumCharges id="minimumCharges">Minimun Rent/Charge</mat-option>
                                            <mat-option value=maximumCharges id="maximumCharges">Maximum Rent/Charge</mat-option>
                                            <mat-option value=utilityAllowances id="utilityAllowances">Utility Allowance</mat-option>
                                            <mat-option value=rentLimits id="rentLimits">Rent Limits (Max Gross Rent - Tax Credits)</mat-option>
                                            <mat-option value=basicRents id="basicRents">Basic Rent (Rural Development Only)</mat-option>
                                            <mat-option value=noteRateRents id="noteRateRents">Note Rate Rent (Rural Development Only)</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div *ngIf="selectedRentCalcOption">
                                <div class="inner-padding" *ngIf="!(selectedRentCalcOption == 'utilityAllowances' && selectedProgram.rentCalc.isUtilityAllowanceCalculations)">
                                    <button
                                        mat-flat-button 
                                        class="hds_button" 
                                        class="marg-left-auto hds_button" 
                                        (click)="newRentCalcOption()"
                                        >Add New Entry
                                    </button>
                                    <p class="leftPadding" *ngIf="selectedRentCalcDetails.length === 0">No values defined</p>
                                </div>
                                <div class="section-body"> 
                                    <table
                                        class="table table-condensed table-bordered table-striped"
                                        *ngIf="selectedRentCalcDetails.length > 0 && !(selectedRentCalcOption == 'utilityAllowances' && selectedProgram.rentCalc.isUtilityAllowanceCalculations)"
                                        >
                                        <tr>
                                            <th>Effective Date</th>
                                            <th>0 Bedrooms</th>
                                            <th>1 Bedroom</th>
                                            <th>2 Bedrooms</th>
                                            <th>3 Bedrooms</th>
                                            <th>4 Bedrooms</th>
                                            <th>5 Bedrooms</th>
                                            <th>6 Bedrooms</th>
                                            <th>7 Bedrooms</th>
                                            <th>8 Bedrooms</th>
                                            <th></th>
                                        </tr>
                                        <tr *ngFor="let f of selectedRentCalcDetails; let i = index"
                                            id="rentCalcOptionInput">
                                            <td><span id="optionEffectiveDate">{{f.effectiveDate | date}}</span></td>
                                            <td><span id="bedroomSize0">{{(f.value[0] ? f.value[0]/100 : 0) | currency}}</span></td>
                                            <td><span id="bedroomSize1">{{(f.value[1] ? f.value[1]/100 : 0) | currency}}</span></td>
                                            <td><span id="bedroomSize2">{{(f.value[2] ? f.value[2]/100 : 0) | currency}}</span></td>
                                            <td><span id="bedroomSize3">{{(f.value[3] ? f.value[3]/100 : 0) | currency}}</span></td>
                                            <td><span id="bedroomSize4">{{(f.value[4] ? f.value[4]/100 : 0) | currency}}</span></td>
                                            <td><span id="bedroomSize5">{{(f.value[5] ? f.value[5]/100 : 0) | currency}}</span></td>
                                            <td><span id="bedroomSize6">{{(f.value[6] ? f.value[6]/100 : 0) | currency}}</span></td>
                                            <td><span id="bedroomSize7">{{(f.value[7] ? f.value[7]/100 : 0) | currency}}</span></td>
                                            <td><span id="bedroomSize8">{{(f.value[8] ? f.value[8]/100 : 0) | currency}}</span></td>
                                            <td>
                                                <div class="layout-row">
                                                    <a style="font-size:18px;" 
                                                        (click)="openRentCalcOptionsDialog(i)"><mat-icon>edit</mat-icon>
                                                    </a>
                                                    <a style="font-size:18px;margin-left: 10px;" 
                                                        (click)="deleteRentCalcOptions(i)"><mat-icon>delete</mat-icon>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </div>

                <div class="section-header chevronAlign">
                    <a class="account__details-collapse">
                        <mat-icon class="collapse-icon" 
                            [ngClass]="{'collapse-icon--open' : accountingInfoPanelOpenState}"
                            (click)="accountingInfoPanelOpenState =!accountingInfoPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="headerPadding">
                        Accounting Information
                    </div>
                </div>
                <mat-expansion-panel [expanded]="accountingInfoPanelOpenState" class="hidePanel">
                    <div *ngIf="this.loading">
                        <mat-spinner diameter="25" class="centered_spinner"> </mat-spinner> Loading ledger data
                    </div>

                    <div class="layout-row layout-align-start-center" class="paddingTable">
                        <mat-form-field appearance="outline" *ngIf="selectedProgram.rentCalc">
                            <mat-label>Fiscal Year End Month</mat-label>
                            <mat-select 
                                ngModel
                                placeholder="Choose One"
                                [(ngModel)]="selectedProgram.rentCalc.monthOfFiscalYearEnd"
                                aria-label="Fiscal Year-End Month"
                                id="fiscalYearEndDropdown"
                                name="fiscalYearEndDropdown">
                                <mat-option [value]="1" id="1">January</mat-option>
                                <mat-option [value]="2" id="2">February</mat-option>
                                <mat-option [value]="3" id="3">March</mat-option>
                                <mat-option [value]="4" id="4">April</mat-option>
                                <mat-option [value]="5" id="5">May</mat-option>
                                <mat-option [value]="6" id="6">June</mat-option>
                                <mat-option [value]="7" id="7">July</mat-option>
                                <mat-option [value]="8" id="8">August</mat-option>
                                <mat-option [value]="9" id="9">September</mat-option>
                                <mat-option [value]="10" id="10">October</mat-option>
                                <mat-option [value]="11" id="11">November</mat-option>
                                <mat-option [value]="12" id="12">December</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" *ngIf="housingAuthority && housingAuthority.fundingSources && selectedProgram.rentCalc">
                            <mat-label>Source of Funding</mat-label>
                            <mat-select 
                                ngModel
                                [(ngModel)]="selectedProgram.rentCalc.fundingSource"
                                aria-label="Funding Source"
                                id="fundingSourceDropdown"
                                name="fundingSourceDropdown">
                                <mat-option [value] = "undefined" id = "none"> none </mat-option>
                                <mat-option [value]="source" *ngFor="let source of housingAuthority.fundingSources"
                                    id="{{source}}">{{source}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    
                    <div has-permission="'admin'" *ngIf="!this.loading">
                        <div class="section-header chevronAlign">
                            <a class="account__details-collapse">
                                <mat-icon class="collapse-icon" 
                                    [ngClass]="{'collapse-icon--open' : tenantLedgerPanelOpenState}"
                                    (click)="tenantLedgerPanelOpenState =!tenantLedgerPanelOpenState">chevron_right
                                </mat-icon>
                            </a>
                            <div class="layout-row rowPaddingLeft layout-align-start-center moveButton">
                                <div class="headerPadding">
                                    Tenant Ledgers
                                </div>
                                <button mat-flat-button 
                                    type="button"
                                    class="hds_button" 
                                    (click)="openNewTenantAccountDialog('Tenant')">
                                    Add Account
                                </button>
                            </div>
                        </div>
                        <mat-expansion-panel [expanded]="tenantLedgerPanelOpenState" class="hidePanel">
                            <p class="headerPadding" *ngIf="!tenantLedgers || !tenantLedgers.length">There are no Tenant
                                Ledgers defined
                            </p>
                            <div *ngIf="!this.loading" class="paddingTable">
                                <table class="table altRows" *ngIf="tenantLedgers && tenantLedgers.length > 0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Subledger Mapping</th>
                                            <th>Rules</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tr class="centerData" *ngFor="let account of tenantLedgers"> 
                                        <td> <h4><a (click)="editTenantAccountDialog(account, 'Tenant')">{{account.name}}</a></h4></td>
                                        <td>
                                            <span class="flex-50" *ngIf="account.chartOfAccountsName">{{account.chartOfAccountsName}}</span>
                                            <span class="flex-50 color-red" *ngIf="!account.chartOfAccountsName">Must select a Subledger
                                                Mapping*.</span>
                                        </td>
                                        <td>
                                            <div class="layout-column">
                                                <div *ngFor="let rule of getNonDeletedActivityRules(account)">
                                                    <a (click)="editActivityRule(account, rule)">{{rule.displayName}}</a>
                                                </div>
                                                <a (click)="newActivityRule(account)">add</a>
                                            </div>
                                        </td>
                                        <td>
                                            <a class="marg-auto" popover-trigger="mouseenter"
                                            (click)="deleteAccountTemplate(account)">
                                                <mat-icon *ngIf="selectedProgram.type === 'Assistance' || tenantLedgers.length > 1  || !account.isPrimary && !account.isPrincipal">delete</mat-icon>
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>            
                        </mat-expansion-panel>
                        
                        <div class="section-header chevronAlign">
                            <a class="account__details-collapse">
                                <mat-icon class="collapse-icon" 
                                    [ngClass]="{'collapse-icon--open' : landlordLedgerPanelOpenState}"
                                    (click)="landlordLedgerPanelOpenState =!landlordLedgerPanelOpenState">chevron_right
                                </mat-icon>
                            </a>
                            <div class="layout-row rowPaddingLeft layout-align-start-center moveButton">
                                <div class="headerPadding">
                                    Landlord Ledger
                                </div>
                                <button 
                                    [disabled]="landlordLedger.length > 0" 
                                    mat-flat-button 
                                    type="button"
                                    class="hds_button" 
                                    (click)="openNewTenantAccountDialog('Landlord')">
                                    Add Account
                                </button>
                            </div>
                        </div>
                        <mat-expansion-panel [expanded]="landlordLedgerPanelOpenState" class="hidePanel">
                            <p class="headerPadding" *ngIf="!landlordLedger || !landlordLedger.length">There are no Landlord
                                Ledgers defined
                            </p>
                            <div *ngIf="!this.loading" class="paddingTable">
                                <table class="table altRows" *ngIf="landlordLedger && landlordLedger.length > 0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Subledger Mapping</th>
                                            <th>Rules</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tr class="centerData" *ngFor="let account of landlordLedger"> 
                                        <td>  <h4><a (click)="editTenantAccountDialog(account, 'Landlord')">{{account.name}}</a></h4></td>
                                        <td>
                                            <span class="flex-50" *ngIf="account.chartOfAccountsName">{{account.chartOfAccountsName}}</span>
                                            <span class="flex-50 color-red" *ngIf="!account.chartOfAccountsName">Must select a Subledger
                                                Mapping*.</span>
                                        </td>
                                        <td>
                                            <div class="layout-column">
                                                <div *ngFor="let rule of account.activityRules">
                                                    <a (click)="editActivityRule(account, rule)">{{rule.displayName}}</a>
                                                </div>
                                                <a (click)="newActivityRule(account)">add</a>
                                            </div>
                                        </td>
                                        <td>
                                            <a class="marg-auto" popover-trigger="mouseenter"
                                            (click)="deleteAccountTemplate(account)">
                                                <mat-icon *ngIf="selectedProgram.type === 'Assistance' || !account.isPrimary && !account.isPrincipal">delete</mat-icon>
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </mat-expansion-panel>

                        <div class="section-header chevronAlign">
                            <a class="account__details-collapse">
                                <mat-icon class="collapse-icon" 
                                    [ngClass]="{'collapse-icon--open' : utilityLedgerPanelOpenState}"
                                    (click)="utilityLedgerPanelOpenState =!utilityLedgerPanelOpenState">chevron_right
                                </mat-icon>
                            </a>
                            <div class="layout-row rowPaddingLeft layout-align-start-center moveButton">
                                <div class="headerPadding">
                                    Utility Ledger
                                </div>
                                <button 
                                    [disabled]="utilityLedger.length > 0" 
                                    mat-flat-button 
                                    type="button"
                                    class="hds_button" 
                                    (click)="openNewTenantAccountDialog('Utility')">
                                    Add Account
                                </button>
                            </div>
                        </div>
                        <mat-expansion-panel [expanded]="utilityLedgerPanelOpenState" class="hidePanel">
                            <p class="headerPadding" *ngIf="!utilityLedger || !utilityLedger.length">There are no Utility
                                Ledgers defined
                            </p>
                            <div *ngIf="!this.loading" class="paddingTable">
                                <table class="table altRows" *ngIf="utilityLedger && utilityLedger.length > 0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Subledger Mapping</th>
                                            <th>Rules</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tr class="centerData" *ngFor="let account of utilityLedger"> 
                                        <td>  <h4><a (click)="editTenantAccountDialog(account, 'Utility')">{{account.name}}</a></h4></td>
                                        <td>
                                            <span class="flex-50" *ngIf="account.chartOfAccountsName">{{account.chartOfAccountsName}}</span>
                                            <span class="flex-50 color-red" *ngIf="!account.chartOfAccountsName">Must select a Subledger
                                                Mapping*.</span>
                                        </td>
                                        <td>
                                            <div class="layout-column">
                                                <div *ngFor="let rule of account.activityRules">
                                                    <a (click)="editActivityRule(account, rule)">{{rule.displayName}}</a>
                                                </div>
                                                <a (click)="newActivityRule(account)">add</a>
                                            </div>
                                        </td>
                                        <td>
                                            <a class="marg-auto" popover-trigger="mouseenter"
                                            (click)="deleteAccountTemplate(account)">
                                                <mat-icon *ngIf="selectedProgram.type === 'Assistance' || !account.isPrimary && !account.isPrincipal">delete</mat-icon>
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </mat-expansion-panel>

                        <div class="section-header chevronAlign">
                            <a class="account__details-collapse">
                                <mat-icon class="collapse-icon" 
                                    [ngClass]="{'collapse-icon--open' : maintenanceLedgerPanelOpenState}"
                                    (click)="maintenanceLedgerPanelOpenState =!maintenanceLedgerPanelOpenState">chevron_right
                                </mat-icon>
                            </a>
                            <div class="layout-row rowPaddingLeft layout-align-start-center moveButton">
                                <div class="headerPadding">
                                    Maintenance Ledger
                                </div>
                                <button 
                                    [disabled]="maintenanceLedger.length > 0" 
                                    mat-flat-button 
                                    type="button"
                                    class="hds_button" 
                                    (click)="openNewTenantAccountDialog('Maintenance')">
                                    Add Account
                                </button>
                            </div>
                        </div>
                        <mat-expansion-panel [expanded]="maintenanceLedgerPanelOpenState" class="hidePanel">
                            <p class="headerPadding" *ngIf="!maintenanceLedger || !maintenanceLedger.length">There are no Maintenance
                                Ledgers defined
                            </p>
                            <div *ngIf="!this.loading" class="paddingTable">
                                <table class="table altRows" *ngIf="maintenanceLedger && maintenanceLedger.length > 0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Subledger Mapping</th>
                                            <th>Rules</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tr class="centerData" *ngFor="let account of maintenanceLedger"> 
                                        <td>  <h4><a (click)="editTenantAccountDialog(account, 'Maintenance')">{{account.name}}</a></h4></td>
                                        <td>
                                            <span class="flex-50" *ngIf="account.chartOfAccountsName">{{account.chartOfAccountsName}}</span>
                                            <span class="flex-50 color-red" *ngIf="!account.chartOfAccountsName">Must select a Subledger
                                                Mapping*.</span>
                                        </td>
                                        <td>
                                            <div class="layout-column">
                                                <div *ngFor="let rule of account.activityRules">
                                                    <a (click)="editActivityRule(account, rule)">{{rule.displayName}}</a>
                                                </div>
                                                <a (click)="newActivityRule(account)">add</a>
                                            </div>
                                        </td>
                                        <td>
                                            <a class="marg-auto" popover-trigger="mouseenter"
                                            (click)="deleteAccountTemplate(account)">
                                                <mat-icon *ngIf="selectedProgram.type === 'Assistance' || !account.isPrimary && !account.isPrincipal">delete</mat-icon>
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </mat-expansion-panel>

                        <div class="section-header chevronAlign">
                            <a class="account__details-collapse">
                                <mat-icon class="collapse-icon" 
                                    [ngClass]="{'collapse-icon--open' : otherLedgerPanelOpenState}"
                                    (click)="otherLedgerPanelOpenState =!otherLedgerPanelOpenState">chevron_right
                                </mat-icon>
                            </a>
                            <div class="layout-row rowPaddingLeft layout-align-start-center moveButton">
                                <div class="headerPadding">
                                    Other Ledger
                                </div>
                                <button 
                                    [disabled]="otherLedger.length > 0" 
                                    mat-flat-button 
                                    type="button"
                                    class="hds_button" 
                                    (click)="openNewTenantAccountDialog('Other')">
                                    Add Account
                                </button>
                            </div>
                        </div>
                        <mat-expansion-panel [expanded]="otherLedgerPanelOpenState" class="hidePanel">
                            <p class="headerPadding" *ngIf="!otherLedger || !otherLedger.length">There are no Other
                                Ledgers defined
                            </p>
                            <div *ngIf="!this.loading" class="paddingTable">
                                <table class="table altRows" *ngIf="otherLedger && otherLedger.length > 0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Subledger Mapping</th>
                                            <th>Rules</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tr class="centerData" *ngFor="let account of otherLedger"> 
                                        <td>  <h4><a (click)="editTenantAccountDialog(account, 'Other')">{{account.name}}</a></h4></td>
                                        <td>
                                            <span class="flex-50" *ngIf="account.chartOfAccountsName">{{account.chartOfAccountsName}}</span>
                                            <span class="flex-50 color-red" *ngIf="!account.chartOfAccountsName">Must select a Subledger
                                                Mapping*.</span>
                                        </td>
                                        <td>
                                            <div class="layout-column">
                                                <div *ngFor="let rule of account.activityRules">
                                                    <a (click)="editActivityRule(account, rule)">{{rule.displayName}}</a>
                                                </div>
                                                <a (click)="newActivityRule(account)">add</a>
                                            </div>
                                        </td>
                                        <td>
                                            <a class="marg-auto" popover-trigger="mouseenter"
                                            (click)="deleteAccountTemplate(account)">
                                                <mat-icon *ngIf="selectedProgram.type === 'Assistance' || !account.isPrimary && !account.isPrincipal">delete</mat-icon>
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </mat-expansion-panel>
                    </div>

                    <div>
                        <div class="section-header chevronAlign">
                            <a class="account__details-collapse">
                                <mat-icon class="collapse-icon" 
                                    [ngClass]="{'collapse-icon--open' : monthlyTransactionBehaviorPanelOpenState}"
                                    (click)="monthlyTransactionBehaviorPanelOpenState =!monthlyTransactionBehaviorPanelOpenState">chevron_right
                                </mat-icon>
                            </a>
                            <div class="headerPadding">
                                Monthly Transaction Behavior
                            </div>
                        </div>
                        <mat-expansion-panel [expanded]="monthlyTransactionBehaviorPanelOpenState" class="hidePanel">
                            <div class="paddingTable">
                                <div class="section-body layout-row layout-align-start-center">
                                    <mat-radio-group ngModel
                                        [(ngModel)]="selectedProgram.enhancedRentToggle"
                                        id="enhancedRentToggle"
                                        name="enhancedRentToggle">
                                        <mat-radio-button [value]="false" class="md-primary leftPadding" id="enhancedRentToggleOff">Legacy Calc 
                                        </mat-radio-button>
                                        <mat-radio-button [value]="true" class="md-primary leftPadding" id="enhancedRentToggleOn">Enhanced Rent </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div *ngIf="selectedProgram.enhancedRentToggle == true">
                                    <div *ngIf="!tenantLedgers.length && !landlordLedger.length && !utilityLedger.length && !maintenanceLedger.length && !otherLedger.length"> 
                                        <b class="headerPadding">Please add a ledger above.</b>
                                    </div>
                                    <div class= "headerPadding" *ngIf="tenantLedgers.length || landlordLedger.length || utilityLedger.length || maintenanceLedger.length || otherLedger.length">
                                        <mat-card-header class="headerPadding">Each Transaction below will post on the 1st of the Month for accounting purposes, but will automatically be created on the input Created Date each month
                                        </mat-card-header>
                                        <div class="section-header layout-row rowPaddingLeft layout-align-start-center moveButton mtb-header">
                                            <div class="headerPadding">
                                                Adjustment 
                                            </div>
                                            <div class="info-header">
                                                Adjustments are often transactions posting to Accounts Payable or Principal type accounts.
                                                <br>
                                                For example: housing assistance payments, utility reimbursements, principal reductions etc.
                                            </div>
                                            <button mat-flat-button color="primary" class="hds_button" 
                                                (click)="addERBTransaction(true)" >
                                                <mat-icon>add</mat-icon>
                                            </button>
                                        </div>
                                        <section>
                                            <table class="table altRows" *ngIf="eRBAdjustments.length > 0; else noERBAdjustments">
                                                <thead>
                                                    <tr>
                                                        <th>Ledger Type</th>
                                                        <th>Account Definition</th>
                                                        <th>Transaction Type</th>
                                                        <th>Created Date of Month</th>
                                                        <th>Created On Month</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody [formGroup]="eRBAdjustmentform">
                                                    <ng-container formArrayName="eRBAdjustments">
                                                        <tr class="centerData" *ngFor="let transactionForm of eRBAdjustments.controls; trackBy: onTrackById; let i = index" [formGroup]="transactionForm">
                                                            <td >
                                                                <mat-form-field class="erbLedgerType" appearance="outline">
                                                                    <mat-select 
                                                                        formControlName="ledgerType"
                                                                        name="eRBLedger" 
                                                                        aria-label="Ledger Type" 
                                                                        id="eRBTLedgerType"
                                                                        (selectionChange)="eRBTLedgerTypeChange(transactionForm.value.ledgerType, i, true)">
                                                                        <mat-option [value]="0" id="Tenant">Tenant</mat-option>
                                                                        <mat-option *ngIf="landlordLedger.length > 0" [value]="1" id="Landlord">Landlord</mat-option>
                                                                        <mat-option *ngIf="utilityLedger.length > 0" [value]="2" id="Utility">Utility</mat-option>
                                                                        <mat-option *ngIf="otherLedger.length > 0" [value]="3" id="Other">Other</mat-option>
                                                                        <mat-option *ngIf="maintenanceLedger.length > 0" [value]="4" id="Maintenance">Maintenance</mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </td>
                                                            <td >
                                                                <mat-form-field class="erbAccountDefinition" appearance="outline">
                                                                    <mat-select 
                                                                        formControlName="accountDefinition"
                                                                        (selectionChange)="eRBTAccountDefinitionChange(transactionForm.value.accountDefinition, i, true)"
                                                                        [compareWith]="compareWith2">
                                                                        <mat-option
                                                                            *ngFor="let accountDef of allowedAdjustmentLedgerAccounts[i]" [value]="accountDef._id">
                                                                            {{accountDef.name}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="erbTransactionType" appearance="outline">
                                                                    <mat-select 
                                                                        formControlName="transactionType"
                                                                        [compareWith]="compareWith"
                                                                        (selectionChange)="eRBTChange()"
                                                                        >
                                                                        <mat-optgroup 
                                                                            *ngFor="let category of allowedERBTAdjustmentTypes[i].chargeCategories"
                                                                            label="{{category.name}}">
                                                                            <mat-option 
                                                                                *ngFor="let subCategory of category.subCategories" 
                                                                                [value]="subCategory">
                                                                                {{subCategory.name}}
                                                                            </mat-option>
                                                                        </mat-optgroup>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </td>
                                                            <td >
                                                                <mat-form-field class="erbCreatedDate" appearance="outline">
                                                                    <input 
                                                                        matInput 
                                                                        formControlName="createdDay"
                                                                        id="createdDayInput" 
                                                                        aria-label="Created Date"
                                                                        type="number"
                                                                        [maxLength]="2"
                                                                        [max]="28"
                                                                        [min]="1"
                                                                        (change)="eRBTDateChanged(transactionForm.value.createdDay)"
                                                                        >
                                                                </mat-form-field>
                                                            </td>
                                                            <td >
                                                                <mat-radio-group
                                                                    formControlName="currentMonth"
                                                                    (change)="eRBTChange()"
                                                                    >
                                                                    <mat-radio-button [value]="true" class="md-primary" >Current 
                                                                    </mat-radio-button>
                                                                    <mat-radio-button [value]="false" class="md-primary leftPadding" >Previous 
                                                                    </mat-radio-button>
                                                                </mat-radio-group>
                                                            </td>
                                                            <td>
                                                                <a class="marg-auto" popover-trigger="mouseenter"
                                                                (click)="deleteERBTransaction(i, true)">
                                                                    <mat-icon>delete</mat-icon>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                            <ng-template #noERBAdjustments>
                                                <p class="headerPadding">No adjustment behavior setup, click Add Behavior to get started.</p>
                                            </ng-template>
                                        </section>
                                        <div class="section-header layout-row rowPaddingLeft layout-align-start-center moveButton  mtb-header">
                                            <div class="headerPadding">
                                                Charges
                                            </div>
                                            <div class="info-header">
                                                Charges are transactions posting to Accounts Receivable type accounts.
                                                <br>
                                                For example: Rent, Admin Fee, etc.
                                            </div>
                                            <button mat-flat-button color="primary" class="hds_button" 
                                                (click)="addERBTransaction(false)">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                        </div>
                                        <section>
                                            <table class="table altRows" *ngIf="eRBTransactions.length > 0; else noERBTransactions">
                                                <thead>
                                                    <tr>
                                                        <th>Ledger Type</th>
                                                        <th>Account Definition</th>
                                                        <th>Transaction Type</th>
                                                        <th>Created Date of Month</th>
                                                        <th>Created On Month</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody [formGroup]="eRBTransactionform">
                                                    <ng-container formArrayName="eRBTransactions">
                                                        <tr class="centerData" *ngFor="let transactionForm of eRBTransactions.controls; trackBy: onTrackById; let i = index" [formGroup]="transactionForm">
                                                            <td >
                                                                <mat-form-field class="erbLedgerType" appearance="outline">
                                                                    <mat-select 
                                                                        formControlName="ledgerType"
                                                                        name="eRBLedger" 
                                                                        aria-label="Ledger Type" 
                                                                        id="eRBTLedgerType"
                                                                        (selectionChange)="eRBTLedgerTypeChange(transactionForm.value.ledgerType, i, false)">
                                                                        <mat-option [value]="0" id="Tenant">Tenant</mat-option>
                                                                        <mat-option *ngIf="landlordLedger.length > 0" [value]="1" id="Landlord">Landlord</mat-option>
                                                                        <mat-option *ngIf="utilityLedger.length > 0" [value]="2" id="Utility">Utility</mat-option>
                                                                        <mat-option *ngIf="otherLedger.length > 0" [value]="3" id="Other">Other</mat-option>
                                                                        <mat-option *ngIf="maintenanceLedger.length > 0" [value]="4" id="Maintenance">Maintenance</mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </td>
                                                            <td >
                                                                <mat-form-field class="erbAccountDefinition" appearance="outline">
                                                                    <mat-select 
                                                                        formControlName="accountDefinition"
                                                                        [compareWith]="compareWith2"
                                                                        (selectionChange)="eRBTAccountDefinitionChange(transactionForm.value.accountDefinition, i, false)">
                                                                        <mat-option
                                                                            *ngFor="let accountDef of allowedTransactionLedgerAccounts[i]" [value]="accountDef._id">
                                                                            {{accountDef.name}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="erbTransactionType" appearance="outline">
                                                                    <mat-select 
                                                                        formControlName="transactionType"
                                                                        [compareWith]="compareWith"
                                                                        (selectionChange)="eRBTChange()"
                                                                        >
                                                                        <mat-optgroup 
                                                                            *ngFor="let category of allowedERBTransactionTypes[i].chargeCategories"
                                                                            label="{{category.name}}">
                                                                            <mat-option 
                                                                                *ngFor="let subCategory of category.subCategories" 
                                                                                [value]="subCategory">
                                                                                {{subCategory.name}}
                                                                            </mat-option>
                                                                        </mat-optgroup>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </td>
                                                            <td >
                                                                <mat-form-field class="erbCreatedDate" appearance="outline">
                                                                    <input 
                                                                        matInput 
                                                                        formControlName="createdDay"
                                                                        id="createdDayInput" 
                                                                        aria-label="Created Date"
                                                                        type="number"
                                                                        [maxLength]="2"
                                                                        [max]="28"
                                                                        [min]="1"
                                                                        (change)="eRBTDateChanged(transactionForm.value.createdDay)"
                                                                        >
                                                                </mat-form-field>
                                                            </td>
                                                            <td >
                                                                <mat-radio-group formControlName="currentMonth" (change)="eRBTChange()">
                                                                    <mat-radio-button [value]="true" class="md-primary" >Current 
                                                                    </mat-radio-button>
                                                                    <mat-radio-button [value]="false" class="md-primary leftPadding" >Previous 
                                                                    </mat-radio-button>
                                                                </mat-radio-group>
                                                            </td>
                                                            <td>
                                                                <a class="marg-auto" popover-trigger="mouseenter"
                                                                (click)="deleteERBTransaction(i, false)">
                                                                    <mat-icon>delete</mat-icon>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                            <ng-template #noERBTransactions>
                                                <p class="headerPadding">No charges behavior setup, click Add Behavior to get started.</p>
                                            </ng-template>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel> 
                    </div>
                </mat-expansion-panel>

                <div class="section-header chevronAlign">
                    <a class="account__details-collapse">
                        <mat-icon class="collapse-icon" 
                            [ngClass]="{'collapse-icon--open' : chargeConfigurationInfoPanelOpenState}"
                            (click)="chargeConfigurationInfoPanelOpenState =!chargeConfigurationInfoPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="headerPadding">
                        Automatic Charge Configurations
                    </div>
                </div>

            
                <mat-expansion-panel [expanded]="chargeConfigurationInfoPanelOpenState" class="hidePanel" has-permission="'admin'"
                    *ngIf="selectedProgram.accountDefinitions && selectedProgram.accountDefinitions.length > 0 && selectedProgram.type != 'Misc'">
                    <div class="section-header">Work Order Tenant Charges</div>
                    <div class="layout-row layout-align-start-center section-body">
                        <mat-form-field class="workOrderAcctDef" appearance="outline">
                            <mat-label>Select Tenant Account Definition</mat-label>
                            <mat-select
                                ngModel
                                [(ngModel)]="selectedAccountDef"
                                (ngModelChange)="chartOfAccountsSelected(selectedAccountDef)"
                                id="selectedAccountDef"
                                name="selectedAccountDef">
                                <mat-option [value] = "undefined" id = "none"> none </mat-option>
                                <mat-option *ngFor="let accountDef of selectedProgram.accountDefinitions" [value]="accountDef">
                                    {{accountDef.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="workOrderSubcategory" appearance="outline" *ngIf="selectedChartOfAccounts">
                            <mat-label>Select SubCategory</mat-label>
                            <mat-select
                                ngModel
                                [(ngModel)]="workOrderAutoChargeConfiguration"
                                (ngModelChange)="addWorkOrderAutoChargeConfiguration(workOrderAutoChargeConfiguration)"
                                id="selectedSubCategory"
                                [compareWith]="compareWithSubCategory"
                                name="selectedSubCategory">
                                <mat-optgroup *ngFor="let category of selectedChartOfAccounts.chargeCategories"
                                    label="{{category.name}}">
                                    <mat-option *ngFor="let subCategory of category.subCategories"
                                        ng-selected="subCategory.chartOfAccountsId === workOrderAutoChargeConfiguration.chartOfAccountsId && subCategory.name === workOrderAutoChargeConfiguration.subCategoryName"
                                        [value]="{
                                                    subCategoryName: subCategory.name,
                                                    accountDefinition: selectedAccountDef._id,
                                                    categoryName: category.name,
                                                    subCategoryId: subCategory._id,
                                                    type: 'Work Order Tenant Charge',
                                                    categoryId: subCategory.categoryId,
                                                    chartOfAccountsId: subCategory.chartOfAccountsId,
                                                    chartOfAccountsName: subCategory.chartOfAccountsName
                                                }">{{subCategory.name}}</mat-option>
                                </mat-optgroup>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button mat-flat-button class="hds_button cancel" (click)="closeDialog()" id="cancelButton">Cancel</button>
            <button mat-flat-button class="hds_button" (click)="save()" id="saveButton" 
                [disabled]="!editProgramForm.valid 
                || editProgramForm.pristine 
                || !tenantLedgers[0].chartOfAccounts 
                || !passbookRateFormGroup.valid 
                || !eRBTransactions.valid 
                || !eRBAdjustments.valid
                || (selectedProgram.type == 'Public Housing' && !minTTPFormGroup.valid) 
                || isSaving ">
                {{saveButtonText()}} <mat-spinner *ngIf="isSaving" diameter="18" > </mat-spinner>
            </button>
        </mat-card-actions>
    </form>
</mat-card>
