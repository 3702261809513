<mat-card class="knso-card">
    <mat-card-header>
        <mat-toolbar>
        <span>{{getHeaderText()}}</span>
        <button mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
    </mat-card-header>
    <mat-card-content>
        <form #glAccountForm="ngForm">
            <div class="layout-row">
                <mat-form-field 
                    appearance="outline">
                    <mat-label flex="20">Account Name:</mat-label>
                        <input 
                            matInput
                            ngModel
                            [(ngModel)]="editGeneralLedgerAccount.name"
                            #accountName="ngModel"
                            name="accountName"
                            type="text" 
                            required                                        
                            aria-label="Account Name">
                        <mat-error *ngIf="accountName.hasError('required')">
                            <div ng-message="required">{{getErrorMessage(accountName,'accountName')}}</div>
                        </mat-error>
                </mat-form-field>
            </div>
            <div class="layout-row">
                <mat-form-field 
                    appearance="outline">
                    <mat-label flex="20">Account Code:</mat-label>
                        <input 
                            matInput
                            ngModel
                            [(ngModel)]="editGeneralLedgerAccount.accountCode"
                            name="accountCode"
                            #accountCode="ngModel"                   
                            required 
                            type="text" 
                            aria-label="Account Code">
                        <mat-error *ngIf="accountCode.hasError('required')">
                            <div ng-message="required">{{getErrorMessage(accountCode,'accountCode')}}</div>
                        </mat-error>
                </mat-form-field>
            </div>
        </form>
    </mat-card-content>
    <mat-card-actions>    
            <button 
                mat-flat-button
                (click)="closeDialog()"
                class="hds_button cancel"            
                id="cancelButton">
                    Cancel
            </button>
            <button 
                mat-flat-button 
                class="hds_button"             
                (click)="saveAccount()"
                [disabled]="!glAccountForm.valid || (editMode && glAccountForm.pristine)"
                id="saveButton">
                    Save
            </button>
    </mat-card-actions>    
</mat-card>
