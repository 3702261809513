import {Authority} from '../../housing-core/services/housing-models';
import {dataWithPlainTextKey, decryptInputObject} from './encryption-model';

export interface FileHeaderInput {
  immediateOriginName: string;
  immediateOriginNumber: string | number;
  immediateDestinationName: string;
  immediateDestinationRoutingNumber: string | number;
  fileIdModifier: string;
  referenceCode?: string;
}

export interface FileTrailerInput {
  batchCount: number;
  blockCount: number;
  entryOrAddendaCount: number;
  entryHash: number;
  totalDebitEntry: number;
  totalCreditEntry: number;
}

export interface BatchHeaderInput {
  serviceClassCode: string;
  companyName: string;
  companyDiscretionaryData?: string;
  companyIdentification: string | number;
  standardEntryClassCode: string;
  companyEntryDescription: string;
  companyDescriptiveDate?: string;
  effectiveEntryDate: string;
  settlementDate?: string;
  originatorStatusCode: string;
  originatingDFIIdentification: string | number;
  batchNumber: number;
}

export interface BatchTrailerInput {
  serviceClassCode: string;
  entryOrAddendaCount: number;
  entryHash: number;
  totalDebitEntry: number;
  totalCreditEntry: number;
  companyIdentification: string;
  messageAuthenticationCode?: string;
  originatingDFIIdentification: string;
  batchNumber: number;
}

export interface PPDAndCCDEntryInput {
  transactionCode: number;
  receivingDFIIdentification: string;
  checkDigit: string;
  dFIAccountNumber: string;
  amount: number;
  identificationNumber?: string;
  receivingIndividualOrCompanyName: string;
  discretionaryData?: string;
  traceNumber: string;
  sequenceNumber: number;
}

export interface PPDCCDAndWEBAddendaRecordsInput {
  paymentRelatedInformation?: string;
  entryDetailSequenceNumber: string;
}

export interface WebAndTelEntryInput {
  transactionCode: number;
  receivingDFIIdentification: string;
  checkDigit: string;
  dFIAccountNumber: string;
  amount: number;
  individualIdentificationNumber?: string;
  individualName: string; // 22
  paymentTypeCode: string; // 2
  addendaRecordIndicator: string;
  traceNumber: string;
  sequenceNumber: number;
}

export interface keyVectorInput {
  vendorId: string;
  dataKey: string;
  dataVector: string;
}

export type HousingAuthority = Pick<Authority, 'name' | 'fedTaxId'>;

export const SECcodes = {
  accountReceivableEntries: 'ARC',
  backOfficeConversion: 'BOC',
  corporateCreditOrDebit: 'CCD',
  corporateCreditOrDebitPlus: 'CCD+',
  customerInitiatedEntries: 'CIE',
  corporateTradeExchange: 'CTX',
  internationalACHTransaction: 'IAT',
  pointOfPurchase: 'POP',
  pointOfSale: 'POS',
  prearrangedPaymentOrDeposit: 'PPD',
  prearrangedPaymentOrDepositPlus: 'PPD+',
  representedCheckEntries: 'RCK',
  telephoneInitiatedEntries: 'TEL',
  internetInitiatedOrMobileEntries: 'WEB',
};

export const ServiceClassCodes = {
  bothDebitsAndCreditsMixed: '200',
  credits: '220',
  debits: '225',
};

export enum NACHALineType {
  FileHeaderRecord = 0,
  FileTrailerRecord,
  BatchHeaderRecord,
  BatchTrailerRecord,
  PPDAndCCDEntry,
  PPDCCDAndWEBAddendaRecords,
  WebAndTelEntry,
}

export const CheckingTransactionCodes = {
  credit: 22,
  creditPrenote: 23,
  debit: 27,
  debitPrenote: 28,
};

export const SavingTransactionCodes = {
  credit: 32,
  creditPrenote: 33,
  debit: 37,
  debitPrenote: 38,
};

export interface VendorData {
  id: string;
  accountNumberData: string;
  createdOn: Date;
  createdBy: string;
  deletedOn: Date;
  deletedBy: string;
  modifiedOn: Date;
  modifiedBy: string;
  siteId: string;
  customerId: string;
}

export interface ModifiedVendorData extends VendorData {
  keyVectorObject?: keyVectorInput;
}

export interface AuthorityACH {
  id: string;
  bankTokenDisplay: string; //last 4 digit of account number to combine with ACH;
  dataKey: string;
  dataVector: string;
  accountId: string;
  odfiRoutingNumber: string;
  odfiName: string;
  achDescription?: string;
  createdOn: Date;
  createdBy: string;
  deletedOn: Date;
  deletedBy: string;
  modifiedOn: Date;
  modifiedBy: string;
  siteId: string;
  customerId: string;
}

export interface VendorACH {
  id: string;
  accountNumberDisplay: string; //last 4 digit of account number to combine with ACH;
  dataKey: string;
  dataVector: string;
  routingNumber: string;
  consumerAcct: boolean; // type is boolean in backend but save at 0 or 1 in SQl
  checkingAcct: boolean; // type is boolean in backend but save at 0 or 1 in SQl
  vendorId: string;
  createdOn: Date;
  createdBy: string;
  deletedOn: Date;
  deletedBy: string;
  modifiedOn: Date;
  modifiedBy: string;
  siteId: string;
  customerId: string;
}

export interface ACHTransactions {
  payTo: string;
  vendorId: string;
  lastPaymentAmount: number;
  balanceToBePaid: number;
  checkNumber: number;
  postedOn: Date;
  voidedOn: Date;
  sequence: number;
  isOrganization: boolean;
}

export interface ModifiedACHTransactions extends ACHTransactions {
  decryptedVendor?: vendorDataWithPlainTextKey;
}

export interface AuthorityData {
  id: string;
  tokenData: string;
  siteId: string;
  customerId: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
}

export interface decryptInputObjectWithVendorId extends decryptInputObject {
  vendorId?: string;
  routingNumber?: string;
  consumerAcct: boolean;
  checkingAcct: boolean;
}

export interface vendorDataWithPlainTextKey extends dataWithPlainTextKey {
  vendorId: string;
  routingNumber?: string;
  consumerAcct: boolean;
  checkingAcct: boolean;
  payTo?: string;
  balanceToBePaid?: number;
}

export interface modifiedACHTransactionsObject {
  ppdTransactionCollection: vendorDataWithPlainTextKey[];
  ccdTransactionCollection: vendorDataWithPlainTextKey[];
}
