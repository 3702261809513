<div class="wizard-title-container flex-fw-container">
    <div class="wizard-title flex-fw-container" >
        <p>
            <ng-content></ng-content>
        </p>
    </div>
    <div 
        class="dot-connect flex-fw-container"   
        [ngClass]="[
        stepper.selectedIndex > position  ? 'active' : '',
        stepper.selectedIndex === position  ? 'semi-active' : '',
        ]">
        <div class="flex-grow"></div>
        <mat-icon>circle</mat-icon>
        <div class="flex-grow"></div>
    </div>
</div>
