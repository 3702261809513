<mat-card class="knso-card">
    <mat-card-header>
        <mat-toolbar>
            <span>{{ headerText }}</span>
            <button mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
          </mat-toolbar>
    </mat-card-header>
    <mat-card-content>
      <div class="whiteSpace" *ngIf="confirmMessage">
        <textarea
          [ngModel]="confirmMessage"
          cols="60"  
          rows="{{expectedRows(confirmMessage, 60, 10)}}" 
          readonly
          >
        </textarea>
      </div>
      <div>
        {{ actionMessage }}
      </div>
    </mat-card-content>
    <mat-card-actions>
        <div class="bottom-buttons">
            <button *ngIf="!confirmButtonText" mat-flat-button class="hds_button" (click)="completeAction()" id="saveButton">Yes</button>
            <button *ngIf="confirmButtonText" mat-flat-button class="hds_button" (click)="completeAction()" id="saveButton">{{confirmButtonText}}</button>          
            <button mat-flat-button class="hds_button cancel"  (click)="closeDialog()" id="cancelButton">No</button>
        </div>
    </mat-card-actions>
</mat-card>
