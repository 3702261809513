import {Component, OnInit} from '@angular/core';
import {Note} from 'src/app/core/service/core-models';
import {Vendor} from 'src/app/custom/housing-core/services/housing-models';
import {VendorService} from 'src/app/custom/vendor/vendor.service';
import {GlobalModalChild, GlobalModalParent} from 'src/app/kanso-common/core/service';

@Component({
  selector: 'app-payables-notes',
  templateUrl: './payables-notes.component.html',
  styleUrls: ['./payables-notes.component.scss'],
})
export class PayablesNotesComponent implements GlobalModalChild<PayablesNotes>, PayablesNotes, OnInit {
  customerId: string;
  siteId: string;
  vendorId: string;
  landLord: string;
  notes: Note[];
  noteIndex: number;
  selectedNote;
  editedNoteBody: string;
  loggedInUser: string;
  globalModalParent: GlobalModalParent;
  loading = true;
  private CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  private SITE_ID = sessionStorage.getItem('SITEID') || '';

  // eslint-disable-next-line
  constructor(private vendorService: VendorService) {}

  // eslint-disable-next-line
  ngOnInit(): void {
    this.customerId = this.CUSTOMER_ID;
    this.siteId = this.SITE_ID;
    this.getCurrentUsersLogInCookie();
    this.getVendorNote();
  }

  getVendorNote(): void {
    this.vendorService.getVendorNotesByGuid(this.siteId, this.vendorId).subscribe(
      (result: Note[]) => {
        this.notes = result;
        this.loading = false;
      },
      error => {
        this.loading = false;
        console.log('there was an error retrieving vendor notes', error);
      }
    );
  }

  onEditActivate(index: number, vendorNote): void {
    this.noteIndex = index;
    this.selectedNote = vendorNote;
    this.editedNoteBody = vendorNote.body;
  }

  onEditDeactivate(): void {
    this.noteIndex = null;
    this.selectedNote = null;
    this.editedNoteBody = null;
  }

  // eslint-disable-next-line
  editPayablesNote(editedNoteBody): void {
    this.noteIndex = null;
    this.loading = true;
    const command = {
      customerId: this.customerId,
      siteId: this.siteId,
      id: this.selectedNote.id,
      body: editedNoteBody,
      category: this.selectedNote.category,
      modifiedBy: this.loggedInUser,
    };

    this.vendorService.updateVendorNote(command).subscribe(
      (result: any) => {
        this.getVendorNote();
        this.loading = false;
      },
      error => {
        console.log('there was an error updating vendor note', error);
      }
    );
  }

  deletePayablesNote(vendorNote) {
    this.loading = true;
    const command = {
      customerId: this.customerId,
      siteId: this.siteId,
      id: vendorNote.id,
      deletedBy: this.loggedInUser,
    };

    this.vendorService.deleteVendorNote(command).subscribe(
      (result: any) => {
        this.getVendorNote();
      },
      error => {
        this.loading = false;
        console.log('there was an error deleting vendor note', error);
      }
    );
  }

  getCurrentUsersLogInCookie() {
    const cookies = document.cookie.split(';');
    let usersAuthCookie;
    for (const cookie of cookies) {
      if (cookie.includes('LastAuthUser')) {
        //found the cookie of the user
        usersAuthCookie = cookie;
      }
    }
    const arrayOfCookieStrings = usersAuthCookie.split('=');
    this.loggedInUser = arrayOfCookieStrings[1];
  }

  onCloseNotes(): void {
    this.globalModalParent.closePopup();
  }

  onSaveNotes(): void {
    this.globalModalParent.closePopup();
  }
}

export interface PayablesNotes {
  vendorId: string;
  landLord: string;
  notes: Note[];
}

export interface NoteDetail {
  author: string;
  releaseDate: Date;
  content: string;
}
