import {Component, Input, ViewChild} from '@angular/core';
import {GlobalModalChild, GlobalModalParent} from '../../../../kanso-common/core/service/globalmodal.service';
import {
  IncomeLimitModalData,
  PaymentStandard,
  PaymentStandardModal,
  PaymentStandardTableModal,
  PaymentStandardValue,
} from '../../../housing-core/services/housing-models';
import moment from 'moment';
import lodash from 'lodash';
@Component({
  selector: 'app-edit-payment-standards',
  templateUrl: './edit-payment-standards.component.html',
  styleUrls: ['./edit-payment-standards.component.scss'],
})
export class EditPaymentStandardsComponent implements GlobalModalChild<IncomeLimitModalData>, IncomeLimitModalData {
  //eslint-disable-next-line
  @Input() updateFunction: Function;
  @Input() name: string;
  @Input() index: number;
  @Input() editMode: boolean;
  _paymentStandard: PaymentStandard;
  createMode: boolean;
  @Input() set paymentStandard(setPayment: PaymentStandard) {
    this._paymentStandard = lodash.cloneDeep(setPayment);
    if (!setPayment) {
      this.createMode = true;
      this.editPayment = new PaymentStandardModal();
      this.editEffectiveDate = false;
    } else {
      this.createMode = false;
      this.editPayment = lodash.cloneDeep(this._paymentStandard);
      for (const table of this.editPayment.paymentStandardTables) {
        table.effectiveDate = new Date(table.effectiveDate);
        for (const value of table.values) {
          if (value.amount) {
            value.amount = value.amount / 100;
          } else {
            value.amount = 0;
          }
        }
      }
    }
    this.loading = false;
  }
  get paymentStandard(): PaymentStandard {
    return this._paymentStandard;
  }

  editPayment: PaymentStandard;
  globalModalParent: GlobalModalParent;
  selectedIndex = 0;
  editEffectiveDate = false;
  loading = true;
  timesFormUpdated = 0;

  enableEditMode() {
    this.editMode = true;
    this.createMode = true;
  }

  addTableRow() {
    const newPaymentStandardTable = new PaymentStandardTableModal(new Date(), [
      new PaymentStandardValue(0, 0),
      new PaymentStandardValue(1, 0),
      new PaymentStandardValue(2, 0),
      new PaymentStandardValue(3, 0),
      new PaymentStandardValue(4, 0),
      new PaymentStandardValue(5, 0),
      new PaymentStandardValue(6, 0),
      new PaymentStandardValue(7, 0),
      new PaymentStandardValue(8, 0),
    ]);
    this.editPayment.paymentStandardTables.push(newPaymentStandardTable);
  }

  deleteTableRow(i) {
    this.editPayment.paymentStandardTables.splice(i, 1);
  }

  cancel() {
    if (!this.index && this.timesFormUpdated > 0) {
      this.updateFunction(this.paymentStandard, this.index);
    }
    this.globalModalParent.closePopup();
  }

  onSubmit() {
    for (const table of this.editPayment.paymentStandardTables) {
      table.effectiveDate = moment(table.effectiveDate).format('YYYY/MM/DD');
      for (const value of table.values) {
        value.amount = value.amount * 100;
      }
    }
    this.paymentStandard = lodash.cloneDeep(this.editPayment);
    this.editMode = false;
    this.timesFormUpdated++;
    if (this.index) {
      this.updateFunction(this.paymentStandard, this.index);
    }
  }
}
