import {Component, EventEmitter, Injector, Input, Output} from '@angular/core';

import * as lodash from 'lodash';

import {CoreService} from '../../../../core/service/core.service';

import {VendorTransaction, TransactionCategory, TransactionRequestType, Transaction} from '../../../accounting/service/accounting-models';
import {AccountingService} from '../../../accounting/service/accounting.service';
import {UserService} from '../../../../core/user/user.service';
import {IdEmmitter, ProcessType, VendorService, VoidOn} from '../../vendor.service';
import {GlobalModalService} from 'src/app/kanso-common/core/service';
import {VoidTransactionPopupComponent} from './void-transaction-popup/void-transaction-popup.component';

@Component({
  selector: 'vendor-transaction-detail',
  templateUrl: './vendor-transaction-detail.component.html',
  styleUrls: ['./vendor-transaction-detail.component.scss'],
})
export class VendorTransactionDetailComponent {
  customerId: string;
  siteId: string;
  @Input() transaction: VendorTransaction;
  // @Input() onTransactionVoided;
  // @Input() onTransactionEdited;
  @Output() voidedBatchId: EventEmitter<IdEmmitter> = new EventEmitter();
  isInEditMode = false;
  obtainingCoa = false;
  editedDescription: string;
  editedCheckNumber: string;
  transactionCategories: TransactionCategory[];
  voidMessage = [];
  selectedSubCategory;
  isBusy = false;
  private CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  private SITE_ID = sessionStorage.getItem('SITEID') || '';
  constructor(
    protected accountingService: AccountingService,
    public userService: UserService,
    public coreServices: CoreService,
    public vendorService: VendorService,
    public globalModalService: GlobalModalService,
    protected injector: Injector
  ) {}

  editTransaction() {
    this.editedDescription = this.transaction.description;
    this.editedCheckNumber = this.transaction.checkNumber;
    this.isInEditMode = true;
  }

  findCategoryBySubCategoryId(subCategoryId) {
    return lodash.find(this.transactionCategories, c => {
      const subCat = lodash.find(c.subCategories, {_id: subCategoryId});
      return !lodash.isNil(subCat);
    });
  }

  cancelEdit() {
    this.editedCheckNumber = null;
    this.editedDescription = null;
    this.isInEditMode = false;
  }

  voidTransaction = () => {
    this.siteId = this.SITE_ID;
    this.voidMessage = [];
    if (this.transaction.isPayment) {
      this.vendorService
        .getVendorBatchPayments(this.siteId, this.transaction.vendorId, this.transaction.batchId)
        .subscribe(result => {
          for (const transaction of result) {
            this.voidMessage.push(`\n ${transaction.vendorAccountName}: $${transaction.amount}`);
          }
          this.coreServices.confirmAction(
            this._deleteConfirmMessage(),
            this._voidPayableTransactions,
            `Void this Payment?`,
            'Are you sure you want to delete this historical payable batch? This cannot be undone.',
            `Void`
          );
        });
    } else {
      this.globalModalService.openModal<VoidOn>(
        VoidTransactionPopupComponent,
        this.injector,
        this.globalModalService.getModalOverlayConfig(),
        {
          transaction: this.transaction,
          updateFunction: this._voidVendorTransaction,
        }
      );
    }
  };
  private _deleteConfirmMessage() {
    return `Are you sure you want to void this transaction? Doing so will also void the following adjustments: ${this.voidMessage}`;
  }

  private _voidPayableTransactions = () => {
    this.customerId = this.CUSTOMER_ID;
    this.siteId = this.SITE_ID;
    try {
      const command = {
        batchId: this.transaction.batchId,
        siteId: this.siteId,
        customerId: this.customerId = this.CUSTOMER_ID,
        voidedBy: this.coreServices.getCurrentUsersLogInCookie(),
        vendorIds: this.transaction.vendorId,
      };
      this.vendorService.voidPayableTransaction(command).subscribe(res => {
        this.voidedBatchId.emit({id: this.transaction.batchId, processType: ProcessType.Payables});
      });
    } catch (error) {
      this.coreServices.displayError(`Error trying to void Transactions: ${error.message}`);
      console.log(`Error trying to void Transactions: ${error.message}`);
    }
  };

  private _voidVendorTransaction = (voidReason: string) => {
    this.customerId = this.CUSTOMER_ID;
    this.siteId = this.SITE_ID;
    try {
      const command = {
        id: this.transaction.id,
        siteId: this.siteId,
        customerId: this.customerId = this.CUSTOMER_ID,
        voidedBy: this.coreServices.getCurrentUsersLogInCookie(),
        description: voidReason,
      };
      this.vendorService.voidVendorTransaction(command).subscribe(res => {
        this.voidedBatchId.emit({id: this.transaction.id, processType: ProcessType.Transaction});
      });
    } catch (error) {
      this.coreServices.displayError(`Error trying to void Transaction: ${error.message}`);
      console.log(`Error trying to void Transaction: ${error.message}`);
    }
  };

  // updateTransaction() {
  //   let category = this.findCategoryBySubCategoryId(this.selectedSubCategory._id);
  //   if (!category) {
  //     this.coreServices.publishError(null, { details: new Error('Unable to locate Category for SubCategory: [' + this.selectedSubCategory._id + ']') });
  //     return;
  //   }

  //   let editedTransaction = Object.assign({}, this.transaction);
  //   editedTransaction.description = this.editedDescription;
  //   editedTransaction.checknumber = this.editedCheckNumber;

  //   this.isBusy = true;
  //   this.accountingService.updateTransaction(editedTransaction._id, editedTransaction)
  //     .subscribe(response => {
  //       this.isBusy = false;
  //       // angular.copy(editedTransaction, transaction);
  //       this.transaction.__v = response.__v;
  //       this.transaction.modifiedBy = response.modifiedBy;
  //       this.transaction.modifiedOn = response.modifiedOn;
  //       //see https://hdslabs.atlassian.net/browse/DOOR-4074
  //       this.cancelEdit();
  //       if (this.onTransactionEdited) this.onTransactionEdited({ data: this.transaction });
  //     },
  //     err => {
  //       this.coreServices.publishError(err.data.error ? err.data.error : null, { details: err });
  //       this.isBusy = false;
  //     });
  // };

  openPrintableTransaction() {
    const reportTags = this._getReportTags();

    //    $state.go('fullScreenState.generateOutput', {
    //      reportTags,
    //      context: {
    //        type: 'Transaction',
    //        object: {
    //          transactionId: transaction._id
    //        }
    //      }
    //    });
  }

  _getReportTags() {
    //    if ($state.current.name === 'accounting.miscellaneous-receipts') {
    //      return ['Miscellaneous Receipt'];
    //    } else {
    return this.transaction.amount > 0 ? ['Receipt'] : ['Invoice'];
    //    }
  }
}
