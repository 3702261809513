import {Component, Injector, OnInit} from '@angular/core';
import {VendorService} from 'src/app/custom/vendor/vendor.service';
import {GlobalModalService} from 'src/app/kanso-common/core/service/globalmodal.service';

@Component({
  selector: 'app-landlord-properties',
  templateUrl: './landlord-properties.component.html',
  styleUrls: ['./landlord-properties.component.scss'],
})
export class LandlordPropertiesComponent {
  showPage = 'landlord-properties';
  loading = true;
  landordProperties = [];

  constructor(public globalModalService: GlobalModalService, protected injector: Injector, private vendorService: VendorService) {
    // this.vendorService.getLandlordProperties(environment.SITE_ID).subscribe(result => {
    //   this.landlordProperties = result.data;
    //   this.pageInfo = result.pageInfo;

    this.landordProperties = mockLandlordProperties;
    this.loading = false;
    //});
  }

  addLandlordProperty() {
    console.log('add landlord Property :');
  }

  deleteLandlordProperty(indexOfLandlordProperty) {
    //this.landordProperties.splice(indexOfLandlordProperty, 1);
    console.log('index of Landlord Property to delete :', indexOfLandlordProperty);
  }

  getNavClass(page: string) {
    return page === this.showPage ? 'nav-active' : '';
  }

  selectedProperty(id) {
    console.log('selected property id :', id);
  }
}

const mockLandlordProperties = [
  {
    id: '12345',
    propertyName: 'Manor Hills Apartments',
    associatedLandlord: 'Brandybuck Properties',
    totalUnits: '300',
    percentOccupied: '5%',
  },
  {
    id: '67891',
    propertyName: 'Grandview Apartments',
    associatedLandlord: 'Brandybuck Properties',
    totalUnits: '145',
    percentOccupied: '14%',
  },
  {
    id: '33333',
    propertyName: 'Manor Hills Apartments',
    associatedLandlord: 'Middle Earth Housing',
    totalUnits: '78',
    percentOccupied: '25%',
  },
];
