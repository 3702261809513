

<mat-card class="knso-card project-setup-container">
    <mat-card-header *ngIf= "!loading">
        <div>
            <mat-toolbar class="layout-row">
                <div *ngIf="editProject.name == null">
                    <span class="projectText">{{editProject.name}}</span>
                </div>
                <div *ngIf="editProject._id == null">
                    <span>New Project</span>
                </div>
                <div *ngIf="editProject._id">
                    <span>Edit Project</span>
                </div>
                <button mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
            </mat-toolbar>
            <div class="programName">
                Program: {{editProject.programName}}
            </div>
        </div>
    </mat-card-header>
    <form #editProjectForm="ngForm">
    <mat-card-content class="project-setup-content-container" *ngIf= "!loading" >
        <div class="section-header chevronAlign">
            <a class="account__details-collapse">
                <mat-icon class="collapse-icon" 
                    [ngClass]="{'collapse-icon--open' : generalInfoPanelOpenState}"
                    (click)="generalInfoPanelOpenState = !generalInfoPanelOpenState">chevron_right
                </mat-icon>
            </a>
            <div class="headerPadding">
                General Information
            </div>
        </div>
        <mat-expansion-panel [expanded]="generalInfoPanelOpenState" class="hidePanel">
            <div class="section-body layout-row layout-align-start-center">
                <mat-form-field class="projectName" appearance="outline">
                    <mat-label>Project Name</mat-label>
                    <input 
                    matInput 
                    ngModel 
                    [(ngModel)]="editProject.name" 
                    #projectName="ngModel" 
                    name="projectName"
                    id="projectName" 
                    type="text" 
                    aria-label="Project Name"
                    required>
                </mat-form-field>
            
                <mat-form-field class="programName" appearance="outline">
                    <mat-label>Program Name</mat-label>
                    <mat-select 
                        (selectionChange)="programSelected($event)"
                        [disabled] = "editMode == true"
                        name="programName" 
                        ngModel 
                        [(ngModel)]="editProject.programName" 
                        id="programName"
                        #programName="ngModel"
                        required
                        aria-label="Program Name">
                        <mat-option *ngFor="let program of programs;" [value]="program.name">
                            <span>{{program.name}}</span>
                        </mat-option>
                        <mat-option [value]="null" id="none"> none </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="section-body layout-row layout-align-start-center">
                <mat-form-field class="projectCode" appearance="outline">
                    <mat-label>Project Code</mat-label>
                    <input matInput ngModel 
                    [(ngModel)]="editProject.projectCode" 
                    #projectCode="ngModel" 
                    name="projectCode"
                    id="projectCode" 
                    type="text" 
                    aria-label="Project Code"
                    required>
                </mat-form-field>
                <mat-form-field class="projectNumber" appearance="outline"
                *ngIf="selectedProject.programType !== 'Multifamily'">
                    <mat-label>IHA Project Number</mat-label>
                    <input matInput ngModel 
                    [(ngModel)]="editProject.projectNumber"
                    #projectNumber="ngModel" 
                    name="projectNumber"
                    id="projectNumber" 
                    type="text"
                    aria-label="Project Number">
                </mat-form-field>
                <mat-form-field class="tracsMailboxNumber" appearance="outline"
                *ngIf="tracsMailboxNumberFormGroup && selectedProject.programType === 'Multifamily'">
                    <mat-label>Mailbox Number</mat-label>
                    <input matInput ngModel 
                    [(ngModel)]="editProject.tracsMailboxNumber"
                    #mailboxNumber="ngModel" 
                    name="tracsMailboxNumber"
                    id="tracsMailboxNumber" 
                    type="text"
                    aria-label="Mailbox Number"
                    required>
                    <mat-error *ngIf="tracsMailboxNumberFormGroup.get('tracsMailboxNumber').errors?.required">
                        <div>Mailbox Number is required for Multifamily programs.</div>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="ampProject" appearance="outline" 
                *ngIf="selectedProject.programType === 'Public Housing' || selectedProject.programType === 'Low Rent'">
                    <mat-label>AMP Project Number</mat-label>
                    <input matInput
                    [formControl]="ampProjectFormGroup.get('ampProject')"    
                    name="ampProject"
                    id="ampProject" 
                    type="text"
                    aria-label="AMP Project Number"
                    (change)="onAmpProjectChange()">
                    <mat-error *ngIf="ampProjectFormGroup.get('ampProject').errors?.minlength">
                        <div>AMP Project Number needs exactly six digits. Ex. 000001</div>
                    </mat-error>
                    <mat-error *ngIf="ampProjectFormGroup.get('ampProject').errors?.maxlength">
                        <div>AMP Project Number needs exactly six digits. Ex. 000001</div>
                    </mat-error>
                    <mat-error *ngIf="ampProjectFormGroup.get('ampProject').errors?.pattern">
                        <div>AMP Project Number is restricted to numbers only</div>
                    </mat-error>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
            <div class="section-header chevronAlign">
                <a class="account__details-collapse">
                    <mat-icon class="collapse-icon" 
                        [ngClass]="{'collapse-icon--open' : customAttributesInfoPanelOpenState}"
                        (click)="customAttributesInfoPanelOpenState = !customAttributesInfoPanelOpenState">chevron_right
                    </mat-icon>
                </a>
                <div class="headerPadding">
                    Custom Attributes
                </div>
            </div>
            <mat-expansion-panel [expanded]="customAttributesInfoPanelOpenState" class="hidePanel">
                <div class="section-body">
                    <app-custom-attributes
                        *ngIf="customAttributesTemplates?.length; else noCustomAttributes"
                        [entity]="'Project'" 
                        [editMode]="true" 
                        [(currentCustomAttributes)]="editProject.customAttributes" 
                        [sqlData]="false">
                    </app-custom-attributes>
                    <ng-template #noCustomAttributes>
                        <p>There are no custom attributes that apply to this project.</p>
                    </ng-template>
                </div>
            </mat-expansion-panel>
            <div *ngIf="selectedProject.programType !== 'Loan' && selectedProject.programType !== 'Misc'">
                <div class="section-header chevronAlign">
                    <a class="account__details-collapse">
                        <mat-icon class="collapse-icon" 
                            [ngClass]="{'collapse-icon--open' : monthlyChargeInfoPanelOpenState}"
                            (click)="monthlyChargeInfoPanelOpenState = !monthlyChargeInfoPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="headerPadding">
                        Monthly Charge Calculation Information
                    </div>
                </div>
                <div *ngIf="!editProject.program" class="project-left-padding">
                    Please select a program
                </div>
            
                <div *ngIf="editProject.program">
                    <mat-expansion-panel 
                        [expanded]="monthlyChargeInfoPanelOpenState" class="hidePanel"> 
                        <div *ngIf="editProject.program" class="checkForOverride">
                            <mat-checkbox 
                                [(ngModel)]="rentCalcOverride" 
                                (change)="rentcalcOverrideChanged()" 
                                ngModel
                                #projectRentCalcOverride="ngModel" 
                                name="projectRentCalcOverride">
                            </mat-checkbox>
                            <mat-label class="wordPadding">Override Program settings</mat-label>
                        </div>        
                        <div class="section-body">
                            <div class="layout-row section-body">
                                <div *ngIf="selectedProject.programType !== 'Rural Development'">
                                    <mat-form-field class="fiscalYearEndDropdown" appearance="outline">
                                        <mat-label>Fiscal Year End Month</mat-label>
                                        <mat-select ngModel
                                            placeholder="Choose One" 
                                            [(ngModel)]="editProject.rentCalc.monthOfFiscalYearEnd"
                                            name="fiscalYearEndDropdown"
                                            aria-label="Fiscal Year-End Month"
                                            #fiscalYearEndDropdown="ngModel"
                                            [disabled]="rentCalcOverride == false"
                                            id="fiscalYearEndDropdown">
                                            <mat-option [value]="1" id="1">January</mat-option>
                                            <mat-option [value]="2" id="2">February</mat-option>
                                            <mat-option [value]="3" id="3">March</mat-option>
                                            <mat-option [value]="4" id="4">April</mat-option>
                                            <mat-option [value]="5" id="5">May</mat-option>
                                            <mat-option [value]="6" id="6">June</mat-option>
                                            <mat-option [value]="7" id="7">July</mat-option>
                                            <mat-option [value]="8" id="8">August</mat-option>
                                            <mat-option [value]="9" id="9">September</mat-option>
                                            <mat-option [value]="10" id="10">October</mat-option>
                                            <mat-option [value]="11" id="11">November</mat-option>
                                            <mat-option [value]="12" id="12">December</mat-option>
                                            <mat-option [value]="null" id="none"> none </mat-option>
                                        </mat-select> 
                                    </mat-form-field>
                                    <mat-form-field class="totalTenantPaymentPercentage" appearance="outline">
                                        <mat-label>Total Tenant Payment Percent</mat-label>
                                        <input matInput ngModel 
                                            [disabled]="rentCalcOverride == false"
                                            [(ngModel)]="editProject.rentCalc.totalTenantPaymentPercentage" 
                                            #projectName="ngModel" 
                                            name="totalTenantPaymentPercentage"
                                            id="totalTenantPaymentPercentage" 
                                            type="text" 
                                            aria-label="Total Tenant Payment Percentage"
                                        
                                            required>
                                        <mat-hint>Input as a decimal, e.g. 0.3 for 30%</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div class="layout-row layout-align-start-center">
                                    <mat-form-field class="allowableDependentDeduction" appearance="outline">
                                        <mat-label>Allowable Dependent Deduction</mat-label>
                                        <input matInput ngModel 
                                            [disabled]="rentCalcOverride == false"
                                            [(ngModel)]="editProject.rentCalc.allowableDependentDeduction" type="number"
                                            id="projectAllowableDependentDeductionInput"
                                            aria-label="Allowable dependent deduction"
                                            #projectRentCalcDedependDeduction="ngModel"
                                            name="projectRentCalcDedependDeduction"
                                            monetary-input> 
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="layout-row section-body">
                                <div class="layout-row layout-align-start-center">
                                    <mat-form-field class="allowableElderlyDeduction" appearance="outline">
                                        <mat-label>Allowable Elderly/Disability Deduction</mat-label>
                                        <input matInput ngModel
                                            [disabled]="rentCalcOverride == false"
                                            [(ngModel)]="editProject.rentCalc.allowableElderlyDisabilityDeduction" type="number"
                                            id="projectAllowableElderlyDeductionInput"
                                            aria-label="Allowable elderly deduction" 
                                            #projectRentCalcElderDeduction="ngModel" 
                                            name="projectRentCalcElderDeduction"
                                            monetary-input> 
                                    </mat-form-field>
                                </div>
                                <div class="layout-row layout-align-start-center">
                                    <mat-form-field class="elderlyDeductionAge" appearance="outline">
                                        <mat-label>Age to Qualify for Elderly Deduction</mat-label>
                                        <input matInput ngModel
                                            [disabled]="rentCalcOverride == false"
                                            [(ngModel)]="editProject.rentCalc.elderlyDeductionAge" type="number"
                                            id="projectElderlyDeductionAgeInput" 
                                            aria-label="Elderly deduction age" 
                                            #projectRentCalcElderAge="ngModel"
                                            name="projectRentCalcElderAge"> 
                                    </mat-form-field>
                                </div>
                                <div class="layout-row layout-align-start-center">
                                    <mat-form-field class="nearElderStatus" appearance="outline">
                                        <mat-label>Age to Qualify for Near Elder Status</mat-label>
                                        <input matInput ngModel
                                            [disabled]="rentCalcOverride == false"
                                            [(ngModel)]="editProject.rentCalc.nearElderAge" type="number" 
                                            id="projectNearElderAgeInput"
                                            aria-label="Near elder age"  
                                            #projectRentCalcNearElderAge="ngModel"
                                            name="projectRentCalcNearElderAge"> 
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="layout-row section-body">
                                <div class="layout-row layout-align-start-center"  *ngIf="housingAuthority && housingAuthority.incomeLimitAreas">
                                    <mat-form-field class="incomeLimitArea" appearance="outline" *ngIf="housingAuthority && housingAuthority.incomeLimitAreas">
                                        <mat-label>Income Limits Area</mat-label>
                                        <mat-select ngModel
                                            [disabled]="rentCalcOverride == false"
                                            [(ngModel)]="editProject.rentCalc.incomeLimitArea"
                                            #projectRentCalcIncomeLimitArea="ngModel" 
                                            name="projectRentCalcIncomeLimitArea"
                                            aria-label="Select income limit area for this Low Rent project"
                                            id="incomeLimitDropdown">
                                            <mat-option [value]="undefined" id="none"> none </mat-option>
                                            <mat-option value="{{a._id}}" *ngFor="let a of housingAuthority.incomeLimitAreas"
                                            id="{{a.name}}">{{a.name}}</mat-option>
                                        </mat-select> 
                                    </mat-form-field>
                                </div>
                                <div class="layout-row layout-align-start-center" *ngIf="housingAuthority && housingAuthority.incomeLimitAreas">
                                    <mat-form-field class="taxCreditIncomeLimitArea" appearance="outline">
                                        <mat-label>Tax Credit Income Limits Area</mat-label>
                                        <mat-select ngModel
                                            [disabled]="rentCalcOverride == false || program.type !== 'Tax Credit'"
                                            [(ngModel)]="editProject.rentCalc.taxCreditIncomeLimitArea"
                                            aria-label="Select income limit area for this Tax Credit project" 
                                            #projectRentCalcTaxCreditArea="ngModel" 
                                            name="projectRentCalcTaxCreditArea"
                                            id="taxCreditIncomeLimitDropdown">
                                            <mat-option [value]="undefined" id="none"> none </mat-option>
                                            <mat-option value="{{a._id}}" *ngFor="let a of housingAuthority.incomeLimitAreas">
                                                {{a.name}}
                                            </mat-option>
                                        </mat-select> 
                                    </mat-form-field>
                                </div>

                                <div class="layout-row layout-align-start-center" *ngIf="housingAuthority && housingAuthority.rentLimitAreas">
                                    <mat-form-field class="rentLimitArea" appearance="outline">
                                        <mat-label>Rent Limits Area</mat-label>
                                        <mat-select ngModel 
                                            [disabled]="rentCalcOverride == false"
                                            [(ngModel)]="editProject.rentCalc.rentLimitArea"
                                            #projectRentCalcRentLimitArea="ngModel" 
                                            name="projectRentCalcRentLimitArea"
                                            aria-label="Select rent limit area for this Low Rent project"
                                            id="rentLimitDropdown">
                                            <mat-option [value]="undefined" id="none"> none </mat-option>
                                            <mat-option value="{{a._id}}" *ngFor="let a of housingAuthority.rentLimitAreas" id="{{a.name}}">
                                                {{a.name}}
                                            </mat-option>
                                        </mat-select> 
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="layout-row section-body" *ngIf="housingAuthority && housingAuthority.paymentStandards">
                                <mat-form-field class="rentLimitArea" appearance="outline">
                                    <mat-label>Payment Standards</mat-label>
                                        <mat-select ngModel
                                            [disabled]="rentCalcOverride == false"
                                            [(ngModel)]="editProject.rentCalc.paymentStandard"
                                            #projectRentCalcPaymentStandard="ngModel" 
                                            name="projectRentCalcPaymentStandard"
                                            aria-label="Select payment standard for this Low Rent project"
                                            id="paymentStandardDropdown">
                                            <mat-option [value]="undefined" id="none"> none </mat-option>
                                            <mat-option value="{{a._id}}" *ngFor="let a of housingAuthority.paymentStandards" id="{{a.name}}">
                                                {{a.name}}
                                            </mat-option>
                                        </mat-select> 
                                </mat-form-field>
                                <mat-form-field class="calcKey" appearance="outline">
                                    <mat-label>Calc Key</mat-label>
                                        <input ngModel
                                            matInput 
                                            type="text"
                                            [disabled]="rentCalcOverride == false"
                                            [(ngModel)]="editProject.rentCalc.calcKey" 
                                            id="rentCalcInput" 
                                            #projectRentCalcKey="ngModel" 
                                            name="projectRentCalcKey"
                                            aria-label="Calc key">
                                </mat-form-field>
                                <mat-form-field class="assistanceCalcKey" appearance="outline">
                                    <mat-label>Assistance Calc Key</mat-label>
                                        <input ngModel
                                            matInput 
                                            type="text"
                                            [disabled]="rentCalcOverride == false"
                                            [(ngModel)]="editProject.rentCalc.assistanceCalcKey" 
                                            id="rentCalcInput" 
                                            #projectRentCalcAssistanceKey="ngModel" 
                                            name="projectRentCalcAssistanceKey"
                                        
                                            aria-label="Calc key"> 
                                </mat-form-field>
                            </div>

                            <div class="layout-row section-body" *ngIf="program.type && program.type == 'Public Housing' && rentCalcOverride == true">
                                <mat-form-field appearance="outline">
                                    <mat-label>Min TTP (50058 Form, 9h)</mat-label>
                                    <input matInput
                                        [formControl]="minTTPFormGroup.get('minTTP')"    
                                        type="number"
                                        id="rentCalcInput"
                                        name="minTTP"
                                        aria-label="Min TTP">
                                        <mat-error *ngIf="minTTPFormGroup.get('minTTP').errors?.min">
                                            <div>Min TTP must be between 0-51</div>
                                        </mat-error>
                                        <mat-error *ngIf="minTTPFormGroup.get('minTTP').errors?.max">
                                            <div>Min TTP must be between 0-51</div>
                                        </mat-error>
                                        <mat-error *ngIf="minTTPFormGroup.get('minTTP').errors?.pattern">
                                            <div>Min TTP must be a whole number</div>
                                        </mat-error>
                                        <mat-error *ngIf="minTTPFormGroup.get('minTTP').errors?.required">
                                            <div>Min TTP is required. Please enter a number between 0-51</div>
                                        </mat-error>
                                </mat-form-field>
                            </div>

                            <div class = "layout-column">
                                <div class="layout-row layout-align-start-center">
                                    <div class="checkboxPadding section-body">
                                        <mat-checkbox ngModel
                                            [disabled]="rentCalcOverride == false"
                                            [(ngModel)]="editProject.rentCalc.observeNahasda30Percent" 
                                            #projectRentCalcOverride="ngModel" 
                                            name="observeNahasda30Percent"
                                            >
                                        </mat-checkbox>
                                        <mat-label class="wordPadding">Observe NAHASDA 30% Rule</mat-label>
                                    </div>
                                </div>
                                <div class="layout-row layout-align-start-center">
                                    <div class="checkboxPadding section-body">
                                        <mat-checkbox ngModel
                                            [disabled]="rentCalcOverride == false"
                                            [(ngModel)]="editProject.rentCalc.incomeBased" 
                                            #projectRentCalcOverride="ngModel"
                                            
                                            name="projectIncomeBased">
                                        </mat-checkbox>
                                        <mat-label class="wordPadding">Project is Income-Based</mat-label>
                                    </div>
                                </div>
                                <div class="layout-row layout-align-start-center">
                                    <div class="section-body">
                                        <mat-form-field appearance="outline">
                                            <mat-label>RentCalc Options</mat-label>
                                            <mat-select
                                            [(ngModel)]="selectedRentCalcOption"
                                            (selectionChange)="changeDisplayedRentCalcOption()"
                                            name="rentCalcOption"
                                            [disabled]="rentCalcOverride == false"
                                            >
                                                <mat-option value=adminFees id="adminFees">Admin Fee</mat-option>
                                                <mat-option value=flatRents id="flatRents">Flat Rent/Charge</mat-option>
                                                <mat-option value=fairMarketRents id="fairMarketRents">Fair Market Rent</mat-option>
                                                <mat-option value=minimumCharges id="minimumCharges">Minimun Rent/Charge</mat-option>
                                                <mat-option value=maximumCharges id="maximumCharges">Maximum Rent/Charge</mat-option>
                                                <mat-option value=utilityAllowances id="utilityAllowances">Utility Allowance</mat-option>
                                                <mat-option value=rentLimits id="rentLimits">Rent Limits (Max Gross Rent - Tax Credits)</mat-option>
                                                <mat-option value=basicRents id="basicRents">Basic Rent (Rural Development Only)</mat-option>
                                                <mat-option value=noteRateRents id="noteRateRents">Note Rate Rent (Rural Development Only)</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
            
                                <div *ngIf="selectedRentCalcOption">
                                    <button mat-flat-button
                                        [disabled]="rentCalcOverride == false"  
                                        class="hds_button" 
                                        class="marg-left-auto hds_button" 
                                        (click)="newRentCalcOption()"
                                        >Add New Entry
                                    </button>
                                </div>
                
                                <div *ngIf="selectedRentCalcOption" class="layout-row">
                                    <p *ngIf="selectedRentCalcDetails.length === 0">No values defined</p>
                                    <div> 
                                        <table class="table table-condensed table-bordered table-striped"
                                            *ngIf="selectedRentCalcDetails.length > 0">
                                            <tr>
                                                <th>Effective Date</th>
                                                <th>0 Bedrooms</th>
                                                <th>1 Bedroom</th>
                                                <th>2 Bedrooms</th>
                                                <th>3 Bedrooms</th>
                                                <th>4 Bedrooms</th>
                                                <th>5 Bedrooms</th>
                                                <th>6 Bedrooms</th>
                                                <th>7 Bedrooms</th>
                                                <th>8 Bedrooms</th>
                                                <th></th>
                                            </tr>
                                            <tr *ngFor="let f of selectedRentCalcDetails; let i = index"
                                                id="rentCalcOptionInput">
                                                <td><span id="optionEffectiveDate">{{f.effectiveDate | date}}</span></td>
                                                <td><span id="bedroomSize0">{{(f.value[0] ? f.value[0]/100 : 0) | currency}}</span></td>
                                                <td><span id="bedroomSize1">{{(f.value[1] ? f.value[1]/100 : 0) | currency}}</span></td>
                                                <td><span id="bedroomSize2">{{(f.value[2] ? f.value[2]/100 : 0) | currency}}</span></td>
                                                <td><span id="bedroomSize3">{{(f.value[3] ? f.value[3]/100 : 0) | currency}}</span></td>
                                                <td><span id="bedroomSize4">{{(f.value[4] ? f.value[4]/100 : 0) | currency}}</span></td>
                                                <td><span id="bedroomSize5">{{(f.value[5] ? f.value[5]/100 : 0) | currency}}</span></td>
                                                <td><span id="bedroomSize6">{{(f.value[6] ? f.value[6]/100 : 0) | currency}}</span></td>
                                                <td><span id="bedroomSize7">{{(f.value[7] ? f.value[7]/100 : 0) | currency}}</span></td>
                                                <td><span id="bedroomSize8">{{(f.value[8] ? f.value[8]/100 : 0) | currency}}</span></td>
                                                <td>
                                                    <div class="layout-row" *ngIf="rentCalcOverride == true">
                                                        <a style="font-size:18px;"
                                                            (click)="openRentCalcOptionsDialog(i)"><mat-icon>edit</mat-icon>
                                                        </a>
                                                        <a style="font-size:18px;margin-left: 10px;"
                                                            (click)="deleteRentCalcOptions(i)"><mat-icon>delete</mat-icon>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </div>
            </div>

            <div class="section-header chevronAlign">
                <a class="account__details-collapse">
                    <mat-icon class="collapse-icon" 
                        [ngClass]="{'collapse-icon--open' : accountingInfoPanelOpenState}"
                        (click)="accountingInfoPanelOpenState =!accountingInfoPanelOpenState">chevron_right
                    </mat-icon>
                </a>
                <div class="headerPadding">
                    Accounting Information
                </div>
            </div>
            
            <mat-expansion-panel [expanded]="accountingInfoPanelOpenState" class="hidePanel"> 
                <div class="layout-row layout-align-start-center section-body">
                    <mat-form-field class="assistanceCalcKey" appearance="outline">
                        <mat-label>Select Tenant Account Definition</mat-label>
                        <mat-select ngModel
                            [(ngModel)]="editProject.chartOfAccounts" 
                            name="selectedChartOfAccounts"
                            placeholder="Select Subledger Mapping"
                            #selectedChartOfAccounts="ngModel"
                            >  
                            <mat-option *ngFor="let coa of chartOfAccounts" [value]="coa._id">
                                {{coa.name}}</mat-option>
                            <mat-option [value]="null" id="none"> none </mat-option>
                        </mat-select>  
                    </mat-form-field>
                    <div class="layout-row layout-align-start-center">
                        <mat-form-field class="sourceOfFunding" appearance="outline">
                            <mat-label>Source of Funding</mat-label>
                            <mat-select
                                ngModel
                                [(ngModel)]="editProject.rentCalc.fundingSource" 
                                name="sourceOfFunding"
                                aria-label="Funding Source"
                                #sourceOfFunding="ngModel"
                                id="fundingSourceDropdown">
                                <mat-option [value] = "undefined" id = "none"> none </mat-option>
                                <mat-option [value]="source" *ngFor="let source of housingAuthority.fundingSources"
                                    id="{{source}}">{{source}}</mat-option>
                            </mat-select>
                        </mat-form-field> 
                    </div>
                </div>
                <div class="layout-row layout-align-start-center section-body"
                    *ngIf="selectedProject.programType != 'Loan'">
                    <mat-form-field class="sourceOfFunding" appearance="outline">
                        <mat-label>General Ledger Segment Code</mat-label>
                        <input matInput ngModel
                            [(ngModel)]="editProject.projectSegmentCode" 
                            id="projectSegmentCodeInput"
                            aria-label="Project Segment Code" 
                            #projectSegmentCode="ngModel"
                    
                            name="projectSegmentCode">
                            <mat-hint>Optional</mat-hint>
                    </mat-form-field>
                </div>
            </mat-expansion-panel>
        <hr />
    </mat-card-content>

    <mat-card-actions *ngIf= "!loading">
        <button mat-flat-button (click)="closeDialog()" class="hds_button cancel" id="cancelButton">
            Cancel
        </button>
        <button mat-flat-button class="hds_button" (click)="onSubmit()"
            [disabled]="!editProjectForm.valid 
            || editProjectForm.pristine
            || (program.type == 'Public Housing' && !minTTPFormGroup.valid)
            || ((program.type == 'Public Housing' || program.type == 'Low Rent') && !ampProjectFormGroup.valid)" 
            id="Submit">
            Save Project
        </button>
    </mat-card-actions>
</form>

</mat-card>
    
