<mat-card class="knso-card adjusment-card">
    <mat-card-header class="adjustment-header">
        <div class="title">
          <p>Post Adjustment</p>
          <button mat-icon-button (click)="onCancelPayment()"><mat-icon>close</mat-icon></button>
        </div>
    </mat-card-header>
    <mat-card-content class="adjusment-content">
       <div class="adjusment-info">
        <p>Landlord: <a>{{ landLord }}</a></p>
        <p>Account: <a>{{ vendorDetail.transactionNumber }}</a></p>
        <p>Associated Tenant: <a>{{ vendorDetail.transactionName }}</a></p>
       </div>
       <form [formGroup]="form">
       <div class="adjusment-input">
        <p>Type</p>
        <mat-form-field class="adjusment-input-area adjustment-option">
            <mat-select [(value)]="vendorDetail.paymentType" formControlName="selectedPaymentOption">
              <mat-option *ngFor="let option of options" [value]="option">
                {{option}}
              </mat-option>
            </mat-select>
        </mat-form-field>
       </div>
       <div class="adjusment-input">
        <p>Amount</p>
        <mat-form-field class="adjusment-input-area">
           <input type="number" matInput formControlName="transactionAmount" />
           <span matPrefix class="dollar-sign">$</span>
        </mat-form-field>
       </div>
       <div class="adjusment-input">
        <p>Posted On Date</p>
         <mat-form-field class="adjusment-input-area adjustment-date-picker">
           <input type="datetime" matInput [matDatepicker]="dateTimePicker" formControlName="lastPayedAmountDate"/>
           <mat-datepicker-toggle matSuffix [for]="dateTimePicker"></mat-datepicker-toggle>
           <mat-datepicker #dateTimePicker></mat-datepicker>
         </mat-form-field>
       </div>
       <div class="adjusment-input">
        <p>Description</p>
        <mat-form-field class="adjusment-input-area">
           <textarea matInput formControlName="transactionDescription" rows="4"></textarea>
        </mat-form-field>
       </div>
    </form>
    </mat-card-content>
    <mat-card-footer class="adjusment-footer">
         <button mat-raised-button class="cancel-btn" (click)="onCancelPayment()">Cancel</button>
         <button mat-raised-button class="post-btn" (click)="onPostPayment()">Post</button>
    </mat-card-footer>
</mat-card>