<mat-card class="knso-card sub-category-container">
    <div class="action-btns">
        <p class="categoryName"> {{ editTransactionSubCategory.name }}</p>
        <div> 
            <mat-icon [class]="getActionBtnClass()" *ngIf="!isEdit" (click)="toggleEdit()">create</mat-icon>
            <mat-icon [class]="getActionBtnClass()" *ngIf="!isEdit" (click)="cloneSubCategory()">file_copy</mat-icon>
            <mat-icon [class]="getActionBtnClass('delete')" *ngIf="!isEdit" (click)="deleteSubCategory()">delete_outline</mat-icon>   
            <mat-icon *ngIf="isEdit" [class]="getActionBtnClass('save')" (click)="saveSubCategory()">check_circle</mat-icon>
            <mat-icon *ngIf="isEdit" [class]="getActionBtnClass('cancel')" (click)="cancelEditSubCategory()">cancel</mat-icon>  
        </div>
    </div>
    <mat-card-content>
        <table *ngIf="!isEdit">
            <tr>
                <td><span>Report Name:</span> </td>
                <td>{{ editTransactionSubCategory.reportName }}</td>
            </tr>
            <tr>
                <td><span>Post To (Debit) Account:</span> </td>
                <td>{{ generalLedgerName(editTransactionSubCategory.postTo) }}</td>
            </tr>
            <tr>
                <td><span>Offset To (Credit) Account:</span> </td>
                <td>{{ generalLedgerName(editTransactionSubCategory.offsetTo) }}</td>
            </tr>
            <tr>
                <td><span>split:</span> </td>
                <td>{{ splitsText() }}</td>
            </tr>
            <tr>
                <td><span>Hidden Subcat:</span> </td>
                <td>{{ nullSafeBoolean(editTransactionSubCategory.isHidden) }}</td>
            </tr>
            <tr>
                <td><span>Rent:</span> </td>
                <td>{{ nullSafeBoolean(editTransactionSubCategory.isRent) }}</td>
            </tr>
            <tr>
                <td><span>Include in 1099:</span> </td>
                <td>{{ nullSafeBoolean(editTransactionSubCategory.include1099) }}</td>
            </tr>
            <tr>
                <td><span>Transaction Description:</span> </td>
                <td>{{ editTransactionSubCategory.transactionDescription }}</td>
            </tr>
            <tr>
                <td><span>Account Code:</span> </td>
                <td>{{ editTransactionSubCategory.accountCode }}</td>
            </tr>
        </table>
        <!-- <ul *ngIf="!isEdit">
            <li><span class="categoryName"> {{ editTransactionSubCategory.name }}</span></li>
            <li><span>Report Name:</span> {{ editTransactionSubCategory.reportName }}</li>
            <li><span>Post To (Debit) Account:</span> {{ generalLedgerName(editTransactionSubCategory.postTo) }}</li>
            <li><span>Offset To (Credit) Account:</span> {{ generalLedgerName(editTransactionSubCategory.offsetTo) }}
            </li>
            <li><span>split: </span>{{ splitsText() }}</li>
            <li><span>Hidden Subcat:</span> {{ nullSafeBoolean(editTransactionSubCategory.isHidden) }}</li>
            <li><span>Rent:</span> {{ nullSafeBoolean(editTransactionSubCategory.isRent) }}</li>
            <li><span>Transaction Description:</span> {{ editTransactionSubCategory.transactionDescription }}</li>
        </ul> -->
        <form #subledgerMapForm="ngForm" class="edit-form" *ngIf="isEdit">
            <div class="general-info">
                <span>General Information</span>
                <div class="layout-row">
                    <mat-form-field appearance="outline">
                        <mat-label flex="20">Name:</mat-label>
                        <input *ngIf="!isSystemTransferSubCategory()" matInput ngModel [(ngModel)]="editTransactionSubCategory.name" #name="ngModel" name="name"
                            type="text" required aria-label="Account Name">
                        <input *ngIf="isSystemTransferSubCategory()" matInput ngModel readonly [ngModel]="editTransactionSubCategory.name" #name="ngModel" name="name"
                            type="text" required aria-label="Account Name">
                        <mat-error *ngIf="name.hasError('required')">
                            <div ng-message="required">You must supply a name</div>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="layout-row">
                    <mat-form-field appearance="outline">
                        <mat-label flex="20">Report Name:</mat-label>
                        <input matInput ngModel [(ngModel)]="editTransactionSubCategory.reportName" #name="ngModel"
                            name="reportName" type="text" aria-label="Account Name">
                    </mat-form-field>
                </div>
                <div class="layout-row">
                    <mat-form-field appearance="fill">
                        <mat-label>Post To (Debit Account)</mat-label>
                        <mat-select [formControl]="postToAccount" [compareWith]="compareGlAccounts"
                            placeholder="select general ledger account">
                            <input [formControl]="glAccountFilter" class="select-search" matInput placeholder="search">
                            <mat-option *ngFor="let account of filteredGlAccounts$ | async" [value]="account._id">
                                {{account.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="layout-row">
                    <mat-form-field appearance="fill">
                        <mat-label>Offset To (Credit Account)</mat-label>
                        <mat-select
                            [formControl]="offsetToAccount" 
                            [compareWith]="compareGlAccounts"
                            placeholder="select general ledger account">
                            <input [formControl]="glAccountFilter" class="select-search" matInput placeholder="search">
                            <mat-option *ngFor="let account of filteredGlAccounts$ | async" [value]="account._id">
                                {{account.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="layout-row">
                    <mat-checkbox 
                        [labelPosition]="'before'"
                        [(ngModel)]="editTransactionSubCategory.isRent"
                        #name="ngModel" 
                        name="isRent">
                        Rent
                    </mat-checkbox>                
                </div>
                <div class="layout-row">
                    <mat-checkbox 
                        [labelPosition]="'before'"
                        [(ngModel)]="editTransactionSubCategory.include1099"
                        #name="ngModel" 
                        name="include1099">
                        Include in 1099
                    </mat-checkbox>                
                </div>
            </div>
            <div class="auto-charge">
                <span>Auto-Charge Posting</span>
                <div class="layout-row">
                    <mat-form-field appearance="outline">
                        <mat-label flex="20">Transaction Description: </mat-label>
                        <input
                         matInput
                         ngModel 
                         [(ngModel)]="editTransactionSubCategory.transactionDescription"
                         #name="ngModel" 
                         name="transactionDescription"
                         type="text"
                         aria-label="Transaction Description">
                    </mat-form-field>
                </div>
                <div class="layout-row">
                    <mat-form-field appearance="outline">
                        <mat-label flex="20">Account Code: </mat-label>
                        <input
                         matInput
                         ngModel 
                         [(ngModel)]="editTransactionSubCategory.accountCode"
                         #name="ngModel" 
                         name="accountCode"
                         type="text"
                         aria-label="Account Code">
                    </mat-form-field>
                </div>
                <div class="layout-row">
                    <mat-checkbox 
                        [labelPosition]="'before'"
                        [(ngModel)]="editTransactionSubCategory.isHidden"
                        #name="ngModel" 
                        name="isHidden">
                        Hidden Subcategory
                    </mat-checkbox>                
                </div>
                <div class="layout-row">
                    <mat-checkbox 
                        [labelPosition]="'before'"
                        [(ngModel)]="editTransactionSubCategory.isSplit1"
                        #name="ngModel" 
                        name="isSplit1">
                        Split 1
                    </mat-checkbox>                
                </div>
                <div class="layout-row">
                    <mat-checkbox 
                        [labelPosition]="'before'"
                        [(ngModel)]="editTransactionSubCategory.isSplit2"
                        #name="ngModel" 
                        name="isSplit2">
                        Split 2
                    </mat-checkbox>                
                </div>
                <div class="layout-row">
                    <mat-checkbox 
                        [labelPosition]="'before'"
                        [(ngModel)]="editTransactionSubCategory.isSplit3"
                        #name="ngModel" 
                        name="isSplit3">
                        Split 3
                    </mat-checkbox>                
                </div>
                <div class="layout-row">
                    <mat-checkbox 
                        [labelPosition]="'before'"
                        [(ngModel)]="editTransactionSubCategory.isSplit4"
                        #name="ngModel" 
                        name="isSplit4">
                        Split 4
                    </mat-checkbox>                
                </div>
                <div class="layout-row">
                    <mat-checkbox 
                        [labelPosition]="'before'"
                        [(ngModel)]="editTransactionSubCategory.isSplit5"
                        #name="ngModel" 
                        name="isSplit5">
                        Split 5
                    </mat-checkbox>                
                </div>
                <div class="layout-row">
                    <mat-checkbox 
                        [labelPosition]="'before'"
                        [(ngModel)]="editTransactionSubCategory.isSplit6"
                        #name="ngModel" 
                        name="isSplit6">
                        Split 6
                    </mat-checkbox>                
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>