import {Component, Injector, ɵɵqueryRefresh} from '@angular/core';
import {GlobalModalService} from '../../../kanso-common/core/service/globalmodal.service';
import {Note} from '../../../core/service/core-models';
import {VendorNotesCreateComponent} from './vendor-notes-create/vendor-notes-create.component';
import {Vendor, VendorNotesModalData} from '../../housing-core/services/housing-models';
import {CoreService} from 'src/app/core/service/core.service';
import {VendorService} from '../vendor.service';
@Component({
  selector: 'app-vendor-notes',
  templateUrl: './vendor-notes.component.html',
  styleUrls: ['./vendor-notes.component.scss'],
})
export class VendorNotesComponent {
  customerId: string;
  siteId: string;
  selectedNote: Note;
  vendor: Vendor;
  notes: Note[];
  loading = true;
  loggedInUser: string;
  noteCategories: string[] = ['Address Change', 'Alert', 'Correspondence', 'Other', 'Ownership Change', 'Payables', 'Phone Call'];
  selectedCategory: string;
  filteredNotes: Note[];
  busyText: string;
  private CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  private SITE_ID = sessionStorage.getItem('SITEID') || '';

  constructor(
    public globalModalService: GlobalModalService,
    protected injector: Injector,
    private vendorService: VendorService,
    public coreService: CoreService
  ) {
    this.setBusyText('Loading Vendor...');
    this.loggedInUser = this.coreService.getCurrentUsersLogInCookie();
    const vendorId = this.coreService.getPathNamePart(this.coreService.VendorPathParts.Id);
    const vendorObservable = this.vendorService.getSelectedVendor(vendorId);
    vendorObservable.subscribe((result: Vendor) => {
      this.vendor = result;
      this.selectedCategory = '';
      if (this.vendor) {
        this.vendorService.getVendorNotes().subscribe(result => {
          this.vendor = {...this.vendor, notes: result};
        });
        this.categoryFilteredNotes();
        this.loading = false;
      }
    });
  }

  createVendorNote() {
    this.selectedNote = {
      body: '',
      category: 'Other',
    };
    this.globalModalService.openModal<VendorNotesModalData>(
      VendorNotesCreateComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      {selectedNote: this.selectedNote, updateFunction: this.saveVendorNote}
    );
  }

  saveVendorNote = vendorNote => {
    this.customerId = this.CUSTOMER_ID;
    this.siteId = this.SITE_ID;
    const command = {
      customerId: this.customerId,
      siteId: this.siteId,
      vendorId: this.vendor.id,
      body: vendorNote.body,
      category: vendorNote.category,
      createdBy: this.loggedInUser,
    };
    this.setBusyText('Creating Note...');
    this.vendorService.createVendorNote(command).subscribe(
      (result: any) => {
        if (result.data.createVendorNote.status === 'SUCCESS') {
          this.vendor = {...this.vendor, notes: [...this.vendor.notes, result.data.createVendorNote.affectedEntity]};
          this.vendorService.sendVendorNotes(this.vendor.notes, true);
          this.categoryFilteredNotes();
        } else {
          this.coreService.displayError('there was an error creating vendor note');
        }
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.coreService.displayError('there was an error creating vendor note');
        console.log(error);
      }
    );
  };

  editVendorNote(vendorNote) {
    this.globalModalService.openModal<VendorNotesModalData>(
      VendorNotesCreateComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      {selectedNote: vendorNote, updateFunction: this.saveEditVendorNote}
    );
  }

  saveEditVendorNote = vendorNote => {
    this.customerId = this.CUSTOMER_ID;
    this.siteId = this.SITE_ID;
    const command = {
      customerId: this.customerId,
      siteId: this.siteId,
      id: vendorNote.id,
      body: vendorNote.body,
      category: vendorNote.category,
      modifiedBy: this.loggedInUser,
    };
    this.setBusyText('Updating Note...');
    this.vendorService.updateVendorNote(command).subscribe(
      (result: any) => {
        if (result.data.updateVendorNote.status === 'SUCCESS') {
          const updatedVendorNotes = this.vendor.notes.map(note => {
            if (note.id === result.data.updateVendorNote.affectedEntity.id) {
              note = {...result.data.updateVendorNote.affectedEntity};
            }
            return note;
          });
          this.vendor = {...this.vendor, notes: updatedVendorNotes};
          this.vendorService.sendVendorNotes(this.vendor.notes, true);
          this.categoryFilteredNotes();
        } else {
          this.coreService.displayError('there was an error updating vendor note');
        }
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.coreService.displayError('there was an error updating vendor note');
        console.log(error);
      }
    );
  };

  deleteVendorNote = vendorNote => {
    this.customerId = this.CUSTOMER_ID;
    this.siteId = this.SITE_ID;
    const command = {
      customerId: this.customerId,
      siteId: this.siteId,
      id: vendorNote.id,
      deletedBy: this.loggedInUser,
    };
    this.setBusyText('Deleting Note...');
    this.vendorService.deleteVendorNote(command).subscribe(
      (result: any) => {
        if (result.data.deleteVendorNote.status === 'SUCCESS') {
          const updatedVendorNotes = this.vendor.notes.filter(note => note.id !== result.data.deleteVendorNote.affectedEntity.id);
          this.vendor = {...this.vendor, notes: updatedVendorNotes};
          this.vendorService.sendVendorNotes(this.vendor.notes, true);
          this.categoryFilteredNotes();
        } else {
          this.coreService.displayError('there was an error deleting vendor note');
        }
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.coreService.displayError('there was an error deleting vendor note');
        console.log(error);
      }
    );
  };

  categoryFilteredNotes() {
    if (this.selectedCategory) {
      this.filteredNotes = this.vendor.notes.filter(note => note.category == this.selectedCategory && !note.deletedOn);
    } else {
      this.filteredNotes = this.vendor.notes.filter(note => !note.deletedOn);
    }
  }

  setBusyText(message: string) {
    this.loading = true;
    this.busyText = message;
  }
}
