<mat-card class="knso-card">
    <mat-card-header>
        <mat-toolbar>
        <span>{{getHeaderText()}}</span>
        <button mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
    </mat-card-header>
    <mat-card-content>        
        <form #subledgerMapForm="ngForm">
            <div class="layout-row">
                <mat-form-field 
                    appearance="outline">
                    <mat-label flex="20">Name:</mat-label>
                        <input 
                            matInput
                            ngModel
                            [(ngModel)] = "editSubledgerMap.name"
                            #name="ngModel"
                            name="name"
                            type="text" 
                            required                                        
                            aria-label="Account Name"> 
                        <mat-error *ngIf="name.hasError('required')">
                            <div ng-message="required">You must supply a name</div>
                        </mat-error>
                </mat-form-field>
            </div>
        
            <div class="layout-row">
                <mat-form-field appearance="fill">
                    <mat-label>General Ledger Account</mat-label>
                    <mat-select
                        [formControl]="glAccount"
                        [compareWith]="compareGlAccounts"
                        placeholder="select general ledger account" >
                        <input 
                            [formControl]="glAccountFilter"
                            class="select-search" 
                            matInput 
                            placeholder="search">
                        <mat-option *ngFor="let account of filteredGlAccounts$ | async" [value]="account._id">
                            {{account.name}}
                        </mat-option>                        
                    </mat-select>
            </mat-form-field>
            </div>
        </form>
    </mat-card-content>
    <mat-card-actions>    
        <div class="bottom-buttons">
            <button 
                mat-flat-button
                (click)="closeDialog()"
                class="hds_button cancel"            
                id="cancelButton">
                    Cancel
            </button>
            <button 
                mat-flat-button 
                class="hds_button"             
                (click)="saveMap()"
                [disabled]="!subledgerMapForm.valid || (editMode && subledgerMapForm.pristine && glAccount.pristine)"
                id="saveButton">
                    Save
            </button>
        </div>
    </mat-card-actions>    
</mat-card>
