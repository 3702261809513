<section *ngIf="showPanelView == 'exported-actions-details'">
  <section class="tabs__panel">
    <section>
      <div layout="row">
        <h2>PIC Batching</h2>
      </div>
    </section>
    <hr class="throughTout" />
    <div></div>
  </section>

  <section class="tabs__panel">
    <div class="flexColumn">
      <div class="flexRowInfo">
        <div class="backButton" (click)="showPanelView = 'exported-actions'">
          <mat-icon svgIcon="download-icon-blue" class="backIcon"></mat-icon>
          <a class="smallLeftSpacer"> Back </a>
        </div>
        <div class="reportLink">
          <div class="leftSpacer rightSpacer">
            <a href="{{this.picSummaryReportLink}}" target="_blank">PIC Summary Report</a>
          </div>
        </div>
        <div class="batchRecap">
          <div class="leftSpacer">
            <div class="picBatchNumberStrong"># {{ this.currentPicBatch.picBatchId }}</div>
            <div class="leftSpacer">
              Exported on {{ this.currentPicBatch.exportedOn | date: 'mediumDate' }} by
              {{ this.currentPicBatch.exportedByFirstName }}
              {{ this.currentPicBatch.exportedByLastName }}
            </div>
          </div>
          <div class="downloadRow" *hasPermission="'admin || performPICExports'">
            <div class="rightSpacer">Actions: {{ this.currentPicBatch.countOfActions }}</div>
            <mat-icon svgIcon="download-icon" (click)="getExportedBatchById(currentPicBatch.picBatchId)"></mat-icon>
          </div>
        </div>
      </div>

      <div *ngIf="this.loading">
        <mat-spinner diameter="50" class="centered_spinner"> </mat-spinner>
      </div>

      <div *ngIf="!this.loading">
        <table mat-table [dataSource]="exportedDataSet">
          <ng-container matColumnDef="Household">
            <th mat-header-cell *matHeaderCellDef>Household</th>
            <td class="name" mat-cell *matCellDef="let element">{{ element.headLastName }}, {{ element.headFirstName }}</td>
          </ng-container>

          <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element">{{ element.action.length ? element.action : '' }}</td>
          </ng-container>

          <ng-container matColumnDef="Effective Date">
            <th mat-header-cell *matHeaderCellDef>Effective Date</th>
            <td mat-cell *matCellDef="let element">{{ element.effectiveDate.length ? (element.effectiveDate | date: 'mediumDate') : '' }}</td>
          </ng-container>

          <ng-container matColumnDef="Created On Date">
            <th mat-header-cell *matHeaderCellDef>Created On Date</th>
            <td mat-cell *matCellDef="let element">{{ element.createdOnDate.length ? (element.createdOnDate | date: 'mediumDate') : '' }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-header"></tr>
        </table>
      </div>
    </div>
  </section>
</section>

<div *ngIf="showPanelView == 'exported-actions'">
  <app-exported-actions></app-exported-actions>
</div>
