import {Apollo, gql} from 'apollo-angular';
import {InMemoryCache} from '@apollo/client/cache';
import {Observable, Subject, pipe} from 'rxjs';
import {map} from 'rxjs/internal/operators/map';
import {FetchResult} from '@apollo/client/link/core/types';
import {BaseService} from 'src/app/kanso-common/core/service';
import {HttpClient} from '@angular/common/http';
import {CoreService} from 'src/app/core/service/core.service';

export class FeatureConfigurationsService extends BaseService {
  apollo: Apollo;
  _http: any;
  constructor(public http: HttpClient, public coreService: CoreService, private apolloProvider: Apollo) {
    super(http);
    this.apollo = this.apolloProvider;
    const configurationHeaders: any = {
      'x-api-key': sessionStorage.getItem('OCCUPANCY_SVC_KEY'),
      'x-site-id': sessionStorage.getItem('SITEID'),
      'x-customer-id': sessionStorage.getItem('CUSTOMERID'),
      'x-token': this.header.headers['x-token'],
    };
    this.apollo.create(
      {cache: new InMemoryCache(), uri: sessionStorage.getItem('OCCUPANCY_SVC_GRAPHQL_URI'), headers: {...configurationHeaders}},
      'featureConfigurations'
    );
  }

  getFeatureConfigurations<T>(): Observable<any> {
    const data = [];
    return this.apollo
      .use('featureConfigurations')
      .query({
        query: this.queryFeatureConfigurations(),
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.featureConfigurations.edges) {
            data.push(edge.node);
          }
          return [data];
        })
      );
  }

  //Once this is over 50 paganation will need to be implemented
  queryFeatureConfigurations() {
    return gql`
      query FeatureConfigurations {
        featureConfigurations {
          edges {
            node {
              id
              legacyId
              domain
              subdomain
              type
              name
              description
              modifiedOn
              modifiedBy
              configurationsOptions {
                id
                featureConfigurationId
                legacyId
                display
                modifiedOn
                modifiedBy
              }
            }
          }
        }
      }
    `;
  }

  getConfigurationsValues<T>(): Observable<any> {
    const data = [];
    return this.apollo
      .use('featureConfigurations')
      .query({
        query: this.queryConfigurationValues(),
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.configurationsValues) {
            data.push(edge);
          }
          return [data];
        })
      );
  }

  queryConfigurationValues() {
    return gql`
      query ConfigurationsValues {
        configurationsValues(where: {siteId: {eq: "pfdylv"}}) {
          id
          legacyId
          siteId
          configurationsOptionsId
          booleanValue
          modifiedOn
          modifiedBy
          featureConfigurationId
          textValue
        }
      }
    `;
  }

  createConfigurationsValue<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('featureConfigurations').mutate({
      mutation: gql`
        mutation CreateConfigurationsValue($command: CreateConfigurationsValueCommandInput!) {
          createConfigurationsValue(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  updateConfigurationsValue<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('featureConfigurations').mutate({
      mutation: gql`
        mutation updateConfigurationsValue($command: UpdateConfigurationsValueCommandInput!) {
          updateConfigurationsValue(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
          }
        }
      `,
      variables: {
        command,
      },
    });
  }
}
