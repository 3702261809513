import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root', // singleton
})
export class UserService {
  apiURL = '';
  private _isAdmin = false;
  private _details = {};
  private _permissions: string[];

  get details(): Record<string, unknown> {
    return this._details;
  }

  get isAdmin(): boolean {
    return this._isAdmin;
  }

  get permissions(): string[] {
    return this._permissions;
  }

  constructor(private http: HttpClient) {}

  getUser(): Observable<any> {
    return this.http.get(`${this.apiURL}/api/user`);
  }

  setUserDetails(user: {permissions: string[]}): void {
    this._details = user;
    this._isAdmin = user.permissions.includes('admin');
    this._permissions = user.permissions;
  }

  permissionExists(permissions: string[], permission: string): boolean {
    if (!permission.includes(' || ') && !permission.includes(' && ')) {
      return permissions.includes(permission);
    }

    const permissionList = [];
    let permissionsNecessary;

    if (permission.includes(' || ')) {
      permissionsNecessary = permissionList.concat(permission.split(' || '));
      return permissionsNecessary.some(permission => permissions.includes(permission));
    }

    if (permission.includes(' && ')) {
      permissionsNecessary = permissionList.concat(permission.split(' && '));
      return permissionsNecessary.every(permission => permissions.includes(permission));
    }
    return false;
  }
}
