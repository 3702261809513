import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from '../../../kanso-common/core/service/base.service';
import {KansoActionResult} from '../../../core/service/core-models';
import {Authority} from './housing-models';

@Injectable({providedIn: 'root'})
export class HousingServices extends BaseService {
  getHousingAuthority(): Observable<KansoActionResult<Authority>> {
    return this.apiGet<KansoActionResult<Authority>>(`${this.apiPath}/housingauthority/api/housingauthority`);
  }

  updateHousingAuthority(authority: Authority): Observable<KansoActionResult<Authority>> {
    return this.apiPut<KansoActionResult<Authority>>(`${this.apiPath}/housingauthority/api/housingauthority/${authority._id}`, authority);
  }
}
