import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ComponentsModule} from '../../kanso-common/core/components/components.module';

import {CoreModule} from '../../core/core.module';
import {KansoCommonModule} from './../../kanso-common/kanso-common.module';

import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';

import {SetupRoutingModule} from './setup-routing.module';
import {AccountingHeaderComponent} from './accounting-setup/accounting-header/accounting-header.component';
import {GeneralLedgerComponent} from './accounting-setup/general-ledger/general-ledger.component';
import {SubledgerMappingComponent} from './accounting-setup/subledger-mapping/subledger-mapping.component';
import {GeneralLedgerEditComponent} from './accounting-setup/general-ledger/general-ledger-edit/general-ledger-edit.component';
import {MaterialModule} from 'projects/material/src/public_api';
import {HousingAuthoritySetupComponent} from './housing-authority-setup/housing-authority-setup.component';
import {EditOfficeLocationComponent} from './housing-authority-setup/edit-office-location/edit-office-location.component';
import {AccountActivityRuleComponent} from './account-activity-rule/account-activity-rule.component';
import {AccountSetupComponent} from './account-setup/account-setup.component';
import {ProgramSetupComponent} from './program-setup/program-setup.component';
import {ProgramSetupListComponent} from './program-setup-list/program-setup-list.component';
import {ProgramRentCalcComponent} from './program-setup/program-rent-calc/program-rent-calc.component';
import {SubledgerMappingEditComponent} from './accounting-setup/subledger-mapping/subledger-mapping-edit/subledger-mapping-edit.component';
import {SubledgerMappingEditFullComponent} from './accounting-setup/subledger-mapping/subledger-mapping-edit-full/subledger-mapping-edit-full.component';
import {SubCategoriesComponent} from './accounting-setup/subledger-mapping/sub-categories/sub-categories.component';
import {SubCategoryComponent} from './accounting-setup/subledger-mapping/sub-category/sub-category.component';
import {UnitsListComponent} from './units/units-list/units-list.component';
import {UnitSetupComponent} from './units/unit-setup/unit-setup.component';
import {UnitSetupHandlerComponent} from './units/unit-edit-handler/unit-setup-handler.component';
import {EditFundingAndDepartmentPopupComponent} from './housing-authority-setup/edit-funding-and-department-popup/edit-funding-and-department-popup.component';
import {EditIncomeLimitAreaComponent} from './housing-authority-setup/edit-income-limit-area/edit-income-limit-area.component';
import {EditRegionalRentLimitComponent} from './housing-authority-setup/edit-regional-rent-limit/edit-regional-rent-limit.component';
import {UnitSetupMonthlyChargeComponent} from './units/unit-setup/unit-setup-monthly-charge/unit-setup-monthly-charge.component';
import {RentcalcOptionPopupComponent} from './rentcalc-option-popup/rentcalc-option-popup.component';
import {ProjectSetupListComponent} from './project-setup-list/project-setup-list.component';
import {ProjectSetupComponent} from './project-setup/project-setup.component';
import {EditPaymentStandardsComponent} from './housing-authority-setup/edit-payment-standards/edit-payment-standards.component';
import {UtilityAllowancesComponent} from './utility-allowances/utility-allowances.component';
import {UtilityActionPopupComponent} from './utility-allowances/utility-action-popup/utility-action-popup.component';

import {AchService} from 'src/app/shared/services/ach-service';
import {ProgramEnhancedRentComponent} from './program-setup/program-enhanced-rent/program-enhanced-rent.component';
import {UnitUtilityOverridesComponent} from './units/unit-utility-overrides/unit-utility-overrides.component';

@NgModule({
  declarations: [
    AccountingHeaderComponent,
    GeneralLedgerComponent,
    SubledgerMappingComponent,
    GeneralLedgerEditComponent,
    SubledgerMappingEditComponent,
    SubledgerMappingEditFullComponent,
    SubCategoriesComponent,
    SubCategoryComponent,
    HousingAuthoritySetupComponent,
    EditOfficeLocationComponent,
    ProgramSetupComponent,
    ProgramSetupListComponent,
    ProgramRentCalcComponent,
    AccountActivityRuleComponent,
    AccountSetupComponent,
    SubledgerMappingEditComponent,
    UnitsListComponent,
    UnitSetupComponent,
    UnitSetupHandlerComponent,
    EditFundingAndDepartmentPopupComponent,
    EditIncomeLimitAreaComponent,
    EditRegionalRentLimitComponent,
    UnitSetupMonthlyChargeComponent,
    RentcalcOptionPopupComponent,
    ProjectSetupListComponent,
    ProjectSetupComponent,
    EditPaymentStandardsComponent,
    UtilityAllowancesComponent,
    UtilityActionPopupComponent,
    ProgramEnhancedRentComponent,
    UnitUtilityOverridesComponent,
  ],
  entryComponents: [AccountSetupComponent],
  imports: [
    CommonModule,
    SetupRoutingModule,
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatExpansionModule,
    CoreModule,
    KansoCommonModule,
    MaterialModule,
    ComponentsModule,
  ],
  providers: [AchService],
})
export class SetupModule {}
