import {Injectable} from '@angular/core';

import {
  ISubLedgerMap,
  GeneralLedgerAccount,
  TransactionCategory,
  TransactionSubCategory,
} from '../../../accounting/service/accounting-models';

export interface GeneralLedgerModalData {
  generalLedgerAccount: GeneralLedgerAccount;
  editMode: boolean;
  // eslint-disable-next-line
  updateFunction: Function;
}

export interface SubledgerMapModalData {
  subledgerMap: ISubLedgerMap;
  generalLedgerAccounts: GeneralLedgerAccount[];
  editMode: boolean;
  // eslint-disable-next-line
  updateFunction: Function;
}

export interface IPanelData {
  contentData: any;
  isEdit: boolean;
  isNew: boolean;
  title: string;
}

export enum categories {
  chargeCategories = 'chargeCategories',
  systemCategories = 'systemCategories',
  creditCategories = 'creditCategories',
}

export enum subCategorySaveAction {
  deleteCategory = 1,
  deleteSubCategory = 2,
}

export interface categorySaveEventPayload {
  saveAction: subCategorySaveAction;
  callback(success: boolean): void;
}
