import {Component, Input, Injectable, Output, EventEmitter} from '@angular/core';
import {CoreService} from 'src/app/core/service/core.service';
import {CustomAttributes} from '../../service/core-models';
import {ControlContainer, NgForm} from '@angular/forms';
import * as lodash from 'lodash';
@Component({
  selector: 'app-custom-attributes',
  templateUrl: './custom-attributes.component.html',
  styleUrls: ['./custom-attributes.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
@Injectable({providedIn: 'root'})
export class CustomAttributesComponent {
  @Input() currentCustomAttributes;
  @Output() currentCustomAttributesChange = new EventEmitter<CustomAttributes[]>();
  @Input()
  set entity(setEntity: string) {
    this._entity = setEntity;
    this.getCustomAttribute();
  }
  get entity() {
    return this._entity;
  }
  _entity: any;
  @Input() editMode: boolean;
  @Input() sqlData: boolean;

  customAttributesTemplates: CustomAttributes[];

  groupedCustomAttributes = [];
  isLoading = true;

  constructor(public coreService: CoreService) {}

  change() {
    this.currentCustomAttributesChange.emit(this.currentCustomAttributes);
  }

  getCustomAttribute() {
    let attributes = [];
    this.coreService.getCustomAttributesByEntity(this.entity).subscribe(result => {
      this.customAttributesTemplates = result.results;
      for (const template of this.customAttributesTemplates) {
        if (template.active) {
          let currentCA = null;
          if (this.sqlData) {
            currentCA = this.currentCustomAttributes.find(attr => attr.attribute.id == template._id);
          } else {
            currentCA = this.currentCustomAttributes.find(attr => attr.name == template.name);
          }
          const customAttribute: any = {
            group: template.group ? template.group : 'Other',
            active: template.active,
            name: template.name,
            type: template.type,
            required: template.required,
            value: null,
            values: template.values ? template.values : [],
            entityAttributeId: null,
          };
          customAttribute.value = currentCA ? currentCA.value : null;
          if (this.sqlData) {
            if (currentCA) {
              customAttribute.id = currentCA.id;
              switch (template.type) {
                case 'text':
                  customAttribute.value = currentCA.textValue;
                  break;
                case 'date':
                  customAttribute.value = currentCA.dateTimeValue;
                  break;
                case 'dropdown':
                  customAttribute.value = currentCA.textValue;
                  break;
                case 'multi':
                  customAttribute.value = currentCA.textValue == null ? null : currentCA.textValue.split(', ');
                  break;
                case 'toggle':
                  customAttribute.value = currentCA.booleanValue;
                  break;
              }
            }
            customAttribute.entityAttributeId = template._id;
          }
          attributes.push(Object.assign({}, customAttribute));
        }
      }
      attributes = attributes.filter(attr => attr.active && attr.group);
      this.currentCustomAttributes = lodash.sortBy(attributes, attribute => attribute.group);
      const customAttributeArray = Object.entries(lodash.groupBy(attributes, attribute => attribute.group));
      for (const group of customAttributeArray) {
        this.groupedCustomAttributes.push({
          name: group[0],
          customAttributes: group[1],
        });
      }
      this.change();
      this.isLoading = false;
    });
  }

  saveAndFormatCustomAttributes(customAttributes) {
    const transformed = customAttributes.map(attr => {
      return {
        name: attr.name,
        value: attr.value,
        values: attr.values,
      };
    });
    return transformed;
  }

  compareValues(o1, o2) {
    if (o1 == o2) {
      return true;
    } else {
      return false;
    }
  }
}
