<div>
    <div class="sectionHead">Sub Categories<mat-icon [class]="getAddBtnClass()" (click)="newSubCategory()" >add_circle</mat-icon></div>
    <div *ngFor="let cat of editSubCategory.subCategories; let i = index;">
        <sub-category
            *ngIf="!subCategoryIsHidden(i)"
            (save)="saveSubCategory()"
            (clone)="cloneSubCategory($event)"
            (cancel)="cancelSubCategory()"
            (delete)="deleteSubCategory($event)"
            (edit)="editMode($event)"
            [index]="i"
            [defaultEdit]="subCategoryIsEdit(i)"
            [otherElementInEdit]="otherElementInEdit"
            [transactionSubCategory]="cat"
            [categoryGroup]="categoryGroup"
            [generalLedgerAccounts]="generalLedgerAccounts"></sub-category>
    </div>
</div>
