<div>
  <div (click)="onCancelClick()" class="dlgClose">x</div>
  <h1 mat-dialog-title class="addedit-header"
    *ngIf="initAddEdit === 'edit' && (userPermissions.includes('editVouchers') || userPermissions.includes('admin'))">
    Edit Voucher Details</h1>
  <h1 mat-dialog-title class="addedit-header"
    *ngIf="initAddEdit === 'new' && (userPermissions.includes('editVouchers') || userPermissions.includes('admin'))">
    Issue New Voucher</h1>
  <br />

  <div *ngIf="spinnerCheck" class="centered_spinner">
    <mat-spinner></mat-spinner>
  </div>

  <div [ngClass]="{'disableBackground': this.overlay}" mat-dialog-content>
    <div class="section-header addedit-heading">General Information</div>
    <form [formGroup]="voucherForm" (ngSubmit)="onSubmit()" class="voucher-form">
      <div layout="row" class="layout-row addedit-body">
        <div flex="30" class="text-right marg-right-1 flex-30"><strong>Funding Program*</strong></div>
        <div flex="60" class="ng-binding flex-60">
          <mat-form-field class="voucher-full-width" appearance="outline">
            <mat-select formControlName="fundingProgram" required (selectionChange)="getProjects()">
              <mat-option *ngFor="let fundingprogram of fundingPrograms" [value]="fundingprogram.node.name">
                {{ fundingprogram.node.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <p flex="30" class="alert alert-danger" *ngIf="voucherForm.invalid && submitted">
          * Marked fields are required.
        </p>
      </div>
      <div layout="row" class="layout-row addedit-body">
        <p flex="30" class="text-right marg-right-1 flex-30"><strong>Funding Project*</strong></p>
        <p flex="60" class="ng-binding flex-60">
          <mat-form-field class="voucher-full-width" appearance="outline">
            <mat-select formControlName="fundingProject" required (selectionChange)="preFillVoucherNumber()">
              <mat-option *ngFor="let fundingproject of fundingProjects" [value]="fundingproject.name">
                {{ fundingproject.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </p>
      </div>
      <div layout="row" class="layout-row addedit-body">
        <p flex="30" class="text-right marg-right-1 flex-30"><strong>Voucher Type</strong></p>
        <p flex="60" class="ng-binding flex-60">
          <mat-form-field class="voucher-full-width" appearance="outline">
            <mat-select formControlName="voucherType">
              <mat-option *ngFor="let vouchertype of voucherTypeList" [value]="vouchertype">
                {{ vouchertype }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </p>
      </div>
      <div layout="row" class="layout-row addedit-body">
        <p flex="30" class="text-right marg-right-1 flex-30"><strong>Special Type</strong></p>
        <p flex="60" class="ng-binding flex-60">
          <mat-form-field class="voucher-full-width" appearance="outline">
            <mat-select formControlName="specialType">
              <mat-option *ngFor="let specialtype of voucherSpecialTypeList" [value]="specialtype">
                {{ specialtype }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </p>
      </div>
      <div layout="row" class="layout-row addedit-body">
        <p flex="30" class="text-right marg-right-1 flex-30"><strong>Voucher Number*</strong></p>
        <p flex="60" class="ng-binding flex-60">
          <mat-form-field class="voucher-full-width" appearance="outline">
            <input matInput formControlName="voucherNumber" required />
          </mat-form-field>
        </p>
      </div>
      <div layout="row" class="layout-row addedit-body">
        <p flex="30" class="text-right marg-right-1 flex-30"><strong>Bedrooms*</strong></p>
        <p flex="60" class="ng-binding flex-60">
          <mat-form-field class="voucher-full-width" appearance="outline">
            <input matInput formControlName="authorizedBedrooms" required />
          </mat-form-field>
        </p>
      </div>
      <div layout="row" class="layout-row addedit-body" *ngIf="this.initAddEdit == 'edit'">
        <p flex="30" class="text-right marg-right-1 flex-30"><strong>Date Issued*</strong></p>
        <p flex="60" class="ng-binding flex-60">{{this.issuedDate}}</p>
      </div>
      <div layout="row" class="layout-row addedit-body" *ngIf="this.initAddEdit == 'new'">
        <p flex="30" class="text-right marg-right-1 flex-30"><strong>Date Issued*</strong></p>
        <p flex="60" class="ng-binding flex-60">
          <mat-form-field class="voucher-full-width" appearance="outline">
            <input matInput [matDatepicker]="dateissued" formControlName="issuedOn" required
              (dateChange)="updateIssuedDays()" />
            <mat-datepicker-toggle matSuffix [for]="dateissued"></mat-datepicker-toggle>
            <mat-datepicker #dateissued></mat-datepicker>
          </mat-form-field>
        </p>
      </div>
      <div layout="row" class="layout-row addedit-body" *ngIf="initLeasedState">
        <p flex="30" class="text-right marg-right-1 flex-30"><strong>Expiration Date*</strong></p>
        <p flex="60" class="ng-binding flex-60">{{this.expiredDate}}</p>
      </div>
      <div layout="row" class="layout-row addedit-body" *ngIf="!initLeasedState">
        <p flex="30" class="text-right marg-right-1 flex-30"><strong>Expiration Date*</strong></p>
        <p flex="60" class="ng-binding flex-60">
          <mat-form-field class="voucher-full-width" appearance="outline">
            <input matInput [matDatepicker]="expirationdate" [min]="minDate" formControlName="expiresOn" required
              (dateChange)="updateIssuedDays()" />
            <mat-datepicker-toggle matSuffix [for]="expirationdate"></mat-datepicker-toggle>
            <mat-datepicker #expirationdate></mat-datepicker>
          </mat-form-field>
        </p>
      </div>
      <div layout="row" class="layout-row addedit-body" *ngIf="!initLeasedState">
        <p flex="30" class="text-right marg-right-1 flex-30"><strong>Issued Days</strong></p>
        <p flex="60" class="ng-binding flex-60">
          <mat-form-field class="voucher-full-width" appearance="outline">
            <input matInput formControlName="issuedDays" type="number" (change)="updateExpiredDate()" />
          </mat-form-field>
        </p>
      </div>
      <div layout="row" class="layout-row addedit-body">
        <p flex="30" class="text-right marg-right-1 flex-30"><strong>Status</strong></p>
        <p flex="60" class="ng-binding flex-60">
          {{ voucher?.state ? voucher.state : "ISSUED" }}
        </p>
      </div>
      <div layout="row" class="layout-row addedit-body">
        <p flex="30" class="text-right marg-right-1 flex-30">*</p>
        <p flex="60" class="ng-binding flex-60">
          Please note that Vouchers cannot be associated to a unit without a Landlord
        </p>
      </div>
      <div layout="row" class="layout-row addedit-body">
        <p flex="30" class="text-right marg-right-1 flex-30"><strong>Unit Search</strong></p>
        <p flex="60" class="ng-binding flex-60">
          <mat-form-field class="voucher-full-width">
            <input type="text" placeholder="start typing address or ppu code of a unit with a landlord"
              aria-label="Unit" matInput (input)="refetchUnits()" formControlName="unit" [matAutocomplete]="auto" />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText">
              <mat-option *ngFor="let option of filteredUnitOptions" [value]="option">
                <span>{{option.node.streetAddress}} Apt: {{option.node.apartmentNumber}} Unit:
                  {{option.node.unitNumber}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </p>
      </div>
      <div layout="row" class="layout-row addedit-body">
        <p flex="30" class="text-right marg-right-1 flex-30"><strong>Unit Selected</strong></p>
        <p flex="60" class="ng-binding flex-60">
          Address: {{ voucherForm.value?.unit?.node?.streetAddress ? voucherForm.value?.unit?.node?.streetAddress :
          (voucher?.unit?.streetAddress ? voucher?.unit?.streetAddress : "--")}} <br />
          Apartment: {{ voucherForm.value?.unit?.node?.apartmentNumber ? voucherForm.value?.unit?.node?.apartmentNumber
          : (voucher?.unit?.apartmentNumber ? voucher?.unit?.apartmentNumber : "--")}}<br />
          Unit Number: {{ voucherForm.value?.unit?.node?.unitNumber ? voucherForm.value?.unit?.node?.unitNumber :
          (voucher?.unit?.unitNumber ? voucher?.unit?.unitNumber : "--")}}
        </p>
      </div>
      <div layout="row" class="layout-row addedit-body">
        <p flex="30" class="text-right marg-right-1 flex-30" style="margin-left: 90px;"><a (click)="removeUnit()">Remove
            Unit</a></p>
      </div>
      <div layout="row" class="layout-row addedit-body">
        <p flex="30" class="text-right marg-right-1 flex-30"><strong>Landlord</strong></p>
        <p flex="60" class="ng-binding flex-60">
          {{ voucherForm.value?.unit?.node?.landlord?.companyName ? voucherForm.value?.unit?.node?.landlord?.companyName
          : (( voucherForm.value?.unit?.node?.landlord?.firstName ? voucherForm.value?.unit?.node?.landlord?.firstName :
          "--"))}} {{ voucherForm.value?.unit?.node?.landlord?.companyName ? null : ((
          voucherForm.value?.unit?.node?.landlord?.lastName ? voucherForm.value?.unit?.node?.landlord?.lastName :
          "--"))}}
        </p>
      </div>
      <br />
    </form>
    <div class="section-header addedit-heading">Custom Attributes</div>
    &nbsp;&nbsp;&nbsp;there are no custom attributes setup for this entity
  </div>
</div>


<div mat-dialog-actions class="addedit-button">
  <button mat-button (click)="onCancelClick()" class="md-button" type="button"
    [(disabled)]="spinnerCheck">Cancel</button>
  <button mat-button (click)="onTerminateClick()" class="md-button addedit-terminatebtn" type="button"
    [(disabled)]="spinnerCheck"
    *ngIf="initAddEdit == 'edit' && (userPermissions.includes('terminateVouchers') || userPermissions.includes('admin'))">
    Terminate
  </button>
  <button mat-button class="md-button" type="submit" [disabled]="!voucherForm.valid || spinnerCheck" (click)="onSubmit()">Save
    Voucher</button>
</div>