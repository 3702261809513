import {Component, EventEmitter, Input, Output} from '@angular/core';
import _ from 'lodash';
import {GeneralLedgerAccount, TransactionCategory, TransactionSubCategory} from '../../../../accounting/service/accounting-models';
import {TransactionSubCategoryClass} from '../../../../accounting/service/accounting.objects';
import {categories, subCategorySaveAction, categorySaveEventPayload} from '../../models/accounting-setup.models';
import {ChangeDetectorRef} from '@angular/core';

@Component({
  selector: 'sub-categories',
  templateUrl: './sub-categories.component.html',
  styleUrls: ['./sub-categories.component.scss'],
})
export class SubCategoriesComponent {
  @Input()
  subCategory: TransactionCategory;

  editSubCategory: TransactionCategory;
  @Input()
  generalLedgerAccounts: GeneralLedgerAccount[];
  @Input()
  otherElementInEdit: boolean;
  @Input()
  categoryGroup: categories;

  @Output() save: EventEmitter<categorySaveEventPayload> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  private _editIndex = -1;
  private _hiddenSubcategories: number[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  private _copySubCategory() {
    this.editSubCategory = _.cloneDeep(this.subCategory);
  }

  ngOnInit(): void {
    this._copySubCategory();
  }

  baseClass = 'action-btn mat-icon notranslate material-icons mat-icon-no-color ng-star-inserted';
  getAddBtnClass() {
    return this.isSubCategoryEdit() ? `${this.baseClass} add-btn disabled` : `${this.baseClass} add-btn`;
  }

  isSubCategoryEdit() {
    return this._editIndex !== -1 || this.otherElementInEdit;
  }

  newSubCategory() {
    if (!this.isSubCategoryEdit()) {
      this._createNewSubCategory();
    }
  }

  private _createNewSubCategory() {
    const newSubCategory = new TransactionSubCategoryClass();
    newSubCategory.name = 'New Sub Category';
    this.editSubCategory.subCategories = this.editSubCategory.subCategories ? this.editSubCategory.subCategories : [];
    this.editSubCategory.subCategories.push(newSubCategory);
    this._editIndex = this.editSubCategory.subCategories.length - 1;
    this.editMode(this._editIndex);
  }

  saveSubCategory() {
    this.subCategory = Object.assign(this.subCategory, this.editSubCategory);
    this._hiddenSubcategories = [];
    this._editIndex = -1;
    this.save.emit();
  }

  cloneSubCategory(subCategory: TransactionSubCategory) {
    this.editSubCategory.subCategories.push(subCategory);
    this._editIndex = this.editSubCategory.subCategories.length - 1;
    this.editMode(this._editIndex);
  }

  cancelSubCategory() {
    this.editSubCategory = Object.assign({}, this.subCategory);
    this._hiddenSubcategories = [];
    this._editIndex = -1;
    this.cancel.emit();
  }

  deleteSubCategory(subCategory: TransactionSubCategory) {
    const deleteIndex = this.editSubCategory.subCategories.findIndex(category => category._id === subCategory._id);
    this.editSubCategory.subCategories.splice(deleteIndex, 1);
    this.subCategory = Object.assign(this.subCategory, this.editSubCategory);
    const eventObject: categorySaveEventPayload = {
      saveAction: subCategorySaveAction.deleteSubCategory,
      callback: null,
    };
    this.save.emit(eventObject);
  }

  editMode(index) {
    for (let i = 0; i < this.subCategory.subCategories.length; i++) {
      this._hiddenSubcategories.push(i);
    }
    this._hiddenSubcategories.splice(index, 1);
    this._editIndex = index;
    this.edit.emit();
  }

  subCategoryIsEdit(index) {
    return index === this._editIndex;
  }

  subCategoryIsHidden(index) {
    return this._hiddenSubcategories.indexOf(index) !== -1;
  }
}
