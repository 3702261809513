<mat-card class="knso-card edit-income-limit-container">
    <mat-card-header>
        <mat-toolbar>
            <span>Income Limit Area</span>
            <button mat-icon-button (click)="cancel()"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
    </mat-card-header>
    <form #popupForm="ngForm" novalidate>
        <mat-card-content>
            <div class="layout-row topAndBottomPadding">
                <div class="rightPadding" *ngIf="!createMode">
                    <button 
                        [disabled]="editMode"
                        mat-flat-button
                        class="hds_button editButton"
                        (click)="enableEditMode()" 
                        id="editButton">
                        Edit
                    </button>
                </div>
                <mat-form-field class="editNameField" appearance="outline">
                    <mat-label>Name</mat-label>
                    <input 
                        matInput  
                        ngModel 
                        [(ngModel)]="editArea.name"
                        #editNameField="ngModel"
                        name="editNameField"
                        [disabled]="!editMode"
                        id="nameInput"
                        type="text"
                        required
                        aria-label="Name">
                </mat-form-field>
                <mat-form-field class="medianIncomeField" appearance="outline">
                    <mat-label>Median Income</mat-label>
                    <input 
                        matInput  
                        ngModel 
                        [(ngModel)]="editArea.incomeLimitTables[selectedIndex].medianIncome"
                        #medianIncomeField="ngModel"
                        name="medianIncomeField"
                        [disabled]="!editMode"
                        id="medianIncomeField"
                        type="number"
                        required
                        aria-label="Median Income">
                </mat-form-field>
                <mat-form-field class="incomeAreaEffectiveDate" appearance="outline" *ngIf="!editEffectiveDate">
                    <mat-label>Effective Date</mat-label>
                        <mat-select 
                            ngModel
                            [(ngModel)]="selectedEffectiveDate"
                            (selectionChange)="effectiveDateChanged()"
                            [compareWith]="compareEffectiveDate"
                            aria-label="Select an effective date" 
                            #incomeAreaEffectiveDate="ngModel" 
                            name="incomeAreaEffectiveDate"
                            id="effectiveDateDropdown"
                            required>
                            <mat-option [value]="a.effectiveDate" *ngFor="let a of editArea.incomeLimitTables; let $index=index">
                                {{a.effectiveDate |date:'yyyy/MM/dd'}}
                            </mat-option>
                        </mat-select> 
                </mat-form-field>
                <mat-form-field class="incomeAreaEffectiveDate" appearance="outline" *ngIf="editEffectiveDate">
                    <mat-label>New Effective Date:</mat-label>
                    <div class="layout-row ">
                        <input class="leftPadding"
                            matInput 
                            [(ngModel)]="editArea.incomeLimitTables[selectedIndex].effectiveDate" 
                            ngModel 
                            required
                            #effectiveDatePicker="ngModel"
                            name="effectiveDatePicker"
                            aria-label="New Effective Date"
                            [matDatepicker]="datepicker">
                        <mat-datepicker-toggle class="kansoBlue" matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                        <mat-datepicker class="kansoBlue" #datepicker></mat-datepicker>
                    </div>
                </mat-form-field>
                <div class="effectiveDateButtons">
                    <button *ngIf="editEffectiveDate"
                        type="button"
                        mat-flat-button 
                        class="hds_button"
                        (click)="saveNewEffectiveDate()" 
                        id="saveNewEffectiveDateButton">
                        Save New Effective Date
                    </button>
                    <button *ngIf="editEffectiveDate && editArea.incomeLimitTables.length > 1"
                        type="button"
                        mat-flat-button 
                        class="hds_button"
                        (click)="cancelNewEffectiveDate()" 
                        id="cancelNewEffectiveDateBUtton">
                        Cancel New Effective Date
                    </button>
                    <button *ngIf="editMode && !editEffectiveDate"
                        appearance="outline"
                        type="button"
                        mat-flat-button 
                        class="hds_button"
                        (click)="addNewEffectiveDate()" 
                        id="addEffectiveDateButton">
                        Add Effective Date
                    </button>
                </div>
            </div>
            <div class="layout-column">
                <div class="addIncomePadding"> 
                    <button 
                        *ngIf="editMode" 
                        appearance="outline"
                        type="button"
                        mat-flat-button
                        class="hds_button"
                        (click)="addTableRow()" 
                        id="cancelButton">
                        Add Income Level
                    </button>
                </div>
                <table *ngIf="!editMode" class="altRows">
                    <thead>
                        <tr >
                            <th class="textCenter">Percent</th>
                            <th class="textCenter">Description</th>
                            <th class="textCenter">1 Person</th>
                            <th class="textCenter">2 Persons</th>
                            <th class="textCenter">3 Persons</th>
                            <th class="textCenter">4 Persons</th>
                            <th class="textCenter">5 Persons</th>
                            <th class="textCenter">6 Persons</th>
                            <th class="textCenter">7 Persons</th>
                            <th class="textCenter">8 Persons</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tr *ngFor="let f of editArea.incomeLimitTables[selectedIndex].incomeLevels; let i = index" id="fairMarketInput">
                        <td><span *ngIf="!editMode" class="tablePadding" id="description">{{f.percentageOfMedianIncome}}</span></td>
                        <td><span *ngIf="!editMode" class="tablePadding" id="description">{{f.name}}</span></td>
                        <td><span *ngIf="!editMode && f.incomeValues[0]" class="tablePadding" id="person1">{{f.incomeValues[0].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.incomeValues[1]" class="tablePadding" id="person2">{{f.incomeValues[1].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.incomeValues[2]" class="tablePadding" id="person3">{{f.incomeValues[2].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.incomeValues[3]" class="tablePadding" id="person4">{{f.incomeValues[3].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.incomeValues[4]" class="tablePadding" id="person5">{{f.incomeValues[4].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.incomeValues[5]" class="tablePadding" id="person6">{{f.incomeValues[5].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.incomeValues[6]" class="tablePadding" id="person7">{{f.incomeValues[6].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.incomeValues[7]" class="tablePadding" id="person8">{{f.incomeValues[7].amount | currency}}</span></td>
                    </tr>
                </table>
                <table>
                    <tr *ngFor="let f of editArea.incomeLimitTables[selectedIndex].incomeLevels; let i = index"
                        id="fairMarketInput">
                        <td>
                            <mat-form-field appearance="outline" *ngIf="editMode" >
                                <mat-label>Percentage</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.percentageOfMedianIncome"
                                    #percentageOfMedianIncomeField="ngModel"
                                    [name]="'percentageOfMedianIncomeField' + i"
                                    id="percentageOfMedianIncomeField"
                                    type="number"
                                    required
                                    aria-label="Percentage">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" *ngIf="editMode" >
                                <mat-label>Description</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.name"
                                    #descriptionField="ngModel"
                                    [name]="'descriptionField' + i"
                                    id="descriptionField"
                                    type="text"
                                    required
                                    aria-label="Description">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" *ngIf="editMode && f.incomeValues[0]" >
                                <mat-label>1 Person</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.incomeValues[0].amount"
                                    #1PersonInput="ngModel"
                                    [name]="'1PersonInput' + i"
                                    id="1PersonInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="whiteBackground" appearance="outline" *ngIf="editMode && f.incomeValues[1]" >
                                <mat-label>2 Persons</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.incomeValues[1].amount"
                                    #2PersonInput="ngModel"
                                    [name]="'2PersonInput' + i"
                                    id="2PersonInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field  appearance="outline" *ngIf="editMode && f.incomeValues[2]" >
                                <mat-label>3 Persons</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.incomeValues[2].amount"
                                    #3PersonInput="ngModel"
                                    [name]="'3PersonInput' + i"
                                    id="3PersonInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" *ngIf="editMode && f.incomeValues[3]" >
                                <mat-label>4 Persons</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.incomeValues[3].amount"
                                    #4PersonInput="ngModel"
                                    [name]="'4PersonInput' + i"
                                    id="4PersonInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" *ngIf="editMode && f.incomeValues[4]" >
                                <mat-label>5 Persons</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.incomeValues[4].amount"
                                    #5PersonInput="ngModel"
                                    [name]="'5PersonInput' + i"
                                    id="5PersonInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" *ngIf="editMode && f.incomeValues[5]" >
                                <mat-label>6 Persons</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.incomeValues[5].amount"
                                    #6PersonInput="ngModel"
                                    [name]="'6PersonInput' + i"
                                    id="6PersonInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" *ngIf="editMode && f.incomeValues[6]" >
                                <mat-label>7 Persons</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.incomeValues[6].amount"
                                    #7PersonInput="ngModel"
                                    [name]="'7PersonInput' + i"
                                    id="7PersonInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field  appearance="outline" *ngIf="editMode && f.incomeValues[7]" >
                                <mat-label>8 Persons</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.incomeValues[7].amount"
                                    #8PersonInput="ngModel"
                                    [name]="'8PersonInput' + i"
                                    id="8PersonInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td *ngIf="editMode" class="bottomPadding" >
                            <a class="kansoBlue"
                                (click)="deleteTableRow(i)"><mat-icon>delete</mat-icon>
                            </a>
                        </td>
                    </tr>
                </table>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button *ngIf="!editMode"
                type="button"
                mat-flat-button 
                class="hds_button cancel" 
                (click)="cancel()" 
                id="cancelButton">
                Close
            </button>
            <button *ngIf="editMode"
                type="button"
                mat-flat-button 
                class="hds_button cancel" 
                (click)="cancel()" 
                [disabled]="!editMode"
                id="cancelButton">
                Cancel
            </button>
            <button 
                type="button"
                mat-flat-button 
                class="hds_button" 
                (click)="onSubmit()" 
                [disabled]="!popupForm.valid || !editMode"
                id="saveButton">
                Update
            </button>
        </mat-card-actions>
    </form>
</mat-card>
