<mat-card class="knso-card vendor-notes-create-container">
    <mat-card-header>
        <mat-toolbar>
            <span>Vendor Note</span>
            <button mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
    </mat-card-header>

    <mat-card-content>
        <form #addVendorNoteForm="ngForm">
            <div class="section-body">
                <mat-form-field class="category" appearance="outline">
                    <mat-select 
                        ngModel    
                        [(ngModel)]="editNote.category"
                        #category="ngModel"
                        name="category"
                        required>
                            <mat-option *ngFor="let cat of noteCategories;" [value]="cat">{{cat}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="body" appearance="outline">
                    <mat-label>Body:</mat-label>
                    <textarea
                        matInput
                        matTextareaAutosize
                        ngModel
                        [(ngModel)]="editNote.body"
                        #body="ngModel"
                        name="body"
                        id="body"
                        type="text"
                        aria-label="Body"
                        required>
                    </textarea>
                </mat-form-field>
            </div>

        <mat-card-actions>
            <button 
                mat-flat-button
                (click)="closeDialog()"
                class="hds_button cancel"            
                id="cancelButton">
                    Cancel
            </button>
            <button 
                mat-flat-button 
                class="hds_button"             
                (click)="saveVendorNote()"
                [disabled]="!addVendorNoteForm.valid || addVendorNoteForm.$pristine"
                id="Submit">
                    Save Note
            </button>
        </mat-card-actions>
        </form>
    </mat-card-content>
</mat-card>
