<mat-card class="knso-card">
    <mat-card-header>
        <mat-toolbar>
            <span>{{getHeader()}}</span>
        <button mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
    </mat-card-header>
    <mat-card-content>
        <form #rentCalcForm="ngForm">
            <div class="layout-row">
                <mat-form-field 
                    appearance="outline">
                    <mat-label flex="20">Effective Date:</mat-label>
                        <input 
                            matInput 
                            [(ngModel)]="selectedDate" 
                            ngModel 
                            #effectiveDate="ngModel"
                            name="effectiveDate"
                            aria-label="Effective Date"
                            [matDatepicker]="datepicker">
                            <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                            <mat-datepicker #datepicker></mat-datepicker
                        >
                </mat-form-field>
            </div>
            <div class="layout-row">
                <mat-form-field 
                    appearance="outline">
                    <mat-label flex="20">Amount:</mat-label>
                        <input 
                            matInput
                            ngModel
                            [(ngModel)]="value"
                            name="amount"
                            #amount="ngModel"                   
                            required 
                            type="number" 
                            aria-label="Amount">
                        <mat-error *ngIf="amount.hasError('required')">
                            <div ng-message="required">Amount is required</div>
                        </mat-error>
                </mat-form-field>
            </div>
        </form>
    </mat-card-content>
    <mat-card-actions>    
            <button 
                mat-flat-button
                (click)="closeDialog()"
                class="hds_button cancel"            
                id="cancelButton">
                    Cancel
            </button>
            <button 
                mat-flat-button 
                class="hds_button"             
                (click)="save()"
                [disabled]="!rentCalcForm.valid || rentCalcForm.pristine"
                id="saveButton">
                    Save
            </button>
    </mat-card-actions>    
</mat-card>
