import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {CoreModule} from '../../../core/core.module';

import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatStepperModule} from '@angular/material/stepper';
import {ClosexComponent} from './closex/closex.component';
import {CustomAttributesComponent} from './custom-attributes/custom-attributes.component';
import {KansoWizardHeaderComponent} from './kanso-wizard-header/kanso-wizard-header.component';
import {KansoWizardContentComponent} from './kanso-wizard-content/kanso-wizard-content.component';

@NgModule({
  declarations: [ClosexComponent, CustomAttributesComponent, KansoWizardHeaderComponent, KansoWizardContentComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatStepperModule,
    CoreModule,
  ],
  exports: [ClosexComponent, CustomAttributesComponent, KansoWizardHeaderComponent, KansoWizardContentComponent],
})
export class ComponentsModule {}
