import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatHorizontalStepper} from '@angular/material/stepper';
import {GlobalModalChild, GlobalModalParent} from 'src/app/kanso-common/core/service';
import {
  PayableObjectWizardView,
  PayableReviewObjectView,
  VendorService,
  vendorPayableViewObject,
} from 'src/app/custom/vendor/vendor.service';
import {payableWizardModal, stepInfo} from '../../service/accounting-models';
import {PaymentPreference} from 'src/app/custom/vendor/vendor.service';
import {CoreService} from 'src/app/core/service/core.service';

@Component({
  selector: 'app-payables-wizard',
  templateUrl: './payables-wizard.component.html',
  styleUrls: ['./payables-wizard.component.scss'],
})
export class PayablesWizardComponent implements GlobalModalChild<payableWizardModal>, payableWizardModal, OnInit {
  customerId: string;
  siteId: string;
  @ViewChild('stepper') stepper: MatHorizontalStepper;
  @Input() payableObject: vendorPayableViewObject[];
  // eslint-disable-next-line
  @Input() updateFunction: Function;
  displayedTransactionColumns: string[] = ['Pay to', 'Last Payment Amount', 'Amount Paid'];
  displayedReviewColumns: string[] = ['Type', 'Number of Payments', 'Amount to be Paid'];
  checkNumber: number;

  achDirectDepositData: PayableObjectWizardView[];
  checksData: PayableObjectWizardView[];
  otherData: PayableObjectWizardView[];
  payableReview: PayableReviewObjectView[];

  stepWizardInfo: stepInfo[] = [
    {stepHeader: 'Process ACH', stepTitle: 'ACH Details'},
    {stepHeader: 'Process Checks', stepTitle: 'Checks Details'},
    {stepHeader: 'Process Other', stepTitle: 'Other Details'},
    {stepHeader: 'Review & Submit', stepTitle: 'Review Payable Details'},
  ];

  globalModalParent: GlobalModalParent;
  private CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  private SITE_ID = sessionStorage.getItem('SITEID') || '';
  // eslint-disable-next-line
  constructor(private vendorService: VendorService, private coreService: CoreService) {}

  ngOnInit(): void {
    this.customerId = this.CUSTOMER_ID;
    this.siteId = this.SITE_ID;
    this.achDirectDepositData = this.onModifyPayableTable(this.payableObject, PaymentPreference.ACH);
    this.checksData = this.onModifyPayableTable(this.payableObject, PaymentPreference.Check);
    this.otherData = this.onModifyPayableTable(this.payableObject, PaymentPreference.Other);
    this.payableReview = this.onModifyPayableReview(this.achDirectDepositData, this.checksData, this.otherData);
    if (this.checksData.length > 0) {
      this.onGetMostRecentCheckNumber();
    } else {
      this.checkNumber = null;
    }
  }

  onGetMostRecentCheckNumber() {
    this.vendorService.getVendorChecks(this.siteId).subscribe(
      result => {
        const sortedCheckNumber = result.sort((oldest, recent) => recent - oldest);
        if (sortedCheckNumber.length > 0) {
          this.checkNumber = sortedCheckNumber[0] + 1;
        } else {
          this.checkNumber = 1;
        }
      },
      error => {
        this.checkNumber = null;
        this.coreService.displayError('There was an error getting most recent check number!');
      }
    );
  }

  onModifyPayableTable(payableObject: vendorPayableViewObject[], paymentPreference: number): PayableObjectWizardView[] {
    const modifyPayable = payableObject.filter(
      payable => payable.paymentPreference == paymentPreference && (!payable.selected || payable.indeterminate)
    );
    return modifyPayable.map(payable => {
      return {
        vendorName: payable.vendorName,
        lastPayment: payable.lastPayment,
        balanceToBePaid: payable.balanceToBePaid,
      };
    });
  }

  onModifyPayableReview(
    achDirectDepositData: PayableObjectWizardView[],
    checksData: PayableObjectWizardView[],
    otherData: PayableObjectWizardView[]
  ): PayableReviewObjectView[] {
    return [
      {
        type: 'ACH/Direct Deposit',
        numberofPayments: achDirectDepositData.length,
        amountToBePaid: this.sumAmount(achDirectDepositData, 'balanceToBePaid'),
      },
      {
        type: 'Checks',
        numberofPayments: checksData.length,
        amountToBePaid: this.sumAmount(checksData, 'balanceToBePaid'),
      },
      {
        type: 'Other',
        numberofPayments: otherData.length,
        amountToBePaid: this.sumAmount(otherData, 'balanceToBePaid'),
      },
    ];
  }

  sumAmount(paymentDetail: PayableObjectWizardView[], property: string): number {
    return paymentDetail.reduce((sum: number, obj: PayableObjectWizardView) => sum + obj[property], 0);
  }

  onCancelPayables(): void {
    this.globalModalParent.closePopup();
  }

  goToPreviousStep(): void {
    const previousStep = this.stepper.selectedIndex - 1;
    if (previousStep >= 0) {
      this.stepper.selectedIndex = previousStep;
    }
  }

  goToNextStep(): void {
    const nextStep = this.stepper.selectedIndex + 1;
    if (nextStep < this.stepper.steps.length) {
      this.stepper.selectedIndex = nextStep;
    } else {
      const unSelectedTransaction = this.onGetUnselectedTransaction(this.payableObject);
      this.updateFunction(unSelectedTransaction, this.checkNumber);
      this.globalModalParent.closePopup();
    }
  }

  onCheckDisableNext(stepper: MatHorizontalStepper) {
    if (this.checksData.length > 0 && stepper.selectedIndex === 1) {
      if (!this.checkNumber) {
        return true;
      }
    }
    return false;
  }

  onGetUnselectedTransaction(payableObject: vendorPayableViewObject[]) {
    const unselectedTransactionId = [];
    for (const payable of payableObject) {
      if (payable.details) {
        for (const transaction of payable.details) {
          if (transaction.selected) {
            unselectedTransactionId.push(transaction.id);
          }
        }
      }
    }
    return unselectedTransactionId;
  }
}
