import {Component, Injector} from '@angular/core';
import {HousingServices} from '../../housing-core/services/housing-services.service';
import {Authority, Project, ProjectModalData, Program} from '../../housing-core/services/housing-models';
import {AccountingService} from '../../accounting/service/accounting.service';
import {FormControl} from '@angular/forms';
import {Observable, combineLatest} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {GlobalModalService} from 'src/app/kanso-common/core/service/globalmodal.service';
import {ProjectSetupComponent} from '../project-setup/project-setup.component';
import {CoreService} from 'src/app/core/service/core.service';
import {CustomAttributes, KansoActionResult} from 'src/app/core/service/core-models';
import {ISubLedgerMap} from '../../accounting/service/accounting-models';

@Component({
  selector: 'project-setup-list',
  templateUrl: './project-setup-list.component.html',
  styleUrls: ['./project-setup-list.component.scss'],
})
export class ProjectSetupListComponent {
  projects: Project[];
  filteredProjects$: Observable<Project[]>;
  projectFilter = new FormControl('');
  projectSort = new FormControl('');
  selectedProject: Project;
  programName: string;
  loading = true;
  selectedFilter;
  error = false;
  errorMsg = '';
  isBusy = false;
  busyText: string;
  programs: Program[];
  housingAuthority: Authority;
  chartOfAccounts?: ISubLedgerMap[];
  customAttributesTemplates: CustomAttributes[];

  constructor(
    public globalModalService: GlobalModalService,
    protected injector: Injector,
    public housingServices: HousingServices,
    public accountingService: AccountingService,
    public coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.getProjects();
  }

  setBusy(message: string) {
    this.busyText = message;
    this.isBusy = true;
  }

  async editProject(project: Project | string) {
    try {
      this.setBusy('Loading Program Details');
      if (!this.programs) {
        await this.getPrograms();
      }
      if (!this.housingAuthority) {
        await this.getHousingAuthority();
      }
      if (!this.chartOfAccounts) {
        await this.getSubLedgerMaps();
      }
      if (!this.customAttributesTemplates) {
        await this.getCustomAttributes();
      }

      const isNew = typeof project === 'string';
      this.selectedProject = isNew ? this.newProject() : (project as Project);
      if (!isNew && this.selectedProject.rentCalc) {
        this.selectedProject.rentCalc.allowableDependentDeduction =
          this.selectedProject.rentCalc.allowableDependentDeduction && this.selectedProject.rentCalc.allowableDependentDeduction / 100;
        this.selectedProject.rentCalc.allowableElderlyDisabilityDeduction =
          this.selectedProject.rentCalc.allowableElderlyDisabilityDeduction &&
          this.selectedProject.rentCalc.allowableElderlyDisabilityDeduction / 100;
      }
      this.globalModalService.openModal<ProjectModalData>(
        ProjectSetupComponent,
        this.injector,
        this.globalModalService.getModalOverlayConfig(),
        {
          selectedProject: this.selectedProject,
          programs: this.programs,
          housingAuthority: this.housingAuthority,
          chartOfAccounts: this.chartOfAccounts,
          customAttributesTemplates: this.customAttributesTemplates,
          editMode: !isNew,
          updateFunction: this.saveNewProject,
        }
      );
    } catch (error) {
      this.coreService.displayError(error.message, null, 'Error Fetching Project Details');
    } finally {
      this.isBusy = false;
    }
  }

  newProject() {
    const newProject = {
      name: '',
      type: '',
      projectNumber: '',
      projectCode: '',
      program: '',
      programName: '',
      programType: '',
      chartOfAccounts: '',
      rentCalc: null,
      customAttributes: [],
      projectSegmentCode: '',
    };
    return newProject;
  }

  projectSelectedRoute(project: Project) {
    return ['/configuration', 'setup', 'projects', project._id];
  }

  getProjects = () => {
    this.setBusy('Loading Projects');
    this.accountingService.getAllProjects().subscribe(
      response => {
        this.projects = response.body;
        this.filteredProjects$ = combineLatest([
          this.projectFilter.valueChanges.pipe(
            startWith(null),
            map(value => value)
          ),
          this.projectSort.valueChanges.pipe(
            startWith('name'),
            map(value => value)
          ),
        ]).pipe(map(([aName, aSort]) => this._filterProjects(aSort, aName)));
        this.loading = false;
        this.isBusy = false;
      },
      error => {
        console.log('Error fetching project list: ', error);
        this.coreService.displayError('An unexpected error occurred fetching list of projects. Please try again.', null, '');
        this.errorMsg = 'An unexpected error occurred fetching list of projects. Please try again.';
        this.isBusy = false;
        this.error = true;
      }
    );
  };

  async getPrograms() {
    try {
      const result = this.accountingService.getAllPrograms().toPromise();
      this.programs = (await result).body;
    } catch (error) {
      this.coreService.displayError(error.message, null, 'Error loading Programs Data');
    }
  }

  async getSubLedgerMaps() {
    try {
      const result = this.accountingService.getAllSubLedgerMaps().toPromise();
      this.chartOfAccounts = (await result).body;
    } catch (error) {
      throw new Error('Error fetching subledger map data');
    }
  }

  async getHousingAuthority() {
    try {
      const result = await this.housingServices.getHousingAuthority().toPromise();
      this.housingAuthority = result.body;
    } catch (error) {
      throw new Error('Error fetching Housing Authority data');
    }
  }

  async getCustomAttributes() {
    try {
      const result = await this.coreService.getCustomAttributesByEntity('Project').toPromise();
      this.customAttributesTemplates = result.results.filter(attr => attr.active);
    } catch (error) {
      throw new Error('Error fetching custom attribute templates');
    }
  }

  saveNewProject = editedProject => {
    if (editedProject.rentCalc) {
      editedProject.rentCalc.allowableDependentDeduction = editedProject.rentCalc.allowableDependentDeduction * 100;
      editedProject.rentCalc.allowableElderlyDisabilityDeduction = editedProject.rentCalc.allowableElderlyDisabilityDeduction * 100;
    }
    if (this.selectedProject._id) {
      this.accountingService.updateProject(editedProject).subscribe(this.saveProgramHandler);
    } else {
      this.accountingService.createProject(editedProject).subscribe(this.saveProgramHandler);
    }
  };

  saveProgramHandler = (response?: KansoActionResult<Project>) => {
    try {
      this.getProjects();
    } catch (error) {
      this.coreService.displayError(`Error trying to save Project ${this.selectedProject.name}: ${error.message}`);
      console.log(`Error trying to save Project: ${JSON.stringify(this.selectedProject.name)} error: ${error.message}`);
    }
  };

  private _filterProjects(sortValue: string, filterValue?: string): Project[] {
    return filterValue
      ? this._sortByProperty(
          this.projects.filter(project => project.name.toLowerCase().includes(filterValue.toLowerCase())),
          sortValue
        )
      : this._sortByProperty(this.projects, sortValue);
  }

  private _sortByProperty(projects: Project[], projectName: string) {
    return projects.sort((a: Project, b: Project) => {
      const aName = a[projectName].toLowerCase();
      const bName = b[projectName].toLowerCase();
      switch (true) {
        case aName > bName:
          return 1;
        case aName < bName:
          return -1;
        default:
          return 0;
      }
    });
  }
}
