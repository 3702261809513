import {Component, Input} from '@angular/core';
import {GlobalModalChild, GlobalModalParent} from '../../../kanso-common/core/service/globalmodal.service';
import * as moment from 'moment';

@Component({
  selector: 'app-rentcalc-option-popup',
  templateUrl: './rentcalc-option-popup.component.html',
  styleUrls: ['./rentcalc-option-popup.component.scss'],
})
export class RentcalcOptionPopupComponent implements GlobalModalChild<any> {
  @Input() index: number;
  @Input() chargeType: string;
  // eslint-disable-next-line
  @Input() updateFunction: Function;
  _rentCalcOption: any;
  @Input() set rentCalcOption(setRentCalcOption: any) {
    this._rentCalcOption = setRentCalcOption;
    this.editRentCalcOption = Object.assign({}, setRentCalcOption);
    this.editRentCalcOption.value = this.editRentCalcOption.value.map(amount => amount / 100);
    this.editRentCalcOption.effectiveDate = setRentCalcOption.effectiveDate ? new Date(setRentCalcOption.effectiveDate) : new Date();
  }

  get rentCalcOption() {
    return this._rentCalcOption;
  }

  editRentCalcOption;
  globalModalParent: GlobalModalParent;

  getHeader() {
    switch (this.chargeType) {
      case 'adminFees':
        return 'Admin Fee Details';
      case 'minimumCharges':
        return 'Minimum Rent Details';
      case 'maximumCharges':
        return 'Maximum Charge Details';
      case 'utilityAllowances':
        return 'Utility Allowance Details';
      case 'fairMarketRents':
        return ' Fair Market Rent Details';
      case 'flatRents':
        return 'Flat Rent Details';
    }
    return;
  }

  closeDialog() {
    this.globalModalParent.closePopup();
  }

  save() {
    this.rentCalcOption = Object.assign(this._rentCalcOption, this.editRentCalcOption);
    this.rentCalcOption.effectiveDate = moment(this.rentCalcOption.effectiveDate).format('YYYY/MM/DD');
    this.rentCalcOption.modifiedOn = new Date();
    this.rentCalcOption.value = this.rentCalcOption.value.map(amount => amount * 100);
    this.updateFunction(this.rentCalcOption, this.index);
    this.closeDialog();
  }
}
