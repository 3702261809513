import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
  selector: 'app-global-angular2-error-handler',
  templateUrl: './global-angular2-error-handler.component.html',
  styleUrls: ['./global-angular2-error-handler.component.scss'],
})
export class GlobalAngular2ErrorHandlerComponent {
  viewData;

  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogRef: MatDialogRef<GlobalAngular2ErrorHandlerComponent>) {
    this.viewData = data;
  }

  closeModal(): void {
    return this.dialogRef.close();
  }
}
