import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

//import { HousingCoreModule } from './housingCore/housing-core.module';
import {SetupModule} from './setup/setup.module';
import {AccountingModule} from './accounting/accounting.module';
import {VendorModule} from './vendor/vendor.module';
import {FeatureConfigurationsModule} from './feature-configurations/feature-configurations.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, SetupModule, AccountingModule, VendorModule, FeatureConfigurationsModule],
  exports: [
    //HousingCoreModule,
    AccountingModule,
  ],
})
export class CustomModule {}
