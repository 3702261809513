<section class="tabs__panel layout-column utility-allowances-container">
    <section class="tab-header">
        <div class="layout-column">
            <h2>Utility Allowances</h2>
        </div>
        <busy-notice
            [isBusy]="this.isBusy"
            [busyText]="this.busyText"
            headerText="KANSO">
        </busy-notice>
        <div class="utility-action-container" *ngIf="utilityAllowanceList.length > 0">
            <div class="unit-type-action">
                <div class="add-new-action">
                    <mat-form-field class="unit-type-selection" appearance="outline">
                        <mat-label>Unit Type</mat-label>
                        <mat-select
                            [(ngModel)]="selectedUtilityAllowance" 
                            ngModel
                            #unitType="ngModel"
                            (selectionChange)="onUnitTypeChange()"
                            name="untiType"
                            >
                            <mat-option *ngFor="let utilityAllowance of utilityAllowanceList"
                                [value]="utilityAllowance">
                                <span>{{utilityAllowance.name}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="crud-action" *ngIf="hasPermission">
                    <button mat-flat-button color="primary" matTooltip="Add Unit Type" class="hds_button"  (click)="openUtilityActionPopup(utilityActionType.addUnitType)">
                        <mat-icon>add</mat-icon>
                    </button>                 
                    <button mat-flat-button  color="primary" matTooltip="Edit Unit Type" class="hds_button" [disabled]="!selectedUtilityAllowance" (click)="openUtilityActionPopup(utilityActionType.updateUnitType)">
                        <mat-icon>create</mat-icon>
                    </button>
                    <button mat-flat-button color="primary" matTooltip="Delete Unit Type" class="hds_button"  [disabled]="!selectedUtilityAllowance" (click)="openUtilityActionPopup(utilityActionType.deleteUnitType)">
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                    <button mat-flat-button color="primary" matTooltip="Print current table" class="hds_button" [disabled]="!selectedUtilityAllowance" (click)="navigateToUtilityReport()">
                        <mat-icon>content_paste_search</mat-icon>
                    </button>
                    <button mat-flat-button color="primary" class="hds_button clone" [disabled]="!selectedUtilityAllowance" (click)="openUtilityActionPopup(utilityActionType.cloneUnitType)">
                        Clone Unit Type
                    </button>
                </div>
            </div>
            <div class="unit-type-effective-date">
                <mat-form-field class="unit-type-date-selection" appearance="outline">
                    <mat-label>Rate Effective Date</mat-label>
                    <mat-select
                        [(ngModel)]="selectedUtilityAllowancesTable" 
                        ngModel
                        #effectiveDate="ngModel"
                        (selectionChange)="onEffectiveDateChange()"
                        name="effectiveDate">
                        <mat-option 
                            *ngFor="let utilityAllowancesTable of utilityAllowancesTableList"
                            [value]="utilityAllowancesTable">
                        <span>{{ utilityAllowancesTable.expectedDisplay }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button *ngIf="hasPermission" mat-flat-button color="primary" matTooltip="Add Effective Date" class="hds_button" [disabled]="!selectedUtilityAllowance" (click)="openUtilityActionPopup(utilityActionType.addEffectiveDate)">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>
    </section>
    <section class="utility-table-container" *ngIf="utilityAllowanceList.length > 0">
        <button *ngIf="hasPermission" mat-flat-button class="hds_button green" [disabled]="!selectedUtilityAllowance" (click)="openUtilityActionPopup(utilityActionType.addUtilityItem)">
            Add New Utility Item
        </button>
        <table mat-table [dataSource]="selectedUtilityTableValueData" *ngIf="selectedUtilityTableValueData.length > 0">
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef>
                    Category 
                    <button
                        mat-icon-button
                        *ngIf="categorySortOrder == sortOrder.descending"
                        (click)="sortValueChanges(utilitySortType.category, sortOrder.ascending)"
                        >
                        <mat-icon>
                            keyboard_arrow_up
                        </mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        *ngIf="categorySortOrder == sortOrder.ascending"
                        (click)="sortValueChanges(utilitySortType.category, sortOrder.descending)"
                        >
                        <mat-icon>
                            keyboard_arrow_down
                        </mat-icon>
                    </button>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div>
                        {{ truncateTextCategory(element.category) }}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>
                    Description
                    <button
                        mat-icon-button
                        *ngIf="descriptionSortOrder == sortOrder.descending"
                        (click)="sortValueChanges(utilitySortType.description, sortOrder.ascending)"
                        >
                        <mat-icon>
                            keyboard_arrow_up
                        </mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        *ngIf="descriptionSortOrder == sortOrder.ascending"
                        (click)="sortValueChanges(utilitySortType.description, sortOrder.descending)"
                        >
                    <mat-icon>
                        keyboard_arrow_down
                    </mat-icon>
                </button>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div>
                        {{ truncateTextDescription(element.description) }}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="studio">
                <th mat-header-cell *matHeaderCellDef>Studio</th>
                <td mat-cell *matCellDef="let element">{{ element.bedroom0 | currency }}
                </td>
            </ng-container>
            <ng-container matColumnDef="1 Bed">
                <th mat-header-cell *matHeaderCellDef>1 Bed</th>
                <td mat-cell *matCellDef="let element">{{ element.bedroom1 | currency }}
                </td>
            </ng-container>
            <ng-container matColumnDef="2 Bed">
                <th mat-header-cell *matHeaderCellDef>2 Bed</th>
                <td mat-cell *matCellDef="let element">{{ element.bedroom2 | currency }}
                </td>
            </ng-container>
            <ng-container matColumnDef="3 Bed">
                <th mat-header-cell *matHeaderCellDef>3 Bed</th>
                <td mat-cell *matCellDef="let element">{{ element.bedroom3 | currency }}
                </td>
            </ng-container>
            <ng-container matColumnDef="4 Bed">
                <th mat-header-cell *matHeaderCellDef>4 Bed</th>
                <td mat-cell *matCellDef="let element">{{ element.bedroom4 | currency }}
                </td>
            </ng-container>
            <ng-container matColumnDef="5 Bed">
                <th mat-header-cell *matHeaderCellDef>5 Bed</th>
                <td mat-cell *matCellDef="let element">{{ element.bedroom5 | currency }}
                </td>
            </ng-container>
            <ng-container matColumnDef="6 Bed">
                <th mat-header-cell *matHeaderCellDef>6 Bed</th>
                <td mat-cell *matCellDef="let element">{{ element.bedroom6 | currency }}
                </td>
            </ng-container>
            <ng-container matColumnDef="7 Bed">
                <th mat-header-cell *matHeaderCellDef>7 Bed</th>
                <td mat-cell *matCellDef="let element">{{ element.bedroom7 | currency }}
                </td>
            </ng-container>
            <ng-container matColumnDef="8 Bed">
                <th mat-header-cell *matHeaderCellDef>8 Bed</th>
                <td mat-cell *matCellDef="let element">{{ element.bedroom8 | currency }}
                </td>
            </ng-container>
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="hasPermission"  matTooltip="Edit Utility Item" mat-icon-button (click)="openUtilityActionPopup(utilityActionType.editUtilityItem, element)">
                        <mat-icon>create</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="hasPermission" matTooltip="Delete Utility Item" mat-icon-button (click)="openUtilityActionPopup(utilityActionType.deleteUtilityItem, element)">
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-row"></tr>
        </table>
    </section>
    <section class="no-utility-container" *ngIf="utilityAllowanceList.length == 0 && !errorFetch && !isBusy">
        <p>There are currently no Unit Types setup. Add one to get started!</p>
        <button *ngIf="hasPermission" mat-flat-button color="primary" class="hds_button" (click)="openUtilityActionPopup(utilityActionType.addUnitType)">
            Add Unit Type
        </button> 
    </section>
    <section class="no-utility-container" *ngIf="errorFetch">
        <p>An unexpected error occurred fetching Utility Allowances. Please try again.</p>
    </section>
</section>