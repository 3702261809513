<section *ngIf="showPage === 'landlord-properties'" class="tabs__panel layout-column">
    <div class="tab-header">
        <div class="top-buttons">
            <button mat-flat-button 
                type="button"
                class="hds_button"
                (click)="addLandlordProperty()" 
                id="addLandlordPropertyButton"
                    >Add Property
            </button>
        </div>
    <hr class="throughTout"/>
    <div *ngIf="this.loading">
        <mat-spinner diameter="25" class="centered_spinner"> </mat-spinner> Loading Landlord Properties
    </div>
    <div *ngIf="!this.loading">
        <p *ngIf="!landordProperties || !landordProperties.length">No properties found</p>
        <p *ngIf="!landordProperties || !landordProperties.length && error ">{{errorMsg}}</p>
    <table class="altRows">
        <thead>
            <tr class="centerData">
                <th>Property Name</th>
                <th>Associated Landlord</th>
                <th>Total Units</th>
                <th>Percentage Occupied/Assigned</th>
                <th></th>
            </tr>
        </thead>
        <tr class="centerData" *ngFor="let property of landordProperties, let $index=index"> 
            <td><a (click)="selectedProperty(property.id)">{{property.propertyName}}</a></td>
            <td>{{property.associatedLandlord}}</td>
            <td>{{property.totalUnits}}</td>
            <td>{{property.percentOccupied}}</td>
            <td> 
                <a (click)="deleteLandlordProperty($index)">
                    <mat-icon>delete_outline</mat-icon>
                </a>
            </td>
        </tr>
    </table>
    </div>
</div>
</section>
