<section class="tabs__panel layout-column">
        <div class="page-header">
            <h2>Projects</h2>
        </div>
        <section>
            <div class="tab-header">
                <mat-form-field appearance="outline">
                    <mat-label>Sort By</mat-label>
                    <mat-select [formControl]="projectSort">
                        <mat-option value="programName">Program</mat-option>
                        <mat-option value="name">Name</mat-option>
                        <mat-option value="projectCode">Project Code</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <input matInput [formControl]="projectFilter" placeholder="search by name..." />
                </mat-form-field>
                <div class="top-buttons">
                    <button mat-flat-button (click)="editProject('new')" class="hds_button">
                        Add New
                    </button>
                </div>
            </div>
        </section>

        <busy-notice
            [isBusy]="this.isBusy"
            [busyText]="this.busyText"
            headerText="KANSO">
        </busy-notice>
        <div *ngIf="!loading">
            <p *ngIf="!projects || !projects.length">There are no Projects added yet</p>
            <p *ngIf="!projects || !projects.length && error ">{{errorMsg}}</p>
            <table class="kanso-table altRows">
                <thead>
                    <tr class="centerData" >
                        <th>Name</th>
                        <th>Program</th>
                        <th>Project Code</th>
                        <th></th>
                    </tr>
                </thead>
                
                <tr class="centerData" *ngFor="let project of filteredProjects$|async" (click)="editProject(project)">
                    <td><a>{{project.name}}</a></td>
                    <td>{{project.programName}}</td>
                    <td>{{project.projectCode}}</td>
                </tr>
            </table>
        </div>
</section>

<!-- <span *ngIf="showPage == 'landlord-properties'">
    <app-landlord-properties></app-landlord-properties>
</span> -->