import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {GeneralLedgerAccount, TransactionSubCategory} from '../../../../accounting/service/accounting-models';
import {categories} from '../../models/accounting-setup.models';

@Component({
  selector: 'sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.scss'],
})
export class SubCategoryComponent implements OnInit {
  @Input()
  transactionSubCategory: TransactionSubCategory;
  @Input()
  generalLedgerAccounts: GeneralLedgerAccount[];
  @Input()
  defaultEdit: boolean;
  @Input()
  index: number;
  @Input()
  otherElementInEdit: boolean;
  @Input()
  categoryGroup: categories;

  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() clone: EventEmitter<TransactionSubCategory> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<number> = new EventEmitter();

  editTransactionSubCategory: TransactionSubCategory;
  glAccountFilter = new FormControl();
  postToAccount = new FormControl(null);
  offsetToAccount = new FormControl(null);
  filteredGlAccounts$: Observable<GeneralLedgerAccount[]>;
  isEdit = false;
  rent = false;
  private __Transfers = 'Transfers';

  ngOnInit(): void {
    this.editTransactionSubCategory = Object.assign({}, this.transactionSubCategory);
    this.isEdit = this.defaultEdit || this.isEdit;
    this.filteredGlAccounts$ = this.glAccountFilter.valueChanges.pipe(
      startWith(null),
      map(accountName => this._filterAccounts(accountName))
    );
    this.postToAccount.setValue(this.transactionSubCategory.postTo);
    this.offsetToAccount.setValue(this.transactionSubCategory.offsetTo);
    this.editTransactionSubCategory.isSplit1 = this.editTransactionSubCategory.isSplit1 || false;
  }

  baseClass = 'action-btn mat-icon notranslate material-icons mat-icon-no-color ng-star-inserted';
  getActionBtnClass(buttonName?: string) {
    const actionBaseClass: string = buttonName ? `${this.baseClass} action-btn ${buttonName}` : `${this.baseClass} action-btn`;
    return (this.otherElementInEdit && buttonName != 'save' && buttonName != 'cancel') ||
      (this.isSystemTransferSubCategory() && buttonName === 'delete')
      ? `${actionBaseClass} action-btn disabled`
      : `${actionBaseClass} action-btn`;
  }

  saveSubCategory() {
    this.transactionSubCategory = Object.assign(this.transactionSubCategory, this.editTransactionSubCategory);
    this.transactionSubCategory.postTo = this.postToAccount.value;
    this.transactionSubCategory.offsetTo = this.offsetToAccount.value;
    this.save.emit();
    this.toggleEdit();
  }

  cancelEditSubCategory() {
    this.editTransactionSubCategory = Object.assign({}, this.transactionSubCategory);
    this.toggleEdit();
    this.cancel.emit();
  }

  cloneSubCategory() {
    if (!this.otherElementInEdit) {
      const newSubCategory = Object.assign({}, this.editTransactionSubCategory);
      newSubCategory.name = `${newSubCategory.name} (cloned)`;
      this.clone.emit(newSubCategory);
    }
  }

  deleteSubCategory() {
    if (!this.otherElementInEdit && !this.isSystemTransferSubCategory()) {
      this.delete.emit(this.transactionSubCategory);
    }
  }
  toggleEdit() {
    if (!this.otherElementInEdit || this.isEdit) {
      this.isEdit = !this.isEdit;
      if (this.isEdit) {
        this.edit.emit(this.index);
      }
    }
  }

  nullSafeBoolean(value) {
    return value ?? false;
  }

  compareGlAccounts(o1: string, o2: string) {
    if (o1 === o2) {
      return true;
    } else {
      return false;
    }
  }

  generalLedgerName(generalLedgerAccountId: string) {
    return generalLedgerAccountId ? this.generalLedgerAccounts.find(gl => gl._id === generalLedgerAccountId).name : '';
  }

  splitsText() {
    const slMap = this.editTransactionSubCategory;
    return `${slMap?.isSplit1 ? '1 ' : ''}${slMap?.isSplit2 ? '2 ' : ''}${slMap?.isSplit3 ? '3' : ''}${slMap?.isSplit4 ? '4' : ''}${
      slMap?.isSplit5 ? '5' : ''
    }${slMap?.isSplit6 ? '6' : ''}`;
  }

  private _filterAccounts(filterValue?: string): GeneralLedgerAccount[] {
    return filterValue
      ? this.generalLedgerAccounts.filter(account => account.name.toLowerCase().includes(filterValue.toLowerCase())).sort(this._sortByName)
      : this.generalLedgerAccounts.sort(this._sortByName);
  }

  private _sortByName = (a: GeneralLedgerAccount, b: GeneralLedgerAccount) => {
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();
    switch (true) {
      case aName > bName:
        return 1;
      case aName < bName:
        return -1;
      default:
        return 0;
    }
  };

  // this is kinda a hack to prevent edit/delete of default system categories 'System Transactions' category
  // don't really like this but alternantive of adding a 'write once' field to data object seems like abigger can of worms
  // so going with this for now
  public isSystemTransferSubCategory(): boolean {
    return this.categoryGroup === categories.systemCategories && this.transactionSubCategory.name === this.__Transfers;
  }
}
