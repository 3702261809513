/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {Component, Input} from '@angular/core';
import {GlobalModalChild, GlobalModalParent} from '../../../kanso-common/core/service/globalmodal.service';
import {ErrorModalData} from '../../service/core-models';

@Component({
  selector: 'error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss'],
})
export class ErrorPopupComponent implements GlobalModalChild<ErrorModalData>, ErrorModalData {
  // eslint-disable-next-line
  @Input() callbackFunction?: Function;
  @Input() errorMessage: string;
  @Input() actionMessage?: string;
  @Input() headerText?: string;
  globalModalParent: GlobalModalParent;

  closeDialog() {
    if (this.callbackFunction) {
      this.callbackFunction();
    }
    this.globalModalParent.closePopup();
  }

  expectedRows(message: string) {
    const lineCount = message.split('\n').length;
    return lineCount > 0 ? lineCount : 1;
  }
}
