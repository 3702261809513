import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VouchersAddEditComponent} from './vouchers-addedit/vouchers-addedit.component';
import {VouchersAssignComponent} from './vouchers-assign/vouchers-assign.component';
import {VouchersContainerComponent} from './vouchers-container.component';
import {VouchersMoveInComponent} from './vouchers-movein/vouchers-movein.component';
import {VouchersMoveOutComponent} from './vouchers-moveout/vouchers-moveout.component';
import {VouchersOverviewComponent} from './vouchers-overview/vouchers-overview.component';
import {SharedModule} from '../shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {VouchersAddEditrftaComponent} from './vouchers-addeditrfta/vouchers-addeditrfta.component';
import {VoucherService} from './vouchers-services/vouchers.service';
import {MatDialogModule, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TollingtimepopupComponent} from './tollingtimepopup/tollingtimepopup.component';
import {FailureLoadFormPopupComponent} from '../form-entry/failure-load-form-popup/failure-load-form-popup.component';
import {GlobalAngular2ErrorHandlerComponent} from '../global-angular2-error-handler/global-angular2-error-handler.component';
import {VouchersIssueWizardComponent} from './vouchers-issue-wizard/vouchers-issue-wizard.component';
import {MaterialModule} from '../../../projects/material/src/public_api';
import {CoreModule} from 'src/app/core';
import {ComponentsModule} from 'src/app/kanso-common/core/components/components.module';
import {FormEntryModule} from '../form-entry/form-entry.module';

@NgModule({
  declarations: [
    VouchersAddEditComponent,
    VouchersAssignComponent,
    VouchersContainerComponent,
    TollingtimepopupComponent,
    VouchersMoveInComponent,
    VouchersMoveOutComponent,
    VouchersOverviewComponent,
    VouchersAddEditrftaComponent,
    VouchersIssueWizardComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    ComponentsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MaterialModule,
    FormEntryModule,
  ],
  exports: [VouchersContainerComponent, VouchersAddEditComponent],
  providers: [
    VoucherService,
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {provide: MAT_DIALOG_DATA, useValue: {}},
  ],
  entryComponents: [
    VouchersContainerComponent, //NOTE: This is for dynamically added components that are added using ViewContainerRef.createComponent(). Adding them to entryComponents tells the offline template compiler to compile them and create factories for them. If you don't list a dynamically added component to entryComponents you'll get an error message a bout a missing factory because Angular won't have created one.
    VouchersAddEditComponent,
    VouchersAddEditrftaComponent,
    VouchersMoveOutComponent,
    VouchersMoveInComponent,
    TollingtimepopupComponent,
    VouchersAssignComponent,
    FailureLoadFormPopupComponent,
    GlobalAngular2ErrorHandlerComponent,
  ],
})
export class VouchersModule {}
