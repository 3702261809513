<div *ngIf="isLoading" class="layout-row spinnerPadding">
    <mat-spinner diameter="25" > </mat-spinner> 
    <div class="checkboxPadding">Loading Custom Attributes</div>
</div>
<div *ngIf="!isLoading" class="custom-attributes-container">
    <div *ngFor="let group of groupedCustomAttributes; let gi = index">
        <h4 >{{group.name}}</h4>
        <div *ngFor="let customAttribute of group.customAttributes; let i=index" >
            <!-- text -->
            <mat-form-field class="customAttributeValues" appearance="outline" *ngIf="customAttribute.type === 'text'">
                <mat-label>{{customAttribute.name}}</mat-label>
                <input
                    matInput
                    [disabled] = "editMode == false" 
                    [(ngModel)]="customAttribute.value" 
                    id="'customAttributeValue' + i"  
                    [required]="customAttribute.required ? true : false"
                    ngModel
                    #customAttributeText="ngModel"
                    [name]="'customAttributeText' + i + 'Group' + gi"
                    type="text"
                    (ngModelChange)="change()"
                    >
                    <mat-hint *ngIf="customAttribute.required">This is a required field!</mat-hint>
            </mat-form-field>

            <!-- date -->
            <mat-form-field class="customAttributeValues custom-attribute-date-input" appearance="outline" *ngIf="customAttribute.type === 'date'">
                <mat-label>{{customAttribute.name}}</mat-label>
                <div class="layout-row ">
                    <input class="leftPadding"
                        matInput 
                        [disabled] = "editMode == false" 
                        [(ngModel)]="customAttribute.value" 
                        id="'customAttributeValue' + i" 
                        ngModel
                        #customAttributeDate="ngModel"
                        [name]="'customAttributeDate' + i + 'Group' + gi" 
                        [matDatepicker]="datepicker"
                        (ngModelChange)="change()">
                    <mat-datepicker-toggle class="kansoBlue" matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker class="kansoBlue" #datepicker></mat-datepicker>
                </div>
            </mat-form-field>

            <!-- dropdown -->
                <mat-form-field class="customAttributeValues" appearance="outline" *ngIf="customAttribute.type === 'dropdown'">
                <mat-label>{{customAttribute.name}}</mat-label>
                <mat-select 
                    matInput
                    [disabled] = "editMode == false" 
                    [(ngModel)]="customAttribute.value" 
                    id="'customAttributeValue' + i"  
                    [required]="customAttribute.required ? true : false"
                    ngModel
                    #customAttributeDropdown="ngModel"
                    [name]="'customAttributeDropdown' + i + 'Group' + gi"
                    [compareWith]="compareValues"
                    (ngModelChange)="change()">
                    <mat-option [value]="null" id="none"> none </mat-option>
                    <mat-option *ngFor="let value of customAttribute.values;" [value]="value">
                        <span>{{value}}</span>
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="customAttribute.required">This is a required field!</mat-hint>
            </mat-form-field>
            <!-- multi-value -->
            <mat-form-field class="customAttributeValues" appearance="outline" *ngIf="customAttribute.type === 'multi'">
                <mat-label>{{customAttribute.name}}</mat-label>
                <mat-select 
                    multiple
                    matInput
                    [disabled] = "editMode == false" 
                    [(ngModel)]="customAttribute.value" 
                    id="'customAttributeValue' + i"  
                    [required]="customAttribute.required ? true : false"
                    ngModel
                    #customAttributeMulti="ngModel"
                    [name]="'customAttributeMulti' + i + 'Group' + gi"
                    [compareWith]="compareValues"
                    (ngModelChange)="change()">
                    <mat-option [value]="null" id="none"> none </mat-option>
                    <mat-option *ngFor="let value of customAttribute.values;" [value]="value">
                        <span>{{value}}</span>
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="customAttribute.required">This is a required field!</mat-hint>
            </mat-form-field>

            <!-- toggle -->
            <div class="customAttributeValues custom-attribute-toggle" appearance="outline" *ngIf="customAttribute.type === 'toggle'">
                <div>{{customAttribute.name}}</div>
                <mat-checkbox 
                    matInput
                    [disabled] = "editMode == false" 
                    [(ngModel)]="customAttribute.value" 
                    id="'customAttributeValue' + i" 
                    ngModel
                    #customAttributeToggle="ngModel"
                    [name]="'customAttributeToggle' + i + 'Group' + gi"
                    (ngModelChange)="change()" 
                    >
                </mat-checkbox>
            </div>
        </div>
    </div>
</div>
