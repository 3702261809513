<busy-notice
  [isBusy]="this.loading"
  [busyText]="this.loading && this.updating ? 'Updating Vendor...' : 'Loading Vendor...'"
  headerText="KANSO">
</busy-notice>

<div *ngIf="!loading">
  <section class="tabs__content">
    <div class="tabs__panel tabs__panel--main nameAndInfoRow">
      <div class="nameAndInfoRow extraPadding">
        <div class="nameAndInfoColumn">
          <div class="nameAndInfoRow edit">
            <div class="member-details__name">
              <span *ngIf="vendor.companyName">{{vendor.companyName}}</span>
              <span *ngIf="!vendor.companyName">{{vendor.lastName}}, {{vendor.firstName}}</span>
            </div>
            <div class="glyphicon glyphicon-pencil kanso-blue-text" (click)="editVendor(vendor)">
              Edit
            </div>
          </div>

          <span>{{vendor.vendorType | titlecase}}</span>

          <hr class="throughTout" />
          <div class="nameAndInfoRow">
            <span class="limitWidth">Phone Number: </span>
            <span *ngIf="vendor.phoneNumber">{{vendor.phoneNumber}}</span>
            <span *ngIf="!vendor.phoneNumber">--</span>
          </div>
          <div class="nameAndInfoRow">
            <span class="limitWidth">Email: </span>
            <span *ngIf="vendor.emailAddress">{{vendor.emailAddress}}</span>
            <span *ngIf="!vendor.emailAddress">--</span>
          </div>
        </div>
      </div>


      <div class="nameAndInfoColumn tout infoFill">
        <div class="nameAndInfoRow">
          <span class="limitWidth">Tax ID: </span>
          <span *ngIf="vendor.taxId">{{vendor.taxId}}</span>
          <span *ngIf="!vendor.taxId">--</span>
        </div>
        <div class="nameAndInfoRow">
          <span class="limitWidth">Vendor ID: </span>
          <span *ngIf="vendor.externalReferenceId">{{vendor.externalReferenceId}}</span>
          <span *ngIf="!vendor.externalReferenceId">--</span>
        </div>
        <div class="nameAndInfoRow">
          <span class="limitWidth">Preferred Method of Payment: </span>
          <span *ngIf="vendor.paymentPreference">{{vendor.paymentPreference}}</span>
          <span *ngIf="!vendor.paymentPreference">--</span>
        </div>
      </div>
    </div>
  </section>

  <section class="widget fillSpace">
    <div class="widget__header roundedCorners addBoxshadow">
      <div class="centerWhiteIcons">
        Notes
      </div>
    </div>
    <div class="layout-column layout-padding widget__body altRows roundedCorners">
      <div *ngFor="let note of filteredNotes; let $index=index">
        <div class="layout-column">
          <div class="layout-row">
            <span>{{note.body}}</span>
          </div>
        </div>
      </div>
      <span *ngIf="!vendor.notes.length">No Notes to Display</span>
    </div>
  </section>

  <section class="widget fillSpace">
    <div class="widget__header roundedCorners addBoxshadow">
      <div class="centerWhiteIcons">
        Custom Attributes
      </div>
    </div>
    <div class="layout-column layout-padding widget__body altRows roundedCorners">
      <div>
        <div class="layout-column">
          <div class="layout-row">
            <form>
              <div *ngIf="activeCustomAttributes?.length; else noCustomAttributes">
                <app-custom-attributes
                  *ngIf="vendor.attributeValues && !globalModalService.isOpen" 
                  [entity]="'Landlord'"
                  [editMode]="false"
                  [sqlData]="true"
                  [currentCustomAttributes]="vendor.attributeValues">
                  </app-custom-attributes>
                <p *ngIf="globalModalService.isOpen">Editing...</p>
              </div>
              <ng-template #noCustomAttributes>
                <p>There are no custom attributes that apply to this Vendor.</p>
              </ng-template>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>