<mat-card class="knso-card">
    <mat-card-header>
      <mat-toolbar>
        <span>{{ headerText ? headerText : 'Unexpected Error Occured' }}</span>
        <button mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
      </mat-toolbar>
    </mat-card-header>
    <mat-card-content>
      <div>
        <h4 class="errorMessage">
          {{ errorMessage }}
        </h4>
        <h5 class="errorMessage" *ngIf="!actionMessage">
            Please try again
        </h5>
        <div class="whiteSpace" *ngIf="actionMessage">
          <textarea
            [ngModel]="actionMessage"
            cols="60"  
            rows="{{expectedRows(actionMessage)}}" 
            readonly
            >
          </textarea>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div class="bottom-buttons">
        <button 
          mat-flat-button
          (click)="closeDialog()"
          class="hds_button">
          Ok
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
