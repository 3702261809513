/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {Component} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {MatDialog} from '@angular/material/dialog';

import {ComingSoonPopupComponent} from '../coming-soon-popup/coming-soon-popup.component';
@Component({
  selector: 'app-hmis',
  templateUrl: './hmis.component.html',
  styleUrls: ['./hmis.component.scss'],
})
export class HmisComponent {
  showFilter: boolean;
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private dialog: MatDialog) {
    this.matIconRegistry.addSvgIconLiteral(
      'filter-icon',
      this.domSanitizer.bypassSecurityTrustHtml(
        '<svg id="Filter_Icon" data-name="Filter Icon" xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14"><defs><style>.cls-1 {fill: #00aad4;}</style></defs><g><rect class="cls-1" x="5" width="6" height="6"/><rect id="Rectangle_9_copy" data-name="Rectangle 9 copy" class="cls-1" x="13" y="2" width="8" height="2"/><rect id="Rectangle_9_copy_2" data-name="Rectangle 9 copy 2" class="cls-1" y="2" width="3" height="2"/></g><g id="Group_1_copy" data-name="Group 1 copy"><rect class="cls-1" x="10" y="8" width="6" height="6"/><rect id="Rectangle_9_copy-2" data-name="Rectangle 9 copy" class="cls-1" y="10" width="8" height="2"/><rect id="Rectangle_9_copy_2-2" data-name="Rectangle 9 copy 2" class="cls-1" x="18" y="10" width="3" height="2"/></g></svg>'
      )
    );
    this.showFilter = false;
    this.comingSoonPopup();
  }

  comingSoonPopup() {
    this.dialog.open(ComingSoonPopupComponent, {panelClass: 'custom-dialog-container'});
  }
}
