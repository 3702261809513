import {HttpClient, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError, EMPTY} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class BaseService {
  uri: string;
  secret: string;
  siteId: string;
  customerId: string;
  stage: string;
  apiPath: string;
  header: any;

  constructor(public http: HttpClient) {
    this.getSiteContext();
  }

  getSiteContext(): void {
    this.apiPath = sessionStorage.getItem('BASE_SERVICE_URI');
    this.secret = sessionStorage.getItem('BASE_SERVICE_API_KEY');
    this.siteId = sessionStorage.getItem('SITEID');
    this.customerId = sessionStorage.getItem('CUSTOMERID');
    this.getStageContext();
    this.setServiceHeaders();
  }

  public getStageContext() {
    this.stage = this.apiPath.includes('dev') ? 'dev' : 'prod';
    return this.stage;
  }

  private setServiceHeaders(): void {
    const cookies = decodeURIComponent(document.cookie).split(';');
    const jwt = cookies.filter(x => x.indexOf('.idToken') > 0)[0].split('=')[1];
    this.header = {
      headers: {
        'content-type': 'application/json',
        'x-api-key': this.secret,
        'x-site-id': this.siteId,
        'x-customer-id': this.customerId,
        'x-token': jwt,
      },
    };
  }

  public handleError(error: HttpResponse<any>) {
    // In a real world app, we might use a remote logging infrastructure
    console.error('Error before trying to concat ', error);
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      if (error.status == 302) {
        window.location.href = '' + error.headers.get('location');
        return EMPTY;
      }
      const errorResponse: HttpErrorResponse = error;
      if (errorResponse.error && errorResponse.error.messageType) {
        errMsg = `${errorResponse.error.messageType} - ${errorResponse.error.message || ''}`;
      } else {
        errMsg = `${errorResponse.name} - ${errorResponse.message || ''}`;
        // WHGMessage = Object.assign(WHGMessage, errorResponse.error);
      }
    } else {
      errMsg = error.body && error.body.message ? error.body.message : error.toString();
    }

    console.error(errMsg);
    const newError = Object.assign({}, error, {errorEmmitted: true});
    return throwError(newError);
  }

  checkResponse<T>(res: any) {
    try {
      return JSON.parse(JSON.stringify(res)) as T;
    } catch (error) {
      console.log(`error in checkresponse ${error}`);
    }
  }

  apiPut<T>(method: string, params?: any) {
    return this.http.put<T>(method, JSON.stringify(params), this.header).pipe(
      map(res => this.checkResponse<T>(res)),
      catchError(error => this.handleError(error))
    );
  }

  apiGet<T>(method: string): Observable<T> {
    try {
      return this.http.get<T>(method, this.header).pipe(
        map(res => this.checkResponse<T>(res)),
        catchError(error => this.handleError(error))
      ) as Observable<T>;
    } catch (error) {
      console.log(`error in get ${error}`);
    }
  }

  apiPost<T>(method: string, params?: any): Observable<T> {
    try {
      params = JSON.stringify(params);
      return this.http.post<T>(method, params, this.header).pipe(
        map(res => this.checkResponse<T>(res)),
        catchError(error => this.handleError(error))
      );
    } catch (error) {
      console.log(`Post error" ${error}`);
    }
  }

  apiDelete<T>(method: string) {
    return this.http.delete<T>(method, this.header).pipe(
      map(res => this.checkResponse<T>(res)),
      catchError(error => this.handleError(error))
    );
  }
}
