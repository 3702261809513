import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {BaseService} from '../../kanso-common/core/service/base.service';
import {GlobalModalService} from '../../kanso-common/core/service/globalmodal.service';
import {
  MongoResults,
  DateRange,
  MongoReport,
  ReportCallback,
  EmailCategories,
  PhoneCategories,
  AddressCategories,
  NotesCategory,
  CustomAttributes,
  SupportedCustomAttributeEntity,
  SiteProperties,
  ErrorModalData,
  ConfirmModalData,
} from './core-models';
//import { IVersion } from '../about/about.component';
import {ErrorPopupComponent} from '../components/error-popup/error-popup.component';
import {ConfirmPopupComponent} from '../components/confirm-popup/confirm-popup.component';
import _ from 'lodash';

export class RouteInfo {
  routerLink: string[];
  // eslint-disable-next-line
  queryParams?: object;
}

@Injectable({providedIn: 'root'})
export class CoreService extends BaseService {
  endOfTime = '9999/99/99';
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  supportedCustomAttributeEntities: SupportedCustomAttributeEntity[];
  sitproperties: SiteProperties;
  constructor(public http: HttpClient, public globalModalService: GlobalModalService, protected injector: Injector) {
    super(http);
    // this.getSupportedCustomAttributeEntities().subscribe((mongoResults: MongoResults<SupportedCustomAttributeEntity>) => {
    //   this.supportedCustomAttributeEntities = mongoResults.results;
    // });
  }

  VendorPathParts = {
    Root: 0,
    Tab: 1,
    Id: 2,
    SubTab: 3,
  };
  getPathNamePart(part): string {
    return window.location.pathname.split('/')[part];
  }

  getAbsoluteValue(number): number {
    return Math.abs(number);
  }

  //   getReport<T>(reportRequest: Object, callback?: ReportCallback<T>): Observable<MongoReport<T>> {
  //     reportRequest = EJSON.serialize(reportRequest);
  //     return this.http.post<MongoReport<T>>(this.apiPath + '/reports', reportRequest).pipe(
  //       map(res => this.checkResponse<MongoReport<T>>(res)),
  //       tap(res => (callback ? callback(undefined, res['aggregationResults'], res['centsConvertedrethis']) : res)),
  //       catchError(error => this.handleError(error)));
  //   }

  //   printReport(reportRequest: Object) {
  //     reportRequest['format'] = 'PDF';
  //     let headers: HttpHeaders = new HttpHeaders();
  //     headers.set("Accept", ["application/pdf"]);

  //     // console.log("localParams before blob call", headers, localParams)
  //     this.http.post(this.apiPath + '/reports', reportRequest, { headers: headers, observe: 'body', responseType: 'blob' }).subscribe((result) => {
  //       var blob = new Blob([result], { type: 'application/pdf' });
  //       var objectUrl = URL.createObjectURL(blob);
  //       window.open(objectUrl);
  //     });
  //   }

  //   getVersion(): Observable<IVersion>{
  //       return this.apiGet<IVersion>('/njs/assets/version.json')
  //   }

  //   getSnapshots(): Observable<MongoReport<object>> {
  //     return this.apiGet<MongoReport<object>>(this.apiPath + '/reports/snapshots');
  //   }

  //   resetSnapshot(name: string, args: object): Observable<MongoReport<object>> {
  //     return this.apiPut<MongoReport<object>>(this.apiPath + '/reports/snapshots', { name, args });
  //   }
  //   publishMsg(msg: string, details: any, autoDismiss?: true) {
  //     if (details && details.handled) return;
  //     msg = msg || 'An error has occurred.';

  //     this.publish(msg, '--', details, autoDismiss);

  //     //eslint-disable-next-line no-console
  //     console.log(details || msg);
  //   }

  // get current user
  getCurrentUsersLogInCookie(): string {
    const cookies = document.cookie.split(';');
    let usersAuthCookie;
    for (const cookie of cookies) {
      if (cookie.includes('LastAuthUser')) {
        //found the cookie of the user
        usersAuthCookie = cookie;
      }
    }
    const arrayOfCookieStrings = usersAuthCookie.split('=');
    return arrayOfCookieStrings[1];
  }

  sortByProperty<T>(arrayToSort: T[], sortPropName: keyof T) {
    return arrayToSort.sort((a, b) => {
      const aName = String(a[sortPropName]).toLowerCase();
      const bName = String(b[sortPropName]).toLowerCase();
      switch (true) {
        case aName > bName:
          return 1;
        case aName < bName:
          return -1;
        default:
          return 0;
      }
    });
  }

  //check two of same objects for equality
  //deep=true checks array & object properties for equality otherwise they are ignored
  areEqual(objA: any, objB: any, deep = false, ignoreKeys: string[] = []): boolean {
    switch (true) {
      case _.isNil(objA) && _.isNil(objB):
        return true;
      case _.isNil(objA) || _.isNil(objB):
        return false;
    }
    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    // Check if the number of properties is the same
    if (keysA.length !== keysB.length) {
      return false;
    }
    const isParentArray = Array.isArray(objA) || Array.isArray(objB);
    if (keysA.length == 0 && !isParentArray) {
      //not an object or array do direct compare
      return objA == objB;
    }
    // Check each property for equality
    for (const key of keysA) {
      //Check skip keys
      if (ignoreKeys.indexOf(key) > -1) {
        continue;
      }
      // Check if the property is of type 'object' or 'array'
      const isObject = this.isObject(objA[key]) || this.isObject(objB[key]);
      const isArray = Array.isArray(objA[key]) || Array.isArray(objB[key]);
      if ((isArray || isObject) && !deep) {
        continue; // Skip properties that are objects or arrays
      }
      switch (true) {
        case isArray: {
          const isArrayEqual = this.areEqual(objA[key], objB[key], true);
          if (!isArrayEqual) {
            return false;
          }
          break;
        }
        case isObject: {
          const isObjectEqual = this.areEqual(objA[key], objB[key]);
          if (!isObjectEqual) {
            return false;
          }
          break;
        }
        default:
          if (!Object.prototype.hasOwnProperty.call(objB, key) || objA[key] !== objB[key]) {
            return false;
          }
          break;
      }
    }

    return true;
  }

  isObject(item) {
    return item !== null && typeof item === 'object' && !Array.isArray(item) && !(item instanceof Date);
  }

  isNullOrEmptyString(value: string | null | undefined): boolean {
    return value === null || value === undefined || value.trim() === '';
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // temp error message implementation
  displayError(
    errorMessage: string,
    // eslint-disable-next-line
    callback?: Function,
    headerText?: string,
    actionMessage?: string
  ) {
    this.globalModalService.openModal<ErrorModalData>(ErrorPopupComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      errorMessage: errorMessage,
      actionMessage: actionMessage,
      headerText: headerText,
      callbackFunction: callback,
    });
  }

  confirmAction(
    confirmMessage: string,
    // eslint-disable-next-line
    callback?: Function,
    headerText?: string,
    actionMessage?: string,
    confirmButtonText?: string
  ) {
    this.globalModalService.openModal<ConfirmModalData>(
      ConfirmPopupComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      {
        confirmMessage: confirmMessage,
        actionMessage: actionMessage,
        headerText: headerText,
        confirmButtonText: confirmButtonText,
        callbackFunction: callback,
      }
    );
  }

  //   publishError(msg: string, details: any) {
  //     if (details && details.handled) return;
  //     msg = msg || 'An error has occurred.';

  //     this.publish(msg, '--', details);
  //     console.error(details || msg);
  //   }

  //   deserialize(res: any) {
  //     EJSON.deserialize(res);
  //   }
  //   getNoteCategories(categoryType: string): Observable<MongoResults<NotesCategory>> {
  //     return this.apiPost<MongoResults<NotesCategory>>(this.apiPath + '/notecategories', {type: categoryType});
  //   }

  //   getEmailCategories(): Observable<MongoResults<EmailCategories>> {
  //     return this.apiGet<MongoResults<EmailCategories>>(this.apiPath + '/emailcategories');
  //   }
  //   getAddressCategories(): Observable<MongoResults<AddressCategories>> {
  //     return this.apiGet<MongoResults<AddressCategories>>(this.apiPath + '/addresscategories');
  //   }

  //   getPhoneCategories(): Observable<MongoResults<PhoneCategories>> {
  //     return this.apiGet<MongoResults<PhoneCategories>>(this.apiPath + '/phonecategories');
  //   }

  // getSupportedCustomAttributeEntities(): Observable<MongoResults<SupportedCustomAttributeEntity>> {
  //   return this.apiGet<MongoResults<SupportedCustomAttributeEntity>>(this.apiPath + '/customattributes/supported');
  // }

  getCustomAttributesByEntity(entity: string): Observable<MongoResults<CustomAttributes>> {
    //Legacy Call to Node endpoint, replace once backend is complete
    return this.apiPost<MongoResults<CustomAttributes>>(`/api/custom-attributes/query`, {filterSet: {entityName: entity}});
  }

  //   readCustomAttribute(id: string | ObjectId): Observable<CustomAttributes> {
  //     return this.apiGet<CustomAttributes>(this.apiPath + '/customattributes/' + id);
  //   }

  //   updateCustomAttribute(customAttribute: CustomAttributes): Observable<CustomAttributes> {
  //     return this.apiPut<CustomAttributes>(this.apiPath + '/customattributes/' + customAttribute._id, customAttribute);
  //   }

  //   listCustomAttribute(query: any): Observable<MongoResults<CustomAttributes>> {
  //     return this.apiPost<MongoResults<CustomAttributes>>(this.apiPath + '/customattributes/', query);
  //   }

  //   createCustomAttribute(customAttribute: CustomAttributes): Observable<CustomAttributes> {
  //     return this.apiPost(this.apiPath + '/customattributes/', customAttribute);
  //   }

  //   getGroups(): Observable<any> {
  //     return this.http.get(this.apiPath + '/groups')
  //   }

  //   createGroupFromReport(reqBody) {
  //     return this.http.post(this.apiPath + '/groups/action-by-report/create-group', reqBody)
  //   }

  //   addHouseholdsToGroup(groupId, reqBody) {
  //     return this.http.post(`/api/groups/${groupId}/action-by-report/add-households`, reqBody)
  //   }

  //   publish(msg: string, source: string, details: any, autoDismiss?: true) {
  //     // Broadcast for UI elements to react if required
  //     //    $rootScope.$broadcast('error-service-msg', {
  //     //      msg: msg,
  //     //      options: {
  //     //        type: 'error',
  //     //        autoDismiss: autoDismiss
  //     //      }
  //     //    });
  //     //
  //     //    // Ship the log statement off to the server
  //     //    Logging.error(msg, source, details);
  //   }

  //   configure(dateObj?: Date, opts?) {
  //     let formatString = 'MM/DD/YYYY';
  //     //set to today if no date Object is passed
  //     if (!dateObj) return moment(moment().format(formatString), formatString, this.timezone).toDate();
  //     if (opts && opts.startOf) return moment(moment(dateObj).format(formatString), formatString, this.timezone).startOf(opts.startOf).toDate();
  //     if (opts && opts.endOf) return moment(moment(dateObj).format(formatString), formatString, this.timezone).endOf(opts.endOf).toDate();
  //     //default if the method is invoked with no parameters
  //     return moment(moment(dateObj).format(formatString), formatString, this.timezone).toDate();
  //   }

  //   getTodayNormalized() {
  //     return this.configure();
  //   }

  //   getStartOfDate(date?: Date) {
  //     return this.configure(date, { startOf: 'day' });
  //   }

  //   getEndOfDate(date?: Date) {
  //     return this.configure(date, { endOf: 'day' });
  //   }

  //   getTodayLocalized() {
  //     return moment().toDate();
  //   }

  //   todaysDateString() {
  //     return moment(new Date(), this.timezone).format('YYYY/MM/DD');
  //   }

  //   dateStringToDate(date: Date) {
  //     return moment(date, 'YYYY/MM/DD').toDate();
  //   }

  //   dateToString(date: Date) {
  //     return moment(date, this.timezone).format('YYYY/MM/DD');
  //   }

  //   getDateRangeByMonth(numberOfMonths): DateRange[] {
  //       var monthNames = moment.months();
  //       var date:moment.Moment = moment(); // Housing Authority Preferred Timezone set from Node environment
  //       var dateRanges:DateRange[] = [];

  //       if (lodash.isNil(numberOfMonths)) numberOfMonths = 4;

  //       var month = 0;
  //       do {
  //           dateRanges.push({
  //               firstDay: new Date(date.year(), date.month(), 1), // ToDo: convert this to a moment that can contain timezone
  //               lastDay: new Date(date.year(), date.month(), date.daysInMonth(), 23, 59, 59, 999),  // ToDo: convert this to moment that can contain timezone
  //               label: monthNames[date.month()]
  //           });
  //           // get the previous number-of-months, so subtract from current month
  //           date = date.subtract(1, 'month');
  //           month++;
  //       } while (month < numberOfMonths);
  //       return dateRanges;
  //   }

  //   getPastYears(numberOfYears: number): number[] {
  //     var currentYear = new Date().getFullYear();
  //     var years:number[] = [];
  //     for (var i = currentYear; i > (currentYear - numberOfYears); i--) {
  //         years.push(i);
  //     }

  //     return years;
  //   }
}
