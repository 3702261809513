import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  constructor(private http: HttpClient) {}

  info(message: string, source: string, details: Error): Promise<any> {
    return this._log('info', message, source, details);
  }

  warn(message: string, source: string, details: Error): Promise<any> {
    return this._log('warn', message, source, details);
  }

  error(message: string, source: string, details: Error): Promise<any> {
    return this._log('error', message, source, details);
  }

  private _log(logLevel: string, message: string, source: string, details: Error): Promise<any> {
    const client = {
      userAgent: window.navigator.userAgent,
      url: window.location.href,
    };

    const logStatement = {
      logLevel,
      message,
      source,
      client,
      details,
    };

    try {
      return this.http.post('api/logs', logStatement).toPromise();
    } catch (error) {
      console.error(`Unable to send log: ${JSON.stringify(error)}`);
    }
  }
}
