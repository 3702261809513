export enum TracsRecordTypeEnum {
    HEADER_INFO_STRING = 1,
    FAMILY_DETAIL = 2,
    INCOME_DETAIL = 3,
    ASSET_DETAIL = 4,
    EXPENSE_DETAIL = 5,
  }
  
  export const TracsRecordOutputOrder: Record<TracsRecordTypeEnum, string[]> = {
    [TracsRecordTypeEnum.HEADER_INFO_STRING]: [
      'recordType',
      'mailbox',
      'unitId',
      'effectiveDate',
      'hc1',
      'action',
      'correctionType',
    ],
    [TracsRecordTypeEnum.FAMILY_DETAIL]: [
      'recordType',
      'hc1',
      'hc2',
      'familyNumber',
      'lastName',
      'firstName',
      'middleName',
      'relation',
      'sex',
      'DOB',
      'age',
      'ssn',
      'citizenship',
      'arn',
      'statuscds',
      'hc3',
      'hc4',
      'hc5',
      'ethnicity',
      'raceIndian',
      'raceAsian',
      'racePacific',
      'raceWhite',
      'raceBlack',
      'raceOther',
      'raceDeclined',
    ],
    [TracsRecordTypeEnum.INCOME_DETAIL]: [
      'recordType',
      'hc1',
      'hc2',
      'familyNumber',
      'incomeType',
      'careCode',
      'incomeAmount',
      'hc3',
      'netExcludedAmount',
      'hc4',
      'hc5',
      'hc6',
      'hc7',
      'hc8',
    ],
    [TracsRecordTypeEnum.ASSET_DETAIL]: [
      'recordType',
      'hc1',
      'familyNumber',
      'current',
      'cashValue',
      'assetIncome',
      'assetType',
      'disposalDate',
      'hc2',
      'hc3',
    ],
    [TracsRecordTypeEnum.EXPENSE_DETAIL]: [
      'recordType',
      'childcareWork',
      'childcareSchool',
      'disability',
      'medical',
      'securityDeposit',
    ],
  };
  
  export enum TracsIncomeTypeEnum {
    TANF_ASSISTANCE = 'A',
    ANNUAL_IMPUTED_WELFARE_INCOME = 'N', // defaulting to Other Nonwage Sources
    CHILD_SUPPORT = 'CS',
    FEDERAL_WAGE = 'F',
    GENERAL_ASSISTANCE = 'G',
    INDIAN_TRUST_PER_CAPITA = 'I',
    MEDICAL_REIMBURSEMENT = 'N', // defaulting to Other Nonwage Sources
    MILITARY_PAY = 'M',
    OTHER_NONWAGE_SOURCES = 'N',
    OTHER_WAGE = 'W',
    OWN_BUSINESS = 'B',
    PENSIONS = 'PE',
    PHA_WAGE = 'N', // listed in Word doc as likely match
    SOCIAL_SECURITY = 'SS',
    SSI = 'SI',
    UNEMPLOYMENT_BENEFITS = 'U',
  }
  
  export const TracsIncomeDescriptionToType: Record<string, TracsIncomeTypeEnum> = {
    'TANF Assistance': TracsIncomeTypeEnum.TANF_ASSISTANCE,
    'Annual imputed Welfare Income': TracsIncomeTypeEnum.ANNUAL_IMPUTED_WELFARE_INCOME,
    'Child Support': TracsIncomeTypeEnum.CHILD_SUPPORT,
    'Federal Wage': TracsIncomeTypeEnum.FEDERAL_WAGE,
    'General Assistance': TracsIncomeTypeEnum.GENERAL_ASSISTANCE,
    'Indian Trust / Per Capita': TracsIncomeTypeEnum.INDIAN_TRUST_PER_CAPITA,
    'Medical Reimbursement': TracsIncomeTypeEnum.MEDICAL_REIMBURSEMENT,
    'Military Pay': TracsIncomeTypeEnum.MILITARY_PAY,
    'Other Nonwage Sources': TracsIncomeTypeEnum.OTHER_NONWAGE_SOURCES,
    'Other Wage': TracsIncomeTypeEnum.OTHER_WAGE,
    'Own Business': TracsIncomeTypeEnum.OWN_BUSINESS,
    Pensions: TracsIncomeTypeEnum.PENSIONS,
    'PHA Wage': TracsIncomeTypeEnum.PHA_WAGE,
    'Social Security': TracsIncomeTypeEnum.SOCIAL_SECURITY,
    SSI: TracsIncomeTypeEnum.SSI,
    'Unemployment Benefits': TracsIncomeTypeEnum.UNEMPLOYMENT_BENEFITS,
  };
  

  export enum TracsAssetType {
    Property = 'Other',
    Land = 'Other',
    Annuity401k = 'Other',
    CashOnHand = 'Other',
    Pension = 'Other',
    Bonds = 'Bonds',
    Other = 'Other',
    Checking = 'Checking',
    CD = 'Cert. Deposit',
    WholeLife = 'Whole Life Ins',
    Savings = 'Savings',
  }
  
  