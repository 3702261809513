import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule} from '@angular/forms';
import {CoreModule} from '../../core/core.module';

import {FeatureConfigurationsListComponent} from './feature-configurations-list/feature-configurations-list.component';
import {FeatureConfigurationsService} from 'src/app/shared/services/feature-configurations.service';

@NgModule({
  declarations: [FeatureConfigurationsListComponent],
  imports: [CommonModule, MatProgressSpinnerModule, MatSlideToggleModule, MatSelectModule, MatSelectModule, FormsModule, CoreModule],
  providers: [FeatureConfigurationsService],
})
export class FeatureConfigurationsModule {}
