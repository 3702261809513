import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {OverlayModule} from '@angular/cdk/overlay';
import {PortalModule} from '@angular/cdk/portal';
import {ComponentsModule} from './core/components/components.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, PortalModule, OverlayModule, ComponentsModule],
  exports: [ComponentsModule],
})
export class KansoCommonModule {
  // eslint-disable-next-line
  constructor() {}
}
