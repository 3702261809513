import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {TracsService} from './services/tracs.service';

@Component({
  selector: 'app-shofcorp-intergrations',
  templateUrl: './shofcorp-intergrations.component.html',
  styleUrls: ['./shofcorp-intergrations.component.scss']
})
export class ShofcorpIntergrationsComponent implements OnInit {
  @ViewChild('tracsForm') tracsForm: ElementRef;

  @Input() household;
  @Input() request;
  @Input() action;
  @Input() programType;
  @Input() currentUser;
  @Input() financialData;
  @Input() service;
  // eslint-disable-next-line
  @Input() callback: Function;
  @Input() submitEvent;
  tracsDetailRecords;
  tracsSubmissionPsvString = '';

  // eslint-disable-next-line
  constructor(private tracsService: TracsService) { }

  ngOnInit(): void {
    if(this.programType === 'Multifamily' && this.financialData) {
      this.tracsDetailRecords = this.tracsService.gatherTracsDetails(this.service, this.household, this.request, this.financialData);

      this.tracsSubmissionPsvString = this.tracsService.tracsSubmissionPrep(this.tracsDetailRecords);
    }
  }

  tracsSubmit() {
    this.tracsForm.nativeElement.submit();
    this.submitEvent();
  }
  
  getFilesReturn = (files) => {
    const returnId = files[0] ? files[0].fileId : null;
    this.callback(returnId);
  }
}
