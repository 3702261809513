<div *ngIf= "spinnerCheck === true && this.data.step != 'review'">
  <mat-spinner class="centered_spinner"></mat-spinner>
</div>

<div class="section-header" *ngIf= "voucherCheck == true && this.data.step != 'review'">Voucher Details</div>

<div class="section-body" *ngIf= "voucherCheck == true && this.data.step != 'review'">
  <p>
    It looks like there is a voucher associated with this unit. Upon completion of this move out action, what should the new voucher status
    be?
  </p>

  <mat-form-field appearance="outline">
    <mat-select [(ngModel)]="voucherstatus">
      <mat-option *ngFor="let voucherstatus of voucherStatusList" [value]="voucherstatus">{{ voucherstatus.name }}</mat-option>
    </mat-select>
  </mat-form-field>

</div>
