//import {C} from '@angular/cdk/keycodes';
import {ISubLedgerMap, GeneralLedgerAccount, TransactionCategory, TransactionSubCategory} from './accounting-models';

export class SubLedgerMap implements ISubLedgerMap {
  constructor(name: string, glAccount: string) {
    this.name = name;
    this.glAccount = glAccount;
    this._id = undefined;
    this.systemCategories = [];
    this.creditCategories = [];
    this.chargeCategories = [];
    const newCategory = new TransactionCategoryClass();
    newCategory.name = 'System Transactions';
    const newSubCategory = new TransactionSubCategoryClass();
    newSubCategory.name = 'Transfers';
    newCategory.subCategories = [];
    newCategory.subCategories.push(newSubCategory);
    this.systemCategories.push(newCategory);
  }
  name: string;
  description: string;
  defaultProgramType?: string;
  glAccount: string;
  chargeCategories: TransactionCategory[];
  creditCategories: TransactionCategory[];
  systemCategories: TransactionCategory[];
  isGeneric: boolean;
  accountCode?: string;
  _id?: string;
  createdOn?: Date;
  createdBy?: string;
  modifiedOn?: Date;
  modifiedBy?: string;
}

export class GeneralLedgerAccountClass implements GeneralLedgerAccount {
  name: string;
  accountCode: string;
  _id?: string;
  createdOn?: Date;
  createdBy?: string;
  modifiedOn?: Date;
  modifiedBy?: string;
}

export class TransactionCategoryClass implements TransactionCategory {
  name: string;
  subCategories: TransactionSubCategory[];
  _id?: string;
  createdOn?: Date;
  createdBy?: string;
}

export class TransactionSubCategoryClass implements TransactionSubCategory {
  name: string;
  reportName: string;
  transactionDescription: string;
  transactionType: string;
  categoryName: string;
  postTo?: string;
  offsetTo?: string;
  chartOfAccountsId?: string;
  payoffPriority: number;
  include1099: boolean;
  accountCode: string;
  isRent: boolean;
  isSplit1: boolean;
  isSplit2: boolean;
  isSplit3: boolean;
  isSplit4: boolean;
  isSplit5: boolean;
  isSplit6: boolean;
  isHidden: boolean;
  _id?: string;
  createdOn?: Date;
  createdBy?: string;
}
