<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css">

<h2>Vouchers Overview</h2>
<div *ngIf="spinnerCheck && !error" class="centered_spinner" >
  <mat-spinner diameter="50"></mat-spinner>
</div>
<hr>
<div [ngClass]="{'disableBackground': this.overlay}">
  <div id="overview" *ngIf="showpanel == 'overview'">
    <h3 style="padding-bottom: 25px;">Current Voucher <a style="float:right" [ngClass]="loading ? 'isDisabled' : ''"
        class="md-button" (click)="issueVoucherWizard()"
        *ngIf="(this.userPermissions.includes('editVouchers') || this.userPermissions.includes('admin'))">Issue New
        Voucher</a></h3>
    <mat-spinner *ngIf="loading && !error" diameter="50" style="margin: 0 auto;"></mat-spinner>
    <ng-container *ngIf="failure">
      <div>
        <p class="bodytext">
          There was a failure loading your vouchers! Please try again!
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="!loading">
      <div *ngIf="currentVouchers.length === 0 && !failure">
        <p class="bodytext">
          There are no current vouchers for this household.
        </p>
      </div>
      <div *ngIf="currentVouchers.length != 0">
        <div *ngFor="let voucher of currentVouchers" style="padding-bottom: 10px;">
          <mat-card>
            <div layout="row" class="layout-row">
              <div flex="70" class="text-left marg-left-1 flex-70 vouchercode">
                <a (click)="selectedIssuedVoucher(voucher)">{{ voucher.voucherNumber }}</a>
              </div>
              <p flex="15" class="text-right marg-right-1 flex-15 currentVoucherLabel"><strong>Current
                  Status:&nbsp;</strong></p>
              <p flex="15" class="ng-binding flex-15 currentVoucherLabel">{{ (voucher.state === 'ISSUED' ? 'Issued' :
                (voucher.state === 'LEASED' ? 'Leased' : (voucher.state === 'SHOP_MODE' ? 'Shop Mode' : (voucher.state
                === 'TERMINATED_WITH_OUT_LEASING' ? 'Terminated Without Leasing' : (voucher.state === 'TERMINATED' ?
                'Terminated' : selectedVoucher.state))))) }}</p>
            </div>
            <div layout="row" class="layout-row">
              <p flex="10" class="text-left marg-left-1 flex-10 currentVoucherLabel"><strong>Date Issued:&nbsp;</strong>
              </p>
              <p flex="30" class="ng-binding currentVoucherLabel">{{ dateToDisplay(voucher.issuedOn) }}</p>
              <p *ngIf="checkIfVouchersAreExpired(voucher)" flex="10"
                class="text-left marg-left-1 flex-10 currentVoucherLabel"><strong>Expiration:&nbsp;</strong></p>
              <p *ngIf="checkIfVouchersAreExpired(voucher)" flex="30" class="ng-binding flex-30 currentVoucherLabel"
                style="color: red">{{ dateToDisplay(voucher.expiresOn)}}</p>
            </div>
          </mat-card>
        </div>
      </div>
    </ng-container>
    <h3>Historical Vouchers</h3>
    <mat-spinner *ngIf="loading && !error" diameter="50" style="margin: 0 auto;"></mat-spinner>
    <ng-container *ngIf="failure">
      <div>
        <p class="bodytext">
          There was a failure loading your vouchers! Please try again!
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="!loading">
      <p class="bodytext" *ngIf="voucherList.length == 0 && !failure">There are no historical vouchers for this
        household</p>
      <mat-table [dataSource]="voucherList" *ngIf="voucherList.length != 0">
        <ng-container *ngFor="let column of columnsHouseholdVoucherHistory" [matColumnDef]="column.columnDef">
          <mat-header-cell *matHeaderCellDef style="font-size: 16px;">{{ column.header }}</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ column.cell(row) }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef class="historyHeader"> &nbsp; </mat-header-cell>
          <mat-cell *matCellDef="let row" class="viewButton">
            <button mat-button>
              <a (click)="selectHistoryVoucher(row)">View</a>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedHouseholdVoucherHistory"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedHouseholdVoucherHistory" style="FONT-WEIGHT: 100;"></mat-row>
      </mat-table>
    </ng-container>
  </div>


  <div id="viewvoucher" *ngIf="showpanel == 'viewvoucher'">
    <div>
      <a (click)="showpanel = 'overview'; loading = false"><span class="arrowalignLabel backToOverviewLink">
          < Back To Vouchers Overview</span></a>
    </div>
    <hr class="throughTout ng-scope" />
    <h3>Voucher Number: {{ selectedVoucher.voucherNumber }}</h3>

    <div>
      <a [ngClass]="showpanelview == 'details' ? 'overview-voucherdetailsselect' : ''"
        (click)="showpanelview = 'details'">Details</a> |
      <a [ngClass]="showpanelview == 'rfta' ? 'overview-voucherdetailsselect' : ''"
        (click)="showpanelview = 'rfta'">RFTA</a> |
      <a [ngClass]="showpanelview == 'history' ? 'overview-voucherdetailsselect' : ''"
        (click)="showpanelview = 'history'">History</a>
    </div>

    <div id="details" *ngIf="showpanelview == 'details'" [@fadeUpAnimation]>
      <div class="section-header overview-heading">General Information</div>
      <div layout="row" class="layout-row overview-voucherdetails-firstrow">
        <p flex="15" class="text-right marg-right-1 flex-15"><strong>Funding Program*</strong></p>
        <p flex="30" class="ng-binding flex-30">{{ selectedVoucher.program ? selectedVoucher.program.name : null }}</p>
      </div>
      <div layout="row" class="layout-row">
        <p flex="15" class="text-right marg-right-1 flex-15"><strong>Funding Project*</strong></p>
        <p flex="30" class="ng-binding flex-30">{{ selectedVoucher.project ? selectedVoucher.project.name : null }}</p>
      </div>
      <div layout="row" class="layout-row">
        <p flex="15" class="text-right marg-right-1 flex-15"><strong>Voucher Number*</strong></p>
        <p flex="30" class="ng-binding flex-30">{{ selectedVoucher.voucherNumber }}</p>
      </div>
      <div layout="row" class="layout-row">
        <p flex="15" class="text-right marg-right-1 flex-15"><strong>Bedrooms*</strong></p>
        <p flex="30" class="ng-binding flex-30">{{ selectedVoucher.authorizedBedrooms }}</p>
      </div>
      <div layout="row" class="layout-row">
        <p flex="15" class="text-right marg-right-1 flex-15"><strong>Date Issued*</strong></p>
        <p flex="30" class="ng-binding flex-30">{{ dateToDisplay(selectedVoucher.issuedOn)}}</p>
      </div>
      <div layout="row" class="layout-row">
        <p flex="15" class="text-right marg-right-1 flex-15"><strong>Expiration Date</strong></p>
        <p flex="30" class="ng-binding flex-30">{{ dateToDisplay(selectedVoucher.expiresOn)}}</p>
      </div>
      <div layout="row" class="layout-row">
        <p flex="15" class="text-right marg-right-1 flex-15"><strong>Issued Days</strong></p>
        <p flex="30" class="ng-binding flex-30">{{ updateIssuedDays(selectedVoucher.issuedOn, selectedVoucher.expiresOn)
          }}</p>
      </div>
      <div layout="row" class="layout-row">
        <p flex="15" class="text-right marg-right-1 flex-15"><strong>Unit</strong></p>
        <p flex="30" class="ng-binding flex-30">{{ unitNameDisplay(selectedVoucher.unit)}}</p>
      </div>
      <div layout="row" class="layout-row" *ngIf="selectedVoucher.unit?.landlord">
        <p flex="15" class="text-right marg-right-1 flex-15"><strong>Landlord</strong></p>
        <p flex="30" class="ng-binding flex-30">{{ selectedVoucher.unit.landlord.companyName ?
          selectedVoucher.unit.landlord.companyName 
          : selectedVoucher.unit.landlord.firstName + " " + selectedVoucher.unit.landlord.lastName}}</p>
      </div>
      <div layout="row" class="layout-row">
        <p flex="15" class="text-right marg-right-1 flex-15"><strong>Status*</strong></p>
        <p flex="30" class="ng-binding flex-30">{{ (selectedVoucher.state === 'ISSUED' ? 'Issued' :
          (selectedVoucher.state === 'LEASED' ? 'Leased' : (selectedVoucher.state === 'SHOP_MODE' ? 'Shop Mode' :
          (selectedVoucher.state === 'TERMINATED_WITH_OUT_LEASING' ? 'Terminated Without Leasing' :
          (selectedVoucher.state === 'TERMINATED' ? 'Terminated' : selectedVoucher.state))))) }}</p>
      </div>
      <div>
        <a class="md-button overview-button" (click)="showEditVoucher('edit')"
          *ngIf="showEditVoucherButton && (this.userPermissions.includes('editVouchers') || userPermissions.includes('admin'))">Edit
          Voucher</a>
      </div>
    </div>

    <div id="rfta" *ngIf="showpanelview == 'rfta'" [@fadeUpAnimation]>
      <div class="section-header overview-heading">Request for Tenancy Approval</div>
      <ng-container *ngIf="tenancyRequests.length > 0">
        <mat-table [dataSource]="tenancyRequests">
          <ng-container *ngFor="let column of columnsSelectedVoucherRFTA" [matColumnDef]="column.columnDef">
            <mat-header-cell *matHeaderCellDef style="font-size: 14px;">{{ column.header }}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ column.cell(row) }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> &nbsp; </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-button [disabled]="isHistoricalVoucher(selectedVoucher.state) ? 'isDisabled' : ''">
                <a [ngClass]="isHistoricalVoucher(selectedVoucher.state) || !isLatestRTFA(row) ? 'isDisabled' : ''"
                  (click)="showEditRFTADialog(row, true)">
                  <mat-icon class="fa fa-pencil"></mat-icon>
                </a>
              </button>
              <button mat-button [disabled]="isHistoricalVoucher(selectedVoucher.state) ? 'isDisabled' : ''">
                <a [ngClass]="isHistoricalVoucher(selectedVoucher.state) ? 'isDisabled' : ''" (click)="deleteRFTA(row)">
                  <mat-icon class="fa fa-trash-o fa-lg"></mat-icon>
                </a>
              </button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumnsSelectedVoucherRFTA"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsSelectedVoucherRFTA"></mat-row>
        </mat-table>
      </ng-container>
      <ng-container *ngIf="!this.voucherHasUnit && this.issuedVoucher">
        <div style="padding: 3rem; text-align: center;">
          <p class="bodytext">
            "There is no unit on the voucher. In order to start a RFTA action, a voucher must be tied to a unit that has
            a landlord association, and be in an Issued or Shop Mode status."
          </p>
        </div>
      </ng-container>
      <ng-container *ngIf="tenancyRequests.length === 0 && (this.voucherHasUnit || !this.issuedVoucher )">
        <div style="padding: 3rem; text-align: center;">
          <p class="bodytext">
            No requests associated with voucher
          </p>
        </div>
      </ng-container>
      <div *ngIf="this.userPermissions.includes('scheduleInspection') || this.userPermissions.includes('admin')">
        <a class="md-button overview-button" [ngClass]="isHistoricalVoucher(selectedVoucher.state) ? 'isDisabled' : ''"
          href="/maintenance/inspection/create">Schedule Inspection</a>
      </div>
      <div *ngIf="this.userPermissions.includes('rftaRecords') || this.userPermissions.includes('admin')">
        <a class="md-button overview-button"
          [ngClass]="isHistoricalVoucher(selectedVoucher.state) || shouldDisableAddingNewRFTARecord() ? 'isDisabled' : ''"
          (click)="showEditRFTADialog({}, false)">Add New RFTA Record</a>
      </div>
    </div>

    <div id="history" *ngIf="showpanelview == 'history'" [@fadeUpAnimation]>
      <br />
      <mat-table [dataSource]="voucherEventHistory">
        <ng-container *ngFor="let column of columnsSelectedVoucherHistory" [matColumnDef]="column.columnDef">
          <mat-header-cell *matHeaderCellDef style="font-size: 16px;">{{ column.header }}</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ column.cell(row) }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> &nbsp; </mat-header-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumnsSelectedVoucherHistory"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsSelectedVoucherHistory"></mat-row>
      </mat-table>
    </div>
  </div>
</div>