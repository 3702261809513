import {Component} from '@angular/core';

@Component({
  selector: 'app-countdown-popup',
  templateUrl: './countdown-popup.component.html',
  styleUrls: ['./countdown-popup.component.scss'],
})
export class CountdownPopupComponent {
  countDownTimer = 60 * 5;
  minsToDisplay = '';
  secondsToDisplay = '';

  updateCountDown(): void {
    this.countDownTimer -= 1;
    this.minsToDisplay =
      Math.trunc(this.countDownTimer / 60) > 10
        ? Math.trunc(this.countDownTimer / 60).toString()
        : '0' + Math.trunc(this.countDownTimer / 60);
    this.secondsToDisplay = this.countDownTimer % 60 > 10 ? (this.countDownTimer % 60).toString() : '0' + (this.countDownTimer % 60);
  }
}
