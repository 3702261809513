import {Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UserService} from '../services/user.service';

/**
 * Custom directive for hasPermission in the new Angular application
 * Closely resembles the legacy directive with Observables
 *
 * // How to use: *hasPermission="'admin'"
 */
@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective implements OnInit, OnDestroy {
  @Input() hasPermission: string;

  stop = new Subject();

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private userService: UserService) {}

  ngOnInit(): void {
    if (Object.keys(this.userService.details).length > 0) {
      if (this.userService.isAdmin || this.permissionExists(this.userService.permissions, this.hasPermission)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    } else {
      this.userService
        .getUser()
        .pipe(takeUntil(this.stop))
        .subscribe(user => {
          this.userService.setUserDetails(user);

          if (!user) {
            this.viewContainer.clear();
          }

          if (this.userService.isAdmin || this.permissionExists(user.permissions, this.hasPermission)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainer.clear();
          }
        });
    }
  }

  // when using this has-permission directive, if the user has any of the permissions they get the feature; uses OR logic
  private permissionExists(permissions: string[], permission: string) {
    if (!permission.includes(' || ') && !permission.includes(' && ')) {
      return permissions.includes(permission);
    }

    const permissionList = [];
    let permissionsNecessary;

    if (permission.includes(' || ')) {
      permissionsNecessary = permissionList.concat(permission.split(' || '));
      return permissionsNecessary.some(permission => permissions.includes(permission));
    }

    if (permission.includes(' && ')) {
      permissionsNecessary = permissionList.concat(permission.split(' && '));
      return permissionsNecessary.every(permission => permissions.includes(permission));
    }

    // This is a catch all and just assumes no permission.
    // Should never run but just in case someone does something odd
    // like nullish coalescing (e.g. ' ?? ')
    return false;
  }

  ngOnDestroy(): void {
    this.stop.next();
  }
}
