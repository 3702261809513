import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {KansoCommonModule} from './../../kanso-common/kanso-common.module';
import {VendorsComponent} from './vendors/vendors.component';
import {LandlordPropertiesComponent} from './vendors/landlord-properties/landlord-properties.component';
import {VendorService} from '../vendor/vendor.service';

import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatSelectModule} from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';
import {CoreModule} from '../../core/core.module';
import {PublicHousingActionComponent} from './household-actions/public-housing-action/public-housing-action.component';

@NgModule({
  declarations: [VendorsComponent, LandlordPropertiesComponent, PublicHousingActionComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    KansoCommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatFormFieldModule,
    MatCardModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatSelectModule,
    MatExpansionModule,
    CoreModule,
  ],
  providers: [VendorService],
})
export class OccupancyModule {}
