import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';

export interface Report {
  name: string;
  categoryName: string;
  groupName: string;
  isCustom: boolean;
  fullName: string;
  customerId?: string;
  customerName?: string;
  isFavorite?: boolean;
  scope: string;
}

export interface Category {
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class TelerikReports {
  header: any;
  baseService: string;
  stage: string;
  telerikURI: string;

  constructor(public http: HttpClient) {}

  getTelerikCatagories(): Observable<any> {
    this.baseService = sessionStorage.getItem('BASE_SERVICE_URI');
    this.telerikURI = sessionStorage.getItem('TELERIK_SERVICE_URI');
    return this.getAllReports<Report>(`${this.telerikURI}`);
  }

  getAllReports<T>(method: string): Observable<HttpEvent<T>> {
    const cookies = decodeURIComponent(document.cookie).split(';');
    const currentJWT = cookies.filter(x => x.indexOf('.idToken') > 0)[0].split('=')[1];
    this.header = {
      headers: {
        Authorization: `Bearer ${currentJWT}`,
      },
    };
    try {
      return this.http.get<T>(method, this.header);
    } catch (error) {
      console.log(`error in get ${error}`);
    }
  }
}
