/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-failure-load-form-popup',
  templateUrl: './failure-load-form-popup.component.html',
  styleUrls: ['./failure-load-form-popup.component.scss'],
})
export class FailureLoadFormPopupComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FailureLoadFormPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {}
}
