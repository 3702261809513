<mat-card class="knso-card office-location-container">
    <mat-card-header>
        <mat-toolbar>
            <span>{{(editMode ? 'Edit':'Add')}} - Office Location</span>
            <button mat-icon-button (click)="cancel()"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
    </mat-card-header>
    <form #editOfficeForm="ngForm" novalidate>
        <mat-card-content>
            <div class="layout-row">
                <mat-form-field appearance="outline" class="largeInputBox">
                    <mat-label>Office Name</mat-label>
                    <input 
                        matInput  
                        ngModel 
                        [(ngModel)]="editOfficeLocation.name"
                        #officeName="ngModel"
                        name="officeName"
                        id="officeNameInput"
                        type="text"
                        required
                        aria-label="Office Name">
                </mat-form-field>
            </div>
            <div class="layout-row">
                <mat-form-field appearance="outline" class="largeInputBox">
                    <mat-label>Office Address</mat-label>
                    <input 
                        matInput  
                        ngModel 
                        [(ngModel)]="editOfficeLocation.address[0].streetAddress"
                        #officeName="ngModel"
                        name="officeAddress"
                        id="officeAddressInput"
                        type="text"
                        required
                        aria-label="Office Address">
                </mat-form-field>
                <mat-form-field appearance="outline" class="medInputBox">
                    <mat-label>Unit</mat-label>
                    <input
                        matInput  
                        ngModel 
                        [(ngModel)]="editOfficeLocation.address[0].unit"
                        #officeName="ngModel"
                        name="unit"
                        id="unitInput"
                        type="text"
                        aria-label="Unit">
                </mat-form-field>
            </div>
            <div class="layout-row">
                <mat-form-field appearance="outline" class="medInputBox">
                    <mat-label>City</mat-label>
                    <input 
                        matInput  
                        ngModel 
                        [(ngModel)]="editOfficeLocation.address[0].city"
                        #officeName="ngModel"
                        name="city"
                        id="cityInput"
                        type="text"
                        required
                        aria-label="City"
                        >
                </mat-form-field>
                <mat-form-field appearance="outline" class="medInputBox">
                    <mat-label>State</mat-label>
                    <input 
                        matInput  
                        ngModel 
                        [(ngModel)]="editOfficeLocation.address[0].state"
                        #officeState="ngModel"
                        name="officeState"
                        id="officeStateInput"
                        type="text"
                        required
                        aria-label="Office State"
                        >
                </mat-form-field>
                <mat-form-field appearance="outline" class="medInputBox">
                    <mat-label>Postal Code</mat-label>
                    <input
                        matInput  
                        ngModel 
                        [(ngModel)]="editOfficeLocation.address[0].postalCode"
                        #officePostalCode="ngModel"
                        name="officePostalCode"
                        id="officePostalCodeInput"
                        type="text"
                        required
                        aria-label="Office State Postal Code"
                        >
                </mat-form-field>
            </div>

            <div class="layout-row addPadding">
                <mat-checkbox 
                    name="cashAccepted" 
                    ngModel 
                    [(ngModel)]="editOfficeLocation.acceptCash"
                    aria-label="Accept Cash?" 
                    id="officeCashCheckbox">
                    Accept Cash?
                </mat-checkbox>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button 
                type="button"
                mat-flat-button 
                class="hds_button cancel" 
                (click)="cancel()" 
                id="cancelButton">
                Cancel
            </button>
            <button 
                type="button"
                mat-flat-button 
                class="hds_button" 
                (click)="onSubmit()" 
                [disabled]="!editOfficeForm.valid || editOfficeForm.pristine"
                id="saveButton">
                Update
            </button>
        </mat-card-actions>
    </form>
</mat-card>
