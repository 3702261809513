import {
  BatchHeaderInput,
  BatchTrailerInput,
  FileHeaderInput,
  FileTrailerInput,
  PPDAndCCDEntryInput,
  PPDCCDAndWEBAddendaRecordsInput,
  WebAndTelEntryInput,
} from './nacha-model';
import moment from 'moment';
export class FileHeaderRecord {
  readonly recordTypeCode: string; // 1 | "1"
  readonly priorityCode: string; // 2 | "01"
  immediateDestinationRoutingNumber: string; // 10 |  preceded by a blank + "9 digit number"
  immediateOriginNumber: string; //  10 |  preceded by a blank + "9 digit number"
  readonly fileCreationDate: string; // 6 | YYMMDD format
  readonly fileCreationTime: string; // 4 | HHMM format
  fileIdModifier: string; // 1 | A-Z, 0-9
  readonly recordSize: string; // 3 |"094"
  readonly blockingFactor: string; // 2 | "10"
  readonly formatCode: string; // 1 | "1" for standard NACHA format
  immediateDestinationName: string; //23
  immediateOriginName: string; //23
  referenceCode?: string; // 8 | optional
  constructor(protected input: FileHeaderInput) {
    this.recordTypeCode = '1';
    this.priorityCode = '01';
    this.immediateDestinationRoutingNumber = stringGenerator(input.immediateDestinationRoutingNumber, 10, true);
    this.immediateOriginNumber = stringGenerator(input.immediateOriginNumber, 10, true);
    this.fileCreationDate = timeConverter('YYMMDD');
    this.fileCreationTime = timeConverter('HHmm');
    this.fileIdModifier = input.fileIdModifier;
    this.recordSize = '094';
    this.blockingFactor = '10';
    this.formatCode = '1';
    this.immediateDestinationName = stringGenerator(input.immediateDestinationName, 23);
    this.immediateOriginName = stringGenerator(input.immediateOriginName, 23);
    this.referenceCode = input.referenceCode ? stringGenerator(input.referenceCode, 8, false, true) : stringGenerator('', 8);
  }
}

export class FileTrailerRecord {
  readonly recordTypeCode: string; // 1 | "9"
  batchCount: string; // 6
  blockCount: string; // 6
  entryOrAddendaCount: string; // 8
  entryHash: string; //10
  totalDebitEntry: string; //12
  totalCreditEntry: string; //12
  readonly reserved: string; //39 | blanks
  constructor(protected input: FileTrailerInput) {
    this.recordTypeCode = '9';
    this.batchCount = stringGenerator(input.batchCount, 6, false, true);
    this.blockCount = stringGenerator(input.blockCount, 6, false, true);
    this.entryOrAddendaCount = stringGenerator(input.entryOrAddendaCount, 8, false, true);
    this.entryHash = stringGenerator(input.entryHash, 10, false, true);
    this.totalDebitEntry = stringGenerator(input.totalDebitEntry, 12, false, true);
    this.totalCreditEntry = stringGenerator(input.totalCreditEntry, 12, false, true);
    this.reserved = stringGenerator('', 39);
  }
}

export class BatchHeaderRecord {
  readonly recordTypeCode: string; // 1 | "5"
  serviceClassCode: string; // 3 | Service class codes enum interfaces
  companyName: string; // 16
  companyDiscretionaryData: string; // 20
  companyIdentification: string; // 10
  standardEntryClassCode: string; // 3 || SEC codes
  companyEntryDescription: string; // 10
  companyDescriptiveDate: string; // 6 YYMMDD
  effectiveEntryDate: string; // 6 YYMMDD
  readonly settlementDate: string; // 3 | leave blank
  originatorStatusCode: string; // 1
  originatingDFIIdentification: string; // 8
  batchNumber: string; // 7
  constructor(protected input: BatchHeaderInput) {
    this.recordTypeCode = '5';
    this.serviceClassCode = input.serviceClassCode;
    this.companyName = stringGenerator(input.companyName, 16);
    this.companyDiscretionaryData = input.companyDiscretionaryData
      ? stringGenerator(input.companyDiscretionaryData, 20)
      : stringGenerator('', 20);
    this.companyIdentification = stringGenerator(input.companyIdentification, 10, true);
    this.standardEntryClassCode = input.standardEntryClassCode;
    this.companyEntryDescription = input.companyEntryDescription
      ? stringGenerator(input.companyEntryDescription, 10)
      : stringGenerator('', 10);
    this.companyDescriptiveDate = input.companyDescriptiveDate
      ? timeConverter('YYMMDD', input.companyDescriptiveDate)
      : stringGenerator('', 6);
    this.effectiveEntryDate = timeConverter('YYMMDD', input.effectiveEntryDate);
    this.settlementDate = stringGenerator('', 3);
    this.originatorStatusCode = input.originatorStatusCode;
    this.originatingDFIIdentification = input.originatingDFIIdentification.toString().substring(0, 8);
    this.batchNumber = stringGenerator(input.batchNumber, 7, false, true);
  }
}

export class BatchTrailerRecord {
  readonly recordTypeCode: string; // 1 | "8"
  serviceClassCode: string; // 3 | Service class codes enum interfaces
  entryOrAddendaCount: string; // 8
  entryHash: string; // 10
  totalDebitEntry: string; //12
  totalCreditEntry: string; //12
  companyIdentification: string; // 10
  messageAuthenticationCode: string; // 19
  readonly reserved: string; //6 | blanks
  originatingDFIIdentification: string; // 8
  batchNumber: string; // 7
  constructor(protected input: BatchTrailerInput) {
    this.recordTypeCode = '8';
    this.serviceClassCode = input.serviceClassCode;
    this.entryOrAddendaCount = stringGenerator(input.entryOrAddendaCount, 6, false, true);
    this.entryHash = stringGenerator(input.entryHash, 10, false, true);
    this.totalDebitEntry = stringGenerator(input.totalDebitEntry, 12, false, true);
    this.totalCreditEntry = stringGenerator(input.totalCreditEntry, 12, false, true);
    this.companyIdentification = stringGenerator(input.companyIdentification, 10, true);
    this.messageAuthenticationCode = input.messageAuthenticationCode
      ? stringGenerator(input.messageAuthenticationCode, 19, false, true)
      : stringGenerator('', 19);
    this.reserved = stringGenerator('', 6);
    this.originatingDFIIdentification = input.originatingDFIIdentification.substring(0, 8);
    this.batchNumber = stringGenerator(input.batchNumber, 7, false, true);
  }
}

export class PPDAndCCDEntry {
  readonly recordTypeCode: string; // 1 | "6"
  transactionCode: string; // 2
  receivingDFIIdentification: string; // 8
  checkDigit: string; // 1
  dFIAccountNumber: string; // 17
  amount: string; // 10
  identificationNumber: string; // 15
  receivingIndividualOrCompanyName: string; // 22
  discretionaryData: string; // 2
  readonly addendaRecordIndicator: string; // 1 | "1"
  traceNumber: string; // 15 | unique identify number (the first 8 is first 8 digits of your Originating DFI number, follow 7 will be diffrent than other)
  sequenceNumber: string;
  constructor(protected input: PPDAndCCDEntryInput) {
    this.recordTypeCode = '6';
    this.transactionCode = input.transactionCode.toString();
    this.receivingDFIIdentification = input.receivingDFIIdentification.toString().substring(0, 8);
    this.checkDigit = input.checkDigit.charAt(input.checkDigit.length - 1);
    this.dFIAccountNumber = stringGenerator(input.dFIAccountNumber, 17);
    this.amount = stringGenerator(input.amount, 10, false, true);
    this.identificationNumber = input.identificationNumber ? stringGenerator(input.identificationNumber, 15) : stringGenerator('', 15);
    this.receivingIndividualOrCompanyName = stringGenerator(input.receivingIndividualOrCompanyName, 22);
    this.discretionaryData = input.discretionaryData ? stringGenerator(input.discretionaryData, 2) : stringGenerator('', 2);
    this.addendaRecordIndicator = '0';
    this.traceNumber = input.traceNumber.substring(0, 8);
    this.sequenceNumber = stringGenerator(input.sequenceNumber, 7, false, true);
  }
}

export class PPDCCDAndWEBAddendaRecords {
  readonly recordTypeCode: string; // 1 | "7"
  readonly addendaTypeCode: string; //2 | "05"
  paymentRelatedInformation: string; // 80
  readonly addendaSequenceNumber: string; // 4 | "1"
  entryDetailSequenceNumber: string; // 7
  constructor(protected input: PPDCCDAndWEBAddendaRecordsInput) {
    this.recordTypeCode = '7';
    this.addendaTypeCode = '5';
    this.paymentRelatedInformation = input.paymentRelatedInformation
      ? stringGenerator(input.paymentRelatedInformation, 80)
      : stringGenerator('', 80);
    this.addendaSequenceNumber = stringGenerator(1, 4);
    this.entryDetailSequenceNumber = input.entryDetailSequenceNumber.slice(-7); //last 7 digit of trace number so it could be update
  }
}

export class WebAndTelEntry {
  readonly recordTypeCode: string; // 1 | "6"
  transactionCode: string; // 2 | must be one of transactionCode enum
  receivingDFIIdentification: string; // 8
  checkDigit: string; // 1
  dFIAccountNumber: string; // 17 | account number of recipents
  amount: string; // 10
  individualIdentificationNumber: string; // 15 | optional
  individualName: string; // 22
  paymentTypeCode: string; // 2 | "S"
  addendaRecordIndicator: string; // 1 | "0" for Web || "0" or "1" for Tell
  traceNumber: string; // 15
  sequenceNumber: string;
  constructor(protected input: WebAndTelEntryInput) {
    this.recordTypeCode = '6';
    this.transactionCode = input.transactionCode.toString();
    this.receivingDFIIdentification = input.receivingDFIIdentification.toString().substring(0, 8);
    this.checkDigit = input.checkDigit.charAt(input.checkDigit.length - 1);
    this.dFIAccountNumber = stringGenerator(input.dFIAccountNumber, 17);
    this.amount = stringGenerator(input.amount, 10, false, true);
    this.individualIdentificationNumber = input.individualIdentificationNumber
      ? stringGenerator(input.individualIdentificationNumber, 15)
      : stringGenerator('', 15);
    this.individualName = stringGenerator(input.individualName, 22);
    this.paymentTypeCode = input.paymentTypeCode;
    this.addendaRecordIndicator = input.addendaRecordIndicator;
    this.traceNumber = input.traceNumber.substring(0, 8);
    this.sequenceNumber = stringGenerator(input.sequenceNumber, 7, false, true);
  }
}

function stringGenerator(text: string | number, length: number, isPadLeft?: boolean, isNumberWithZero?: boolean): string {
  const textLen = typeof text == 'string' ? text.length : Math.floor(Math.log10(text)) + 1;
  if (textLen < length) {
    if (isPadLeft) {
      return ' '.repeat(length - (typeof text == 'string' ? text.length : Math.floor(Math.log10(text)) + 1)) + text;
    }
    if (isNumberWithZero) {
      return '0'.repeat(length - (text != 0 ? Math.floor(Math.log10(<number>text)) + 1 : 1)) + text;
    }
    return text + ' '.repeat(length - (typeof text == 'string' ? text.length : Math.floor(Math.log10(text)) + 1));
  } else {
    const returnVal = typeof text == 'string' ? text.substring(0, length) : text.toString().substring(0, length);
    return returnVal;
  }
}

function timeConverter(format: string, dateInput?) {
  if (dateInput) {
    return moment(dateInput).format(format);
  }
  return moment().format(format);
}
