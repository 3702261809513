/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {from, Subscription} from 'rxjs';

import {ErrorPopupComponent} from '../error-popup/error-popup.component';
import {IExportedActionsBatch} from './exported-actions-interface';
import {PICActionsDataService} from './exported-actions-data-service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FormControl} from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'app-exported-actions',
  templateUrl: './exported-actions.component.html',
  styleUrls: ['./exported-actions.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          left: '0px',
          top: '190px',
          backgroundColor: '#fff',
          zIndex: 2,
        })
      ),
      state(
        'closed',
        style({
          left: '-1300px',
          top: '190px',
          opacity: 0,
          backgroundColor: '#fff',
          zIndex: 2,
        })
      ),
      transition('open => closed', [animate('1s')]),
      transition('closed => open', [animate('0.3s')]),
    ]),
  ],
})
export class ExportedActionsComponent implements OnInit, OnDestroy {
  @Input() picBatchHouseholdIds: string;
  exportedByUsers = new FormControl();
  private subscriptions: Subscription = new Subscription();
  exportedActionsBatch: IExportedActionsBatch[] = [];
  exportedActionsDetail: IExportedActionsBatch[] = [];
  fromDateFilteredExportBatch: IExportedActionsBatch[] = [];
  toDateFilteredExportBatch: IExportedActionsBatch[] = [];
  userFilteredExport: IExportedActionsBatch[] = [];
  allExportedActionsBatch: IExportedActionsBatch[] = [];
  userFilteredExportBatch: IExportedActionsBatch[] = [];
  showFilter = false;
  _fNameFilter: string;
  _lNameFilter: string;
  _ssnFilter: string;
  errorMessage: string;
  showPanelView: any = 'exported-actions';
  loggedInUser: string;
  loading = true;
  allUsers: string[] = [];
  filteredAllUsers: IExportedActionsBatch[] = [];
  startDate: moment.Moment;
  convertedEffectiveDate: Date;
  endDate: moment.Moment;
  overlay: boolean;

  displayedColumns: string[] = ['Export', 'Exported On', 'Exported By', 'Actions', 'Download'];
  picBatchFile: string;
  constructor(
    private picActionsDataService: PICActionsDataService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private dialog: MatDialog
  ) {
    this.matIconRegistry.addSvgIconLiteral(
      'filter-icon',
      this.domSanitizer.bypassSecurityTrustHtml(
        '<svg id="Filter_Icon" data-name="Filter Icon" xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14"><defs><style>.cls-1 {fill: #00aad4;}</style></defs><g><rect class="cls-1" x="5" width="6" height="6"/><rect id="Rectangle_9_copy" data-name="Rectangle 9 copy" class="cls-1" x="13" y="2" width="8" height="2"/><rect id="Rectangle_9_copy_2" data-name="Rectangle 9 copy 2" class="cls-1" y="2" width="3" height="2"/></g><g id="Group_1_copy" data-name="Group 1 copy"><rect class="cls-1" x="10" y="8" width="6" height="6"/><rect id="Rectangle_9_copy-2" data-name="Rectangle 9 copy" class="cls-1" y="10" width="8" height="2"/><rect id="Rectangle_9_copy_2-2" data-name="Rectangle 9 copy 2" class="cls-1" x="18" y="10" width="3" height="2"/></g></svg>'
      )
    );
    this.matIconRegistry.addSvgIconLiteral(
      'download-icon',
      this.domSanitizer.bypassSecurityTrustHtml(
        '<svg id="Download_Icon" data-name="Download Icon" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffa500"><g><rect fill="none" height="24" width="24"/><path d="M12,4c4.41,0,8,3.59,8,8s-3.59,8-8,8s-8-3.59-8-8S7.59,4,12,4 M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10 c5.52,0,10-4.48,10-10C22,6.48,17.52,2,12,2L12,2z M13,12l0-4h-2l0,4H8l4,4l4-4H13z"/></g></svg>'
      )
    );
  }

  /**
   * A helper function that only returns the unique historical export entries for a given site
   * This functions is leveraged as a guard against what the server side may return
   *
   * @param actions {IExportedActionsBatch[]}
   * @returns {IExportedActionsBatch[]} Only return single unique entries for exported historical actions
   */
  unique = (actions: IExportedActionsBatch[]): IExportedActionsBatch[] => {
    // Hoist the picBatchId to ensure we stay O(1) constant no matter how large this array grows and transform to object
    const actionsDefinedByHoistID = actions.map(action => ({[action.picBatchId]: action})).reduce((acc, curr) => ({...acc, ...curr}), {});

    // Make array a set from the ids and reference the key of that given object
    return Array.from(new Set(actions.map(action => action.picBatchId))).map(id => actionsDefinedByHoistID[id]);
  };

  async ngOnInit() {
    this.subscriptions.add(
      from(await this.picActionsDataService.getExportedActionsBatchs()).subscribe({
        next: exportedActionsGroups => {
          this.allExportedActionsBatch = exportedActionsGroups;
          this.exportedActionsBatch = this.unique(this.allExportedActionsBatch);
          this.loading = false;
        },
        error: err => {
          this.errorMessage = err;
          console.log('error message: ', this.errorMessage);
          this.errorPopup();
        },
      })
    );
    this.overlay = false;
  }

  showFilterToggle() {
    this.overlay = !this.overlay;
    this.showFilter = !this.showFilter;

    this.allUsers = this.allExportedActionsBatch.map(eachExportedBy => {
      return eachExportedBy.exportedByLastName;
    });
    this.filteredAllUsers = this.removeDuplicates(this.allUsers);
  }

  removeDuplicates(array) {
    return (array = array.filter((a, b) => b === array.indexOf(a)));
  }

  setStartDate(date: string) {
    this.startDate = moment(date);
  }
  setEndDate(date: string) {
    this.endDate = moment(date);
  }

  applyFilter() {
    this.loading = true;
    this.overlay = !this.overlay;
    this.showFilter = !this.showFilter;
    if (typeof this.startDate !== 'undefined' && this.startDate !== null) {
      this.fromDateFilteredExportBatch = this.allExportedActionsBatch.filter(fd => {
        const A = moment(fd.exportedOn);
        const B = moment(this.startDate);
        console.log(A.diff(B, 'days'));
        if (A.diff(B, 'days') > -1) {
          return fd;
        }
      });
    } else {
      this.fromDateFilteredExportBatch = this.allExportedActionsBatch;
    }

    if (typeof this.endDate !== 'undefined' && this.endDate !== null) {
      this.toDateFilteredExportBatch = this.fromDateFilteredExportBatch.filter(td => {
        const A = moment(td.exportedOn);
        const B = moment(this.endDate);
        if (A.diff(B, 'days') < 1) {
          return td;
        }
      });
    } else {
      this.toDateFilteredExportBatch = this.fromDateFilteredExportBatch;
    }

    if (this.exportedByUsers.pristine == false) {
      if (this.exportedByUsers.value.length !== 0) {
        this.userFilteredExportBatch = [];
        for (const users of this.exportedByUsers.value) {
          this.toDateFilteredExportBatch.filter(a => {
            if (users == a.exportedByLastName || this.exportedByUsers.value.length == 0) {
              this.userFilteredExportBatch.push(a);
            }
          });
        }
      } else {
        this.userFilteredExportBatch = this.toDateFilteredExportBatch;
      }
    } else {
      this.userFilteredExportBatch = this.toDateFilteredExportBatch;
    }

    this.exportedActionsBatch = this.userFilteredExportBatch;
    this.loading = false;
  }

  getExportedAction(row) {
    this.exportedActionsDetail = row;
    this.showPanelView = 'exported-actions-details';
  }

  getCurrentUsersLogInCookie() {
    const cookies = document.cookie.split(';');
    let usersAuthCookie;
    for (const cookie of cookies) {
      if (cookie.includes('LastAuthUser')) {
        //found the cookie of the user
        usersAuthCookie = cookie;
      }
    }
    const arrayOfCookieStrings = usersAuthCookie.split('=');
    this.loggedInUser = arrayOfCookieStrings[1];
  }

  async getExportedBatchById(batchId) {
    this.getCurrentUsersLogInCookie();
    const batchRequest = {
      batchId: batchId,
      user: '',
    };

    batchRequest.user = this.loggedInUser;
    this.subscriptions.add(
      from(await this.picActionsDataService.getExportedBatchById(batchRequest)).subscribe({
        next: (data: any) => {
          const downloadUrl = window.URL.createObjectURL(data);
          const link = document.createElement('a');

          link.href = downloadUrl;
          link.download = this.exportedActionsBatch.find(forms => forms.picBatchId == batchId).exportedFileName;

          link.click();
        },
        error: err => {
          this.errorMessage = err;
          console.log('error message: ', this.errorMessage);
          this.errorPopup();
        },
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  errorPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {errorMessage: this.errorMessage};
    this.dialog.open(ErrorPopupComponent, dialogConfig);
  }
}
