<section class="tabs__panel kanso-wizard-container">
    <div class="wizard-header">
        <p>Process Payables</p>
        <button mat-icon-button (click)="onCancelPayables()" ><mat-icon>close</mat-icon></button>
    </div>
    <mat-horizontal-stepper class="kanso-wizard" #stepper>
        <mat-step *ngFor="let info of stepWizardInfo; let i = index">
            <ng-template matStepLabel>
                <kanso-wizard-header [stepper]="stepper" [position]="i">{{info.stepHeader}}</kanso-wizard-header>
            </ng-template>
            <ng-container [ngSwitch]="info.stepTitle">
                <kanso-wizard-content *ngSwitchCase="'ACH Details'" [contentTitle]="info.stepTitle">
                    <div class="error-message">
                        <mat-icon>error</mat-icon>
                        <p class="error">The following vendors have ACH/Direct Deposit selected as the preferred method of payment.</p>
                    </div>
                     <payable-wizard-table [displayedColumns]="displayedTransactionColumns" [tableData]="achDirectDepositData"></payable-wizard-table>                       
                </kanso-wizard-content>
                <kanso-wizard-content *ngSwitchCase="'Checks Details'" [contentTitle]="info.stepTitle">
                    <div class="check-input-container" *ngIf="checksData.length > 0">
                        <p>Starting Check#</p>
                        <mat-form-field appearance="outline">
                            <input 
                                matInput
                                [(ngModel)]="checkNumber"             
                                name="checkNumber"
                                type="number"
                                />
                        </mat-form-field>
                        <mat-hint *ngIf="!checkNumber">Starting check number is required!</mat-hint>
                    </div>
                    <div class="error-message">
                        <mat-icon>error</mat-icon>
                        <p class="error">The following vendors have check selected as the preferred method of payment.</p>
                    </div>
                    <payable-wizard-table [displayedColumns]="displayedTransactionColumns" [tableData]="checksData"></payable-wizard-table>                       
                </kanso-wizard-content>
                <kanso-wizard-content *ngSwitchCase="'Other Details'" [contentTitle]="info.stepTitle">
                    <div class="error-message">
                        <mat-icon>error</mat-icon>
                        <p class="error">The following vendors have Other selected as the preferred method of payment.</p>
                    </div>
                    <payable-wizard-table [displayedColumns]="displayedTransactionColumns" [tableData]="otherData"></payable-wizard-table>                     
                </kanso-wizard-content>
                <kanso-wizard-content *ngSwitchCase="'Review Payable Details'" [contentTitle]="info.stepTitle">
                    <div class="error-message">
                        <mat-icon>error</mat-icon>
                        <p class="error">Once confirmed, files can be accessed from the historical payment record. In order for vendor accounts to receive credit adjustments, the final post must be made from the historical record.</p>
                    </div>
                    <payable-wizard-table [displayedColumns]="displayedReviewColumns" [tableData]="payableReview"></payable-wizard-table>                     
                </kanso-wizard-content>
            </ng-container>
        </mat-step>
       </mat-horizontal-stepper>
       <div class="wizard-footer">
        <button class="hds_button"  mat-flat-button (click)="goToPreviousStep()" *ngIf="stepper.selectedIndex !== 0">Back</button>
        <button class="hds_button" [disabled]="onCheckDisableNext(stepper)"  [ngClass]="stepper.selectedIndex === (stepper.steps.length -1) ? 'green': ''"  mat-flat-button (click)="goToNextStep()" >{{ stepper.selectedIndex === (stepper.steps.length -1) ? 'Submit':  'Next' }}</button>
      </div>
</section>