import {ILandlordAddress} from '../housing-core/services/housing-models';

export interface responseObject {
  data: responseVendorData;
  loading: boolean;
  networkStatus: number;
}

export interface responseVendorData {
  vendors: vendorData;
}

export interface vendorData {
  __typename: string;
  edges: vendorEdges[];
  pageInfo: qqlPageInfo;
  totalCount: number;
}

export interface vendorResponse {
  data: vendorNode;
  pageInfo: qqlPageInfo;
  totalCount: number;
}

export interface vendorNode {
  __typename: string;
  id: string;
  name: string;
  vendorType: string;
  address: string;
  companyName: string;
  firstName: string;
  lastName: string;
  externalReferenceId: string;
  addresses: ILandlordAddress[];
}

export interface vendorEdges {
  __typename: string;
  node: vendorNode;
  cursor: string;
}

export interface qqlPageInfo {
  __typename: string;
  startCursor: string;
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export enum VendorSortField {
  Name = 0,
  Type = 1,
}

export interface VendorAttributeCommand {
  id?: string;
  siteId: string;
  customerId: string;
  textValue?: string;
  booleanValue?: boolean;
  dateTimeValue?: Date;
  entityAttributeId?: string; //Id of the custom attributes in Mongo
  entityInstanceId?: string; //Guid Id field from SQL(Vendor Id)
}

export interface VendorDetail {
  id: string;
  vendorAccountId: string;
  amount: number;
  checkNumber: string;
  ppuCode: string;
  headFirstName: string;
  headLastName: string;
  postedOn: Date;
  postedBy: string;
  subLedgerMappingName: string;
  chargeTypeName: string;
  sequence: number;
  vendorAccountName: string;
  selected: boolean;
}
export interface PaymentDetail {
  landLord: string;
  vendorDetail: VendorDetail;
}

export interface VendorLedgerPayoutHistory {
  vendorId: string;
  batchId: number;
  chargeTypeName: string;
  postedOn: number;
  createdOn?: Date;
  checkNumber?: string;
  siteId: string;
  customerId: string;
  totalAmount: number;
  numberOfTransactions: number;
}
