<section class="tabs__panel layout-column" >
    <nav>
        <ul class="nav-pills">
          <li>
            <a [class]="getNavClass('general-ledger')" (click)="showPage = 'general-ledger'" routerLinkActive="active">General Ledger</a>
          </li>
          <li>
            <a [class]="getNavClass('subledger-maps')" (click)="showPage = 'subledger-maps'" routerLinkActive="active">Subledger Mapping</a>
          </li>
        </ul>
    </nav>
    <span *ngIf="showPage === 'general-ledger'">
        <div class="page-header">
            <h2>General Ledger Accounts</h2>

            <p> <span class="glyphicon glyphicon-warning-sign"></span> The <strong>General Ledger Accounts</strong> section lists the applicable accounts from the housing authority's general ledger that are used by HDS
            Doorways general ledger export feature. These must be set up in order to successfully export HDS Doorways activity to a third party general ledger.</p>
        </div>
        <section>
        <div class="tab-header">
            <mat-form-field appearance="outline">
                <mat-label>Sort By</mat-label>
                <mat-select [formControl]="accountSort">
                    <mat-option value="accountCode">Account Code</mat-option>
                    <mat-option value="name">Name</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <input 
                    matInput
                    [formControl]="accountFilter"
                    placeholder="search..."/>
            </mat-form-field>
            <div class="top-buttons">
                <button
                    mat-flat-button
                    (click)="editGeneralLedgerAccount('new')"
                    class="hds_button" 
                    style="margin: 20px 0;">
                    Add New
                </button>
            </div>    
        </div>
        </section>
        <section>
                <div *ngIf="this.loading">
                    <mat-spinner diameter="25" class="centered_spinner"> </mat-spinner> Loading account data
                </div>
                <div *ngIf="!this.loading">
                    <p *ngIf="(!glAccounts || !glAccounts.length) && !error ">There are no External Accounts configured</p>
                    <p *ngIf="!glAccounts || !glAccounts.length && error ">{{errorMsg}}</p>
                    <table class="kanso-table altRows" *ngIf="glAccounts && glAccounts.length">
                        <thead>
                            <tr class="centerData">
                                <th>Name</th>
                                <th>Account Code</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tr class="centerData"
                            *ngFor="let account of filteredAccounts$|async"> 
                            <td> {{account.name}}</td>
                            <td>
                                {{account.accountCode}}
                            </td>
                            <td class="actionColumn">
                                <mat-icon
                                    (click)="editGeneralLedgerAccount(account)"
                                >create</mat-icon>
                            </td>
                            <td class="actionColumn delete">
                                <mat-icon
                                    (click)="deleteGeneralLedgerAccount(account)"
                                >delete_outline</mat-icon>
                            </td>
                        </tr>
                    </table>
                </div>
        </section>
    </span>
    <span *ngIf="showPage === 'subledger-maps'">
        <subledger-mapping></subledger-mapping>
    </span>
</section> 


