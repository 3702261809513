/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {InMemoryCache} from '@apollo/client/cache';
import {ParameterStoreService, ParameterStoreVariables} from '../../core';
import {Observable} from 'rxjs';
import {ApolloQueryResult, FetchResult} from '@apollo/client/core';
import {HttpClient} from '@angular/common/http';
import {CoreService} from 'src/app/core/service/core.service';
import {BaseService} from 'src/app/kanso-common/core/service';

@Injectable()
export class AppConfig {
  testValiUrl: string;
  othertestVal: string;
}

export class VoucherService extends BaseService {
  baseURL = '/api/vouchers';
  baseURLRFTA = '/api/rfta';
  baseURL_Reporting = '/api/reporting-data-service/data/';
  baseURL_getUnitsBySearch = '/graphql'; //'/api/units/search';
  actionItems: any;
  apollo: Apollo;
  _http: any;
  public config: any;

  async setEnvironmentParameters(parameterStoreService: ParameterStoreService, parameters: ParameterStoreVariables[]): Promise<string[]> {
    const fetchedParameters = await parameterStoreService.fetchAndSetParameters(parameters);
    return fetchedParameters;
  }

  constructor(public http: HttpClient, public coreService: CoreService, private apolloProvider: Apollo) {
    super(http);
    this.apollo = this.apolloProvider;
    const commonHeaders: any = {
      'x-api-key': sessionStorage.getItem('OCCUPANCY_SVC_KEY'),
      'x-site-id': sessionStorage.getItem('SITEID'),
      'x-customer-id': sessionStorage.getItem('CUSTOMERID'),
      'x-token': this.header.headers['x-token'],
    };
    this.apollo.create(
      {cache: new InMemoryCache(), uri: sessionStorage.getItem('OCCUPANCY_SVC_GRAPHQL_URI'), headers: {...commonHeaders}},
      'occupancy'
    );
    this.apollo.create(
      {cache: new InMemoryCache(), uri: sessionStorage.getItem('VOUCHERS_SVC_GRAPHQL_ENDPOINT'), headers: {...commonHeaders}},
      'vouchers'
    );
  }

  setActionItems(input: any): void {
    this.actionItems = input;
  }

  loadActionItems(): any {
    return this.actionItems;
  }

  //TODO: needs unitId added
  getVoucherByHouseHoldId<T>(id: string): Observable<ApolloQueryResult<T>> {
    return this.apollo.use('vouchers').query({
      query: gql`
        query GetVoucherByHouseHoldId($id: String!) {
          vouchers(where: {householdId: {eq: $id}}) {
            edges {
              node {
                id
                voucherNumber
                voucherStateChangeEvents {
                  id
                  stateValue
                  changedOn
                  stateDisplay
                  effectiveDate
                  changedBy
                }
                siteId
                voucherType
                specialType
                customerId
                state
                program {
                  name
                  programCode
                  id
                }
                project {
                  name
                  projectCode
                  id
                }
                issuedOn
                householdId
                authorizedBedrooms
                leasedOn
                unitId
                unit {
                  id
                  streetAddress
                  unitNumber
                  apartmentNumber
                }
                createdOn
                expiresOn
                createdBy
                modifiedOn
                modifiedBy
                tenancyRequests {
                  id
                  requestStartOn
                  requestStopOn
                  archivedOn
                  result
                  modifiedOn
                  resultComments
                  createdOn
                  landlordId
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id,
      },
    });
  }
  // This only checks to see if a voucher exists. Used in the actions to quickly load the voucher number
  getVoucherByHouseHoldIdTrimmed<T>(id: string): Observable<ApolloQueryResult<T>> {
    return this.apollo.use('vouchers').query({
      query: gql`
        query GetVoucherByHouseHoldId($id: String!) {
          vouchers(where: {householdId: {eq: $id}}) {
            edges {
              node {
                id
                voucherNumber
                issuedOn
                householdId
                unitId
                state
                program {
                  name
                  programCode
                  id
                }
                project {
                  name
                  projectCode
                  id
                }
                siteId
                customerId
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id,
      },
    });
  }

  getProgramsAndProjects<T>(): Observable<ApolloQueryResult<T>> {
    //get the specific apollo client by name and its isntance
    return this.apollo.use('occupancy').query({
      query: gql`
        query GetProgramsAndProjects {
          programs(first: 50) {
            edges {
              node {
                id
                name
                programCode
                projects {
                  projectCode
                  name
                  id
                }
              }
            }
          }
        }
      `,
    });
  }

  changeVoucherStatus<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vouchers').mutate({
      mutation: gql`
        mutation Mutation($command: TransitionVoucherCommandInput!) {
          transitionVoucher(command: $command) {
            status
            failureReason
            affectedEntity {
              id
              voucherNumber
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  deleteRFTA<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vouchers').mutate({
      mutation: gql`
        mutation Mutation($command: DeleteRftaCommandInput!) {
          deleteRfta(command: $command) {
            status
            failureReason
            affectedEntity {
              id
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  editVoucher<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vouchers').mutate({
      mutation: gql`
        mutation Mutation($command: EditVoucherCommandInput!) {
          editVoucher(command: $command) {
            status
            failureReason
            affectedEntity {
              id
              voucherNumber
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  getVoucherTotalInProject<T>(id: string): Observable<ApolloQueryResult<T>> {
    //get the specific apollo client by name and its isntance
    return this.apollo.use('vouchers').query({
      query: gql`
        query GetVoucherByHouseHoldId($id: String!) {
          voucherCountByProject(projectId: $id) {
            count
          }
        }
      `,
      variables: {
        id,
      },
    });
  }

  getUnits<T>(id: string, name: string): Observable<ApolloQueryResult<T>> {
    //get the specific apollo client by name and its isntance
    return this.apollo.use('occupancy').query({
      query: gql`
        query GetUnits($id: String!, $name: String!) {
          units(where: {streetAddress: {contains: $name}, siteId: {eq: $id}, landlordId: {neq: null}}, order: {streetAddress: ASC}) {
            edges {
              node {
                id
                unitNumber
                apartmentNumber
                landlordId
                streetAddress
              }
            }
          }
        }
      `,
      variables: {
        id,
        name,
      },
    });
  }

  getUnit<T>(id: string): Observable<ApolloQueryResult<T>> {
    //get the specific apollo client by name and its isntance
    return this.apollo.use('occupancy').query({
      query: gql`
        query getUnit($id: String!) {
          units(where: {id: {eq: $id}}) {
            nodes {
              id
              streetAddress
              landlordId
              ppuCode
              unitNumber
              apartmentNumber
            }
          }
        }
      `,
      variables: {
        id,
      },
    });
  }

  getLandlordInfo<T>(id: string): Observable<ApolloQueryResult<T>> {
    //get the specific apollo client by name and its isntance
    return this.apollo.use('occupancy').query({
      query: gql`
        query landlordById($id: String!) {
          landlordById(id: $id) {
            id
            firstName
            lastName
            companyName
          }
        }
      `,
      variables: {
        id,
      },
    });
  }

  editRFTACommand<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vouchers').mutate({
      mutation: gql`
        mutation Mutation($command: EditRftaCommandInput!) {
          editRfta(command: $command) {
            status
            failureReason
            affectedEntity {
              id
              requestStartOn
              requestStopOn
              result
              modifiedOn
              resultComments
              landlordId
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  createRFTACommand<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vouchers').mutate({
      mutation: gql`
        mutation Mutation($command: CreateRftaCommandInput!) {
          createRfta(command: $command) {
            status
            failureReason
            affectedEntity {
              id
              requestStartOn
              requestStopOn
              createdOn
              result
              modifiedOn
              resultComments
              landlordId
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  createVoucher<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vouchers').mutate({
      mutation: gql`
        mutation Mutation($command: CreateVoucherCommandInput!) {
          createVoucher(command: $command) {
            status
            failureReason
            affectedEntity {
              id
              voucherNumber
              customerId
              siteId
              state
              program {
                name
              }
              project {
                name
              }
              issuedOn
              householdId
              authorizedBedrooms
              leasedOn
              unit {
                id
                streetAddress
              }
              createdOn
              expiresOn
              createdBy
              modifiedOn
              modifiedBy
              tenancyRequests {
                id
                unitId
                landlordId
                requestStartOn
                requestStopOn
                result
                modifiedOn
                resultComments
              }
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }
}
