<div *ngIf= "spinnerCheck === true && step === 'date'">
  <mat-spinner class="centered_spinner"></mat-spinner>
</div>

<div class="section-header" *ngIf= "activeVoucher == true && step === 'date'">Voucher Details</div>

<div class="section-body" *ngIf= "activeVoucher == true && step === 'date' && !multiVoucher">
  <p>
    This action will update the voucher: {{ voucherNumber }} to a status of "Leased". If this is not valid, please exit the move-in process and update the voucher"
  </p>

  <mat-divider></mat-divider>
</div>

<div class="section-body" *ngIf= "activeVoucher == true && step === 'date' && multiVoucher">
  <p>
    This household has multiple issued Vouchers. Please select the one that should transition to a status of "Leased"
  </p>
  <mat-form-field appearance="outline">
    <mat-select [(ngModel)]="selectedVoucher" (selectionChange)="changeSelectedVoucher(selectedVoucher)">
      <mat-option *ngFor="let voucher of multiVoucherArray" [value]="voucher">{{ voucher.voucherNumber }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-divider></mat-divider>
</div>

<div class="section-body" *ngIf= "activeVoucher == true && step === 'date' && unitCheck == true">
  <p>
    <strong>Attention!</strong> It looks like there is an active voucher associated with this household. For the voucher to accurately track a leased status 
    upon move-in, the unit must be tied to the voucher. Would you like to update the unit associated with {{ voucherNumber }}?
  </p>

  <mat-radio-group id="voucherunitSel" [(ngModel)]="updateVoucherUnit" attr.voucherunit-src="{{ updateVoucherUnit }}">
    <mat-radio-button class="rdoSpace" [value]="true">Yes</mat-radio-button>

    <mat-radio-button class="rdoSpace" [value]="false" style="margin-left:50px" >No</mat-radio-button>
  </mat-radio-group>
</div>