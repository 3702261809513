<mat-card class="knso-card">
   <mat-card-header class="payables-notes-header">
      <div class="title">
        <p>Notes</p>
        <button mat-icon-button (click)="onCloseNotes()"><mat-icon>close</mat-icon></button>
      </div>
   </mat-card-header>
   <mat-card-content class="payables-note-info">
        <div class="payables-info">
          <p>Landlord: <a>{{ landLord }}</a></p>
        </div>
         <div *ngIf="loading" class="spinnerRow">
            <mat-spinner diameter="25"></mat-spinner>
            <div class="spinnerSpacer">Loading Notes...</div>
         </div>
         <div  *ngIf="!loading" class="payables-notes-content" [ngClass]="{'payables-notes-content-scroll': (notes && notes.length > 2)}">
           <div *ngFor="let note of notes; let $index=index" class="note-content">
             <div class="note-content-header">
               <p>Posted by: {{ note.createdBy }}</p>
               <div>
                <mat-icon *ngIf="loggedInUser == note.createdBy" class="edit" (click)="onEditActivate($index, note)">create</mat-icon>
                <mat-icon *ngIf="loggedInUser == note.createdBy" (click)="deletePayablesNote(note)">delete_outline</mat-icon>
               </div>
             </div>
             <p>Category: {{ note.category }}</p>
             <p>{{note.createdOn | date: 'medium'}}</p>
             <div *ngIf="noteIndex === $index" class="note-edit-content">
                <textarea
                 matInput
                 cdkTextareaAutosize
                 #autosize="cdkTextareaAutosize"
                 cdkAutosizeMinRows="2"
                 cdkAutosizeMaxRows="4"
                 [(ngModel)]="editedNoteBody"
                 class="note-content"
                 ></textarea>
             </div>
             <div *ngIf="noteIndex == null">
               <p>{{note.body}}</p>
             </div>
           </div>   
        </div>
        <div *ngIf="!loading && (!notes || notes.length === 0)">
                <p class="announcement">There are no notes for this vendor</p>
        </div>
   </mat-card-content>
   <mat-card-actions *ngIf="noteIndex != null" class="payables-notes-footer">
    <button  mat-flat-button class="hds_button cancel"  (click)="onEditDeactivate()">Cancel</button>
    <button  mat-flat-button class="hds_button" (click)="editPayablesNote(editedNoteBody)">Save</button>
  </mat-card-actions>
   <mat-card-actions *ngIf="noteIndex == null" class="payables-notes-footer">
      <button  mat-flat-button class="hds_button cancel"  (click)="onCloseNotes()">Close</button>
      <button  mat-flat-button class="hds_button" (click)="onSaveNotes()">Ok</button>
  </mat-card-actions>
</mat-card>