import {Component, Injector} from '@angular/core';
import {FormControl} from '@angular/forms';
import {GlobalModalService} from '../../../../kanso-common/core/service/globalmodal.service';
import {AccountingService} from '../../../accounting/service/accounting.service';
import {CoreService} from '../../../../core/service/core.service';
import {KansoActionResult} from '../../../../core/service/core-models';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ISubLedgerMap, GeneralLedgerAccount} from '../../../accounting/service/accounting-models';
import {SubLedgerMap} from '../../../accounting/service/accounting.objects';
import {SubledgerMappingEditFullComponent} from './subledger-mapping-edit-full/subledger-mapping-edit-full.component';
import {subCategorySaveAction, categorySaveEventPayload, SubledgerMapModalData} from '../models/accounting-setup.models';
import {SubledgerMappingEditComponent} from './subledger-mapping-edit/subledger-mapping-edit.component';

interface callbackDelegate {
  (success: boolean): void;
}

@Component({
  selector: 'subledger-mapping',
  templateUrl: './subledger-mapping.component.html',
  styleUrls: ['./subledger-mapping.component.scss'],
})
export class SubledgerMappingComponent {
  subledgerMaps: ISubLedgerMap[];
  filteredSubledgerMaps$: Observable<ISubLedgerMap[]>;
  subledgerMapFilter = new FormControl();
  selectedMap: ISubLedgerMap;
  mapSentToApi: ISubLedgerMap;
  private glAccounts: GeneralLedgerAccount[];
  loading = true;
  error = false;
  errorMsg = '';

  private deleteActionMsg = 'Do you wish to continue?';
  private cantDeleteMsg =
    'You are trying to delete a Subledger Mapping that has transactions posted to it. Deleting this would orphan existing transactions. This is not something that can happen and this Subledger Mapping will not be deleted.';
  private cantDeleteCategoryMsg =
    'You are trying to delete a category that has transactions posted to it. Deleting this would orphan existing transactions. This is not something that can happen and this category will not be deleted.';
  private cantDeleteSubCategoryMsg =
    'You are trying to delete a subcategory that has transactions posted to it. Deleting this would orphan existing transactions. This is not something that can happen and this subcategory will not be deleted.';

  constructor(
    public globalModalService: GlobalModalService,
    public coreService: CoreService,
    protected injector: Injector,
    private accountingService: AccountingService
  ) {}

  ngOnInit(): void {
    this.getMaps();
    this.getAccts();
  }
  getMaps() {
    this.loading = true;
    this.accountingService.getAllSubLedgerMaps().subscribe(
      response => {
        this.subledgerMaps = response.body;
        this.filteredSubledgerMaps$ = this.subledgerMapFilter.valueChanges.pipe(
          startWith(null),
          map(mapName => this._filterMaps(mapName))
        );
        this.loading = false;
      },
      error => {
        console.log(`Error fetching subledger maps: ${error}`);
        this.errorMsg = 'An unexpected error occurred fetching general ledger records. Please try again.';
        this.loading = false;
        this.error = true;
      }
    );
  }

  getAccts() {
    this.accountingService.getAllGeneralLedgerAccounts().subscribe(response => {
      this.glAccounts = response.body;
    });
  }

  private _filterMaps(filterValue?: string): ISubLedgerMap[] {
    return filterValue
      ? this.subledgerMaps.filter(map => map.name.toLowerCase().includes(filterValue.toLowerCase())).sort(this._sortByName)
      : this.subledgerMaps.sort(this._sortByName);
  }

  private _sortByName = (a: ISubLedgerMap, b: ISubLedgerMap) => {
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();
    switch (true) {
      case aName > bName:
        return 1;
      case aName < bName:
        return -1;
      default:
        return 0;
    }
  };

  editSubledgerMapFull(map?: ISubLedgerMap) {
    this.selectedMap = map ? (map as SubLedgerMap) : new SubLedgerMap('', null);

    this.globalModalService.openModal<SubledgerMapModalData>(
      SubledgerMappingEditFullComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      {subledgerMap: this.selectedMap, updateFunction: this.saveMap, generalLedgerAccounts: this.glAccounts, editMode: true}
    );
  }

  editSubledgerMap(map?: ISubLedgerMap) {
    this.selectedMap = map ? (map as SubLedgerMap) : new SubLedgerMap('', null);

    this.globalModalService.openModal<SubledgerMapModalData>(
      SubledgerMappingEditComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      {
        subledgerMap: this.selectedMap,
        updateFunction: this.saveMap,
        generalLedgerAccounts: this.glAccounts,
        editMode: map ? true : false,
      }
    );
  }
  cloneSubledgerMap(map: ISubLedgerMap) {
    this.selectedMap = Object.assign({}, map);
    this.selectedMap.name = `${this.selectedMap.name} (Clone)`;
    delete this.selectedMap._id;
    this.globalModalService.openModal<SubledgerMapModalData>(
      SubledgerMappingEditComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      {
        subledgerMap: this.selectedMap,
        updateFunction: this.saveMap,
        generalLedgerAccounts: this.glAccounts,
        editMode: this.selectedMap._id !== undefined,
      }
    );
  }

  deleteSubledgerMap(map: ISubLedgerMap) {
    this.selectedMap = map;
    this.coreService.confirmAction(
      this._deleteConfirmMessage(),
      this._deleteSubledgerMap,
      `Confirm Delete - "${this.selectedMap.name}"`,
      this.deleteActionMsg
    );
  }

  private _deleteConfirmMessage() {
    return `Are you sure you want to delete HDS Ledger Account ${this.selectedMap.name}? This action cannot be undone. Once deleted, you will have to resolve transactions posted against this account.`;
  }

  private _deleteSubledgerMap = () => {
    this.accountingService.deleteSubLedgerMap(this.selectedMap._id).subscribe(res => {
      switch (res.statusCode) {
        case 200:
          console.log(`Deleted: ${JSON.stringify(this.selectedMap)}`);
          this.selectedMap = null;
          break;
        case 412:
          //subledger in use in transactions collection
          this.coreService.displayError(
            this.cantDeleteMsg,
            null,
            `Sorry, you cannot delete this Subledger Mapping: ${this.selectedMap.name}.`,
            ''
          );
          break;
        default:
          this.coreService.displayError(
            `Error deleting ${this.selectedMap.name}: ${res.message}`,
            null,
            'Error deleting Sub-Ledger Mapping'
          );
      }
      this.getMaps();
    });
  };

  refreshMaps = () => {
    this.getMaps();
    setTimeout(() => {
      this.editSubledgerMap(this.selectedMap);
    }, 50);
  };

  private saveAction: subCategorySaveAction;
  private saveActionCallback: callbackDelegate;
  // // have to use the = () => format to pass as an input to the modals
  saveMap = (saveActionPayload: categorySaveEventPayload) => {
    if (saveActionPayload) {
      this.saveAction = saveActionPayload.saveAction;
      this.saveActionCallback = saveActionPayload.callback;
    }
    this.mapSentToApi = Object.assign({}, this.selectedMap);
    if (this.selectedMap._id === undefined) {
      delete this.selectedMap._id;
      this.accountingService.createSubLedgerMap(this.selectedMap).subscribe(this.saveAccountHandler);
    } else {
      this.accountingService.updateSubLedgerMap(this.selectedMap).subscribe(this.saveAccountHandler);
    }
  };

  saveAccountHandler = (response?: KansoActionResult<ISubLedgerMap>) => {
    //note: since categories & sub-categories are child objects of subLedgerMap aka chartOfAccounts Document
    //deletes of either of these items are still "save" actions. \_O_/
    switch (response.statusCode) {
      case 200:
        this.selectedMap = Object.assign(this.selectedMap, response.body);
        if (this.mapSentToApi._id === undefined) {
          this.getMaps();
        }
        break;
      case 412:
        //Category delete acton: subledger in use in transactions collection
        // eslint-disable-next-line
        let message: string =
          this.saveAction == subCategorySaveAction.deleteCategory ? this.cantDeleteCategoryMsg : this.cantDeleteSubCategoryMsg;
        // eslint-disable-next-line
        let itemDescription: string = this.saveAction == subCategorySaveAction.deleteCategory ? 'Category' : 'Subcategory';
        this.coreService.displayError(message, null, `Sorry, you cannot delete this ${itemDescription}.`, '');
        this.selectedMap = Object.assign(this.selectedMap, response.body);
        break;
      default:
        this.coreService.displayError(
          `Error saving ${this.selectedMap.name}: ${response.message}`,
          null,
          'Error saving Sub-Ledger Mapping'
        );
    }
    if (this.saveActionCallback) {
      this.saveActionCallback(response.statusCode === 200);
    }
  };
}
