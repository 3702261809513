import {enableProdMode, StaticProvider} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as angular from 'angular';
import {downgradeComponent, downgradeModule, setAngularJSGlobal} from '@angular/upgrade/static';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import {AppComponent} from './app/app.component';
import {FormEntryComponent} from './app/form-entry/form-entry.component';
import {PicBatchingComponent} from './app/data-export/pic-batching/pic-batching.component';
import {MincComponent} from './app/data-export/minc/minc.component';
import {TicComponent} from './app/data-export/tic/tic.component';
import {HmisComponent} from './app/data-export/hmis/hmis.component';
import {VouchersContainerComponent} from './app/vouchers/vouchers-container.component';
import {VouchersMoveOutComponent} from './app/vouchers/vouchers-moveout/vouchers-moveout.component';
import {VouchersMoveInComponent} from './app/vouchers/vouchers-movein/vouchers-movein.component';
import {VouchersAssignComponent} from './app/vouchers/vouchers-assign/vouchers-assign.component';
import {VouchersAddEditComponent} from './app/vouchers/vouchers-addedit/vouchers-addedit.component';
import {VouchersIssueWizardComponent} from './app/vouchers/vouchers-issue-wizard/vouchers-issue-wizard.component';
import {ForceInitComponent} from './app/shared/force-init/force-init.component';

import {AccountingHeaderComponent} from './app/custom/setup/accounting-setup/accounting-header/accounting-header.component';
import {GeneralLedgerComponent} from './app/custom/setup/accounting-setup/general-ledger/general-ledger.component';
import {HousingAuthoritySetupComponent} from './app/custom/setup/housing-authority-setup/housing-authority-setup.component';
import {ProgramSetupComponent} from './app/custom/setup/program-setup/program-setup.component';
import {ProgramSetupListComponent} from './app/custom/setup/program-setup-list/program-setup-list.component';
import {ProjectSetupComponent} from './app/custom/setup/project-setup/project-setup.component';
import {ProjectSetupListComponent} from './app/custom/setup/project-setup-list/project-setup-list.component';
import {SubledgerMappingComponent} from './app/custom/setup/accounting-setup/subledger-mapping/subledger-mapping.component';
import {AccountActivityRuleComponent} from './app/custom/setup/account-activity-rule/account-activity-rule.component';

import {VendorsComponent} from './app/custom/occupancy/vendors/vendors.component';
import {FileroomUploadButtonComponent} from './app/fileroom/fileroom-upload-button/fileroom-upload-button.component';
import {VendorLedgerComponent} from './app/custom/vendor/vendor-ledger/vendor-ledger.component';
import {VoidTransactionPopupComponent} from './app/custom/vendor/vendor-ledger/vendor-transaction-detail/void-transaction-popup/void-transaction-popup.component';
import {VendorAdjustmentComponent} from './app/custom/vendor/vendor-ledger/vendor-adjustment/vendor-adjustment.component';
import {LandlordPropertiesComponent} from './app/custom/occupancy/vendors/landlord-properties/landlord-properties.component';
import {VendorOverviewComponent} from './app/custom/vendor/vendor-overview/vendor-overview.component';
import {VendorHeaderComponent} from './app/custom/vendor/vendor-header/vendor-header.component';
import {EditFundingAndDepartmentPopupComponent} from './app/custom/setup/housing-authority-setup/edit-funding-and-department-popup/edit-funding-and-department-popup.component';
import {EditIncomeLimitAreaComponent} from './app/custom/setup/housing-authority-setup/edit-income-limit-area/edit-income-limit-area.component';
import {EditRegionalRentLimitComponent} from './app/custom/setup/housing-authority-setup/edit-regional-rent-limit/edit-regional-rent-limit.component';
import {UnitSetupMonthlyChargeComponent} from './app/custom/setup/units/unit-setup/unit-setup-monthly-charge/unit-setup-monthly-charge.component';
import {RentcalcOptionPopupComponent} from './app/custom/setup/rentcalc-option-popup/rentcalc-option-popup.component';
import {VendorNotesComponent} from './app/custom/vendor/vendor-notes/vendor-notes.component';
import {VendorNotesCreateComponent} from './app/custom/vendor/vendor-notes/vendor-notes-create/vendor-notes-create.component';
import {VendorEditComponent} from './app/custom/vendor/vendor-edit/vendor-edit.component';
import {UnitsListComponent} from './app/custom/setup/units/units-list/units-list.component';
import {UnitSetupComponent} from './app/custom/setup/units/unit-setup/unit-setup.component';
import {UnitSetupHandlerComponent} from './app/custom/setup/units/unit-edit-handler/unit-setup-handler.component';
import {UtilityAllowancesComponent} from './app/custom/setup/utility-allowances/utility-allowances.component';
import {UtilityActionPopupComponent} from './app/custom/setup/utility-allowances/utility-action-popup/utility-action-popup.component';

import {CustomAttributesComponent} from './app/kanso-common/core/components/custom-attributes/custom-attributes.component';
import {PublicHousingActionComponent} from './app/custom/occupancy/household-actions/public-housing-action/public-housing-action.component';

import {PayoutsComponent} from './app/custom/accounting/payouts/payouts.component';
import {PayablesHistoryComponent} from './app/custom/accounting/payouts/payables-history/payables-history.component';
import {PayableDetailComponent} from './app/custom/accounting/payouts/payable-detail/payable-detail.component';
import {PostAdjustmentComponent} from './app/custom/accounting/payouts/post-adjustment/post-adjustment.component';
import {PayablesNotesComponent} from './app/custom/accounting/payouts/payables-notes/payables-notes.component';
import {PayablePostedOnComponent} from './app/custom/accounting/payouts/payable-detail/payable-posted-on/payable-posted-on.component';
import {PayablesWizardComponent} from './app/custom/accounting/payouts/payables-wizard/payables-wizard.component';
import {PayableWizardTableComponent} from './app/custom/accounting/payouts/payables-wizard/payable-wizard-table/payable-wizard-table.component';
import {KansoWizardHeaderComponent} from './app/kanso-common/core/components/kanso-wizard-header/kanso-wizard-header.component';
import {KansoWizardContentComponent} from './app/kanso-common/core/components/kanso-wizard-content/kanso-wizard-content.component';

import {FeatureConfigurationsListComponent} from './app/custom/feature-configurations/feature-configurations-list/feature-configurations-list.component';

export function bootstrapAngular(extra: StaticProvider[]): any {
  setAngularJSGlobal(angular);
  if (environment.production) {
    enableProdMode();
  }
  return platformBrowserDynamic(extra)
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
}

const downgraded = angular
  .module('downgraded', [downgradeModule(bootstrapAngular)])
  .directive('appRoot', downgradeComponent({component: AppComponent, propagateDigest: false}));

angular.element(document).ready(function() {
  // ! Below is a commented out exmaple of how to downgrade a specific component
  // angular.module('mean').directive('financeDashboardComponent', downgradeComponent({ component: FinanceDashboardComponent }));
  angular.module('mean').directive(
    'formEntryComponent',
    downgradeComponent({
      component: FormEntryComponent,
      inputs: ['formIdToDisplay', 'recentlyCreatedFormToDisplay', 'actionRequest'],
      outputs: ['emitDocumentId'],
    })
  );

  angular.module('mean').directive('accountingHeaderComponent', downgradeComponent({component: AccountingHeaderComponent}));
  angular.module('mean').directive('generalLedgerComponent', downgradeComponent({component: GeneralLedgerComponent}));
  angular.module('mean').directive('housingAuthoritySetupComponent', downgradeComponent({component: HousingAuthoritySetupComponent}));
  angular
    .module('mean')
    .directive('editFundingAndDepartmentPopupComponent', downgradeComponent({component: EditFundingAndDepartmentPopupComponent}));
  angular.module('mean').directive('editIncomeLimitAreaComponent', downgradeComponent({component: EditIncomeLimitAreaComponent}));
  angular.module('mean').directive('editRegionalRentLimitComponent', downgradeComponent({component: EditRegionalRentLimitComponent}));
  angular.module('mean').directive('programSetupComponent', downgradeComponent({component: ProgramSetupComponent}));
  angular.module('mean').directive('programSetupListComponent', downgradeComponent({component: ProgramSetupListComponent}));
  angular.module('mean').directive('projectSetupComponent', downgradeComponent({component: ProjectSetupComponent}));
  angular.module('mean').directive('projectSetupListComponent', downgradeComponent({component: ProjectSetupListComponent}));
  angular.module('mean').directive('subledgerMappingComponent', downgradeComponent({component: SubledgerMappingComponent}));
  angular.module('mean').directive('accountActivityRuleComponent', downgradeComponent({component: AccountActivityRuleComponent}));
  angular.module('mean').directive('unitsListComponent', downgradeComponent({component: UnitsListComponent}));
  angular.module('mean').directive('unitSetupComponent', downgradeComponent({component: UnitSetupComponent}));
  angular.module('mean').directive('unitSetupHandlerComponent', downgradeComponent({component: UnitSetupHandlerComponent}));
  angular.module('mean').directive('utilityAllowancesComponent', downgradeComponent({component: UtilityAllowancesComponent}));
  angular.module('mean').directive('utilityActionPopupComponent', downgradeComponent({component: UtilityActionPopupComponent}));
  angular.module('mean').directive('unitSetupMonthlyChargeComponent', downgradeComponent({component: UnitSetupMonthlyChargeComponent}));
  angular.module('mean').directive('rentcalcOptionPopupComponent', downgradeComponent({component: RentcalcOptionPopupComponent}));

  angular.module('mean').directive('vendorsComponent', downgradeComponent({component: VendorsComponent}));
  angular.module('mean').directive('voidTransactionPopupComponent', downgradeComponent({component: VoidTransactionPopupComponent}));
  angular.module('mean').directive('vendorLedgerComponent', downgradeComponent({component: VendorLedgerComponent}));
  angular.module('mean').directive('vendorAdjustmentComponent', downgradeComponent({component: VendorAdjustmentComponent}));
  angular.module('mean').directive('vendorOverviewComponent', downgradeComponent({component: VendorOverviewComponent}));
  angular.module('mean').directive('vendorHeaderComponent', downgradeComponent({component: VendorHeaderComponent}));
  angular.module('mean').directive('vendorEditComponent', downgradeComponent({component: VendorEditComponent}));
  angular.module('mean').directive('vendorNotesComponent', downgradeComponent({component: VendorNotesComponent}));
  angular.module('mean').directive('vendorNotesCreateComponent', downgradeComponent({component: VendorNotesCreateComponent}));

  angular.module('mean').directive('landlordPropertiesComponent', downgradeComponent({component: LandlordPropertiesComponent}));

  angular.module('mean').directive('payoutsComponent', downgradeComponent({component: PayoutsComponent}));
  angular.module('mean').directive('payablesHistoryComponent', downgradeComponent({component: PayablesHistoryComponent}));
  angular.module('mean').directive('payableDetailComponent', downgradeComponent({component: PayableDetailComponent}));
  angular.module('mean').directive('postAdjustmentComponent', downgradeComponent({component: PostAdjustmentComponent}));
  angular.module('mean').directive('payablesNotesComponent', downgradeComponent({component: PayablesNotesComponent}));
  angular.module('mean').directive('payablePostedOnComponent', downgradeComponent({component: PayablePostedOnComponent}));
  angular.module('mean').directive('payablesWizardComponent', downgradeComponent({component: PayablesWizardComponent}));
  angular.module('mean').directive('payableWizardTableComponent', downgradeComponent({component: PayableWizardTableComponent}));
  angular.module('mean').directive('kansoWizardHeaderComponent', downgradeComponent({component: KansoWizardHeaderComponent}));
  angular.module('mean').directive('kansoWizardContentComponent', downgradeComponent({component: KansoWizardContentComponent}));

  angular.module('mean').directive('picBatchingComponent', downgradeComponent({component: PicBatchingComponent}));
  angular.module('mean').directive('mincComponent', downgradeComponent({component: MincComponent}));
  angular.module('mean').directive('ticComponent', downgradeComponent({component: TicComponent}));
  angular.module('mean').directive('hmisComponent', downgradeComponent({component: HmisComponent}));

  angular.module('mean').directive('vouchersContainerComponent', downgradeComponent({component: VouchersContainerComponent}));
  angular.module('mean').directive('vouchersAddEditComponent', downgradeComponent({component: VouchersAddEditComponent}));
  angular.module('mean').directive('vouchersMoveOutComponent', downgradeComponent({component: VouchersMoveOutComponent}));
  angular.module('mean').directive('vouchersMoveInComponent', downgradeComponent({component: VouchersMoveInComponent}));
  angular.module('mean').directive('vouchersAssignComponent', downgradeComponent({component: VouchersAssignComponent}));
  angular.module('mean').directive('vouchersIssueWizardComponent', downgradeComponent({component: VouchersIssueWizardComponent}));

  angular.module('mean').directive('publicHousingActionComponent', downgradeComponent({component: PublicHousingActionComponent}));

  angular.module('mean').directive('forceInitComponent', downgradeComponent({component: ForceInitComponent}));
  angular.module('mean').directive('customAttributesComponent', downgradeComponent({component: CustomAttributesComponent}));

  angular
    .module('mean')
    .directive('featureConfigurationsListComponent', downgradeComponent({component: FeatureConfigurationsListComponent}));

  // fileroom components
  angular.module('mean').directive(
    'fileroomUploadButtonComponent',
    downgradeComponent({
      component: FileroomUploadButtonComponent,
      inputs: ['refreshDelegate', 'householdId', 'userId', 'siteSection', 'siteSectionId', 'siteSubsection'],
    })
  );

  angular.bootstrap(document, ['mean', downgraded.name]);
});
