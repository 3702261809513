<div [ngClass]="{'disableBackground': this.overlay}">
  <section *ngIf="showPanelView == 'new-actions'">
    <section class="tabs__panel">
      <section>
        <div layout="row">
          <h2>PIC Batching</h2>
        </div>
      </section>
      <hr class="throughTout" />
      <div *ngIf="this.enableFilter && !this.loading && this.allSelectedPicBatch.length > 0">
        <div (click)="showFilterToggle()" class="filterRow">
          <mat-icon>filter_alt</mat-icon>
          <a class="filterButton">SHOW FILTERS</a>
        </div>
      </div>
    </section>

    <section class="tabs__panel">
      <div class="flexColumn">
        <nav>
          <ul class="nav-pills">
            <li>
              <a class="nav-active" (click)="showPanelView = 'new-actions'" routerLinkActive="active">New Actions</a>
            </li>
            <li>
              <a (click)="showPanelView = 'exported-actions'" routerLinkActive="active">Exported Actions</a>
            </li>
          </ul>
        </nav>
        <div *ngIf="this.loading">
          <mat-spinner diameter="50" class="centered_spinner"> </mat-spinner>
        </div>
        <div *ngIf="!this.loading">
          <div class="master-checkbox-padding">
            <mat-checkbox
              class="master-checkbox"
              [checked]="allPicActions"
              [indeterminate]="someSelected()"
              (change)="selectAllPicActions($event.checked)"
            >
            </mat-checkbox>
            {{ checkboxMessage }}
          </div>
  
          <table mat-table [dataSource]="this.filteredPicBatch">
            <ng-container matColumnDef="select" >
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row">
                <span *ngIf="row.disable">
                  <mat-icon svgIcon="alarm-clock-icon" class="grayBox"
                    ></mat-icon> 
                </span>
                <span *ngIf="!row.disable">
                  <mat-checkbox 
                  [checked]="row.selected" 
                  (change)="updateAllSelected(row.id)"
                  [disabled]="row.disable"
                  > </mat-checkbox>
                </span>
              </td>
            </ng-container>
            
            <ng-container matColumnDef="Household">
              <th mat-header-cell *matHeaderCellDef>Household</th>
              <td class="name" mat-cell *matCellDef="let element">{{ element.headLastName }}, {{ element.headFirstName }}</td>
            </ng-container>
  
            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let element">{{ element.action.length ? element.action : '' }}</td>
            </ng-container>
            <ng-container matColumnDef="Program">
              <th mat-header-cell *matHeaderCellDef>Program</th>
              <td mat-cell *matCellDef="let element">{{ element.program.length ? element.program : '' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Project">
              <th mat-header-cell *matHeaderCellDef>Project</th>
              <td mat-cell *matCellDef="let element">{{ element.project.length ? element.project : '' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Effective Date">
              <th mat-header-cell *matHeaderCellDef>Effective Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.effectiveDate.length ? (element.effectiveDate | date: 'mediumDate') : '' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Created On Date">
              <th mat-header-cell *matHeaderCellDef>Created On Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.createdOnDate.length ? (element.createdOnDate | date: 'mediumDate') : '' }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-header"></tr>
          </table>
  
          <p *ngIf="this.filteredPicBatch == 0" class="centered_message">
            No New Actions found.
          </p>
          <div *hasPermission="'admin || performPICExports'">
            <button
              mat-button
              class="btn-right"
              (click)="btnClick()"
              [(disabled)]="!enableExportDetailsBtn"
              *ngIf="filteredPicBatch.length !== 0"
            >
              Export
            </button>
          </div>
        </div>
      </div>
    </section>  
  </section>
</div>

<div *ngIf="this.enableFilter">
  <div [@openClose]="showFilter ? 'open' : 'closed'" class="dataFilter">
    <div class="flexColumn">
      <h4>Action Effective Date</h4>
      <div class="dateFlexRow ">
        <div>From</div>
        <mat-form-field appearance="outline">
          <mat-label>Choose a start date</mat-label>
          <input matInput [matDatepicker]="picker" (dateChange)="setStartDate($event.value)" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <div>To</div>
        <mat-form-field appearance="outline">
          <mat-label>Choose a end date</mat-label>
          <input matInput [matDatepicker]="picker2" (dateChange)="setEndDate($event.value)" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
      <div>
        <h4>Action Type</h4>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>All Actions</mat-label>
        <mat-select [formControl]="actions" placeholder="Action" multiple>
          <mat-option *ngFor="let action of this.filteredActions" [value]="action">{{ action }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div>
        <h4>Program</h4>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Low Rent, VASH, Housing Choice</mat-label>
        <mat-select [formControl]="programs" multiple>
          <mat-option *ngFor="let program of this.filteredPrograms" [value]="program">{{ program }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div>
        <h4>Project</h4>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>All Projects</mat-label>
        <mat-select [formControl]="projects" multiple>
          <mat-option *ngFor="let project of this.filteredProjects" [value]="project">{{ project }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="buttonFlexRow">
      <a class="cancelLink " (click)="showFilterToggle()">
        Cancel
      </a>
      <button mat-button class="btn-right" (click)="applyFilter()">
        Apply
      </button>
    </div>
  </div>
</div>


<div class="center_popUp">
  <div *ngIf="this.popUp" class="popUp_panel">
    <div class="popUp_text">You are about to batch and export {{ this.totalChecked }} actions.</div>
    <div class="popUp_text">These actions will no longer be able to be edited or</div>
    <div class="popUp_text">"undone" in Doorways.</div><br>
    <div class="popUp_text">Do you wish to continue?</div><br>
    <div class="button-flex">
      <button mat-button class="btn-cancel" (click)="btnClick()">Cancel</button>
      <button mat-button class="btn-confirm" (click)="reviewExportDetails()">Confirm</button>
    </div>
  </div>
</div>


<div *ngIf="showPanelView == 'exported-actions'">
  <app-exported-actions [picBatchHouseholdIds]="this.picBatchHouseholdIds"></app-exported-actions>
</div>
