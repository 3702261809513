import {ErrorHandler, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

import {GlobalAngular2ErrorHandlerComponent} from './global-angular2-error-handler.component';
import {GlobalAngular2ErrorHandlerService} from './global-angular2-error-handler.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private toast: MatSnackBar, private errorHandlerService: GlobalAngular2ErrorHandlerService) {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleError(error: any): void {
    //so we do not break any current functionality
    console.log(error);
    if (error.rejection.userMessage) {
      this.toast.openFromComponent(GlobalAngular2ErrorHandlerComponent, {
        duration: 1000 * 10,
        verticalPosition: 'top',
        panelClass: ['mat-toolbar', 'mat-warn'],
        data: error.rejection.userMessage || 'An Error Has Occurred.',
      });
    } else {
      //i really want to see my errors
      console.log(error);
    }

    this.errorHandlerService.error(error.message || '', error);
    // ! IMPORTANT: Rethrow the error otherwise it gets swallowed
    throw error;
  }
}
