import {Component, Input} from '@angular/core';
import {GlobalModalChild, GlobalModalParent} from '../../../../kanso-common/core/service/globalmodal.service';
import {FundingAndDepartmentModalData} from '../../../housing-core/services/housing-models';
import lodash from 'lodash';

@Component({
  selector: 'app-edit-funding-and-department-popup',
  templateUrl: './edit-funding-and-department-popup.component.html',
  styleUrls: ['./edit-funding-and-department-popup.component.scss'],
})
export class EditFundingAndDepartmentPopupComponent
  implements GlobalModalChild<FundingAndDepartmentModalData>, FundingAndDepartmentModalData {
  //eslint-disable-next-line
  @Input() updateFunction: Function;
  @Input() index: number;
  @Input() editMode: boolean;
  @Input() type: string;
  @Input() allObjects: any;
  _selectedObject: any;
  @Input() set selectedObject(setName: any) {
    this._selectedObject = setName;
    this.editObject = lodash.cloneDeep(setName);
  }

  globalModalParent: GlobalModalParent;
  editObject: any;

  // eslint-disable-next-line
  get selectedObject(): any {
    return this._selectedObject;
  }

  getHeaderText() {
    return `${this.editMode ? 'Edit' : 'New'} ${this.type}`;
  }

  cancel() {
    this.globalModalParent.closePopup();
  }

  onSubmit() {
    this.selectedObject = lodash.assign(this._selectedObject, {...this.editObject});
    this.updateFunction(this.index);
    this.globalModalParent.closePopup();
  }
}
