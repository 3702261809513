import {Component, Input} from '@angular/core';
import {GlobalModalChild, GlobalModalParent} from '../../../kanso-common/core/service/globalmodal.service';
import {ConfirmModalData} from '../../service/core-models';

@Component({
  selector: 'confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss'],
})
export class ConfirmPopupComponent implements GlobalModalChild<ConfirmModalData>, ConfirmModalData {
  // eslint-disable-next-line
  @Input() callbackFunction: Function;
  @Input() confirmMessage: string;
  @Input() actionMessage: string;
  @Input() headerText: string;
  @Input() confirmButtonText: string;
  globalModalParent: GlobalModalParent;

  closeDialog() {
    this.globalModalParent.closePopup();
  }

  completeAction() {
    this.callbackFunction();
    this.closeDialog();
  }

  expectedRows(message: string, charatersPerRows: number, maximumRows: number) {
    let lineCount = 0;
    const lines = message.split('\n');
    const nonEmptyLines = lines.filter(line => line.trim() !== '');
    lineCount = nonEmptyLines.length;
    for (const line of lines) {
      if (line.length > charatersPerRows) {
        lineCount += Math.floor(line.length / charatersPerRows);
      }
    }
    if (lineCount > maximumRows) {
      lineCount = maximumRows;
    }
    return lineCount;
  }
}
