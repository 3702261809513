/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {Component, OnInit, Input} from '@angular/core';
import {VoucherService} from '../vouchers-services/vouchers.service';
import {Query, Voucher} from 'src/generated/graphql';
import * as moment from 'moment';
@Component({
  selector: 'app-vouchers-assign',
  templateUrl: './vouchers-assign.component.html',
  styleUrls: ['./vouchers-assign.component.scss'],
})
export class VouchersAssignComponent implements OnInit {
  customerId: string;
  siteId: string;
  @Input() data: any;
  loggedInUser: string;
  voucherunit = true;
  observable: any;
  unitCheck: boolean;
  voucher: Voucher;
  spinnerCheck: boolean;
  selectedVoucherId: any;
  multiVoucher = false;
  multiVoucherArray = [];
  private CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  private SITE_ID = sessionStorage.getItem('SITEID') || '';

  constructor(public voucherService: VoucherService) {}

  ngOnDestroy() {
    if (this.data.step === 'review') {
      this.voucherService.setActionItems(null); //Setting service item to null so it will not carry over to different households or actions
    } else {
      this.voucherService.setActionItems([this.unitCheck, this.voucherunit, this.selectedVoucherId]); // Saving these items so they can be used on the last step of the wizard
    }
  }
  ngOnInit() {
    this.customerId = this.CUSTOMER_ID;
    this.siteId = this.SITE_ID;
    this.getCurrentUsersLogInCookie();
    if (this.data.step === 'date') {
      this.spinnerCheck = true;
      this.observable = this.voucherService.getVoucherByHouseHoldIdTrimmed(this.data.household._id).subscribe(
        (result: any) => {
          const query: Query = result?.data;
          if (query.vouchers.edges.length > 0) {
            const finder = query.vouchers.edges.filter(edge => edge.node.state === 'ISSUED' || edge.node.state === 'SHOP_MODE');
            if (finder && finder.length === 1) {
              this.voucher = finder[0].node;
              if (this.voucher && this.voucher.unitId != this.data.selectedUnit._id) {
                this.selectedVoucherId = this.voucher.id;
                this.unitCheck = true;
                this.spinnerCheck = false;
              } else {
                this.spinnerCheck = false;
              }
            } else if (finder && finder.length > 1) {
              for (const node of finder) {
                this.multiVoucherArray.push(node.node);
              }
              this.spinnerCheck = false;
              this.multiVoucher = true;
              this.changeSelectedVoucher(finder[0].node);
            }
          }
        },
        error => {
          this.spinnerCheck = false;
        }
      );
    } else {
      const serviceArray = this.voucherService.loadActionItems();
      if (serviceArray) {
        this.unitCheck = serviceArray[0];
        this.voucherunit = serviceArray[1];
        this.selectedVoucherId = serviceArray[2];
      }
      if (this.unitCheck == true && this.voucherunit == true) {
        // If the unitCheck is true, then the voucher is valid
        this.observable = this.voucherService.getVoucherByHouseHoldIdTrimmed(this.data.household._id).subscribe(
          (result: any) => {
            const query: Query = result?.data;
            this.voucher = query.vouchers.edges.find(edge => edge.node.id === this.selectedVoucherId).node;
            if (this.voucher) {
              const command = {
                // Taken from addEdit component; needs trimming
                voucherId: this.voucher.id,
                voucherNumber: this.voucher.voucherNumber,
                fundingProgramId: this.voucher.program.id,
                fundingProjectId: this.voucher.project.id,
                customerId: this.customerId,
                siteId: this.siteId,
                modifiedBy: this.loggedInUser,
                unitId: this.data.selectedUnit._id,
              };
              this.voucherService.editVoucher(command).subscribe(
                (results: any) => {},
                error => {
                  console.log('There was an error updating voucher unit', error);
                }
              );
            }
          },
          error => {
            console.log('There was an error getting the voucher', error);
          }
        );
      }
    }
  }

  getCurrentUsersLogInCookie() {
    const cookies = document.cookie.split(';');
    let usersAuthCookie;
    for (const cookie of cookies) {
      if (cookie.includes('LastAuthUser')) {
        //found the cookie of the user
        usersAuthCookie = cookie;
      }
    }
    const arrayOfCookieStrings = usersAuthCookie.split('=');
    this.loggedInUser = arrayOfCookieStrings[1];
  }

  changeSelectedVoucher(voucher) {
    this.selectedVoucherId = voucher.id;
    if (voucher.unitId != this.data.selectedUnit._id) {
      this.unitCheck = true;
    } else {
      this.unitCheck = false;
    }
  }
}
