import {Component, EventEmitter, Injector, OnInit, Output} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {GlobalModalService} from 'src/app/kanso-common/core/service';
import {BatchHistoryData, VendorService} from 'src/app/custom/vendor/vendor.service';
import {CoreService} from 'src/app/core/service/core.service';
import {qqlPageInfo} from 'src/app/custom/vendor/vendor-model';
import {FormControl} from '@angular/forms';
import {goToPagePayload, pageNext} from 'src/app/core/service/core-models';

@Component({
  selector: 'app-payables-history',
  templateUrl: './payables-history.component.html',
  styleUrls: ['./payables-history.component.scss'],
})
export class PayablesHistoryComponent implements OnInit {
  customerId: string;
  siteId: string;
  batchData: BatchHistoryData[] = [];
  @Output() pageEmitter = new EventEmitter<string>();
  @Output() batchIdEmitter = new EventEmitter();
  displayedColumns = ['Batch #', 'Date', 'Number of Payments', 'Amount', 'Created By', 'delete'];
  loggedInUser;
  selectedBatchId: number;
  loading = true;
  error = false;
  isBusy: boolean;
  busyText: string;

  pageSize = new FormControl('15');
  pageInfo: qqlPageInfo;
  lastPageSize = 0;
  totalCount = 0;
  currentRecordsCount = 0;
  private CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  private SITE_ID = sessionStorage.getItem('SITEID') || '';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public vendorService: VendorService,
    public globalModalService: GlobalModalService,
    public coreService: CoreService,
    protected injector: Injector
  ) {
    this.loggedInUser = this.coreService.getCurrentUsersLogInCookie();
    this.matIconRegistry.addSvgIconLiteral(
      'download-icon-blue',
      this.domSanitizer.bypassSecurityTrustHtml(
        '<svg id="Download_Icon" data-name="Download Icon" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#5CA3D4"><g><rect fill="none" height="24" width="24"/><path d="M12,4c4.41,0,8,3.59,8,8s-3.59,8-8,8s-8-3.59-8-8S7.59,4,12,4 M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10 c5.52,0,10-4.48,10-10C22,6.48,17.52,2,12,2L12,2z M13,12l0-4h-2l0,4H8l4,4l4-4H13z"/></g></svg>'
      )
    );
  }
  // eslint-disable-next-line
  ngOnInit(): void {
    this.customerId = this.CUSTOMER_ID;
    this.siteId = this.SITE_ID;
    this.getVendorPayableHistory('', pageNext, this.pageSize.value);
    this.pageSize.valueChanges.subscribe(value => {
      this.currentRecordsCount = 0;
      this.getVendorPayableHistory('', pageNext, value);
    });
  }

  getVendorPayableHistory(cursor: string, direction: string, pageSize: number) {
    this.setBusy('Loading');
    const countMultiplier: number = direction != 'forward' ? -1 : 1;
    this.vendorService.getVendorPayablesHistoricBatches(this.siteId, cursor, direction, pageSize).subscribe(
      result => {
        this.batchData = result.data;
        this.pageInfo = result.pageInfo;
        this.totalCount = result.totalCount;
        const countAdjSize = direction != 'forward' ? this.lastPageSize : result.data.length;
        this.currentRecordsCount = this.currentRecordsCount + countAdjSize * countMultiplier;
        this.lastPageSize = result.data.length;
        this.isBusy = false;
      },
      error => {
        console.log(`Error fetching Historic Payables: ${error}`);
        const errorMsg = 'An unexpected error occurred fetching historic payables. Please try again.';
        this.isBusy = false;
        this.error = true;
        this.coreService.displayError(errorMsg, null, 'Error fetching historic payables');
      }
    );
  }

  goToPageHandler(pagePayload: goToPagePayload) {
    this.getVendorPayableHistory(pagePayload.cursor, pagePayload.direction, pagePayload.pageSize);
  }

  onBatchHistorySelected(batch): void {
    this.pageEmitter.emit('payable-detail');
    this.batchIdEmitter.emit(batch);
  }

  onDeleteBatchHistory(event, element): void {
    event.stopPropagation();
    const deleteMsg = `Are you sure you want to delete this historical payable batch? This cannot be undone.`;
    this.selectedBatchId = element.batchId;
    this.coreService.confirmAction('', this.onDeleteBatch, 'Reminder', deleteMsg);
  }

  onDeleteBatch = () => {
    const deleteBatchCommand = {
      batchId: this.selectedBatchId,
      createdBy: this.loggedInUser,
      customerId: this.customerId,
      siteId: this.siteId,
    };
    this.setBusy('Deleting payable batch');
    this.vendorService.deletePayableMutation(deleteBatchCommand).subscribe(
      (result: any) => {
        if (result.data.deletePayable.status == 'SUCCESS') {
          this.batchData = this.batchData.filter(batch => batch.batchId !== this.selectedBatchId);
        } else {
          this.coreService.displayError('There was an error deleting payable batch');
        }
        this.isBusy = false;
      },
      error => {
        console.log(error);
        this.isBusy = false;
        this.coreService.displayError('There was an error deleting payable batch');
      }
    );
  };

  setBusy(message: string) {
    this.isBusy = true;
    this.busyText = message;
  }

  getAbsoluteValue(number) {
    return this.coreService.getAbsoluteValue(number);
  }
}
