import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {FormEntryService, PublicHousingActionEvent, Reexamination} from 'src/app/form-entry/form-entry.service';

interface emitPublicHousing {
  loading: boolean;
  type: number;
}
@Component({
  selector: 'app-public-housing-action',
  templateUrl: './public-housing-action.component.html',
  styleUrls: ['./public-housing-action.component.scss'],
})
export class PublicHousingActionComponent implements OnInit {
  @ViewChild('publicHousingActionForm') PHAform: NgForm;
  @Input() data: any;
  reExamination: Reexamination = new Reexamination();
  isLoading = false;
  busyText: string;
  @Output() emitPublicHousing = new EventEmitter<emitPublicHousing>();

  constructor(private formEntryService: FormEntryService) {}

  async ngOnInit(): Promise<void> {
    await this.getIncomeBasedRent();
  }

  async getIncomeBasedRent() {
    if (!this.PHAform) {
      this.setBusyText('Loading...');
    } else {
      this.setBusyText('Loading Rent Details...');
    }
    this.publicHousingLoadingEmitter();
    this.data.request.rentCalc.familySubsidyStatus = this.data.request.familySubsidyStatus;
    let publicHousingRentcalcDetails: any;

    try {
      publicHousingRentcalcDetails = await this.formEntryService.calculatePublicHousingRentcalc(
        this.data.request.hudTemplateId,
        this.data.request.household,
        this.data.request.unit,
        this.data.request.actionType.toLowerCase(),
        this.data.request
      );
      //Overwrite final rent value with Cloudstack Income Based rent
      this.data.request.amount = publicHousingRentcalcDetails.publicHousingIncomeBasedRent * 100;
      this.data.request.rentCalc.finalRent = publicHousingRentcalcDetails.publicHousingIncomeBasedRent * 100;
      this.data.request.rentCalc.split1 = publicHousingRentcalcDetails.publicHousingIncomeBasedRent * 100;
    } catch (error) {
      console.log('public housing rentcalc calculation failed: ' + JSON.stringify(error));
      throw error;
    }
    this.isLoading = false;
    this.publicHousingLoadingEmitter();
  }

  publicHousingLoadingEmitter(): void {
    if (this.PHAform) {
      if (this.data.requires58PicForm && !this.data.unit.landlord) {
        if (this.PHAform.value.familySubsidyStatus.length != 1) {
          this.emitPublicHousing.emit({loading: false, type: PublicHousingActionEvent.checkValidButtonEvent});
        } else {
          this.emitPublicHousing.emit({loading: this.PHAform.valid, type: PublicHousingActionEvent.checkValidButtonEvent});
        }
      } else {
        this.emitPublicHousing.emit({loading: this.PHAform.valid, type: PublicHousingActionEvent.checkValidButtonEvent});
      }
    } else {
      if (this.data.request.familySubsidyStatus) {
        if (this.data.requiresHotmaUpdates && this.data.request.actionType == 'Interim Recertification') {
          if (this.data.request.reExaminationReason) {
            this.emitPublicHousing.emit({loading: true, type: PublicHousingActionEvent.checkValidButtonEvent});
          } else {
            this.emitPublicHousing.emit({loading: false, type: PublicHousingActionEvent.checkValidButtonEvent});
          }
        } else {
          this.emitPublicHousing.emit({loading: true, type: PublicHousingActionEvent.checkValidButtonEvent});
        }
      } else {
        this.emitPublicHousing.emit({loading: this.isLoading, type: PublicHousingActionEvent.fisrtTimeLoadingEvent});
      }
    }
  }

  setBusyText(value: string) {
    this.isLoading = true;
    this.busyText = value;
  }
}
