<div id="form-entry">
  <div *ngIf="validationErrors" class="validationErrors">
    <div *ngIf="revalidateMessage" class="title">Please fix the following, then click Revalidate</div>
    <mat-list >
      <mat-list-item style="height:auto" *ngFor="let item of validationErrors">
      <div matLine>
      {{ item.ErrorMessage }}
      </div>
    </mat-list-item>
    </mat-list>
    <button *ngIf="allow50058Revalidate" mat-flat-button class="hds_button" (click)="revalidate50058Form()">
      Revalidate
    </button>
  </div>
  <div [hidden]="isLoading" id="pdfkit"></div>
  <div *ngIf="isLoading && !errorLoadingDocument" id="pdfkit-spinner">
    <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    <p id="spinner-text">
      Please wait while we populate your form.
    </p>
  </div>
</div>
