import {Component, Injector, Input, OnInit} from '@angular/core';
import {GlobalModalService} from 'src/app/kanso-common/core/service';
import {PayablePostedOnComponent} from './payable-posted-on/payable-posted-on.component';
import {FormGroup} from '@angular/forms';
import {CoreService} from 'src/app/core/service/core.service';
import {VendorService, PaymentPreference, PayablesDetail, PostedOn} from 'src/app/custom/vendor/vendor.service';
import {UserService} from 'src/app/core/user/user.service';
import {FeatureConfigurationsService} from 'src/app/shared/services/feature-configurations.service';
import {BaseService} from 'src/app/kanso-common/core/service';
import lodash from 'lodash';
import {GenerateNACHAService} from '../../service/generate-nacha.service';
import moment from 'moment';

@Component({
  selector: 'app-payable-detail',
  templateUrl: './payable-detail.component.html',
  styleUrls: ['./payable-detail.component.scss'],
})
export class PayableDetailComponent implements OnInit {
  customerId: string;
  siteId: string;
  @Input() showSummary: boolean;
  @Input() showAchDirectDeposit: boolean;
  @Input() showChecks: boolean;
  @Input() showOthers: boolean;
  @Input() showTableName: boolean;
  @Input() currentBatchId;
  displayedColumns: string[] = ['Pay to', 'Last Payment Amount', 'Amount Paid', 'download', 'Post'];
  achDirectDeposit = [];
  checks = [];
  other = [];
  payableReview;
  selectedType;
  voidVendors = [];
  voidMessage = [];
  achPosted: boolean;
  checkPosted: boolean;
  otherPosted: boolean;
  checkReport: string;
  generalLedgerReports: string;
  payablesChecksLink: string;
  generalLedgerReportsLink: string;
  reportingSubDomain: string;
  isBusy: boolean;
  busyText: string;
  featureConfigurations = [];
  configurationsValues = [];
  stage: string;
  private CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  private SITE_ID = sessionStorage.getItem('SITEID') || '';
  constructor(
    public globalModalService: GlobalModalService,
    protected injector: Injector,
    public vendorService: VendorService,
    public coreService: CoreService,
    public userService: UserService,
    public baseService: BaseService,
    private featureConfigurationsService: FeatureConfigurationsService,
    private generateNACHAService: GenerateNACHAService
  ) {}
  // eslint-disable-next-line
  ngOnInit(): void {
    this.customerId = this.CUSTOMER_ID;
    this.siteId = this.SITE_ID;
    this.setBusy('Loading');
    this.stage = this.baseService.getStageContext();
    if (!this.showTableName) {
      this.displayedColumns = ['Pay to', 'Last Payment Amount', 'Amount Paid'];
    }
    if (this.currentBatchId) {
      //need to add sequence number from transactions to this view
      //Also add companyName to identify is this vendor is individual or organization;
      this.vendorService.getBatchedTransactionsWithVendorNames(this.siteId, this.currentBatchId).subscribe(result => {
        this.showTableName = true;
        this.showSummary = true;
        this.displayedColumns.push('download', 'Post');
        this.achDirectDeposit = result.filter(g => g.paymentPreference == PaymentPreference[PaymentPreference.ACH])[0]?.transactions;
        this.checks = result.filter(g => g.paymentPreference == PaymentPreference[PaymentPreference.Check])[0]?.transactions;
        this.other = result.filter(g => g.paymentPreference == PaymentPreference[PaymentPreference.Other])[0]?.transactions;
        if (this.achDirectDeposit && this.achDirectDeposit.length > 0) {
          this.showAchDirectDeposit = true;
          this.achPosted = this.achDirectDeposit.filter(t => t.postedOn).length > 0;
        }
        if (this.checks && this.checks.length > 0) {
          this.showChecks = true;
          this.checkPosted = this.checks.filter(t => t.postedOn).length > 0;
        }
        if (this.other && this.other.length > 0) {
          this.showOthers = true;
          this.otherPosted = this.other.filter(t => t.postedOn).length > 0;
        }
        this.isBusy = false;
      });
      this.getReportCategories();
    }
  }

  getReportCategories() {
    this.featureConfigurationsService.getFeatureConfigurations().subscribe(result => {
      this.featureConfigurations = lodash.cloneDeep(result[0]);
      this.featureConfigurations = this.featureConfigurations.sort((a, b) => a.domain.localeCompare(b.domain));
      this.featureConfigurationsService.getConfigurationsValues().subscribe(configValues => {
        this.configurationsValues = lodash.cloneDeep(configValues[0]);
        for (const featureConfig of this.featureConfigurations) {
          const selectedValue = this.configurationsValues.find(config => config.featureConfigurationId === featureConfig.id);
          if (selectedValue) {
            switch (true) {
              case featureConfig.subdomain == 'selectTelerikCheckReport': {
                this.checkReport = selectedValue.textValue;
                this.buildTelerikCheckReportURL(this.checkReport);
                break;
              }
              case featureConfig.subdomain == 'selectTelerikGLReport': {
                this.generalLedgerReports = selectedValue.textValue;
                this.buildTelerikOtherReportURL(this.generalLedgerReports);
                break;
              }
            }
          }
        }
      });
    });
  }

  buildTelerikCheckReportURL(checkReport) {
    this.reportingSubDomain = this.stage === 'dev' ? 'dev-reporting' : 'reporting';
    this.payablesChecksLink = `${window.location.protocol}//${window.location.host
      .replace(':3000', '')
      .replace('.app', '')
      .replace('.hdsdoorways', `.${this.reportingSubDomain}.hdsdoorways`)}/reports/Payables/${checkReport}?c=${this.customerId}&s=${
      this.siteId
    }&reportsListCategory=Payables`;
  }

  buildTelerikOtherReportURL(glReport) {
    this.reportingSubDomain = this.stage === 'dev' ? 'dev-reporting' : 'reporting';
    this.generalLedgerReportsLink = `${window.location.protocol}//${window.location.host
      .replace(':3000', '')
      .replace('.app', '')
      .replace('.hdsdoorways', `.${this.reportingSubDomain}.hdsdoorways`)}/reports/General%20Ledger%20Exports/${glReport}?c=${
      this.customerId
    }&s=${this.siteId}&reportsListCategory=General%20Ledger%20Exports`;
  }

  sumAmount(paymentDetail: PayablesDetail[], property: string): number {
    return this.getAbsoluteValue(paymentDetail.reduce((sum: number, obj: PayablesDetail) => sum + obj[property], 0));
  }

  getAbsoluteValue(number) {
    return this.coreService.getAbsoluteValue(number);
  }

  onOpenPostedOn(type): void {
    this.selectedType = type;
    this.globalModalService.openModal<PostedOn>(PayablePostedOnComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      date: new Date(),
      updateFunction: this.onSavePostedOn,
      description: '',
    });
  }

  onSavePostedOn = async (formInfo: FormGroup) => {
    this.setBusy('Post Payable Transactions');
    const dataToDatabase = {
      date: formInfo.value.postedOnDate,
      description: formInfo.value.postedOnDescription,
    };
    const postVendors = [];
    switch (this.selectedType) {
      case PaymentPreference[PaymentPreference.ACH]:
        for (const transaction of this.achDirectDeposit) {
          postVendors.push(transaction.vendorId);
        }
        break;
      case PaymentPreference[PaymentPreference.Check]:
        for (const transaction of this.checks) {
          postVendors.push(transaction.vendorId);
        }
        break;
      case PaymentPreference[PaymentPreference.Other]:
        for (const transaction of this.other) {
          postVendors.push(transaction.vendorId);
        }
        break;
    }
    try {
      const command = {
        batchId: this.currentBatchId,
        siteId: this.siteId,
        customerId: this.customerId,
        createdBy: this.coreService.getCurrentUsersLogInCookie(),
        postedOn: dataToDatabase.date,
        description: dataToDatabase.description,
        vendorIds: postVendors,
      };
      this.vendorService.postPayableTransaction(command).subscribe(res => {
        switch (this.selectedType) {
          case PaymentPreference[PaymentPreference.ACH]:
            for (const transaction of this.achDirectDeposit) {
              transaction.postedOn = dataToDatabase.date;
              this.achPosted = true;
            }
            break;
          case PaymentPreference[PaymentPreference.Check]:
            for (const transaction of this.checks) {
              transaction.postedOn = dataToDatabase.date;
              this.checkPosted = true;
            }
            break;
          case PaymentPreference[PaymentPreference.Other]:
            for (const transaction of this.other) {
              transaction.postedOn = dataToDatabase.date;
              this.otherPosted = true;
            }
            break;
        }
        this.isBusy = false;
      });
    } catch (error) {
      this.isBusy = false;
      this.coreService.displayError(`Error trying to post Payable Transactions: ${error.message}`);
      console.log(`Error trying to post Payable Transactions: ${error.message}`);
    }
  };

  voidPayableBatch(type) {
    this.voidMessage = [];
    this.voidVendors = [];
    this.selectedType = type;
    switch (type) {
      case PaymentPreference[PaymentPreference.ACH]:
        for (const transaction of this.achDirectDeposit) {
          this.voidVendors.push(transaction.vendorId);
          this.voidMessage.push(`${transaction.payTo}: $${transaction.balanceToBePaid} \n `);
        }
        break;
      case PaymentPreference[PaymentPreference.Check]:
        for (const transaction of this.checks) {
          this.voidVendors.push(transaction.vendorId);
          this.voidMessage.push(`${transaction.payTo}: $${transaction.balanceToBePaid}, Check #: ${transaction.checkNumber} \n `);
        }
        break;
      case PaymentPreference[PaymentPreference.Other]:
        for (const transaction of this.other) {
          this.voidVendors.push(transaction.vendorId);
          this.voidMessage.push(`${transaction.payTo}: $${transaction.balanceToBePaid} \n `);
        }
        break;
    }
    this.coreService.confirmAction(
      this._deleteConfirmMessage(),
      this._voidPayableTransactions,
      `Void all ${type} payments in this Batch?`,
      'Are you sure you want to void these transactions? This cannot be undone.',
      `Void`
    );
  }

  private _deleteConfirmMessage() {
    return `This will void the following transactions:                 
    ${this.voidMessage}`;
  }

  private _voidPayableTransactions = () => {
    this.setBusy('Void Payable Transactions');
    try {
      const command = {
        batchId: this.currentBatchId,
        siteId: this.siteId,
        customerId: this.customerId,
        voidedBy: this.coreService.getCurrentUsersLogInCookie(),
        vendorIds: this.voidVendors,
      };
      this.vendorService.voidPayableTransaction(command).subscribe(res => {
        switch (this.selectedType) {
          case PaymentPreference[PaymentPreference.ACH]:
            for (const transaction of this.achDirectDeposit) {
              transaction.voidedOn = new Date();
              transaction.postedOn = null;
            }
            break;
          case PaymentPreference[PaymentPreference.Check]:
            for (const transaction of this.checks) {
              transaction.voidedOn = new Date();
              transaction.postedOn = null;
            }
            break;
          case PaymentPreference[PaymentPreference.Other]:
            for (const transaction of this.other) {
              transaction.voidedOn = new Date();
              transaction.postedOn = null;
            }
            break;
        }
        this.isBusy = false;
      });
    } catch (error) {
      this.isBusy = false;
      this.coreService.displayError(`Error trying to void Payable Transactions: ${error.message}`);
      console.log(`Error trying to void Payable Transactions: ${error.message}`);
    }
  };

  async generateNACHAFile() {
    try {
      this.setBusy('Generating NACHA File');
      const {
        housingAuthority,
        authorityACH,
        decryptedAuthority,
        modifiedACHTransactionsObject,
      } = await this.generateNACHAService.ACHDatabuilder(this.achDirectDeposit);
      const NACHAFileString = this.generateNACHAService.NACHAFileBuilder(
        housingAuthority,
        authorityACH,
        decryptedAuthority,
        modifiedACHTransactionsObject,
        this.currentBatchId
      );
      const blob = new Blob([NACHAFileString], {type: 'text/plain;charset=utf-8'});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `NACHA-${moment().format('MM-DD-YY')}-${this.currentBatchId}.txt`;

      // Programmatically trigger a click event on the link
      a.dispatchEvent(new MouseEvent('click'));

      window.URL.revokeObjectURL(url);
    } catch (error) {
      this.coreService.displayError(error);
    } finally {
      this.isBusy = false;
    }
  }

  setBusy(message: string) {
    this.isBusy = true;
    this.busyText = message;
  }
}
