<mat-card class="knso-card void-transaction-container">
    <mat-card-header>
        <mat-toolbar>
            <span>Void this Payment?</span>
            <button mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
          </mat-toolbar>
    </mat-card-header>
    <form #voidTransactionForm="ngForm">   
        <mat-card-content>
        <div>
            <p>
                Are you sure you want to void this transaction? Doing so will also void the following adjustments:
            </p>
            <p>
                {{transaction.vendorAccountName}}: ${{transaction.amount}}
            </p>
            <div class="description-area">
                <p>Description: </p>
                <mat-form-field appearance="outline">
                    <textarea
                        class="void-reason-input"
                        matInput
                        ngModel
                        [(ngModel)]="voidReason"
                        #voidReasonInput="ngModel"
                        name="voidReasonInput"
                        placeholder="Please enter your reason"
                        required
                        rows="4">
                    </textarea>
                </mat-form-field>
            </div>
        </div>
        <div>
            Are you sure you want to void this transaction? This cannot be undone.
        </div>
        </mat-card-content>
        <mat-card-actions>
            <div class="bottom-buttons">
                <button mat-flat-button [disabled]="!voidTransactionForm.valid" class="hds_button" (click)="completeAction()" id="saveButton">Yes</button>       
                <button mat-flat-button class="hds_button cancel"  (click)="closeDialog()" id="cancelButton">No</button>
            </div>
        </mat-card-actions>
    </form>
</mat-card>
