import {Component, Input, ViewChild, Injector, OnInit} from '@angular/core';
import {GlobalModalChild, GlobalModalParent, GlobalModalService} from '../../../kanso-common/core/service/globalmodal.service';
import {Authority, Program, Project, ProjectModalData} from '../../housing-core/services/housing-models';
import {CustomAttributes} from 'src/app/core/service/core-models';
import {AccountingService} from '../../accounting/service/accounting.service';
import {ISubLedgerMap} from '../../accounting/service/accounting-models';
import {ControlContainer, NgForm, FormGroup, Validators, FormBuilder} from '@angular/forms';
import {CoreService} from 'src/app/core/service/core.service';
import {RentcalcOptionPopupComponent} from '../rentcalc-option-popup/rentcalc-option-popup.component';
import {HousingServices} from '../../housing-core/services/housing-services.service';

@Component({
  selector: 'project-setup',
  templateUrl: './project-setup.component.html',
  styleUrls: ['./project-setup.component.scss'],
  providers: [{provide: ControlContainer, useExisting: NgForm}],
})
export class ProjectSetupComponent implements GlobalModalChild<ProjectModalData>, OnInit {
  @ViewChild('editProjectForm') editProjectForm;
  name: string;
  type: string;
  projectNumber?: string;
  projectCode: string;
  programName: string;
  programType: string;
  projectSegmentCode?: string;
  rentCalcOverride = false;
  loading = false;

  editProject: Project;
  program: Program;
  selectedChartOfAccounts: ISubLedgerMap;
  globalModalParent: GlobalModalParent;
  minTTPFormGroup: FormGroup;
  ampProjectFormGroup: FormGroup;

  loggedInUser;
  selectedRentCalcOption;
  selectedRentCalcDetails = [];

  generalInfoPanelOpenState = true;
  customAttributesInfoPanelOpenState = false;
  monthlyChargeInfoPanelOpenState = false;
  accountingInfoPanelOpenState = false;

  @Input() programs: Program[];
  @Input() housingAuthority: Authority;
  @Input() chartOfAccounts?: ISubLedgerMap[];
  @Input() customAttributesTemplates: CustomAttributes[];
  // eslint-disable-next-line
  @Input() updateFunction: Function;
  @Input() editMode: boolean;
  @Input() selectedProject: Project;

  constructor(
    public accountingService: AccountingService,
    private coreService: CoreService,
    protected injector: Injector,
    public globalModalService: GlobalModalService,
    public housingServices: HousingServices,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.createRentCalc();
    this.getSelectedChartOfAccounts();
    if (this.customAttributesTemplates.filter(attr => attr.required).length > 0) {
      this.customAttributesInfoPanelOpenState = true;
    }
    this.setMinTTPFormGroup();
    this.setAmpProjectFormGroup();
  }

  getSelectedChartOfAccounts() {
    if (this.selectedProject.chartOfAccounts) {
      this.selectedChartOfAccounts = this.chartOfAccounts.find(coa => coa._id == this.selectedProject.chartOfAccounts);
    }
  }

  onAmpProjectChange() {
    if (this.ampProjectFormGroup.valid) {
      this.onCheckPristineForm();
    }
  }

  onCheckPristineForm() {
    if (this.editProjectForm.valid) {
      this.editProjectForm.form.pristine = false;
    }
  }

  closeDialog = () => {
    this.globalModalParent.closePopup();
  };

  programSelected(event) {
    if (event.value !== null) {
      this.program = this.programs.find(program => program.name === event.value);
      this.selectedProject.programType = this.program.type;
      this.rentcalcOverrideChanged();
      this.setMinTTPFormGroup();
      this.setAmpProjectFormGroup();
    }
  }

  setMinTTPFormGroup() {
    if (this.program && this.program.type == 'Public Housing') {
      const initialMinTTP =
        this.selectedProject.rentCalc && this.selectedProject.rentCalc.minTTP ? this.selectedProject.rentCalc.minTTP : 0;
      return (this.minTTPFormGroup = this.formBuilder.group({
        minTTP: [initialMinTTP, [Validators.min(0), Validators.max(51), Validators.pattern(/^[^.]*$/), Validators.required]],
      }));
    }
  }

  setAmpProjectFormGroup() {
    if (this.program && (this.program.type == 'Public Housing' || this.program.type == 'Low Rent')) {
      const initialAmpProject = this.selectedProject.ampProject;
      return (this.ampProjectFormGroup = this.formBuilder.group({
        ampProject: [initialAmpProject, [Validators.minLength(6), Validators.maxLength(6), Validators.pattern(/(^[0-9]+$)/)]],
      }));
    }
  }

  createRentCalc() {
    this.program = this.programs.find(program => program._id === this.selectedProject.program);
    switch (true) {
      case this.selectedProject.rentCalc == null && this.program == null:
        this.editProject = {
          ...this.selectedProject,
          rentCalc: {
            monthOfFiscalYearEnd: null,
          },
        };
        break;
      case this.selectedProject.rentCalc == null && !(this.program == null):
        this.editProject = {
          ...this.selectedProject,
          rentCalc: {...this.program.rentCalc},
        };
        break;
      case !(this.selectedProject.rentCalc == null):
        this.rentCalcOverride = true;
        this.editProject = {
          ...this.selectedProject,
          rentCalc: {...this.selectedProject.rentCalc},
        };
        break;
    }
  }

  rentcalcOverrideChanged() {
    switch (true) {
      case this.editProject.rentCalc.monthOfFiscalYearEnd == null:
        this.monthlyChargeInfoPanelOpenState = true;
        this.editProject = {
          ...this.editProject,
          program: this.program._id,
          programName: this.program.name,
          programType: this.program.type,
          rentCalc: {...this.program.rentCalc},
        };
        break;
      case this.rentCalcOverride && this.selectedProject.rentCalc == null && this.selectedProject.name !== '':
        this.monthlyChargeInfoPanelOpenState = true;
        this.editProject = {
          ...this.selectedProject,
          rentCalc: {...this.program.rentCalc},
        };
        break;
      case this.rentCalcOverride && this.selectedProject.rentCalc !== null:
        this.monthlyChargeInfoPanelOpenState = true;
        this.editProject = {
          ...this.selectedProject,
          rentCalc: {...this.selectedProject.rentCalc},
        };
        break;
      case !this.rentCalcOverride && !this.selectedProject.name == null:
        this.editProject = {
          ...this.selectedProject,
          rentCalc: {...this.program.rentCalc},
        };
        break;
      case !this.rentCalcOverride && this.selectedProject.name == null:
        this.editProject = {
          ...this.editProject,
          rentCalc: {...this.program.rentCalc},
        };
        break;
    }
  }

  getHeaderText() {
    return `${this.editMode ? this.editProject.name : 'New Project'} `;
  }

  changeDisplayedRentCalcOption() {
    this.selectedRentCalcDetails = this.editProject.rentCalc[this.selectedRentCalcOption];
  }

  openRentCalcOptionsDialog(index) {
    const editRentCalcOption = this.editProject.rentCalc[this.selectedRentCalcOption][index];
    this.globalModalService.openModal(RentcalcOptionPopupComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      index: index,
      chargeType: this.selectedRentCalcOption,
      rentCalcOption: editRentCalcOption,
      updateFunction: this.updateRentCalcOption,
    });
  }

  newRentCalcOption() {
    const newRentCalcOption = {
      createdBy: this.loggedInUser,
      createdOn: new Date(),
      effectiveDate: new Date(),
      modifiedBy: null,
      modifiedOn: new Date(),
      value: [],
    };
    this.globalModalService.openModal(RentcalcOptionPopupComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      chargeType: this.selectedRentCalcOption,
      rentCalcOption: newRentCalcOption,
      updateFunction: this.addNewRentCalcOption,
    });
  }

  deleteRentCalcOptions(index) {
    this.editProject.rentCalc[this.selectedRentCalcOption].splice(index, 1);
  }

  addNewRentCalcOption = rentCalcOption => {
    this.editProject.rentCalc[this.selectedRentCalcOption].push(rentCalcOption);
  };

  updateRentCalcOption = (rentCalcOption, index) => {
    this.editProject.rentCalc[this.selectedRentCalcOption][index] = rentCalcOption;
  };

  getCurrentUsersLogInCookie() {
    const cookies = document.cookie.split(';');
    let usersAuthCookie;
    for (const cookie of cookies) {
      if (cookie.includes('LastAuthUser')) {
        //found the cookie of the user
        usersAuthCookie = cookie;
      }
    }
    const arrayOfCookieStrings = usersAuthCookie.split('=');
    this.loggedInUser = arrayOfCookieStrings[1];
  }

  saveAmpProject() {
    if (this.program.type !== 'Public Housing' && this.program.type !== 'Low Rent') {
      return;
    }
    const ampProject = this.ampProjectFormGroup.get('ampProject').value;
    this.editProject.ampProject = ampProject != '' ? ampProject : null;
  }

  onSubmit() {
    if (this.rentCalcOverride == true) {
      this.editProject = {
        ...this.editProject,
        rentCalc: {...this.editProject.rentCalc},
      };
      this.editProject.rentCalc.minTTP = this.program.type == 'Public Housing' ? this.minTTPFormGroup.get('minTTP').value : null;
    } else {
      this.editProject = {
        ...this.editProject,
        rentCalc: null,
      };
    }
    this.editProject.chartOfAccounts = this.editProject.chartOfAccounts == '' ? null : this.editProject.chartOfAccounts;
    this.saveAmpProject();
    this.updateFunction(this.editProject);
    this.globalModalParent.closePopup();
  }
}
