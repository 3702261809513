import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
//import { ObjectId } from 'bson';
import {GlobalModalChild, GlobalModalParent} from '../../../../../kanso-common/core/service/globalmodal.service';
import _ from 'lodash';
import {ISubLedgerMap, GeneralLedgerAccount, TransactionCategory} from '../../../../accounting/service/accounting-models';
import {TransactionCategoryClass} from '../../../../accounting/service/accounting.objects';
import {
  categories,
  IPanelData,
  subCategorySaveAction,
  categorySaveEventPayload,
  SubledgerMapModalData,
} from '../../models/accounting-setup.models';

@Component({
  selector: 'subledger-mapping-edit-full',
  templateUrl: './subledger-mapping-edit-full.component.html',
  styleUrls: ['./subledger-mapping-edit-full.component.scss'],
})
export class SubledgerMappingEditFullComponent implements GlobalModalChild<SubledgerMapModalData>, SubledgerMapModalData {
  _subledgerMap: ISubLedgerMap;

  @Input()
  set subledgerMap(selectedMap: ISubLedgerMap) {
    this._subledgerMap = selectedMap;
    this.editSubledgerMap = _.cloneDeep(selectedMap);
    this._createPanels();
  }

  get subledgerMap() {
    return this._subledgerMap;
  }

  @Input() generalLedgerAccounts: GeneralLedgerAccount[];
  @Input() editMode: boolean;
  // eslint-disable-next-line
  @Input() updateFunction: Function;

  knsoPanelsCharges: IPanelData[];
  knsoPanelsPayments: IPanelData[];
  knsoPanelsSystem: IPanelData[];

  editSubledgerMap: ISubLedgerMap;
  globalModalParent: GlobalModalParent;

  @ViewChild('scrollDiv') scrollDiv: ElementRef<HTMLElement>;

  categories = categories;

  panelTypes: any = {
    chargeCategories: 'knsoPanelsCharges',
    systemCategories: 'knsoPanelsSystem',
    creditCategories: 'knsoPanelsPayments',
  };

  private _subCategoryEdit = false;
  private _categoryEditName = '';
  private _editPanelIndex = -1;
  private _scrollTop = 0;
  private __SytemTransactions = 'System Transactions';

  preventDefaultAction(e: Event) {
    e.stopPropagation();
  }

  private _createPanels() {
    this.knsoPanelsCharges = [];
    this.editSubledgerMap.chargeCategories.forEach(category => {
      this.knsoPanelsCharges.push(this._createPanel(category));
    });
    this.knsoPanelsPayments = [];
    this.editSubledgerMap.creditCategories.forEach(category => {
      this.knsoPanelsPayments.push(this._createPanel(category));
    });
    this.knsoPanelsSystem = [];
    this.editSubledgerMap.systemCategories.forEach(category => {
      this.knsoPanelsSystem.push(this._createPanel(category));
    });
  }

  private _createPanel(category: TransactionCategory): IPanelData {
    return {
      contentData: category,
      isEdit: false,
      isNew: false,
      title: category.name,
    };
  }

  openPanels: panelState[] = [];

  // next two functions track state of panels.
  // allows form to keep previously open panels open
  // when exp panel component is re-rendered
  panelOpen(index, category) {
    if (this._findPanel(index, category) === -1) {
      this.openPanels.push({
        category: category,
        index: index,
      });
    }
  }

  panelClosed(index, category) {
    const closedIndex = this._findPanel(index, category);
    this.openPanels.splice(closedIndex, 1);
  }

  panelIsOpen(index, category) {
    const panelIndex = this._findPanel(index, category);
    return panelIndex !== -1;
  }

  private _findPanel(index, category) {
    return this.openPanels.findIndex(panel => panel.category === category && panel.index === index);
  }

  contentScroll(e) {
    // track scroll position when in browse mode
    if (!this.isCategoryEdit()) {
      this._scrollTop = this.scrollDiv.nativeElement.scrollTop;
    }
  }

  isCategoryEdit() {
    // search all categories for one in edit mode.
    // since all these category/subcategory objects all
    // live in the same document can only allow editing
    //of one object at a time.
    const allPanels = [...this.knsoPanelsCharges, ...this.knsoPanelsPayments, ...this.knsoPanelsSystem];
    return allPanels.findIndex(panel => panel.isEdit === true) !== -1 || this._subCategoryEdit;
  }

  sectionIsVisible(category: string) {
    // when editing a sub-category hide all sections except section that is being edited
    return !this._subCategoryEdit || this._categoryEditName === category;
  }

  panelIsVisible(index: number, category: string) {
    // when editing a sub-category hide all sub-categories except sub-category that is being edited
    const returnVar = !this._subCategoryEdit || (this._categoryEditName === category && this._editPanelIndex === index);
    return !this._subCategoryEdit || (this._categoryEditName === category && this._editPanelIndex === index);
  }

  getAddBtnClass(): string {
    //sets add btn class and adds disabled styles if appropriate
    return this.isCategoryEdit() ? `add-btn disabled ${this.baseClass}` : `add-btn ${this.baseClass}`;
  }

  private baseClass = 'action-btn mat-icon notranslate material-icons mat-icon-no-color ng-star-inserted';
  getActionBtnClass(panel?: IPanelData, categoryGroup?: string, buttonName?: string) {
    //sets action btn class and adds disabled styles if appropriate
    const customBase = buttonName ? `${this.baseClass} ${buttonName}` : this.baseClass;
    switch (true) {
      case panel != undefined && this._isSystemTransactionCategory(panel, categoryGroup):
        return (this.isCategoryEdit() && buttonName != 'save' && buttonName != 'cancel') || panel.title === this.__SytemTransactions
          ? `${customBase} disabled`
          : customBase;
      default:
        return this.isCategoryEdit() && buttonName != 'save' && buttonName != 'cancel' ? `${customBase} disabled` : customBase;
    }
  }

  getDoneButtonClass() {
    const baseDoneClass = 'hds_button mat-focus-indicator mat-flat-button mat-button-base';
    return this.isCategoryEdit() ? `${baseDoneClass} disabled` : `${baseDoneClass}`;
  }

  getPanelClass() {
    return this.isCategoryEdit() ? 'isedit' : '';
  }

  private _setScrollPosition() {
    setTimeout(() => {
      this.scrollDiv.nativeElement.scrollTop = this._scrollTop;
    }, 10);
  }

  editSubCategory(category: string, i) {
    //handles sub category edit event
    this._categoryEditName = category;
    this._editPanelIndex = i;
    this._subCategoryEdit = true;
  }

  cancelSubCategory() {
    //handles sub category cancel edit event
    this._subCategoryEdit = false;
    this._categoryEditName = '';
    this._editPanelIndex = -1;
    this._setScrollPosition();
  }

  saveSubCategory(payload: categorySaveEventPayload) {
    //handles sub category save event
    try {
      this.subledgerMap = Object.assign(this.subledgerMap, this.editSubledgerMap);
      this._subCategoryEdit = false;
      this.updateFunction(payload);
      this._setScrollPosition();
    } catch (error) {
      console.log(error);
    }
  }

  saveCategory(panel: IPanelData) {
    //save category edits (.i.e. name)
    panel.contentData.name = panel.title;
    panel.isNew = false;
    this.subledgerMap = Object.assign(this.subledgerMap, this.editSubledgerMap);
    this.updateFunction();
    this._setScrollPosition();
  }

  cancelEditCategory(panel: IPanelData, categoryGroup: string, index: number) {
    panel.title = panel.contentData.name;
    if (panel.isNew) {
      this.editSubledgerMap[categoryGroup].splice(index, 1);
      this._createPanels();
    }
    this._setScrollPosition();
  }

  editCategory(panel: IPanelData, categoryGroup?: string) {
    //if editable and nothing else is being edited put in edit mode
    if (!this.isCategoryEdit() && !this._isSystemTransactionCategory(panel, categoryGroup)) {
      panel.isEdit = true;
    }
  }

  newCategory(categoryGroup: string) {
    if (!this.isCategoryEdit()) {
      this._createNewCategory(categoryGroup);
    }
  }

  private _createNewCategory(categoryGroup: string) {
    const newCategory = new TransactionCategoryClass();
    newCategory.name = 'New Category';
    this.editSubledgerMap[categoryGroup].push(newCategory);
    this._createPanels();
    this._setNewEditMode(categoryGroup);
  }

  private _setNewEditMode(categoryGroup: string) {
    const panels = this[this.panelTypes[categoryGroup]];
    panels[panels.length - 1].isEdit = true;
    panels[panels.length - 1].isNew = true;
  }

  cloneCategory(panel: IPanelData, categoryGroup: string) {
    if (!this.isCategoryEdit()) {
      this._cloneCategory(panel, categoryGroup);
    }
  }

  private _cloneCategory(panel: IPanelData, categoryGroup: string) {
    const newCategory = Object.assign({}, panel.contentData);
    newCategory.name = `${panel.contentData.name} (cloned)`;
    //newCategory._id = new ObjectId()
    this.editSubledgerMap[categoryGroup].push(newCategory);
    this._createPanels();
    this._setNewEditMode(categoryGroup);
  }

  deleteCategory(index: number, categoryGroup: string) {
    // create bogus panel object to pass into _isSystemTransactionCategory
    // which only looks at title prop
    const panel = {
      title: this.editSubledgerMap[categoryGroup][index].name,
      contentData: {},
      isEdit: false,
      isNew: false,
    };
    if (!this.isCategoryEdit() && !this._isSystemTransactionCategory(panel, categoryGroup)) {
      this._deleteCategory(index, categoryGroup);
    }
  }

  private _deleteCategory(index: number, categoryGroup: string) {
    this.editSubledgerMap[categoryGroup].splice(index, 1);
    this._createPanels();
    this.subledgerMap = Object.assign(this.subledgerMap, this.editSubledgerMap);
    const eventObject: categorySaveEventPayload = {
      saveAction: subCategorySaveAction.deleteCategory,
      callback: this.deleteCategoryHandler,
    };
    this.updateFunction(eventObject);
  }

  deleteCategoryHandler = (success: boolean) => {
    if (!success) {
      this.editSubledgerMap = _.cloneDeep(this.subledgerMap);
      this._createPanels();
    }
  };
  // this is kinda a hack to prevent edit/delete of default system categories 'System Transactions' category
  // don't really like this but alternantive of adding a 'write once' field to data object seems like abigger can of worms
  // so going with this for now
  private _isSystemTransactionCategory(panel: IPanelData, categoryGroup?: string): boolean {
    return categoryGroup != undefined && categoryGroup == categories.systemCategories && panel.title === this.__SytemTransactions;
  }

  closeDialog() {
    if (!this.isCategoryEdit()) {
      this.globalModalParent.closePopup();
    }
  }
}

interface panelState {
  category: string;
  index: number;
}
