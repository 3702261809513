import {Component, Input, OnInit} from '@angular/core';
import {MatHorizontalStepper} from '@angular/material/stepper';

@Component({
  selector: 'kanso-wizard-header',
  templateUrl: './kanso-wizard-header.component.html',
  styleUrls: ['./kanso-wizard-header.component.scss'],
})
export class KansoWizardHeaderComponent implements OnInit {
  @Input('stepper') stepper: MatHorizontalStepper;
  @Input('position') position: number;

  // eslint-disable-next-line
  constructor() {}

  // eslint-disable-next-line
  ngOnInit(): void {}
}
