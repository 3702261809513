export class CreateUtilityAllowanceCommandInput {
  name: string;
  effectiveDate: Date;
  createdBy: string;
  siteId: string;
  customerId: string;
  constructor() {
    this.name = '';
    this.effectiveDate = new Date();
    this.createdBy = '';
    this.siteId = '';
    this.customerId = '';
  }
}

export class UpdateUtilityAllowanceCommandInput {
  id?: string;
  name?: string;
  modifiedBy?: string;
  siteId: string;
  customerId: string;
  constructor() {
    this.id = '';
    this.name = '';
    this.modifiedBy = '';
    this.siteId = '';
    this.customerId = '';
  }
}

export class DeleteUtilityAllowanceCommandInput {
  id: string;
  siteId: string;
  customerId: string;
  constructor() {
    this.id = '';
    this.siteId = '';
    this.customerId = '';
  }
}

export class CloneUtilityAllowanceCommandInput implements CreateUtilityAllowanceCommandInput {
  id: string;
  name: string;
  effectiveDate: Date;
  createdBy: string;
  utilityAllowanceId?: string;
  siteId: string;
  customerId: string;
  constructor() {
    this.id = '';
    this.name = '';
    this.effectiveDate = new Date();
    this.createdBy = '';
    this.siteId = '';
    this.customerId = '';
  }
}

export class CreateUtilityAllowanceTableCommandInput {
  utilityAllowanceId: string;
  effectiveDate: Date;
  cloneUtilityAllowanceId?: string;
  createdBy: string;
  siteId: string;
  customerId: string;
  constructor() {
    this.utilityAllowanceId = '';
    this.effectiveDate = new Date();
    this.createdBy = '';
    this.siteId = '';
    this.customerId = '';
  }
}

export class CreateUtilityAllowanceTableValuesCommandInput {
  utilityAllowanceTableId: string;
  category: string;
  description?: string;
  createdBy: string;
  bedroom0: number;
  bedroom1: number;
  bedroom2: number;
  bedroom3: number;
  bedroom4: number;
  bedroom5: number;
  bedroom6: number;
  bedroom7: number;
  bedroom8: number;
  siteId: string;
  customerId: string;
  constructor() {
    this.utilityAllowanceTableId = '';
    this.category = '';
    this.createdBy = '';
    this.bedroom0 = 0;
    this.bedroom1 = 0;
    this.bedroom2 = 0;
    this.bedroom3 = 0;
    this.bedroom4 = 0;
    this.bedroom5 = 0;
    this.bedroom6 = 0;
    this.bedroom7 = 0;
    this.bedroom8 = 0;
    this.siteId = '';
    this.customerId = '';
  }
}

export class UpdateUtilityAllowanceTableValuesCommandInput {
  id: string;
  utilityAllowanceTableId: string;
  category: string;
  description?: string;
  modifiedBy: string;
  bedroom0: number;
  bedroom1: number;
  bedroom2: number;
  bedroom3: number;
  bedroom4: number;
  bedroom5: number;
  bedroom6: number;
  bedroom7: number;
  bedroom8: number;
  siteId: string;
  customerId: string;
  constructor() {
    this.id = '';
    this.utilityAllowanceTableId = '';
    this.category = '';
    this.modifiedBy = '';
    this.bedroom0 = 0;
    this.bedroom1 = 0;
    this.bedroom2 = 0;
    this.bedroom3 = 0;
    this.bedroom4 = 0;
    this.bedroom5 = 0;
    this.bedroom6 = 0;
    this.bedroom7 = 0;
    this.bedroom8 = 0;
    this.siteId = '';
    this.customerId = '';
  }
}

export class DeleteUtilityAllowanceTableValueCommandInput {
  id: string;
  siteId: string;
  customerId: string;
  constructor() {
    this.id = '';
    this.siteId = '';
    this.customerId = '';
  }
}
