import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {qqlPageInfo} from 'src/app/custom/vendor/vendor-model';
import {goToPagePayload, pageNext, pagePrevious} from '../../service/core-models';

@Component({
  selector: 'page-nav',
  templateUrl: './page-nav.component.html',
  styleUrls: ['./page-nav.component.scss'],
})
export class PageNavComponent implements OnChanges {
  @Input() totalCount: number;
  @Input() currentRecordsCount: number;
  @Input() pageInfo: qqlPageInfo;
  @Input() pageSize: FormControl;
  @Output() goToPage: EventEmitter<goToPagePayload> = new EventEmitter();

  //pageSize = new FormControl('25');
  pageOf = '';
  disabledNavButtonClass = 'disabled-icon';
  pagePreviousClass = 'navButton-previous';
  baseClass = 'mat-icon notranslate material-icons mat-icon-no-color';

  ngOnChanges(changes: SimpleChanges): void {
    if ('pageInfo' in changes) {
      this.pageOf = `page ${Math.ceil(this.currentRecordsCount / this.pageSize.value)} of ${Math.ceil(
        this.totalCount / this.pageSize.value
      )}`;
    }
  }

  navButtonClass(button: string) {
    switch (button) {
      case 'previous':
        return !this.pageInfo.hasPreviousPage
          ? `${this.baseClass} ${this.disabledNavButtonClass} ${this.pagePreviousClass}`
          : `${this.baseClass} ${this.pagePreviousClass}`;
      case 'next':
        return !this.pageInfo.hasNextPage ? `${this.baseClass} ${this.disabledNavButtonClass}` : `${this.baseClass}`;
      default:
        return '';
    }
  }

  goPage(page) {
    switch (page) {
      case 'previous':
        this.goToPage.emit({
          cursor: this.pageInfo.startCursor,
          direction: pagePrevious,
          pageSize: this.pageSize.value,
        });
        break;
      case 'next':
        this.goToPage.emit({
          cursor: this.pageInfo.endCursor,
          direction: pageNext,
          pageSize: this.pageSize.value,
        });
        break;
      default:
        break;
    }
  }
}
