import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'force-init',
  templateUrl: './force-init.component.html',
  styleUrls: ['./force-init.component.scss'],
})
export class ForceInitComponent implements OnInit {
  constructor() {
    /* do nothing */
  }

  ngOnInit(): void {
    /* do nothing */
  }
}
