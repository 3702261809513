import {Component, OnDestroy} from '@angular/core';
import {Vendor} from '../../housing-core/services/housing-models';
import {VendorService} from '../vendor.service';
import {ActivatedRoute} from '@angular/router';
import {CoreService} from 'src/app/core/service/core.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-vendor-header',
  templateUrl: './vendor-header.component.html',
  styleUrls: ['./vendor-header.component.scss'],
})
export class VendorHeaderComponent implements OnDestroy {
  subscription: Subscription;
  vendor: Vendor;
  loading = true;

  constructor(private vendorService: VendorService, private route: ActivatedRoute, public coreService: CoreService) {
    this.subscription = this.vendorService.getVendor().subscribe(result => {
      if (result) {
        this.vendor = result;
        this.loading = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
