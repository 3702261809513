<div class="navControlsWrapper">
    <mat-icon [class]="navButtonClass('previous')" (click)="goPage('previous')" >navigate_next</mat-icon>
    <span class="pageOfText">
        {{pageOf}}
    </span>
    <mat-icon [class]="navButtonClass('next')" (click)="goPage('next')">navigate_next</mat-icon>
    <mat-form-field appearance="outline">
    <mat-select [formControl]="pageSize" >
        <mat-option value="10">10 per page</mat-option>
        <mat-option value="15">15 per page</mat-option>
        <mat-option value="25">25 per page</mat-option>
        <mat-option value="50">50 per page</mat-option>
    </mat-select>        
    </mat-form-field>
    </div>

