import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GlobalAngular2ErrorHandlerService {
  constructor(private location: Location, private http: HttpClient) {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async error(msg: string, details: any): Promise<void> {
    await this._log('error', msg, details);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async _log(logLevel: string, message: string, details: any): Promise<void> {
    const client = {
      userAgent: window.navigator.userAgent,
      url: this.location.path(),
    };

    const source = '--';

    const logStatement = {
      logLevel,
      message,
      source,
      client,
      details,
    };

    try {
      this.http.post('api/logs', logStatement);
    } catch (error) {
      console.error(`Unable to send log: ${error}`);
    }
  }
}
