<div class="flexColumn">
    <busy-notice
        [isBusy]="this.isBusy"
        [busyText]="this.busyText"
        headerText="KANSO">
    </busy-notice>
    <table mat-table [dataSource]="batchData" *ngIf="batchData && batchData.length > 0;else noPayableHistory">
        <ng-container matColumnDef="Batch #">
            <th mat-header-cell *matHeaderCellDef>Batch #</th>
            <td mat-cell *matCellDef="let element">
                {{ element.batchId.toString().padStart(3, '0') }}</td>
        </ng-container>
        <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">{{ element.createdOn | date: 'MMM dd,YYYY' }}</td>
        </ng-container>
        <ng-container matColumnDef="Number of Payments">
            <th mat-header-cell *matHeaderCellDef>Number Of Payments</th>
            <td mat-cell *matCellDef="let element">
                {{ element.numberOfTransactions }}
            </td>
        </ng-container>
        <ng-container matColumnDef="Amount">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td mat-cell *matCellDef="let element">{{ getAbsoluteValue(element.amount) | currency}}
            </td>
        </ng-container>
        <ng-container matColumnDef="Created By">
            <th mat-header-cell *matHeaderCellDef>Created By</th>
            <td mat-cell *matCellDef="let element">{{ element.createdBy}}
            </td>
        </ng-container>
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button [disabled]="element.postedOn != 0"  (click)="onDeleteBatchHistory($event, element)">
                    <mat-icon [ngClass]="element.postedOn == 0 ? 'posted-enabled' : 'posted-disabled'">delete_outline</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header table-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-header table-row" (click)="onBatchHistorySelected(row)"></tr>
    </table>
    <div class="pageNavigation" *ngIf="batchData && batchData.length > 0">
        <page-nav
            [totalCount]="totalCount"
            [pageInfo]="pageInfo"
            [currentRecordsCount]="currentRecordsCount"
            [pageSize]="pageSize"
            (goToPage)="goToPageHandler($event)">
        </page-nav>
    </div>
    <ng-template #noPayableHistory>
        <p>There are no historical payable batches</p>
    </ng-template>
</div>
