import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FileroomUploadButtonComponent} from './fileroom-upload-button/fileroom-upload-button.component';
import {MatIconModule} from '@angular/material/icon';
import {FileroomDashboardComponent, FileroomDashboardModule, FileroomUploaderComponent, FileroomUploaderModule} from '@hds1/fileroom';

@NgModule({
  declarations: [FileroomUploadButtonComponent],
  imports: [CommonModule, MatIconModule, FileroomDashboardModule, FileroomUploaderModule],
  exports: [FileroomUploadButtonComponent],
  entryComponents: [FileroomUploadButtonComponent, FileroomUploaderComponent, FileroomDashboardComponent],
})
export class FileroomModule {}
