<section class="tabs__panel">
  <section class="fillBox">
    <section>
      <div layout="row">
        <h2>TIC</h2>
      </div>
    </section>
    <hr class="throughTout" />
    <div layout="row" layout-align="start center" class="filterRow">
      <div (click)="showFilter = true" class="filterButton">
        <mat-icon svgIcon="filter-icon"></mat-icon>
        <a>SHOW FILTERS</a>
      </div>
      <div *ngIf="showFilter" class="filter" offset="74">
        <div layout="row" layout-align="space-between center" class="filterHeader">
          <div>
            <a>FILTERS</a>
          </div>
          <div class="filterActionButtons">
            <a (click)="showFilter = false">Hide</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
