<mat-card class="knso-card edit-payment-standard-container">
    <mat-card-header>
        <mat-toolbar>
            <span>Payment Standards</span>
            <button mat-icon-button (click)="cancel()"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
    </mat-card-header>
    <form #popupForm="ngForm" novalidate>
        <mat-card-content *ngIf="!loading">
            <div class="layout-row topAndBottomPadding">
                <div class="rightPadding" *ngIf="!createMode">
                    <button 
                        [disabled]="editMode"
                        mat-flat-button
                        class="hds_button editButton"
                        (click)="enableEditMode()" 
                        id="editButton">
                        Edit
                    </button>
                </div>
                <mat-form-field class="editNameField" appearance="outline">
                    <mat-label>Name</mat-label>
                    <input 
                        matInput  
                        ngModel 
                        [(ngModel)]="editPayment.name"
                        #editNameField="ngModel"
                        name="editNameField"
                        [disabled]="!editMode"
                        id="nameInput"
                        type="text"
                        required
                        aria-label="Name">
                </mat-form-field>
            </div>
            <div class="layout-column">
                <div class="addIncomePadding"> 
                    <button 
                        *ngIf="editMode" 
                        appearance="outline"
                        type="button"
                        mat-flat-button
                        class="hds_button"
                        (click)="addTableRow()" 
                        id="cancelButton">
                        Add Income Level
                    </button>
                </div>
                <table *ngIf="!editMode" class="altRows">
                    <thead>
                        <tr >
                            <th class="textCenter">Effective Date</th>
                            <th class="textCenter">0 Bedroom</th>
                            <th class="textCenter">1 Bedroom</th>
                            <th class="textCenter">2 Bedrooms</th>
                            <th class="textCenter">3 Bedrooms</th>
                            <th class="textCenter">4 Bedrooms</th>
                            <th class="textCenter">5 Bedrooms</th>
                            <th class="textCenter">6 Bedrooms</th>
                            <th class="textCenter">7 Bedrooms</th>
                            <th class="textCenter">8 Bedrooms</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tr *ngFor="let f of editPayment.paymentStandardTables; let i = index" id="paymentStandardsDisplay">
                        <td><span *ngIf="!editMode" class="tablePadding" id="effectiveDate">{{f.effectiveDate |date:'MM/dd/yyyy'}}</span></td>
                        <td><span *ngIf="!editMode && f.values[0]" class="tablePadding" id="bedroom0">{{f.values[0].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.values[1]" class="tablePadding" id="bedroom1">{{f.values[1].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.values[2]" class="tablePadding" id="bedroom2">{{f.values[2].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.values[3]" class="tablePadding" id="bedroom3">{{f.values[3].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.values[4]" class="tablePadding" id="bedroom4">{{f.values[4].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.values[5]" class="tablePadding" id="bedroom5">{{f.values[5].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.values[6]" class="tablePadding" id="bedroom6">{{f.values[6].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.values[7]" class="tablePadding" id="bedroom7">{{f.values[7].amount | currency}}</span></td>
                        <td><span *ngIf="!editMode && f.values[8]" class="tablePadding" id="bedroom8">{{f.values[8].amount | currency}}</span></td>
                    </tr>
                </table>
                <table>
                    <tr *ngFor="let f of editPayment.paymentStandardTables; let i = index"
                        id="paymentStandardsInput">
                        <td>
                            <mat-form-field class="paymentStandardEffectiveDate" appearance="outline" *ngIf="editMode">
                                <mat-label>Effective Date:</mat-label>
                                <div class="layout-row ">
                                    <input class="leftPadding"
                                        matInput 
                                        [(ngModel)]="f.effectiveDate" 
                                        ngModel 
                                        required
                                        #effectiveDatePicker="ngModel"
                                        [name]="'effectiveDatePicker' + i"
                                        id="effectiveDate"
                                        aria-label="Effective Date"
                                        [matDatepicker]="datepicker">
                                    <mat-datepicker-toggle class="kansoBlue" matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                                    <mat-datepicker class="kansoBlue" #datepicker></mat-datepicker>
                                </div>
                            </mat-form-field> 
                        </td>
                        <td>
                            <mat-form-field class="paymentStandards" appearance="outline" *ngIf="editMode" >
                                <mat-label>0 Bedroom</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.values[0].amount"
                                    #0BedroomInput="ngModel"
                                    [name]="'0BedroomInput' + i"
                                    id="0BedroomInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="paymentStandards" appearance="outline" *ngIf="editMode" >
                                <mat-label>1 Bedroom</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.values[1].amount"
                                    #1BedroomInput="ngModel"
                                    [name]="'1BedroomInput' + i"
                                    id="1BedroomInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field  class="paymentStandards" appearance="outline" *ngIf="editMode" >
                                <mat-label>2 Bedrooms</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.values[2].amount"
                                    #2Bedroomput="ngModel"
                                    [name]="'2Bedroomput' + i"
                                    id="2Bedroomput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="paymentStandards" appearance="outline" *ngIf="editMode" >
                                <mat-label>3 Bedrooms</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.values[3].amount"
                                    #3BedroomInput="ngModel"
                                    [name]="'3BedroomInput' + i"
                                    id="3BedroomInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="paymentStandards" appearance="outline" *ngIf="editMode" >
                                <mat-label>4 Bedrooms</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.values[4].amount"
                                    #4BedroomInput="ngModel"
                                    [name]="'4BedroomInput' + i"
                                    id="4BedroomInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="paymentStandards" appearance="outline" *ngIf="editMode" >
                                <mat-label>5 Bedrooms</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.values[5].amount"
                                    #5BedroomInput="ngModel"
                                    [name]="'5BedroomInput' + i"
                                    id="5BedroomInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="paymentStandards" appearance="outline" *ngIf="editMode" >
                                <mat-label>6 Bedrooms</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.values[6].amount"
                                    #6BedroomInput="ngModel"
                                    [name]="'6BedroomInput' + i"
                                    id="6BedroomInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field  class="paymentStandards" appearance="outline" *ngIf="editMode" >
                                <mat-label>7 Bedrooms</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.values[7].amount"
                                    #7BedroomInput="ngModel"
                                    [name]="'7BedroomInput' + i"
                                    id="7BedroomInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field  class="paymentStandards" appearance="outline" *ngIf="editMode" >
                                <mat-label>8 Bedrooms</mat-label>
                                <input 
                                    matInput  
                                    ngModel 
                                    [(ngModel)]="f.values[8].amount"
                                    #8BedroomInput="ngModel"
                                    [name]="'8BedroomInput' + i"
                                    id="8BedroomInput"
                                    type="number">
                            </mat-form-field>
                        </td>
                        <td *ngIf="editMode" class="bottomPadding" >
                            <a class="kansoBlue"
                                (click)="deleteTableRow(i)"><mat-icon>delete</mat-icon>
                            </a>
                        </td>
                    </tr>
                </table>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button *ngIf="!editMode"
                type="button"
                mat-flat-button 
                class="hds_button cancel" 
                (click)="cancel()" 
                id="cancelButton">
                Close
            </button>
            <button *ngIf="editMode"
                type="button"
                mat-flat-button 
                class="hds_button cancel" 
                (click)="cancel()" 
                [disabled]="!editMode"
                id="cancelButton">
                Cancel
            </button>
            <button 
                type="button"
                mat-flat-button 
                class="hds_button" 
                (click)="onSubmit()" 
                [disabled]="!popupForm.valid || !editMode"
                id="saveButton">
                Update
            </button>
        </mat-card-actions>
    </form>
</mat-card>

