import {Component, OnInit, Injector} from '@angular/core';
import * as lodash from 'lodash';
import {CoreService} from '../../../core/service/core.service';
import {AccountingService} from '../../accounting/service/accounting.service';
import {HousingServices} from '../../housing-core/services/housing-services.service';
import {IdEmmitter, ProcessType, VendorService} from '../vendor.service';
import {GlobalModalService} from '../../../kanso-common/core/service/globalmodal.service';
import {
  VendorLedgerReport,
  VendorTransaction,
  TransactionQuery,
  PaymentAccountRemaining,
  VendorAdjustmentModalData,
  VendorAdjustmentInput,
} from '../../accounting/service/accounting-models';
import {VendorAdjustmentComponent} from './vendor-adjustment/vendor-adjustment.component';
import moment from 'moment';
import {FormControl} from '@angular/forms';
import {VendorLedgerPayoutHistory, qqlPageInfo} from '../vendor-model';
import {goToPagePayload, pageNext} from 'src/app/core/service/core-models';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

export type AccountId = string;

export interface AugmentedVendorLedgerReport {
  closingBalance: number;
  expectedDisplayInfo: string;
  infoToSearch: string;
  node: VendorLedgerReport;
  cursor: string;
}

@Component({
  selector: 'app-vendor-ledger',
  templateUrl: './vendor-ledger.component.html',
  styleUrls: ['./vendor-ledger.component.scss'],
})
export class VendorLedgerComponent implements OnInit {
  siteId: string;
  ledgerTabs = [];
  showPage = 'individual-accounts';
  vendorName = 'N/A';
  vendorType = 'N/A';
  tenantName;
  tenantAddress;
  transactions: VendorTransaction[];
  allTransactions: VendorTransaction[];
  payoutHistory: VendorLedgerPayoutHistory[];
  allVendorLedgerReport: AugmentedVendorLedgerReport[];
  vendorLedgerReport: AugmentedVendorLedgerReport[];
  vendorAccountPageInfo;
  transactionPageInfo;
  amountOwedTotal = 0;
  firstDay: Date;
  lastDay: Date;
  includeVoidedTransactions = false;
  showOnlyHoldTransactions = false;
  displayRunningBalance = true;
  hasNoAccounts = true;
  selectedAccountId;
  transactionTypeFilters = {
    charge: true,
    adjustment: true,
  };
  noLoanPrincipalAccountRecords;
  principalAccountRecords;
  showFilters = false;
  isBusy = true;
  currentUnit = {
    programName: '',
  };
  tenantStatus;
  transactionsCurrentPage: number;
  transactionsTotalCount: number;
  paymentAmountsRemaining: PaymentAccountRemaining[];
  vendorId;
  minDate: Date;
  maxDate: Date;
  vendorAdjustmentInput: VendorAdjustmentInput;
  busyText = '';

  disabledNavButtonClass = 'disabled-icon';
  pagePreviousClass = 'navButton-previous';
  baseClass = 'mat-icon notranslate material-icons mat-icon-no-color';
  accountCurrentPage = 1;
  accountHasPreviousPage = false;
  accountHasNextPage = false;

  vendorAccountCtrl = new FormControl('');
  filteredVendorLedgerReport: AugmentedVendorLedgerReport[];

  payOutPageSize = new FormControl('10');
  payOutPageInfo: qqlPageInfo;
  payOutLastPageSize = 0;
  payOutTotalCount = 0;
  currentPayoutRecordsCount = 0;
  ledgerPageSize = new FormControl('15');
  ledgerPageInfo: qqlPageInfo;
  ledgerTotalCount = 0;
  currentLedgerRecordsCount = 0;
  private SITE_ID = sessionStorage.getItem('SITEID') || '';
  constructor(
    public coreService: CoreService,
    public accountingService: AccountingService,
    public housingService: HousingServices,
    public vendorService: VendorService,
    public globalModalService: GlobalModalService,
    protected injector: Injector
  ) {
    this.activate();
  }

  // eslint-disable-next-line
  ngOnInit(): void {
    this.siteId = this.SITE_ID;
  }

  setBusyText = (input: string) => {
    this.isBusy = true;
    this.busyText = input;
  };
  getNavClass(page: string) {
    return page === this.showPage ? 'nav-active' : '';
  }

  getTotalOwed() {
    this.amountOwedTotal = 0;
    for (const vendorLedger of this.vendorLedgerReport) {
      if (vendorLedger.closingBalance < 0) {
        this.amountOwedTotal -= vendorLedger.closingBalance;
      }
    }
  }

  setTabs() {
    this.ledgerTabs = [
      {
        heading: 'Overview',
        id: 'Overview',
        routerLink: [''],
      },
    ];
  }

  async activate() {
    this.setBusyText('Loading...');
    this.vendorId = this.coreService.getPathNamePart(this.coreService.VendorPathParts.Id);
    await this.vendorService.getSelectedVendor(this.vendorId).subscribe(result => {
      this.vendorName = result.companyName ? result.companyName : `${result.firstName} ${result.lastName}`;
      this.vendorType = result.vendorType;
    });
    try {
      await this.vendorService.getVendorAccounts(this.siteId, this.vendorId).subscribe(result => {
        if (result && result[0].length > 0) {
          const vendorLedgers = lodash.cloneDeep(result);
          this.allVendorLedgerReport = vendorLedgers[0];
          this.getAllTransactions();
          this.vendorLedgerReport = this.allVendorLedgerReport;
        } else {
          this.isBusy = false;
        }
      });
      this.getVendorPayoutHistory(this.vendorId, '', pageNext, this.payOutPageSize.value);
      this.payOutPageSize.valueChanges.subscribe(value => {
        this.currentPayoutRecordsCount = 0;
        this.getVendorPayoutHistory(this.vendorId, '', pageNext, value);
      });
      this.ledgerPageSize.valueChanges.subscribe(value => {
        this.currentLedgerRecordsCount = 0;
        if (this.selectedAccountId) {
          this.getTransactions(this.selectedAccountId, '', pageNext, value);
        }
      });
      this.vendorAccountCtrl.valueChanges.subscribe(value => {
        this.filteredVendorLedgerReport = this.searchVendorAccount(value);
      });
    } catch (error) {
      this.isBusy = false;
      console.log('Error occured getting Vendor Accounts', error);
    }
  }

  resetTlr() {
    console.log('reset tlr');
  }

  searchVendorAccount(input) {
    const allVendorAccount = this.allVendorLedgerReport;
    if (!input) {
      return allVendorAccount;
    }
    if (typeof input == 'object') {
      return allVendorAccount.filter(vc => vc.infoToSearch.toLowerCase().includes(input.infoToSearch.toLowerCase()));
    } else {
      return allVendorAccount.filter(vc => vc.infoToSearch.toLowerCase().includes(input.toLowerCase()));
    }
  }

  goPage(page) {
    switch (page) {
      case 'previous':
        this.getAccountPage('backwards');
        break;
      case 'next':
        this.getAccountPage('forward');
        break;
      default:
        break;
    }
  }

  getAccountPage(direction?) {
    if (this.allVendorLedgerReport.length > 10) {
      switch (direction) {
        case 'forward':
          this.accountCurrentPage++;
          this.setVendorAccountData();
          break;
        case 'backwards':
          this.accountCurrentPage--;
          this.setVendorAccountData();
          break;
        default:
          this.setVendorAccountData();
          break;
      }
      if (this.accountCurrentPage == 1) {
        this.accountHasPreviousPage = false;
      } else {
        this.accountHasPreviousPage = true;
      }
      if (this.allVendorLedgerReport.length / (this.accountCurrentPage * 10) > 1) {
        this.accountHasNextPage = true;
      } else {
        this.accountHasNextPage = false;
      }
    } else {
      this.setVendorAccountData();
    }
  }

  setVendorAccountData() {
    const pagedData = this.allVendorLedgerReport.slice((this.accountCurrentPage - 1) * 10, this.accountCurrentPage * 10);
    this.vendorLedgerReport = pagedData;
  }

  navButtonClass(button: string) {
    switch (button) {
      case 'previous':
        return !this.accountHasPreviousPage
          ? `${this.baseClass} ${this.disabledNavButtonClass} ${this.pagePreviousClass}`
          : `${this.baseClass} ${this.pagePreviousClass}`;
      case 'next':
        return !this.accountHasNextPage ? `${this.baseClass} ${this.disabledNavButtonClass}` : `${this.baseClass}`;
      default:
        return '';
    }
  }

  vendorAccountSelectDisplay(option): string {
    return option?.expectedDisplayInfo || '';
  }

  onAccountSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedVendorAccount = event.option.value;
    this.vendorLedgerReport = [selectedVendorAccount];
    this.getTransactions(selectedVendorAccount.node.id, '', pageNext, this.ledgerPageSize.value);
    this.accountCurrentPage = 1;
    this.accountHasPreviousPage = false;
    this.accountHasNextPage = false;
    this.vendorAccountCtrl.markAsDirty();
  }

  clearVendorAccountSelection(): void {
    this.vendorAccountCtrl.setValue(null);
    this.vendorLedgerReport = this.allVendorLedgerReport;
    this.getAccountPage(null);
    this.vendorAccountCtrl.markAsDirty();
  }

  getDate(date?) {
    if (date) {
      this.firstDay = date.firstDay;
      this.lastDay = date.lastDay;
    } else {
      this.firstDay = undefined;
      this.lastDay = undefined;
    }
  }

  openPostAdjust() {
    this.vendorAdjustmentInput = {
      account: '',
      type: '',
      postedOn: moment().format('YYYY-MM-DD'),
      amount: null,
      description: '',
    };

    this.globalModalService.openModal<VendorAdjustmentModalData>(
      VendorAdjustmentComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      {
        vendorLedgerReport: this.allVendorLedgerReport,
        vendorAdjustmentInput: this.vendorAdjustmentInput,
        updateFunction: this.saveVendorTransaction,
      }
    );
  }

  saveVendorTransaction = command => {
    this.setBusyText('Loading...');
    this.vendorService.createVendorTransaction(command).subscribe(
      (response: any) => {
        this.getTransactions(response.data.createVendorTransaction.affectedEntity.vendorAccountId, '', pageNext, this.ledgerPageSize.value);
      },
      error => {
        console.log(`Error creating vendor transaction`);
        this.isBusy = false;
      }
    );
  };

  getVendorPayoutHistory(vendorId: string, cursor: string, direction: string, pageSize: number) {
    this.setBusyText('Loading...');
    const countMultiplier: number = direction != 'forward' ? -1 : 1;
    this.vendorService.getVendorLedgerHistoricalPayout(this.siteId, vendorId, cursor, direction, pageSize).subscribe(
      result => {
        this.payoutHistory = result.data;
        this.payOutPageInfo = result.pageInfo;
        this.payOutTotalCount = result.totalCount;
        const countAdjSize = direction != 'forward' ? this.payOutLastPageSize : result.data.length;
        this.currentPayoutRecordsCount = this.currentPayoutRecordsCount + countAdjSize * countMultiplier;
        this.payOutLastPageSize = result.data.length;
        this.isBusy = false;
      },
      error => {
        console.log(error);
        this.coreService.displayError('There was an error getting Payout History');
      }
    );
  }

  goToPageHandler(pagePayload: goToPagePayload, payout) {
    if (payout) {
      this.getVendorPayoutHistory(this.vendorId, pagePayload.cursor, pagePayload.direction, pagePayload.pageSize);
    } else {
      this.getTransactions(this.selectedAccountId, pagePayload.cursor, pagePayload.direction, pagePayload.pageSize);
    }
  }

  async getAllTransactions(page?: number) {
    this.hasNoAccounts = false;
    this.selectedAccountId = null;
    try {
      await this.vendorService.getVendorTransactions(this.siteId, this.vendorId).subscribe(async result => {
        this.allTransactions = lodash.cloneDeep(result[0]);

        if (this.allTransactions && this.allTransactions.length > 0) {
          for (const account of this.allVendorLedgerReport) {
            const filteredTransactions = this.allTransactions.filter(a => a.vendorAccountId == account.node.id);
            let balance = lodash.sumBy(filteredTransactions, t => t.amount);
            account.node.displayName = `${account.node.displayName}`;
            let occupiedStatus = 'Vacant';
            if (account.node.unit) {
              if (account.node.unit.unitStatusChangedEvents.length > 0) {
                const effectiveMoveInDate = account.node.unit.unitStatusChangedEvents[0].household.effectiveMoveInDate;
                if (effectiveMoveInDate) {
                  const headOfHouseholdId = account.node.unit.unitStatusChangedEvents[0].household.headOfHouseholdId;
                  for (const member of account.node.unit.unitStatusChangedEvents[0].household.members) {
                    if (member.id == headOfHouseholdId) {
                      occupiedStatus = `${member.firstName} ${member.lastName}`;
                    }
                  }
                }
              }
              account.infoToSearch = `${account.node.unit.streetAddress} - ${occupiedStatus}`;
              if ((account.node.unit.ppuCode && this.vendorType == 'Landord') || this.vendorType == 'Utility') {
                account.expectedDisplayInfo = `${account.node.unit.ppuCode.toLocaleUpperCase()} - ${occupiedStatus}`;
                account.infoToSearch += ` - ${account.node.unit.ppuCode}`;
              } else {
                account.expectedDisplayInfo = account.node.unit.apartmentNumber
                  ? `${account.node.unit.streetAddress} - ${account.node.unit.apartmentNumber} - ${occupiedStatus}`
                  : `${account.node.unit.streetAddress} - ${occupiedStatus}`;
              }
              if ((account.node.unit.ppuCode && this.vendorType == 'Maintenance') || this.vendorType == 'Other') {
                account.expectedDisplayInfo = `${account.node.displayName.toLocaleUpperCase()} - ${occupiedStatus}`;
                account.infoToSearch += ` - ${account.node.displayName}`;
              } else {
                account.expectedDisplayInfo = account.node.unit.apartmentNumber
                  ? `${account.node.unit.streetAddress} - ${account.node.unit.apartmentNumber} - ${occupiedStatus}`
                  : `${account.node.unit.streetAddress} - ${occupiedStatus}`;
              }
            } else {
              await this.vendorService.getProgram(this.siteId, account.node.programId).subscribe(result => {
                const programInfo = result[0].node;
                account.infoToSearch = `${account.node.displayName} - ${
                  programInfo.programCode ? programInfo.programCode : programInfo.programName
                }`;
                account.expectedDisplayInfo = `${programInfo.programCode ? programInfo.programCode : programInfo.programName} - ${
                  this.vendorType
                }`;
              });
            }
            account.closingBalance = balance;
            this.allTransactions = this.allTransactions.map(tx => {
              tx.isCollapsed = true;
              tx.runningBalance = balance;
              if (tx.voidedOn) {
                return tx;
              }
              balance = balance + tx.amount;
              return tx;
            });
          }
        }
        this.allVendorLedgerReport = lodash.orderBy(this.allVendorLedgerReport, ['infoToSearch'], ['asc']);
        this.vendorAccountCtrl.setValue(null);
        this.getAccountPage(null);
        this.isBusy = false;
        this.getTotalOwed();
        this.isBusy = false;
      });
    } catch (error) {
      this.isBusy = false;
      console.log('Error in fetching Transactions', error);
    }
  }

  getTransactions = (accountId: AccountId, cursor: string, direction: string, pageSize: number) => {
    this.setBusyText('Loading...');
    this.hasNoAccounts = false;
    this.selectedAccountId = accountId;
    const cursorState = cursor ? cursor : '';
    const currentDirection = direction ? direction : 'forward';
    const countMultiplier: number = currentDirection != 'forward' ? -1 : 1;
    const currentPageSize: number = pageSize ? pageSize : 15;
    try {
      if (this.minDate || this.maxDate) {
        let yearFromToday = new Date();
        yearFromToday = new Date(yearFromToday.setFullYear(yearFromToday.getFullYear() + 1));
        const minDate = this.minDate ? this.minDate : new Date('1990/01/01'); // Create Dynamic Min Date?
        const maxDate = this.maxDate ? this.maxDate : yearFromToday; // Set to Year Ahead, need to be dynamic?

        this.vendorService
          .getVendorAccountSpecificTransactionsWithDateRange(
            this.siteId,
            this.vendorId,
            accountId,
            minDate,
            maxDate,
            cursorState,
            currentDirection,
            currentPageSize
          )
          .subscribe(result => {
            this.transactions = lodash.cloneDeep(result[0]);
            // let pageInfo = result[1];
            if (this.transactions && this.transactions.length > 0) {
              if (!this.includeVoidedTransactions) {
                this.transactions = this.transactions.filter(a => !a.voidedOn);
              }
              if (this.showOnlyHoldTransactions) {
                this.transactions = this.transactions.filter(a => !a.hold);
              }
              this.transactions = lodash.orderBy(this.transactions, ['postedOn', 'createdOn'], ['desc', 'desc']);
              this.transactions = this.transactions.map(tx => {
                tx.isCollapsed = true;
                return tx;
              });
              this.tenantName = this.transactions[0].headFirstName
                ? `${this.transactions[0].headFirstName} ${this.transactions[0].headLastName}`
                : '';
              this.tenantAddress = this.transactions[0].ppuCode ? this.transactions[0].ppuCode : '';
              this.ledgerPageInfo = result[1];
              this.ledgerTotalCount = result[2];
              const countAdjSize = result[0].length;
              this.currentLedgerRecordsCount = this.currentLedgerRecordsCount + countAdjSize * countMultiplier;
              this.isBusy = false;
            }
            this.isBusy = false;
          });
      } else {
        this.vendorService
          .getVendorAccountSpecificTransactions(this.siteId, this.vendorId, accountId, cursorState, currentDirection, currentPageSize)
          .subscribe(result => {
            this.transactions = lodash.cloneDeep(result[0]);
            // let pageInfo = result[1];
            if (this.transactions && this.transactions.length > 0) {
              if (!this.includeVoidedTransactions) {
                this.transactions = this.transactions.filter(a => !a.voidedOn);
              }
              if (this.showOnlyHoldTransactions) {
                this.transactions = this.transactions.filter(a => !a.hold);
              }
              this.transactions = lodash.orderBy(this.transactions, ['postedOn', 'createdOn'], ['desc', 'desc']);
              let balance = lodash.sumBy(this.transactions, t => t.amount);
              this.transactions = this.transactions.map(tx => {
                tx.isCollapsed = true;
                tx.runningBalance = balance;
                if (tx.voidedOn) {
                  return tx;
                }
                balance = balance - tx.amount;
                return tx;
              });
              this.tenantName = this.transactions[0].headFirstName
                ? `${this.transactions[0].headFirstName} ${this.transactions[0].headLastName}`
                : '';
              this.tenantAddress = this.transactions[0].ppuCode ? this.transactions[0].ppuCode : '';
              this.ledgerPageInfo = result[1];
              this.ledgerTotalCount = result[2];
              const countAdjSize = result[0].length;
              this.currentLedgerRecordsCount = this.currentLedgerRecordsCount + countAdjSize * countMultiplier;
              this.isBusy = false;
            }
            this.isBusy = false;
          });
      }
    } catch (error) {
      this.isBusy = false;
      console.log('Error in fetching Transactions', error);
    }
  };

  async getAllTransactionsDateRange() {
    this.setBusyText('Loading...');
    try {
      let yearFromToday = new Date();
      yearFromToday = new Date(yearFromToday.setFullYear(yearFromToday.getFullYear() + 1));
      const minDate = this.minDate ? this.minDate : new Date('1990/01/01'); // Create Dynamic Min Date?
      const maxDate = this.maxDate ? this.maxDate : yearFromToday; // Set to Year Ahead, need to be dynamic?
      await this.vendorService.getVendorTransactionsWithDateRange(this.siteId, this.vendorId, minDate, maxDate).subscribe(result => {
        this.transactions = lodash.cloneDeep(result[0]);
        this.transactionPageInfo = lodash.cloneDeep(result[1]);
        if (this.transactions && this.transactions.length > 0) {
          if (!this.includeVoidedTransactions) {
            this.transactions = this.transactions.filter(a => !a.voidedOn);
          }
          if (this.showOnlyHoldTransactions) {
            this.transactions = this.transactions.filter(a => !a.hold);
          }
          // Need more info on pagination
          // this.transactionsCurrentPage = 1;
          // this.transactionsTotalCount = 4;
          this.transactions = lodash.orderBy(this.transactions, ['postedOn', 'createdOn'], ['desc', 'desc']);
          this.transactions = this.transactions.map(tx => {
            tx.isCollapsed = true;
            return tx;
          });
        }
        this.isBusy = false;
      });
    } catch (error) {
      this.isBusy = false;
      console.log('Error in fetching Transactions', error);
    }
  }

  voidAdditionalTransactions(idEmmitter: IdEmmitter) {
    if (idEmmitter.processType == ProcessType.Payables) {
      this.transactions.map(t => {
        if (t.batchId == idEmmitter.id) {
          t.voidedOn = new Date().toString();
          t.voidedBy = this.coreService.getCurrentUsersLogInCookie();
        }
      });
    }
    if (idEmmitter.processType == ProcessType.Transaction) {
      this.transactions.map(t => {
        if (t.id == idEmmitter.id) {
          t.voidedOn = new Date().toString();
          t.voidedBy = this.coreService.getCurrentUsersLogInCookie();
        }
      });
    }
    this.getAllTransactions();
  }

  applyFilters() {
    this.displayRunningBalance = !this.minDate && !this.maxDate;
    if (this.selectedAccountId) {
      this.getTransactions(this.selectedAccountId, '', pageNext, this.ledgerPageSize.value);
    } else {
      this.getAllTransactionsDateRange();
    }
    this.showFilters = false;
  }

  clearDates() {
    this.minDate = null;
    this.maxDate = null;
  }

  print() {
    console.log('print ledger');
  }
}
