<mat-card class="knso-card subledger-mapping-edit-full-container">
    <mat-card-header>       
        <mat-toolbar>
        <span class="headerText">{{subledgerMap.name}}: Categories</span>
        <button mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
    </mat-card-header>    
    <mat-card-content>
      <div  #scrollDiv (scroll)="contentScroll($event)" class="mat-card-content-content">
        <ng-container *ngIf="sectionIsVisible(categories.chargeCategories)">
          <span class="sectionHead">Charge & Adjustment Categories
            <mat-icon [class]="getAddBtnClass()" (click)="newCategory(categories.chargeCategories)" >add_circle</mat-icon>
          </span>         
          <mat-accordion class="example-headers-align" multi>
              <!-- #enddocregion multi -->
                <span [class]="getPanelClass()">
                <mat-expansion-panel 
                  [expanded]="panelIsOpen(i,categories.chargeCategories)"
                  (opened)="panelOpen(i,categories.chargeCategories)" 
                  (closed)="panelClosed(i,categories.chargeCategories)"
                  *ngFor="let panel of knsoPanelsCharges; let i = index;">
                     <mat-expansion-panel-header *ngIf="panelIsVisible(i,categories.chargeCategories)">
                      <mat-panel-title (keydown.Space)="$event.stopImmediatePropagation();" (click)="$event.preventDefault()">
                        <span *ngIf="!panel.isEdit">{{panel.title}}</span>
                        <span *ngIf="panel.isEdit">
                          <mat-form-field class="exp-panel-input" appearance="outline">
                            <mat-label flex="20">Category Name:</mat-label>
                            <input 
                              matInput 
                              ngModel 
                              [(ngModel)]="panel.title" 
                              #name="ngModel"
                              (click)="preventDefaultAction($event)"
                              name="categoryName" 
                              type="text" 
                              aria-label="Category Name">
                          </mat-form-field>                      
                        </span> 
                      </mat-panel-title>
                      <mat-panel-description >        
                        <span class="action-btns" (click)="preventDefaultAction($event)">
                          <mat-icon [class]="getActionBtnClass()" *ngIf="!panel.isEdit" (click)="editCategory(panel)">create</mat-icon>
                          <mat-icon [class]="getActionBtnClass()" *ngIf="!panel.isEdit" (click)="cloneCategory(panel,categories.chargeCategories)">file_copy</mat-icon>
                          <mat-icon [class]="getActionBtnClass( null, null, 'delete')"  *ngIf="!panel.isEdit" (click)="deleteCategory(i,categories.chargeCategories)">delete_outline</mat-icon>
                          <mat-icon [class]="getActionBtnClass( null, null, 'save')" *ngIf="panel.isEdit" (click)="panel.isEdit = false; saveCategory(panel);">check_circle</mat-icon>
                          <mat-icon [class]="getActionBtnClass( null, null, 'cancel')" *ngIf="panel.isEdit" (click)="panel.isEdit = false; cancelEditCategory(panel, categories.chargeCategories, i)">cancel</mat-icon>
                      </span>
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <sub-categories
                      *ngIf="panelIsVisible(i,categories.chargeCategories)" 
                      (save)="saveSubCategory($event)"
                      (edit)="editSubCategory(categories.chargeCategories, i)"
                      (cancel)="cancelSubCategory()"
                      [otherElementInEdit]="isCategoryEdit()"                  
                      [subCategory]="panel.contentData"
                      [categoryGroup]="categories.chargeCategories"
                      [generalLedgerAccounts]="generalLedgerAccounts">
                    </sub-categories>                 
                </mat-expansion-panel>
              </span>
          </mat-accordion>
        </ng-container>
        <ng-container *ngIf="sectionIsVisible(categories.creditCategories)">
          <span class="sectionHead" >Payment Categories 
            <mat-icon [class]="getAddBtnClass()" (click)="newCategory(categories.creditCategories)" >add_circle</mat-icon>
          </span>
          <mat-accordion class="example-headers-align" multi> 
          <!-- #enddocregion multi -->
            <span [class]="getPanelClass()">
              <mat-expansion-panel 
                [expanded]="panelIsOpen(i,categories.creditCategories)"
                (opened)="panelOpen(i,categories.creditCategories)" 
                (closed)="panelClosed(i,categories.creditCategories)" 
                *ngFor="let panel of knsoPanelsPayments; let i = index;">
                <mat-expansion-panel-header>
                  <mat-panel-title (keydown.Space)="$event.stopImmediatePropagation();" (click)="$event.preventDefault()">
                    <span *ngIf="!panel.isEdit">{{panel.title}}</span>
                    <span *ngIf="panel.isEdit">
                      <mat-form-field class="exp-panel-input" appearance="outline">
                        <mat-label flex="20">Category Name:</mat-label>
                        <input 
                          matInput 
                          ngModel 
                          [(ngModel)]="panel.title" 
                          #name="ngModel"
                          (click)="preventDefaultAction($event)"
                          name="categoryName" 
                          type="text" 
                          aria-label="Category Name">
                      </mat-form-field>                      
                    </span> 
                  </mat-panel-title>
                  <mat-panel-description >        
                    <span class="action-btns" (click)="preventDefaultAction($event)">
                    <mat-icon [class]="getActionBtnClass()" *ngIf="!panel.isEdit" (click)="editCategory(panel)">create</mat-icon>
                    <mat-icon [class]="getActionBtnClass()" *ngIf="!panel.isEdit" (click)="cloneCategory(panel,categories.creditCategories)">file_copy</mat-icon>
                    <mat-icon [class]="getActionBtnClass( null, null, 'delete')" *ngIf="!panel.isEdit" (click)="deleteCategory(i,categories.creditCategories)">delete_outline</mat-icon>
                    <mat-icon [class]="getActionBtnClass( null, null, 'save')" *ngIf="panel.isEdit" (click)="panel.isEdit = false; saveCategory(panel);">check_circle</mat-icon>
                    <mat-icon [class]="getActionBtnClass( null, null, 'cancel')" *ngIf="panel.isEdit" (click)="panel.isEdit = false; cancelEditCategory(panel, categories.creditCategories, i)">cancel</mat-icon>
                  </span>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <sub-categories 
                  (save)="saveSubCategory($event)"
                  (edit)="editSubCategory(categories.creditCategories, i)"
                  (cancel)="cancelSubCategory()"
                  [otherElementInEdit]="isCategoryEdit()"                  
                  [subCategory]="panel.contentData"
                  [categoryGroup]="categories.creditCategories"
                  [generalLedgerAccounts]="generalLedgerAccounts">
                </sub-categories>
              </mat-expansion-panel>
            </span>
          </mat-accordion>
        </ng-container>
        <ng-container *ngIf="sectionIsVisible(categories.systemCategories)">    
          <span class="sectionHead">System Categories 
          <mat-icon [class]="getAddBtnClass()" (click)="newCategory(categories.systemCategories)" >add_circle</mat-icon>
          </span>
          <mat-accordion class="example-headers-align" multi>
          <!-- #enddocregion multi -->
            <span [class]="getPanelClass()">
              <mat-expansion-panel
                [expanded]="panelIsOpen(i,categories.systemCategories)"
                (opened)="panelOpen(i,categories.systemCategories)" 
                (closed)="panelClosed(i,categories.systemCategories)" 
                *ngFor="let panel of knsoPanelsSystem; let i = index;">
                <mat-expansion-panel-header>
                  <mat-panel-title (keydown.Space)="$event.stopImmediatePropagation();" (click)="$event.preventDefault()">
                    <span *ngIf="!panel.isEdit">{{panel.title}}</span>
                    <span *ngIf="panel.isEdit">
                      <mat-form-field class="exp-panel-input" appearance="outline">
                        <mat-label flex="20">Category Name:</mat-label>
                        <input 
                          matInput 
                          ngModel 
                          [(ngModel)]="panel.title" 
                          #name="ngModel"
                          (click)="preventDefaultAction($event)"
                          name="categoryName" 
                          type="text" 
                          aria-label="Category Name">
                      </mat-form-field>                      
                    </span> 
                  </mat-panel-title>
                  <mat-panel-description >        
                    <span class="action-btns" (click)="preventDefaultAction($event)">
                      <mat-icon [class]="getActionBtnClass(panel,categories.systemCategories)" *ngIf="!panel.isEdit" (click)="editCategory(panel,categories.systemCategories)">create</mat-icon>
                      <mat-icon [class]="getActionBtnClass()" *ngIf="!panel.isEdit" (click)="cloneCategory(panel,categories.systemCategories)">file_copy</mat-icon>
                      <mat-icon [class]="getActionBtnClass(panel,categories.systemCategories, 'delete')" *ngIf="!panel.isEdit" (click)="deleteCategory(i,categories.systemCategories)">delete_outline</mat-icon>
                      <mat-icon [class]="getActionBtnClass( null, null, 'save')" *ngIf="panel.isEdit" (click)="panel.isEdit = false; saveCategory(panel);">check_circle</mat-icon>
                      <mat-icon [class]="getActionBtnClass( null, null, 'cancel')" *ngIf="panel.isEdit" (click)="panel.isEdit = false; cancelEditCategory(panel, categories.systemCategories, i)">cancel</mat-icon>
                    </span>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <sub-categories 
                  (save)="saveSubCategory($event)"
                  (edit)="editSubCategory(categories.systemCategories, i)"
                  (cancel)="cancelSubCategory()"
                  [otherElementInEdit]="isCategoryEdit()"                  
                  [subCategory]="panel.contentData"
                  [categoryGroup]="categories.systemCategories"
                  [generalLedgerAccounts]="generalLedgerAccounts">
                </sub-categories>
              </mat-expansion-panel>
            </span>
          </mat-accordion>          
        </ng-container>    
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button
      mat-flat-button
      (click)="closeDialog()"  
      [class]="getDoneButtonClass()" >
      Done
      </button>
    </mat-card-actions>
</mat-card> 
