import {Component, Input, OnInit} from '@angular/core';
import {GlobalModalChild, GlobalModalParent} from 'src/app/kanso-common/core/service';
import {VoidOn} from '../../../vendor.service';
import {VendorTransaction} from 'src/app/custom/accounting/service/accounting-models';

@Component({
  selector: 'app-void-transaction-popup',
  templateUrl: './void-transaction-popup.component.html',
  styleUrls: ['./void-transaction-popup.component.scss'],
})
export class VoidTransactionPopupComponent implements GlobalModalChild<VoidOn>, VoidOn {
  @Input() transaction: VendorTransaction;
  // eslint-disable-next-line
  @Input() updateFunction: Function;
  globalModalParent: GlobalModalParent;
  voidReason = '';
  // eslint-disable-next-line
  constructor() {}

  closeDialog() {
    this.globalModalParent.closePopup();
  }

  completeAction() {
    this.updateFunction(this.voidReason);
    this.closeDialog();
  }
}
