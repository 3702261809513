import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-accounting-header',
  templateUrl: './accounting-header.component.html',
  styleUrls: ['./accounting-header.component.scss'],
})
export class AccountingHeaderComponent implements OnInit {
  // eslint-disable-next-line
  constructor() {}

  // eslint-disable-next-line
  ngOnInit(): void {}
}

// import { Component } from '@angular/core';
// import { HdsTab } from '../../../housingCore/views/hds-tabset/hds-tabset.component';

// @Component({
//   selector: 'accounting-header',
//   templateUrl: './accounting-header.component.html',
//   styleUrls: ['./accounting-header.component.scss']
// })
// export class AccountingHeaderComponent {

//   setupTabs: HdsTab[] = [];

//   constructor() {
//     this.setTabs();
//   }

//   setTabs() {
//     this.setupTabs = [
//       {
//         'heading': `General Ledger Accounts`,
//         'id': 'GeneralLedger',
//         'routerLink': ['/configuration', 'setup', 'accounting','general-ledger' ]
//       },
//       {
//         'heading': `Subledger Mapping`,
//         'id': 'SubledgerMapping',
//         'routerLink': ['/configuration', 'setup', 'accounting','subledger-mapping']
//       }
//     ]
//   }

// }
