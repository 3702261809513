import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {GlobalModalChild, GlobalModalParent} from '../../../../../kanso-common/core/service/globalmodal.service';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ISubLedgerMap, GeneralLedgerAccount} from '../../../../accounting/service/accounting-models';
import {SubledgerMapModalData} from '../../models/accounting-setup.models';

@Component({
  selector: 'subledger-mapping-edit',
  templateUrl: './subledger-mapping-edit.component.html',
  styleUrls: ['./subledger-mapping-edit.component.scss'],
})
export class SubledgerMappingEditComponent implements GlobalModalChild<SubledgerMapModalData>, SubledgerMapModalData {
  @Input() editMode: boolean;
  // eslint-disable-next-line
  @Input() updateFunction: Function;

  _generalLedgerAccounts: GeneralLedgerAccount[];
  @Input() set generalLedgerAccounts(glAccounts: GeneralLedgerAccount[]) {
    this._generalLedgerAccounts = glAccounts;
    this.filteredGlAccounts$ = this.glAccountFilter.valueChanges.pipe(
      startWith(''),
      map(accountName => this._filterAccounts(accountName))
    );
    this._setGlAccount();
  }

  get generalLedgerAccounts() {
    return this._generalLedgerAccounts;
  }

  _subledgerMap: ISubLedgerMap;
  @Input() set subledgerMap(selectedMap: ISubLedgerMap) {
    this._subledgerMap = selectedMap;
    this.editSubledgerMap = Object.assign({}, selectedMap);
    this._setGlAccount();
  }

  get subledgerMap() {
    return this._subledgerMap;
  }

  editSubledgerMap: ISubLedgerMap;
  globalModalParent: GlobalModalParent;
  glAccountFilter = new FormControl();
  glAccount = new FormControl(null);
  filteredGlAccounts$: Observable<GeneralLedgerAccount[]>;
  selected: string;

  private _setGlAccount() {
    if (this.generalLedgerAccounts && this.editSubledgerMap) {
      this.glAccount.setValue(this.glAccount.pristine ? this.editSubledgerMap.glAccount : this.glAccount.value);
    }
  }

  private _filterAccounts(filterValue?: string): GeneralLedgerAccount[] {
    return filterValue
      ? this.generalLedgerAccounts.filter(account => account.name.toLowerCase().includes(filterValue.toLowerCase())).sort(this._sortByName)
      : this.generalLedgerAccounts.sort(this._sortByName);
  }

  private _sortByName = (a: GeneralLedgerAccount, b: GeneralLedgerAccount) => {
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();
    switch (true) {
      case aName > bName:
        return 1;
      case aName < bName:
        return -1;
      default:
        return 0;
    }
  };

  compareGlAccounts(o1: string, o2: string) {
    if (o1 === o2) {
      return true;
    } else {
      return false;
    }
  }

  getHeaderText() {
    return `${this.editSubledgerMap._id ? 'Edit' : 'New'} Subledger Map`;
  }

  closeDialog() {
    this.globalModalParent.closePopup();
  }

  saveMap() {
    this.subledgerMap = Object.assign(this.subledgerMap, this.editSubledgerMap);
    this.subledgerMap.glAccount = this.glAccount.value;
    this.updateFunction();
    this.closeDialog();
  }
}
