import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {GlobalModalChild, GlobalModalParent} from '../../../../kanso-common/core/service/globalmodal.service';
import {
  VendorTransaction,
  VendorAdjustmentModalData,
  VendorAdjustmentInput,
  ChargeType,
  ChargeGroup,
} from '../../../accounting/service/accounting-models';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {VendorService} from '../../vendor.service';
import moment from 'moment';

@Component({
  selector: 'app-vendor-adjustment',
  templateUrl: './vendor-adjustment.component.html',
  styleUrls: ['./vendor-adjustment.component.scss'],
})
export class VendorAdjustmentComponent implements GlobalModalChild<VendorAdjustmentModalData>, VendorAdjustmentModalData {
  vendorAdjustmentInput: VendorAdjustmentInput;
  vendorLedgerReport: any;
  // eslint-disable-next-line
  @Input() updateFunction: Function;
  globalModalParent: GlobalModalParent;
  filteredAccounts$: Observable<any>;
  accountFilter = new FormControl('');
  subledgers$: Observable<ChargeType[]>;
  subledgerMapFilter = new FormControl();
  chargeTypes: ChargeType[];
  chargeGroup: ChargeGroup;
  loggedInUser: string;
  chargeGroupList;

  constructor(private vendorService: VendorService) {}

  ngOnInit(): void {
    this.getAccts();
    this.getSubledgers(null);
    this.getCurrentUsersLogInCookie();
  }

  closeDialog() {
    this.globalModalParent.closePopup();
  }

  saveAdjustment(vendorId) {
    const fileredChargeGroup = this.chargeGroupList.filter(x =>
      x.chargeGroup.chargeTypes.some(c => c.id == this.vendorAdjustmentInput.type)
    );

    const CreateVendorTransactionCommandInput = {
      customerId: sessionStorage.CUSTOMERID,
      siteId: sessionStorage.SITEID,
      vendorId: vendorId,
      vendorAccountId: this.vendorAdjustmentInput.account,
      chargeGroupId: fileredChargeGroup[0].chargeGroup.id,
      chargeTypeId: this.vendorAdjustmentInput.type,
      description: this.vendorAdjustmentInput.description,
      amount: this.vendorAdjustmentInput.amount,
      postedOn: moment(this.vendorAdjustmentInput.postedOn).format('MM/DD/YYYY'),
      createdBy: this.loggedInUser,
    };
    this.updateFunction(CreateVendorTransactionCommandInput);
    this.closeDialog();
  }

  private getAccts() {
    this.filteredAccounts$ = this.accountFilter.valueChanges.pipe(
      startWith(null),
      map(value => this._filterAccounts(value))
    );
  }

  private _filterAccounts(filterValue?: string): any {
    return filterValue
      ? this.vendorLedgerReport.filter(account => account.node.displayName.toLowerCase().includes(filterValue.toLowerCase()))
      : this.vendorLedgerReport;
  }

  private getSubledgers(subledgerMappingId) {
    if (subledgerMappingId) {
      this.vendorService.getSubledgerChargeTypes(sessionStorage.SITEID, subledgerMappingId).subscribe(
        response => {
          this.chargeGroupList = response[0].data.subledgerMappings.nodes[0].subledgerChargeGroups;
          this.chargeTypes = [];
          for (const group of this.chargeGroupList) {
            for (const type of group.chargeGroup.chargeTypes) {
              this.chargeTypes.push(type);
            }
            console.log('group', group);
          }
          //this.chargeTypes = response[0].data.subledgerMappings.nodes[0].subledgerChargeGroups[0].chargeGroup.chargeTypes;
          this.subledgers$ = this.subledgerMapFilter.valueChanges.pipe(
            startWith(null),
            map(mapName => this._filterMaps(mapName))
          );
        },
        error => {
          console.log(`Error fetching subledger charge groups`);
        }
      );
    }
  }

  private _filterMaps(filterValue?: string): ChargeType[] {
    return this.chargeTypes;
  }

  getCurrentUsersLogInCookie() {
    const cookies = document.cookie.split(';');
    let usersAuthCookie;
    for (const cookie of cookies) {
      if (cookie.includes('LastAuthUser')) {
        //found the cookie of the user
        usersAuthCookie = cookie;
      }
    }
    const arrayOfCookieStrings = usersAuthCookie.split('=');
    this.loggedInUser = arrayOfCookieStrings[1];
  }
}
