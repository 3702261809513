import {Component, Injector, Input, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, FormArray, Validators, NgForm, ControlContainer} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HousingServices} from '../../housing-core/services/housing-services.service';
import {Program, Authority, EnhancedRentTransactionsSchema, ProgramModalData} from '../../housing-core/services/housing-models';
import {AccountingService} from '../../accounting/service/accounting.service';
import {UserService} from '../../../core/user/user.service';
import {CoreService} from '../../../core/service/core.service';
import {GlobalModalChild, GlobalModalParent} from '../../../kanso-common/core/service/globalmodal.service';
import {CustomAttributesComponent} from '../../../kanso-common/core/components/custom-attributes/custom-attributes.component';
import {KansoActionResult, CustomAttributes} from '../../../core/service/core-models';
import {ISubLedgerMap, AccountDefinition, AccountDefinitionType} from '../../accounting/service/accounting-models';
import {GlobalModalService} from '../../../kanso-common/core/service/globalmodal.service';
import {AccountActivityRuleComponent} from '../account-activity-rule/account-activity-rule.component';
import {AccountSetupComponent} from '../account-setup/account-setup.component';
import {RentcalcOptionPopupComponent} from '../rentcalc-option-popup/rentcalc-option-popup.component';
import _ from 'lodash';

@Component({
  selector: 'program-setup',
  templateUrl: './program-setup.component.html',
  styleUrls: ['./program-setup.component.scss'],
  providers: [{provide: ControlContainer, useExisting: NgForm}],
})
export class ProgramSetupComponent implements GlobalModalChild<ProgramModalData>, ProgramModalData {
  @ViewChild('editProgramForm') editProgramForm;
  @Input() selectedProgram: Program;
  @Input() editMode: boolean;
  // eslint-disable-next-line
  updateFunction: Function;
  selectedChartOfAccounts: ISubLedgerMap;
  selectedAccountDef: AccountDefinition;
  housingAuthority: Authority;
  subLedgerMaps: ISubLedgerMap[];
  globalModalParent: GlobalModalParent;
  workOrderAutoChargeConfiguration;
  user;
  currentUserEmail;
  query = {
    filterSet: {},
  };
  tenantLedgers;
  landlordLedger;
  utilityLedger;
  maintenanceLedger;
  otherLedger;
  enhancedRentBehaviorTransactionsForm: FormGroup[] = [];
  enhancedRentBehaviorAdjustmentsForm: FormGroup[] = [];
  passbookRateFormGroup: FormGroup;
  minTTPFormGroup: FormGroup;
  allowedTransactionLedgerAccounts = [];
  allowedAdjustmentLedgerAccounts = [];
  allowedERBTransactionTypes = [];
  allowedERBTAdjustmentTypes = [];
  updatedProgram;
  selectedRentCalcOption;
  selectedRentCalcDetails = [];
  loading = true;
  isSaving = false;
  generalInfoPanelOpenState = true;
  certificationInfoPanelOpenState = false;
  customAttributesInfoPanelOpenState = false;
  monthlyChargeInfoPanelOpenState = false;
  accountingInfoPanelOpenState = false;
  chargeConfigurationInfoPanelOpenState = false;
  tenantLedgerPanelOpenState = false;
  landlordLedgerPanelOpenState = false;
  utilityLedgerPanelOpenState = false;
  maintenanceLedgerPanelOpenState = false;
  otherLedgerPanelOpenState = false;
  monthlyTransactionBehaviorPanelOpenState = false;
  customAttributesActive: boolean;
  loggedInUser;
  customAttributesTemplates: CustomAttributes[];

  programTypes: string[] = [
    'Assistance',
    'Home Ownership',
    'Lease Purchase',
    'Loan',
    'Low Rent',
    'Misc',
    'Public Housing',
    'Rural Development',
    'Tax Credit',
  ];

  rentCalcProgramTypes: string[] = [
    'Assistance',
    'Home Ownership',
    'Lease Purchase',
    'Low Rent',
    'Public Housing',
    'Rural Development',
    'Tax Credit',
  ];

  eRBAdjustmentform = this.fb.group({
    eRBAdjustments: this.fb.array([]),
  });
  eRBTransactionform = this.fb.group({
    eRBTransactions: this.fb.array([]),
  });

  constructor(
    protected route: ActivatedRoute,
    public housingServices: HousingServices,
    public accountingService: AccountingService,
    public userService: UserService,
    protected router: Router,
    public globalModalService: GlobalModalService,
    protected injector: Injector,
    public coreService: CoreService,
    private fb: FormBuilder,
    private formBuilder: FormBuilder
  ) {
    this.loading = true;
    this.userService.checkIfAuthorized().subscribe(user => {
      this.user = user;
      this.currentUserEmail = user.email;
    });
    this.getCurrentUsersLogInCookie();
    this.coreService.getCustomAttributesByEntity('Program').subscribe(result => {
      this.customAttributesTemplates = result.results.filter(attr => attr.active);
      if (this.customAttributesTemplates.filter(attr => attr.required).length > 0) {
        this.customAttributesInfoPanelOpenState = true;
      }
    });
  }

  ngOnInit(): void {
    const initialPassbookRate =
      this.selectedProgram.rentCalc && this.selectedProgram.rentCalc.imputedAssetRate
        ? this.selectedProgram.rentCalc.imputedAssetRate
        : null;
    this.passbookRateFormGroup = this.formBuilder.group({
      imputedAssetRate: [initialPassbookRate, [Validators.min(0), Validators.max(0.9999), Validators.pattern(/^\d+(\.\d{1,4})?$/)]],
    });
    this.setMinTTPFormGroup();
    this.selectedProgram = this.setDefaultHotma(this.selectedProgram);
    this.splitLedgerTypes();
    if (!this.selectedProgram.enhancedRentToggle) {
      this.selectedProgram.enhancedRentToggle = false;
    }
    this.housingServices.getHousingAuthority().subscribe(result => {
      this.housingAuthority = result.body;
      this.accountingService.getAllSubLedgerMaps().subscribe((results: KansoActionResult<any>) => {
        if (results.statusCode == 200) {
          this.subLedgerMaps = results.body;
          if (this.selectedProgram.autoChargeConfigurations && this.selectedProgram.autoChargeConfigurations.length > 0) {
            const workOrderAutoChargeConfiguration = this.selectedProgram.autoChargeConfigurations.find(
              config => config.type === 'Work Order Tenant Charge'
            );
            this.workOrderAutoChargeConfiguration = workOrderAutoChargeConfiguration;
            if (this.workOrderAutoChargeConfiguration) {
              this.selectedAccountDef = this.selectedProgram.accountDefinitions.find(
                accountDef => accountDef.chartOfAccounts == workOrderAutoChargeConfiguration.chartOfAccountsId
              );
              this.chartOfAccountsSelected(this.selectedAccountDef);
            }
          }
          this.loadEnhancedRentTransactions();

          if (!this.selectedProgram._id) {
            // New program
            this.selectedProgram.rentCalc = !_.isNil(this.housingAuthority.rentCalc)
              ? this.housingAuthority.rentCalc
              : this.selectedProgram.rentCalc;
            if (this.selectedProgram.rentCalc) {
              this.selectedProgram.rentCalc._id = null;
            }
            if (this.selectedProgram.type === 'Loan') {
              delete this.selectedProgram.rentCalc.calcKey;
            }
            if (this.selectedProgram.rentCalc) {
              this.selectedProgram.rentCalc.lateFeeAmount = 0;
            }
            this.selectedProgram.accountDefinitions.push({
              name: 'Accounts Receivable',
              activityRules: [],
              isAR: true,
              isPrimary: true,
              isPrincipal: false,
              accountDefinitionType: AccountDefinitionType.Tenant,
            });
            this.accountingInfoPanelOpenState = true;
            this.tenantLedgerPanelOpenState = true;
            this.splitLedgerTypes();
            if (this.housingAuthority.incomeLimitAreas.length > 0) {
              if (this.selectedProgram.rentCalc) {
                this.selectedProgram.rentCalc.incomeLimitArea = this.housingAuthority.incomeLimitAreas[0]._id;
              }
              if (this.selectedProgram.type === 'Tax Credit') {
                this.selectedProgram.rentCalc.taxCreditIncomeLimitArea = this.housingAuthority.incomeLimitAreas[0]._id;
              }
            }

            if (this.selectedProgram.rentCalc) {
              this.selectedProgram.rentCalc.fundingSource = this.housingAuthority.fundingSources[0]; // initialize to the first in the list
            }
          }
          this.loading = false;
        } else {
          console.log('an error has occured', results.message);
          this.loading = false;
        }
      });
    });
    this.loading = false;
  }

  setDefaultHotma(selectedProgram) {
    if (selectedProgram.rentCalc && !selectedProgram.rentCalc.requiresHotmaUpdates) {
      selectedProgram.rentCalc.requiresHotmaUpdates = false;
    }
    return selectedProgram;
  }

  programTypeSelected(event) {
    if (event.value !== null) {
      this.setMinTTPFormGroup();
    }
  }

  setMinTTPFormGroup() {
    if (this.selectedProgram.type == 'Public Housing') {
      const initialMinTTP =
        this.selectedProgram.rentCalc && this.selectedProgram.rentCalc.minTTP ? this.selectedProgram.rentCalc.minTTP : 0;
      this.minTTPFormGroup = this.formBuilder.group({
        minTTP: [initialMinTTP, [Validators.min(0), Validators.max(51), Validators.pattern(/^[^.]*$/), Validators.required]],
      });
    }
  }

  closeDialog() {
    this.updateFunction('cancel');
    this.globalModalParent.closePopup();
  }

  chartOfAccountsSelected(selectedAccountDefinition: AccountDefinition) {
    if (selectedAccountDefinition !== undefined) {
      this.selectedChartOfAccounts = this.subLedgerMaps.find(chart => chart._id === selectedAccountDefinition.chartOfAccounts);
    } else {
      this.clearWorkOrderAutoChargeConfiguration();
    }
  }

  public workOrderObjectComparisonFunction = function(option, value): boolean {
    if (option == undefined || value == undefined) {
      return option === value;
    }
    return (
      option.subCategoryName === value.subCategoryName &&
      option.accountDefinition === value.accountDefinition &&
      option.categoryName === value.categoryName &&
      option.subCategoryId === value.subCategoryId &&
      option.type === value.type &&
      option.categoryId === value.categoryId &&
      option.chartOfAccountsId === value.chartOfAccountsId &&
      option.chartOfAccountsName === value.chartOfAccountsName
    );
  };

  splitLedgerTypes() {
    this.tenantLedgers = this.selectedProgram.accountDefinitions.filter(a => a.accountDefinitionType == AccountDefinitionType.Tenant);
    this.landlordLedger = this.selectedProgram.accountDefinitions.filter(a => a.accountDefinitionType == AccountDefinitionType.Landlord);
    this.utilityLedger = this.selectedProgram.accountDefinitions.filter(a => a.accountDefinitionType == AccountDefinitionType.Utility);
    this.maintenanceLedger = this.selectedProgram.accountDefinitions.filter(
      a => a.accountDefinitionType == AccountDefinitionType.Maintenance
    );
    this.otherLedger = this.selectedProgram.accountDefinitions.filter(
      a =>
        a.accountDefinitionType == AccountDefinitionType.Other ||
        a.accountDefinitionType == a.accountDefinitionType[AccountDefinitionType.Other]
    );
  }

  changeDisplayedRentCalcOption() {
    this.selectedRentCalcDetails = this.selectedProgram.rentCalc[this.selectedRentCalcOption];
  }

  openRentCalcOptionsDialog(index) {
    const editRentCalcOption = this.selectedProgram.rentCalc[this.selectedRentCalcOption][index];
    // editRentCalcOption.value = editRentCalcOption.value.map(amount => amount / 100);
    this.globalModalService.openModal(RentcalcOptionPopupComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      index: index,
      chargeType: this.selectedRentCalcOption,
      rentCalcOption: editRentCalcOption,
      updateFunction: this.updateRentCalcOption,
    });
  }

  newRentCalcOption() {
    const newRentCalcOption = {
      createdBy: this.loggedInUser,
      createdOn: new Date(),
      effectiveDate: new Date(),
      modifiedBy: null,
      modifiedOn: new Date(),
      value: [],
    };
    this.globalModalService.openModal(RentcalcOptionPopupComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      chargeType: this.selectedRentCalcOption,
      rentCalcOption: newRentCalcOption,
      updateFunction: this.addNewRentCalcOption,
    });
  }

  onPassBookRateChange() {
    if (this.passbookRateFormGroup.valid) {
      this.onCheckPristineForm();
    }
  }

  deleteRentCalcOptions(index) {
    this.selectedProgram.rentCalc[this.selectedRentCalcOption].splice(index, 1);
  }

  addNewRentCalcOption = rentCalcOption => {
    this.selectedProgram.rentCalc[this.selectedRentCalcOption].push(rentCalcOption);
  };

  updateRentCalcOption = (rentCalcOption, index) => {
    this.selectedProgram.rentCalc[this.selectedRentCalcOption][index] = rentCalcOption;
  };

  programSetupFormValid(): boolean {
    return false;
  }

  getCurrentUsersLogInCookie() {
    const cookies = document.cookie.split(';');
    let usersAuthCookie;
    for (const cookie of cookies) {
      if (cookie.includes('LastAuthUser')) {
        //found the cookie of the user
        usersAuthCookie = cookie;
      }
    }
    const arrayOfCookieStrings = usersAuthCookie.split('=');
    this.loggedInUser = arrayOfCookieStrings[1];
  }

  addWorkOrderAutoChargeConfiguration(workOrderAutoChargeConfiguration) {
    if (workOrderAutoChargeConfiguration == undefined) {
      this.clearWorkOrderAutoChargeConfiguration();
    }
  }

  private clearWorkOrderAutoChargeConfiguration() {
    this.selectedChartOfAccounts = this.subLedgerMaps.find(chart => chart._id === 'none');
    this.selectedAccountDef = undefined;
    this.workOrderAutoChargeConfiguration = undefined;
  }

  deleteAccountTemplate(account) {
    this.selectedProgram.accountDefinitions = this.selectedProgram.accountDefinitions.filter(a => a._id !== account._id);
    this.updateTables();
  }

  openNewTenantAccountDialog(type) {
    this.globalModalService.openModal<any>(AccountSetupComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      program: this.selectedProgram,
      accountDefinition: null,
      chartOfAccounts: this.subLedgerMaps,
      editMode: false,
      ledgerType: AccountDefinitionType[type],
      updateFunction: this.updateTables,
    });
  }
  editTenantAccountDialog(a, type) {
    this.globalModalService.openModal<any>(AccountSetupComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      program: this.selectedProgram,
      accountDefinition: a,
      chartOfAccounts: this.subLedgerMaps,
      editMode: true,
      ledgerType: AccountDefinitionType[type],
      updateFunction: this.updateTables,
    });
  }

  editActivityRule(a, rule) {
    const filteredCOA = this.subLedgerMaps.filter(coa => coa._id == a.chartOfAccounts);
    this.globalModalService.openModal<any>(AccountActivityRuleComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      activityRule: rule,
      accountDefinitions: this.selectedProgram.accountDefinitions,
      selectedAccountDefinition: a,
      chartOfAccount: filteredCOA[0],
      allChartOfAccounts: this.subLedgerMaps,
      editMode: true,
      user: this.loggedInUser,
      updateFunction: this.updateTables,
    });
  }

  newActivityRule(accountDefinition: AccountDefinition) {
    const filteredCOA = this.subLedgerMaps.filter(coa => coa._id == accountDefinition.chartOfAccounts);
    this.globalModalService.openModal<any>(AccountActivityRuleComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      activityRule: accountDefinition.activityRules,
      accountDefinitions: this.selectedProgram.accountDefinitions,
      selectedAccountDefinition: accountDefinition,
      chartOfAccount: filteredCOA[0],
      allChartOfAccounts: this.subLedgerMaps,
      user: this.loggedInUser,
      updateFunction: this.updateTables,
    });
  }

  getNonDeletedActivityRules(account) {
    return account.activityRules.filter(rule => rule.deletedOn === null);
  }

  get eRBAdjustments() {
    return this.eRBAdjustmentform.controls.eRBAdjustments as FormArray;
  }

  get eRBTransactions() {
    return this.eRBTransactionform.controls.eRBTransactions as FormArray;
  }

  addERBTransaction(adjustmentFlag) {
    if (adjustmentFlag) {
      const transactionForm = this.fb.group({
        ledgerType: [0, Validators.required],
        accountDefinition: [this.tenantLedgers[0]._id, Validators.required],
        transactionType: [null, Validators.required],
        createdDay: ['1', [Validators.max(28), Validators.min(1)]],
        currentMonth: [true, Validators.required],
        adjustment: [true, Validators.required],
      });
      this.eRBAdjustments.push(transactionForm);
      this.allowedAdjustmentLedgerAccounts.push(this.tenantLedgers);
      this.allowedERBTAdjustmentTypes.push(this.subLedgerMaps.find(s => s._id == this.tenantLedgers[0].chartOfAccounts));
    } else {
      const transactionForm = this.fb.group({
        ledgerType: [0, Validators.required],
        accountDefinition: [this.tenantLedgers[0]._id, Validators.required],
        transactionType: [null, Validators.required],
        createdDay: ['1', [Validators.max(28), Validators.min(1)]],
        currentMonth: [true, Validators.required],
        adjustment: [false, Validators.required],
      });
      this.eRBTransactions.push(transactionForm);
      this.allowedTransactionLedgerAccounts.push(this.tenantLedgers);
      this.allowedERBTransactionTypes.push(this.subLedgerMaps.find(s => s._id == this.tenantLedgers[0].chartOfAccounts));
    }
  }

  deleteERBTransaction(index: number, adjustmentFlag) {
    if (adjustmentFlag) {
      this.eRBAdjustments.removeAt(index);
      this.allowedAdjustmentLedgerAccounts.splice(index, index + 1);
      this.allowedERBTAdjustmentTypes.splice(index, index + 1);
    } else {
      this.eRBTransactions.removeAt(index);
      this.allowedTransactionLedgerAccounts.splice(index, index + 1);
      this.allowedERBTransactionTypes.splice(index, index + 1);
    }
    this.onCheckPristineForm();
  }
  compareWith(x1: any, x2: any): boolean {
    return x1._id == x2._id;
  }

  compareWith2(x1: any, x2: any): boolean {
    return x1 == x2;
  }

  compareWithSubCategory(x1: any, x2: any): boolean {
    return x1.subCategoryName == x2.subCategoryName;
  }

  loadEnhancedRentTransactions() {
    if (!this.selectedProgram.enhancedRentTransactions) {
      this.selectedProgram.enhancedRentTransactions = [];
    }
    const enhancedRentBehaviorTransactions = this.selectedProgram.enhancedRentTransactions.filter(t => t.adjustment == false);
    const enhancedRentBehaviorAdjustments = this.selectedProgram.enhancedRentTransactions.filter(t => t.adjustment == true);
    for (const adjustment of enhancedRentBehaviorAdjustments) {
      const transactionForm = this.fb.group({
        ledgerType: [Number(adjustment.ledgerType), Validators.required],
        accountDefinition: [adjustment.accountDefinition, Validators.required],
        transactionType: [adjustment.transactionType, Validators.required],
        createdDay: [adjustment.createdDay, [Validators.max(31), Validators.min(1)]], // Validators.pattern("^[0-9]*$")
        currentMonth: [adjustment.currentMonth, Validators.required],
        adjustment: [true, Validators.required],
      });
      switch (Number(adjustment.ledgerType)) {
        case AccountDefinitionType.Tenant:
          this.allowedAdjustmentLedgerAccounts.push(this.tenantLedgers);
          break;
        case AccountDefinitionType.Landlord:
          this.allowedAdjustmentLedgerAccounts.push(this.landlordLedger);
          break;
        case AccountDefinitionType.Utility:
          this.allowedAdjustmentLedgerAccounts.push(this.utilityLedger);
          break;
        case AccountDefinitionType.Maintenance:
          this.allowedAdjustmentLedgerAccounts.push(this.maintenanceLedger);
          break;
        case AccountDefinitionType.Other:
          this.allowedAdjustmentLedgerAccounts.push(this.otherLedger);
          break;
        default:
          this.allowedAdjustmentLedgerAccounts.push(this.tenantLedgers);
          break;
      }
      const selectedCoa = this.selectedProgram.accountDefinitions.find(a => a._id == adjustment.accountDefinition);
      this.allowedERBTAdjustmentTypes.push(this.subLedgerMaps.find(s => s._id == selectedCoa.chartOfAccounts));
      this.eRBAdjustments.push(transactionForm);
    }
    for (const transaction of enhancedRentBehaviorTransactions) {
      const transactionForm = this.fb.group({
        ledgerType: [Number(transaction.ledgerType), Validators.required],
        accountDefinition: [transaction.accountDefinition, Validators.required],
        transactionType: [transaction.transactionType, Validators.required],
        createdDay: [transaction.createdDay, [Validators.max(31), Validators.min(1)]], // Validators.pattern("^[0-9]*$")
        currentMonth: [transaction.currentMonth, Validators.required],
        adjustment: [false, Validators.required],
      });
      switch (Number(transaction.ledgerType)) {
        case AccountDefinitionType.Tenant:
          this.allowedTransactionLedgerAccounts.push(this.tenantLedgers);
          break;
        case AccountDefinitionType.Landlord:
          this.allowedTransactionLedgerAccounts.push(this.landlordLedger);
          break;
        case AccountDefinitionType.Utility:
          this.allowedTransactionLedgerAccounts.push(this.utilityLedger);
          break;
        case AccountDefinitionType.Maintenance:
          this.allowedTransactionLedgerAccounts.push(this.maintenanceLedger);
          break;
        case AccountDefinitionType.Other:
          this.allowedTransactionLedgerAccounts.push(this.otherLedger);
          break;
        default:
          this.allowedTransactionLedgerAccounts.push(this.tenantLedgers);
          break;
      }
      const selectedCoa = this.selectedProgram.accountDefinitions.find(a => a._id == transaction.accountDefinition);
      this.allowedERBTransactionTypes.push(this.subLedgerMaps.find(s => s._id == selectedCoa.chartOfAccounts));
      this.eRBTransactions.push(transactionForm);
    }
  }

  onTrackById(index: number, item: FormGroup) {
    return index;
  }

  eRBTLedgerTypeChange(ledgerType, index, adjustmentFlag) {
    let accDef = null;
    if (adjustmentFlag) {
      switch (ledgerType) {
        case AccountDefinitionType.Tenant:
          (<FormArray>this.eRBAdjustmentform.get('eRBAdjustments')).controls[index].get('accountDefinition').setValue(null);
          (<FormArray>this.eRBAdjustmentform.get('eRBAdjustments')).controls[index].get('transactionType').setValue(null);
          this.allowedAdjustmentLedgerAccounts[index] = this.tenantLedgers;
          accDef = this.selectedProgram.accountDefinitions.find(ac => ac._id == this.tenantLedgers[0]._id);
          this.allowedERBTAdjustmentTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.chartOfAccounts);
          break;
        case AccountDefinitionType.Landlord:
          (<FormArray>this.eRBAdjustmentform.get('eRBAdjustments')).controls[index].get('accountDefinition').setValue(null);
          (<FormArray>this.eRBAdjustmentform.get('eRBAdjustments')).controls[index].get('transactionType').setValue(null);
          this.allowedAdjustmentLedgerAccounts[index] = this.landlordLedger;
          accDef = this.selectedProgram.accountDefinitions.find(ac => ac._id == this.landlordLedger[0]._id);
          this.allowedERBTAdjustmentTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.chartOfAccounts);
          break;
        case AccountDefinitionType.Utility:
          (<FormArray>this.eRBAdjustmentform.get('eRBAdjustments')).controls[index].get('accountDefinition').setValue(null);
          (<FormArray>this.eRBAdjustmentform.get('eRBAdjustments')).controls[index].get('transactionType').setValue(null);
          this.allowedAdjustmentLedgerAccounts[index] = this.utilityLedger;
          accDef = this.selectedProgram.accountDefinitions.find(ac => ac._id == this.utilityLedger[0]._id);
          this.allowedERBTAdjustmentTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.chartOfAccounts);
          break;
        case AccountDefinitionType.Other:
          (<FormArray>this.eRBAdjustmentform.get('eRBAdjustments')).controls[index].get('accountDefinition').setValue(null);
          (<FormArray>this.eRBAdjustmentform.get('eRBAdjustments')).controls[index].get('transactionType').setValue(null);
          this.allowedAdjustmentLedgerAccounts[index] = this.otherLedger;
          accDef = this.selectedProgram.accountDefinitions.find(ac => ac._id == this.otherLedger[0]._id);
          this.allowedERBTAdjustmentTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.chartOfAccounts);
          break;
        default:
          this.allowedAdjustmentLedgerAccounts[index] = this.tenantLedgers;
          break;
      }
    } else {
      switch (ledgerType) {
        case AccountDefinitionType.Tenant:
          (<FormArray>this.eRBTransactionform.get('eRBTransactions')).controls[index].get('accountDefinition').setValue(null);
          (<FormArray>this.eRBTransactionform.get('eRBTransactions')).controls[index].get('transactionType').setValue(null);
          this.allowedTransactionLedgerAccounts[index] = this.tenantLedgers;
          accDef = this.selectedProgram.accountDefinitions.find(ac => ac._id == this.tenantLedgers[0]._id);
          this.allowedERBTransactionTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.chartOfAccounts);
          break;
        case AccountDefinitionType.Landlord:
          (<FormArray>this.eRBTransactionform.get('eRBTransactions')).controls[index].get('accountDefinition').setValue(null);
          (<FormArray>this.eRBTransactionform.get('eRBTransactions')).controls[index].get('transactionType').setValue(null);
          this.allowedTransactionLedgerAccounts[index] = this.landlordLedger;
          accDef = this.selectedProgram.accountDefinitions.find(ac => ac._id == this.landlordLedger[0]._id);
          this.allowedERBTransactionTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.chartOfAccounts);
          break;
        case AccountDefinitionType.Utility:
          (<FormArray>this.eRBTransactionform.get('eRBTransactions')).controls[index].get('accountDefinition').setValue(null);
          (<FormArray>this.eRBTransactionform.get('eRBTransactions')).controls[index].get('transactionType').setValue(null);
          this.allowedTransactionLedgerAccounts[index] = this.utilityLedger;
          accDef = this.selectedProgram.accountDefinitions.find(ac => ac._id == this.utilityLedger[0]._id);
          this.allowedERBTransactionTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.chartOfAccounts);
          break;
        case AccountDefinitionType.Other:
          (<FormArray>this.eRBTransactionform.get('eRBTransactions')).controls[index].get('accountDefinition').setValue(null);
          (<FormArray>this.eRBTransactionform.get('eRBTransactions')).controls[index].get('transactionType').setValue(null);
          this.allowedTransactionLedgerAccounts[index] = this.otherLedger;
          accDef = this.selectedProgram.accountDefinitions.find(ac => ac._id == this.otherLedger[0]._id);
          this.allowedERBTransactionTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.chartOfAccounts);
          break;
        default:
          this.allowedTransactionLedgerAccounts[index] = this.tenantLedgers;
          break;
      }
    }
    this.onCheckPristineForm();
  }

  eRBTAccountDefinitionChange(accDefId, index, adjustmentFlag) {
    if (adjustmentFlag) {
      (<FormArray>this.eRBAdjustmentform.get('eRBAdjustments')).controls[index].get('transactionType').setValue(null);
      const accDef = this.selectedProgram.accountDefinitions.find(ac => ac._id == accDefId);
      this.allowedERBTAdjustmentTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.chartOfAccounts);
    } else {
      (<FormArray>this.eRBTransactionform.get('eRBTransactions')).controls[index].get('transactionType').setValue(null);
      const accDef = this.selectedProgram.accountDefinitions.find(ac => ac._id == accDefId);
      this.allowedERBTransactionTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.chartOfAccounts);
    }
    this.onCheckPristineForm();
  }

  eRBTDateChanged(t) {
    if (t > 28) {
      t = 28;
    }
    if (t <= 0) {
      t = 1;
    }
    this.onCheckPristineForm();
  }

  eRBTChange() {
    this.onCheckPristineForm();
  }

  updateTables = () => {
    this.saveAccountDefinitions();
  };

  onCheckPristineForm() {
    if (this.editProgramForm.valid) {
      this.editProgramForm.form.pristine = false;
    }
  }

  saveButtonText() {
    return this.isSaving ? 'Saving Program...' : 'Save Program';
  }

  save = () => {
    try {
      this.formatProgram();
      this.updateFunction(this.updatedProgram);
    } catch (error) {
      this.isSaving = false;
      this.coreService.displayError(`Error trying to save Program ${this.updatedProgram.name}: ${error.message}`);
      console.log(`Error trying to save Program: ${JSON.stringify(this.updatedProgram.name)} error: ${error.message}`);
    } finally {
      this.isSaving = false;
      this.globalModalParent.closePopup();
    }
  };

  formatProgram() {
    this.isSaving = true;
    if (this.selectedProgram.rentCalc) {
      this.selectedProgram.rentCalc.imputedAssetRate = this.passbookRateFormGroup.get('imputedAssetRate').value;
      this.selectedProgram.rentCalc.minTTP =
        this.selectedProgram.type == 'Public Housing' ? this.minTTPFormGroup.get('minTTP').value : null;
    }
    this.selectedProgram.isHousingProgram = this.rentCalcProgramTypes.includes(this.selectedProgram.type) ? true : false;
    this.selectedProgram.autoChargeConfigurations =
      this.workOrderAutoChargeConfiguration === undefined ? [] : [this.workOrderAutoChargeConfiguration];
    this.updatedProgram = Object.assign({}, this.selectedProgram);
    const ERBFormValues = [...this.eRBTransactions.value, ...this.eRBAdjustments.value];
    for (const formValue of ERBFormValues) {
      formValue.accountName = this.selectedProgram.accountDefinitions.find(a => a._id == formValue.accountDefinition).name;
    }
    this.updatedProgram.enhancedRentTransactions = ERBFormValues;
  }

  saveAccountDefinitions = () => {
    try {
      this.formatProgram();
      if (!this.updatedProgram._id) {
        this.accountingService.createProgram(this.updatedProgram).subscribe(this.saveProgramHandler);
      } else {
        this.accountingService.updateProgram(this.updatedProgram).subscribe(this.saveProgramHandler);
      }
    } catch (error) {
      this.isSaving = false;
      this.coreService.displayError(`Error trying to save Program and Account Definition: ${error.message}`);
    }
  };

  saveProgramHandler = (response?: KansoActionResult<Program>) => {
    try {
      console.log('Successfully create | update program!', response.body);
      this.selectedProgram = response.body;
      this.splitLedgerTypes();
      this.onCheckPristineForm();
      this.isSaving = false;
    } catch (e) {
      this.coreService.displayError('Error trying to save Program');
    }
  };

  cancel() {
    this.globalModalParent.closePopup();
  }
}
