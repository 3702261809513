<mat-card class="knso-card">
    <mat-card-header>
        <mat-toolbar>
            <span>{{getHeaderText()}}</span>
            <button mat-icon-button (click)="cancel()"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
    </mat-card-header>
    <form #popupForm="ngForm" novalidate>
        <mat-card-content>
            <div class="layout-row">
                <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input 
                        matInput  
                        ngModel 
                        [(ngModel)]="editObject.name"
                        #editNameField="ngModel"
                        name="editNameField"
                        id="nameInput"
                        type="text"
                        required
                        aria-label="Name">
                </mat-form-field>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button 
                type="button"
                mat-flat-button 
                class="hds_button cancel" 
                (click)="cancel()" 
                id="cancelButton">
                Cancel
            </button>
            <button 
                type="button"
                mat-flat-button 
                class="hds_button" 
                (click)="onSubmit()" 
                [disabled]="!popupForm.valid || popupForm.pristine"
                id="saveButton">
                Update
            </button>
        </mat-card-actions>
    </form>
</mat-card>
