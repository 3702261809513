import {Injectable} from '@angular/core';
import * as SSM from 'aws-sdk/clients/ssm';

type Stage = 'dev' | 'prod';
export type ParameterStoreVariables = {
  name: string;
  application?: string;
};

// TODO: We need better demarcation on URLs as this will fail on us quickly (as things are removed and added);
const INTERNAL_SITES = [
  'apple',
  'test',
  'doorways-training',
  'dev',
  'defaults',
  'doorways-dev',
  'middleearth-dev',
  'fargate',
  'release',
  '-dev',
  'qa',
  'reporting',
  'demo',
  'andrew',
  'brian',
  'jeremy',
  'joep',
  'zach',
  'emilio',
  'morgan',
  'haile',
  'local',
];

@Injectable({
  providedIn: 'root',
})
export class ParameterStoreService {
  stage: string;
  ssm: SSM;

  /**
   * Helper function that declares what sites exist as internal (dev) and production (prod)
   * @param location Global window object is scraped for pertinent information
   * @returns {string} Either dev or prod
   */
  private getStage(location: string): Stage {
    return INTERNAL_SITES.some(site => location.includes(site)) ? 'dev' : 'prod';
  }

  /**
   * Function short circuits to return the sessionStorage entry for the given variable or completes the AWS handshake to pull
   * the property from the AWS Parameter Store under the given parameter name.
   *
   * Currently the class handles articulating the parameter name by using the provided environment variables:
   * - stage (prod or dev derived from the database connection string. defaults to 'dev' if no condition matches explicitly)
   * - Application (defaults to 'doorways')
   * - parameter (provided string from developer)
   *
   * @param application {string} Kanso Application
   * @param parameter {string} Parameter Store Entry
   * @returns {string | null} Value of Parameter Store Entry or Null If Parameter Is Not Present
   */
  private async getParameter(application: string, parameter: string): Promise<string | null> {
    try {
      if (sessionStorage.getItem(parameter)) {
        return sessionStorage.getItem(parameter);
      }

      // const {
      //   Parameter: {Value},
      // }: SSM.GetParameterResult = await this.ssm.getParameter({Name: `/${application}/${this.stage}/${parameter}`}).promise();
      // sessionStorage.setItem(parameter, Value);

      return null;
    } catch (err) {
      console.error(`error fetching parameter: ${application}, ${this.stage}, ${parameter}`, err);
      return null;
    }
  }

  // TODO: Change this to a singleton in the new repository once we understand the main "bootstrap" method
  constructor() {
    try {
      this.stage = this.getStage(window.location.origin || 'local');
      this.ssm = new SSM();
    } catch (err) {
      console.error(`
              Failed constructing ParameterStore With Following Information:

              stage: ${this.stage}

              'unset' denotes the environment variable was not present by the name on the environment
          `);
    }
  }

  /**
   * Helper function that returns parameter store values set in session storage or fetched from AWS
   *
   * @param parameters {{ name: string, application?: string }[]} Parameter Store variables wanted to be fetched and saved
   * @returns {string | null} AWS Parameter Store Entries (if applicable)
   */
  public async fetchAndSetParameters(parameters: ParameterStoreVariables[]): Promise<(string | null)[]> {
    return await Promise.all(parameters.map(async parameter => this.getParameter(parameter.application || 'doorways', parameter.name)));
  }

  /**
   * Helper function that returns parameter store values set in session storage or fetched from AWS
   *
   * @param parameters {string[]} Parameter Store variables wanted to be fetched and saved
   * @param application {string} Kanso Application (defaults to 'doorways')
   * @returns {(string|null)} String or null (null if parameter is not found in Parameter Store)
   */
  public async fetchAndSetParameter(parameter: string, application = 'doorways'): Promise<string | null> {
    try {
      if (sessionStorage.getItem(parameter)) {
        return sessionStorage.getItem(parameter);
      }

      // const {
      //   Parameter: {Value},
      // }: SSM.GetParameterResult = await this.ssm.getParameter({Name: `/${application}/${this.stage}/${parameter}`}).promise();
      // sessionStorage.setItem[parameter] = Value;

      // return Value;
      return null;
    } catch (err) {
      console.error(`error fetching parameter: ${application}, ${this.stage}, ${parameter}`, err);
      return null;
    }
  }

  /**
   * Helper function that clears the session storage when invoked.
   * Allowing the end user to quickly fetch updated parameter store values from AWS in case of emergency
   */
  public refreshParameterStoreCache(): void {
    sessionStorage.clear();
  }
}
