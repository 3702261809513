/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import moment from 'moment';

@Component({
  selector: 'app-tollingtimepopup',
  templateUrl: './tollingtimepopup.component.html',
  styleUrls: ['./tollingtimepopup.component.scss'],
})
export class TollingtimepopupComponent {
  tollingTime: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<TollingtimepopupComponent>) {
    const rftaStart = this.data.rftaStart;
    const rftaStop = this.data.rftaStop;
    this.tollingTime = this.getNumberOfDays(rftaStart, rftaStop);
  }

  selectOption(value): void {
    this.dialogRef.close({response: value, daysToIncrease: this.tollingTime});
  }

  getNumberOfDays(IssuedDate, ExpirationDate) {
    let issuedDays = 0;

    if (typeof IssuedDate == 'undefined' || IssuedDate == null) {
      return 0;
    }
    if (typeof ExpirationDate == 'undefined' || ExpirationDate == null) {
      return 0;
    }

    const date1 = moment(IssuedDate);
    const date2 = moment(ExpirationDate);

    if (date1.isSameOrAfter(date2)) {
      return 0;
    }

    issuedDays = date2.diff(date1, 'days');

    return issuedDays;
  }
}
