import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SiteProperties} from './interfaces';

@Injectable({
  providedIn: 'root', // singleton
})
export class SitePropertiesService {
  constructor(private http: HttpClient) {}

  url = '/api/site-properties';

  getSiteProperties(): Observable<SiteProperties> {
    return this.http.get<SiteProperties>(this.url);
  }
}
